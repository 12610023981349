import { calculateAdvertsFormStyle } from './calculateAdvertsFormStyle'
import { Formik, Form, ErrorMessage } from 'formik'
import React, { useRef } from 'react'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { useCalculateAdvertsForm } from './useCalculateAdvertsForm'
import { checkIfAdmin, getFromQuery, useQuery } from '../../../utils/common'
import { useTranslation } from 'react-i18next'
import CustomSelectField from '../CustomSelectField/CustomSelectField'
import BaseNumberInput from '../BaseNumberInput/BaseNumberInput'
import { transactionTypes, propertyTypes, AdvertType, plotTypes } from '../../../types/advert'
import LoadingButton from '../LoadingButton/LoadingButton'
import CustomFlatpickrInput from '../CustomFlatpickrInput/CustomFlatpickrInput'
import Flatpickr from 'react-flatpickr'
import { monthList, dayListShorthand, dayList } from '../../../types/common'
import { calculateAdvertsFormValidation } from '../../../utils/validations/calculateAdvertsFormValidation'
import moment from 'moment'
import DataApi from '../../../api/DataApi'
import { CircularProgress } from '@material-ui/core'

type Props = {
  reset?: boolean
  allLocations?: boolean
  resetButton?: boolean
  onFinish: (params: any, count: number) => void
  beforeSubmit?: () => void
  onFormReset?: () => void
}

const CalculateAdvertsForm = (props: Props) => {
  const classes = calculateAdvertsFormStyle()

  const query = useQuery()
  const t = useTranslation('forms').t
  const tEventList = useTranslation('eventList').t

  const formRef = useRef() as any
  const dateRangeFormRef = useRef() as any

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [subRegionList, setSubRegionList] = React.useState<any[]>([])
  const [townList, setTownList] = React.useState<any[]>([])
  const [districtList, setDistrictList] = React.useState<any[]>([])

  const [chosenRegion, setChosenRegion] = React.useState<string>('')
  const [chosenSubregion, setChosenSubregion] = React.useState<string>('')
  const [chosenTown, setChosenTown] = React.useState<string[]>([])
  const [chosenDistrict, setChosenDistrict] = React.useState<string[]>([])

  const [chosenTransactionType, setChosenTransactionType] = React.useState<string>('')
  const [chosenPropertyType, setChosenPropertyType] = React.useState<string>('')
  const [chosenPropertySubType, setChosenPropertySubType] = React.useState<string>('')

  const [chosenMinPrice, setChosenMinPrice] = React.useState<string>('')
  const [chosenMaxPrice, setChosenMaxPrice] = React.useState<string>('')
  const [chosenMinArea, setChosenMinArea] = React.useState<string>('')
  const [chosenMaxArea, setChosenMaxArea] = React.useState<string>('')
  const [chosenMinPricePerArea, setChosenMinPricePerArea] = React.useState<string>('')
  const [chosenMaxPricePerArea, setChosenMaxPricePerArea] = React.useState<string>('')

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)

  const { 
    regions, assumedUser,
    fetchRegionList, fetchAssumedUser
	} = useCalculateAdvertsForm()

  const resetFormState = () => {
    setChosenRegion('')
    setChosenSubregion('')
    setChosenTown([])
    setChosenDistrict([])
    setChosenTransactionType('')
    setChosenPropertyType('')
    setChosenPropertySubType('')
    setChosenMinPrice('')
    setChosenMaxPrice('')
    setChosenMinArea('')
    setChosenMaxArea('')
    setChosenMinPricePerArea('')
    setChosenMaxPricePerArea('')
    setSelectedDateRange(null)
    dateRangeFormRef?.current?.flatpickr.clear()
    if (formRef.current) {
      formRef.current.resetForm()
    }
    if (props.onFormReset) {
      props.onFormReset()
    }
  }

  React.useEffect(() => {
    resetFormState()
  }, [props.reset])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchRegionList(getFromQuery(query, 'assume'), props.allLocations).then(res => {
        setIsLoading(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (chosenPropertyType !== `${AdvertType.PLOT}`) {
      setChosenPropertySubType('')
      formRef?.current?.setFieldValue('propertySubType', '')
    }
  }, [chosenPropertyType])

  React.useEffect(() => {
    if (chosenRegion !== '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const subRegions = selRegion.subRegions.filter(e => !e.doNotShow).map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setSubRegionList(subRegions)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      if (!(subRegions.findIndex(e => e.value === chosenSubregion) > -1)) {
        setChosenSubregion('')
        formRef.current.setFieldValue('subRegion', '')
      }
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion === '') {
      setSubRegionList([])
      setTownList([])
      setDistrictList([])
      setChosenSubregion('')
      setChosenTown([])
      setChosenDistrict([])
      formRef.current.setFieldValue('subRegion', '')
      formRef.current.setFieldValue('town', [])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenRegion])

  React.useEffect(() => {
    if (chosenRegion !== '' && chosenSubregion !== '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const towns = selSubregion.towns.map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setTownList(towns)
      const selectedTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion !== '' && chosenSubregion === '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    }
  }, [chosenSubregion])

  React.useEffect(() => {
    if (chosenRegion !== '' && chosenSubregion === '' && chosenTown.length > 0) {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregions = selRegion.subRegions.filter(e => e.towns.findIndex(el => chosenTown.includes(el.uuid)) > -1)
      const selTowns: any[] = []
      for (const subregion of selSubregions) {
        selTowns.push(...subregion.towns.filter(e => chosenTown.includes(e.uuid)))
      }
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: selTowns.length > 1 ? `${e.name} (${selTown.name})` : e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion !== '' && chosenTown.length > 0) {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const selTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion !== '' && chosenTown.length === 0) {
      setDistrictList([])
      setChosenDistrict([])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenTown])

  const handleSubmit = async (data) => {
    try {
      if (props.beforeSubmit) {
        props.beforeSubmit()
      }
      setIsSubmitting(true)
      const mappedData = {
        params: {
          advertType: data.propertyType === '' ? undefined : parseInt(data.propertyType),
          advertSubType: data.propertySubType === '' ? undefined : parseInt(data.propertySubType),
          offerType: data.transactionType === '' ? undefined : parseInt(data.transactionType),
          regionUuid: data.region === '' ? undefined : data.region,
          subRegionUuid: data.subRegion === '' ? undefined : data.subRegion,
          townUuids: data.town === null ? undefined : data.town,
          districtUuids: data.district == null ? undefined : data.district,
          minArea: data.minArea === '' ? undefined : parseFloat(data.minArea.replace(',', '.')),
          maxArea: data.maxArea === '' ? undefined : parseFloat(data.maxArea.replace(',', '.')),
          minPrice: data.minPrice === '' ? undefined : parseFloat(data.minPrice.replace(',', '.')),
          maxPrice: data.maxPrice === '' ? undefined : parseFloat(data.maxPrice.replace(',', '.')),
          minPricePerArea: !data.minPricePerArea || data.minPricePerArea === '' ? undefined : parseFloat(data.minPricePerArea.replace(',', '.')),
          maxPricePerArea: !data.maxPricePerArea || data.maxPricePerArea === '' ? undefined : parseFloat(data.maxPricePerArea.replace(',', '.')),
          start: moment(data.selectedDateRange[0]).format(),
          end: moment(data.selectedDateRange[1]).format()
        }
      }
      const res = await DataApi.calculateAdvertsCount(mappedData)
      props.onFinish(mappedData.params, res.count)
      // resetFormState()
      setIsSubmitting(false)
    } catch (error) {
      if (error.response?.status === 409 && error.response?.data?.code === 200 && error.response?.data?.subcode === 219) {
        formRef.current.setFieldTouched('town', true, false)
        formRef.current.setFieldError('town', t('common:field_required'))
      }
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div 
        className={classes.container}
        style={{
          display: isLoading ? 'none' : 'block'
        }}
      >
        <Formik
          innerRef={formRef}
          validationSchema={calculateAdvertsFormValidation(tEventList)}
          initialValues={{
            minPrice: '',
            maxPrice: '',
            minArea: '',
            maxArea: '',
            region: '',
            subRegion: '',
            town: [],
            district: [],
            transactionType: '',
            propertyType: '',
            propertySubType: '',
            minPricePerArea: '',
            maxPricePerArea: '',
            selectedDateRange: null
            // calculateAdvertsFrom: null,
            // calculateAdvertsTo: null
          }}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                width: '100%',
              }}
            >
              <div className={classes.sectionLabel}>
                {t('advert_search_params_section_label')}
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('location_field_label')}
                  </p>
                  <div 
                    className={`${classes.clearButton}`} 
                    onClick={() => {
                      setChosenRegion('')
                      formikProps.setFieldValue('region', '')
                    }}
                  >
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'region-field'}
                    name='region'
                    placeholder={'Województwo'}
                    options={regions.map(e => {
                      return {
                        value: e.uuid,
                        label: e.name
                      }
                    }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenRegion}
                    setValue={setChosenRegion}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.region &&
                      Boolean(formikProps.errors.region)
                    }
                  />
                </div>
                <div className={classes.fieldWithoutLabel}>
                  <div 
                    hidden={chosenRegion === ''}
                    className={`${classes.clearButton}`} 
                    onClick={()=> {
                      setChosenSubregion('')
                      formikProps.setFieldValue('subRegion', '')
                    }}
                  >
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'subRegion-field'}
                    disabled={chosenRegion === ''}
                    name='subRegion'
                    placeholder={'Powiat'}
                    options={subRegionList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenSubregion}
                    setValue={setChosenSubregion}
                    formikProps={formikProps}
                    warning={
                      chosenSubregion != null && chosenSubregion != ''
                    }
                    warningMessage={t('subregion_warning_label')}
                    error={
                      formikProps.touched.subRegion &&
                      Boolean(formikProps.errors.subRegion)
                    }
                  />
                </div>
                <div className={classes.fieldWithoutLabel}>
                  <div 
                    hidden={chosenRegion === ''}
                    className={`${classes.clearButton}`} 
                    onClick={()=> {
                      setChosenTown([])
                      formikProps.setFieldValue('town', [])
                    }}
                  >
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'town-field'}
                    disabled={chosenRegion === ''}
                    name='town'
                    isMulti
                    placeholder={'Miasto/Miejscowość'}
                    options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenTown}
                    setValue={setChosenTown}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.town &&
                      Boolean(formikProps.errors.town)
                    }
                  />
                </div>
                <div className={classes.lastFieldWithoutLabel}>
                  <div 
                    hidden={chosenTown.length === 0}
                    className={`${classes.clearButton}`} 
                    onClick={()=> {
                      setChosenDistrict([])
                      formikProps.setFieldValue('district', [])
                    }}
                  >
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'district-field'}
                    disabled={chosenTown.length === 0}
                    name='district'
                    isMulti
                    placeholder={'Dzielnica (opcjonalnie)'}
                    options={districtList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenDistrict}
                    setValue={setChosenDistrict}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.district &&
                      Boolean(formikProps.errors.district)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                  {t('transaction_type_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenTransactionType('')
                    formikProps.setFieldValue('transactionType', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'transaction-type-field'}
                    name='transactionType'
                    placeholder={'Wybierz typ transakcji'}
                    options={transactionTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenTransactionType}
                    setValue={setChosenTransactionType}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.transactionType &&
                      Boolean(formikProps.errors.transactionType)
                    }
                  />
                </div>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                  {t('property_type_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenPropertyType('')
                    formikProps.setFieldValue('propertyType', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'property-type-field'}
                    name='propertyType'
                    placeholder={'Wybierz typ nieruchomości'}
                    options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenPropertyType}
                    setValue={setChosenPropertyType}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.propertyType &&
                      Boolean(formikProps.errors.propertyType)
                    }
                  />
                </div>
                <div className={classes.lastFieldWithLabel} style={{
                  ...(chosenPropertyType !== `${AdvertType.PLOT}` ? { display: 'none' } : {})
                }}>
                  <p className={classes.fieldLabel}>
                  {t('plot_type_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenPropertySubType('')
                    formikProps.setFieldValue('propertySubType', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'property-subtype-field'}
                    name='propertySubType'
                    placeholder={t('plot_type_field_placeholder')}
                    options={plotTypes}
                    value={chosenPropertySubType}
                    setValue={setChosenPropertySubType}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.propertySubType &&
                      Boolean(formikProps.errors.propertySubType)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('min_price_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMinPrice('')
                    formikProps.setFieldValue('minPrice', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='minPrice' 
                    placeholder={t('min_price_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenMinPrice}
                    monetaryValue
                    setValue={setChosenMinPrice}
                    error={
                      formikProps.touched.minPrice &&
                      Boolean(formikProps.errors.minPrice)
                    }
                  />
                </div>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('max_price_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMaxPrice('')
                    formikProps.setFieldValue('maxPrice', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='maxPrice' 
                    placeholder={t('max_price_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenMaxPrice}
                    setValue={setChosenMaxPrice}
                    monetaryValue
                    error={
                      formikProps.touched.maxPrice &&
                      Boolean(formikProps.errors.maxPrice)
                    }
                  />
                </div>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('min_area_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMinArea('')
                    formikProps.setFieldValue('minArea', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='minArea' 
                    placeholder={t('min_area_field_placeholder')}
                    formikProps={formikProps}
                    monetaryValue
                    value={chosenMinArea}
                    setValue={setChosenMinArea}
                    error={
                      formikProps.touched.minArea &&
                      Boolean(formikProps.errors.minArea)
                    }
                  />
                </div>
                <div className={classes.lastFieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('max_area_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMaxArea('')
                    formikProps.setFieldValue('maxArea', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='maxArea' 
                    placeholder={t('max_area_field_placeholder')}
                    formikProps={formikProps}
                    monetaryValue
                    value={chosenMaxArea}
                    setValue={setChosenMaxArea}
                    error={
                      formikProps.touched.maxArea &&
                      Boolean(formikProps.errors.maxArea)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('min_price_per_area_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMinPricePerArea('')
                    formikProps.setFieldValue('minPricePerArea', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='minPricePerArea' 
                    placeholder={t('min_price_per_area_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenMinPricePerArea}
                    setValue={setChosenMinPricePerArea}
                    monetaryValue
                    error={
                      formikProps.touched.minPricePerArea &&
                      Boolean(formikProps.errors.minPricePerArea)
                    }
                  />
                </div>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('max_price_per_area_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setChosenMaxPricePerArea('')
                    formikProps.setFieldValue('maxPricePerArea', '')
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <BaseNumberInput
                    name='maxPricePerArea' 
                    placeholder={t('max_price_per_area_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenMaxPricePerArea}
                    setValue={setChosenMaxPricePerArea}
                    monetaryValue
                    error={
                      formikProps.touched.maxPricePerArea &&
                      Boolean(formikProps.errors.maxPricePerArea)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldWithLabel}>
                  <p className={classes.fieldLabel}>
                    {t('advert_date_field_label')}
                  </p>
                  <div className={`${classes.clearButton}`} onClick={()=> {
                    setSelectedDateRange(null)
                    formikProps.setFieldValue('selectedDateRange', null)
                    dateRangeFormRef?.current?.flatpickr.clear()
                  }}>
                    {t('clear_field_button_label')}
                  </div>
                  <Flatpickr
                    id={'date-range-picker'}
                    ref={dateRangeFormRef}
                    data-enable-time
                    options={{
                      locale: {
                        firstDayOfWeek: 1,
                        rangeSeparator: ' - ',
                        months: {
                          shorthand: monthList,
                          longhand: monthList
                        },
                        weekdays: {
                          shorthand: dayListShorthand,
                          longhand: dayList
                        }
                      },
                      dateFormat: 'd.m.Y',
                      mode: 'range',
                      maxDate: new Date()
                    }}
                    onClose={(date) => {
                      formikProps.setFieldTouched('selectedDateRange', false)
                      if (date && date.length === 2) {
                        setSelectedDateRange(date)
                        formikProps.setFieldValue('selectedDateRange', date)
                      }
                      formikProps.setFieldTouched('selectedDateRange', true)
                    }}
                    render={({ defaultValue, value, ...props }, ref) => {
                      return (
                        <CustomFlatpickrInput
                          defaultValue={defaultValue}
                          placeholder={'Wybierz przedział dat'}
                          inputRef={ref}
                          width={'100%'}
                          error={
                            formikProps.touched.selectedDateRange &&
                            Boolean(formikProps.errors.selectedDateRange)
                          }
                        />
                      )
                    }}
                  />
                  <div className={classes.errorLabel} style={{
                    float: 'left'
                  }}>
                    <ErrorMessage name={'selectedDateRange'} />
                  </div>
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('calculate_button_label')}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
              {
                props.resetButton
                ? <div style={{
                    float: 'right',
                    marginBottom: '30px',
                    marginRight: '10px'
                  }}>
                    <LoadingButton 
                      text={'Resetuj'}
                      type='button'
                      loading={isSubmitting}
                      className={classes.resetButton}
                      style={{
                        height: '40px'
                      }}
                      onClick={() => {
                        resetFormState()
                      }}
                    />
                  </div>
                : null
              }
              
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default CalculateAdvertsForm