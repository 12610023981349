import { SmsUserSettings } from './common'
export type Login = {
  login: string
  password: string
}

export type Register = Login & {
  passwordConfirmation: string
  fullName: string
}

export type AuthData = {
  uuid: string
  email: string
  name: string
  permissionLevels: number[]
  settings: SmsUserSettings
  changes?: {
    minPrices: boolean
    templates: boolean
  }
  sessionToken: string
  refreshToken: string
}

export enum PermissionLevel {
  ADMIN = 0,
  STANDARD = 1,
  CHILD = 2,
  SUPERADMIN = 3
}

export const UserRoles = [
  {
    value: `${PermissionLevel.STANDARD}`,
    label: 'Konto główne'
  },
  {
    value: `${PermissionLevel.ADMIN}`,
    label: 'Administrator'
  }
]