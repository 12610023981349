import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { resendActivationEmailValidation } from '../../../utils/validations/resendActivationEmailValidation'
import BaseInput from "../../common/BaseInput/BaseInput"
import LoadingButton from "../../common/LoadingButton/LoadingButton"
import AuthCover from "../AuthCover/AuthCover"
import { commonAuthStyle } from "../commonAuthStyle"
import { useResendActivationEmailRequest } from "./useResendActivationEmailRequest"

const ResendActivationEmail = () => {
  const { t } = useTranslation("auth")
  const { loading, onSubmitResendActivationEmail } = useResendActivationEmailRequest()
  const commonAuthClasses = commonAuthStyle()

  return (
    <AuthCover
      title={t("resend_activation_email_request_title")}
      subTitle={t("resend_activation_email_request_subtitle")}
    >
      <div>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={onSubmitResendActivationEmail}
          validationSchema={resendActivationEmailValidation(t)}
        >
          {(formikProps) => (
            <Form
              style={{
                minWidth: "360px",
              }}
            >
              <BaseInput
                name="email"
                label={t("email_address")}
                error={
                  Boolean(formikProps.errors.email) &&
                  formikProps.touched.email
                }
                formikProps={formikProps}
              />
              <LoadingButton
                text={t("send")}
                type="submit"
                className={commonAuthClasses.loadingButton}
                loading={loading}
                disabled={
                  !formikProps.isValid || !formikProps.dirty
                }
                style={{ marginTop: "10px" }}
              />
              <div
                style={{ 
                  marginTop: "25px",
                  textAlign: 'center'
                }}
              >
                <Link
                  to="/login"
                  className={commonAuthClasses.link}
                >
                  {t("back_to_login")}
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthCover>
  )
}

export default ResendActivationEmail
