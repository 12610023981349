import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { State } from "../../redux/reducers"
import { useLocation } from 'react-router-dom'

const ignoreMessagesList: string[] = [
  // MessageEnum.TOKEN_INVALID,
  // MessageEnum.TOKEN_ALREADY_USED,
  // MessageEnum.TOKEN_ALREADY_USED,
  // MessageEnum.TOKEN_ALREADY_USED
]


const pathnameForLeftMessages = [
  '/login',
  '/password/reset/finish',
  '/password/reset',
  '/account/activation/resend',
  '/account/activate',
  '/account/email/verify'
]

const Messages = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [horPos, setHorPos] = React.useState<'right' | 'left'>('right')
  const { t } = useTranslation("messages")
  const location = useLocation()
  const messages = useSelector((state: State) => state.messages)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setOpen(false)        
  }

  React.useEffect(() => {
    if (pathnameForLeftMessages.includes(location.pathname)) {
      setHorPos('left')
    } else {
      setHorPos('right')

    }
  }, [location])

  React.useEffect(() => {
    if (
      (messages.error && ignoreMessagesList.indexOf(messages.error) === -1) 
      || (messages.success && ignoreMessagesList.indexOf(messages.error) === -1)) {
      setOpen(true)
    }
  }, [messages])

  const renderMessage = () => {
    if (messages.success !== '') {
      return <Alert severity="success">{t(messages.success)}</Alert>
    } else if (messages.error !== '') {
      return <Alert severity="error">{t(messages.error)}</Alert>
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: horPos,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      style={{
        zIndex: 10000
      }}
    >
      <div>{renderMessage()}</div>
    </Snackbar>
  )
}


export default Messages
