import { CircularProgress } from '@material-ui/core'
import { Form, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import AuthApi from '../../../api/AuthApi'
import { removeToken } from '../../../redux/auth/actions'
import { addSuccessMessage } from '../../../redux/messages/actions'
import { removePermissions } from '../../../redux/permissions/actions'
import { State } from "../../../redux/reducers"
import { removeUserName } from '../../../redux/user/actions'
import store from '../../../store'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../../utils/token/StorageService'
import { setFirstPasswordValidation } from "../../../utils/validations/setFirstPasswordValidation"
import LoadingButton from "../../common/LoadingButton/LoadingButton"
import PasswordInput from "../../common/PasswordInput/PasswordInput"
import { MessageEnum } from '../../Messages/types'
import AuthCover from "../AuthCover/AuthCover"
import { commonAuthStyle } from "../commonAuthStyle"

const ResetPasswordFinish = () => {
  const { t } = useTranslation("auth")

  const message = useSelector((e: State) => e.messages)
  const locations = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const commonAuthClasses = commonAuthStyle()
  const query = new URLSearchParams(locations.search)

  const token = query.get("token")

  const [loading, setLoading] = React.useState<boolean>(false)
  const [checkLoading, setCheckLoading] = React.useState<boolean>(true)

	const [password, setPassword] = React.useState<string>('')
	const [passwordRepeat, setPasswordRepeat] = React.useState<string>('')

  React.useEffect(() => {
    const checkChangePasswordToken = async () => {
      try {
        await AuthApi.checkChangePasswordToken(token)
      } catch (e) {
        onErrorResponseShowMessage(e, false)
        history.push("/login")
      } finally {
        setCheckLoading(false)
      }
    }
    checkChangePasswordToken()
  }, [token, history])

  React.useEffect(() => {
    if (message.error) {
      history.push('/login')
    }
  }, [message])

  const handleSubmitChangePassword = async (values: {
    password: string
    passwordConfirmation: string
  }, form: any) => {
    setLoading(true)
    try {
      await AuthApi.changePassword(values.password, token)
      dispatch(
        addSuccessMessage(
          MessageEnum.PASSWORD_HAS_BEEN_CHANGED
        )
      )
      StorageService.getInstance().clearUserCache()
      store.dispatch(removeToken())
      store.dispatch(removeUserName())
      store.dispatch(removePermissions())
      history.push("/login")
    } catch (e) {
      onErrorResponseShowMessage(e, false)
      setPassword('')
      setPasswordRepeat('')
      form.resetForm()
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthCover
      title={t("reset_password_finish_title")}
      subTitle={t("reset_password_finish_subtitle")}
    >
      { checkLoading 
        ? <CircularProgress size={48}/>
        : <>
            <Formik
              validationSchema={setFirstPasswordValidation(t)}
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={handleSubmitChangePassword}
            >
              {(formikProps) => (
                <Form>
                  <PasswordInput
                    label={t("set_new_password_label")}
                    name="password"
                    value={password}
                    setValue={setPassword}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.password &&
                      Boolean(formikProps.errors.password)
                    }
                  />
                  <PasswordInput
                    name="passwordConfirmation"
                    label={t("repeat_new_password_label")}
                    value={passwordRepeat}
                    setValue={setPasswordRepeat}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.passwordConfirmation &&
                      Boolean(formikProps.errors.passwordConfirmation)
                    }
                  />
                  <LoadingButton
                    text={t("set_button")}
                    type="submit"
                    className={commonAuthClasses.loadingButton}
                    loading={loading}
                    disabled={
                      !formikProps.isValid || !formikProps.dirty
                    }
                    style={{ marginTop: "10px" }}
                  />
                  <div
                    style={{ 
                      marginTop: "25px",
                      textAlign: 'center'
                    }}
                  >
                    <Link
                      to="/login"
                      className={commonAuthClasses.link}
                    >
                      {t("back_to_login")}
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </>
      }
    </AuthCover>
  )
}

export default ResetPasswordFinish
