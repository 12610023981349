import * as Yup from "yup"
import { email, forbiddenSpecialCharacters } from './validationRegexps'

export const addParamsForm = (t: (val: string) => string) => {
	return Yup.object({
		name: Yup.string().required(t('common:field_required')),
		minPrice: Yup.string()
			.required(t('common:field_required'))
			.test('compare_max_min_price', t('min_price_higher_than_max_validation'), function (val: any) {
				const maxPrice = this.resolve(Yup.ref('maxPrice')) as any
				if (!maxPrice || maxPrice === '') return true
				const parsedMaxPrice = parseFloat(maxPrice.replace(/\s/g, '').replace(',', '.'))
				const parsedMinPrice = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxPrice >= parsedMinPrice
			}),
		maxPrice: Yup.string()
			.required(t('common:field_required'))
			.test('compare_min_max_price', t('max_price_lower_than_min_validation'), function (val: any) {
				const minPrice = this.resolve(Yup.ref('minPrice')) as any
				if (!minPrice || minPrice === '') return true
				const parsedMinPrice = parseFloat(minPrice.replace(/\s/g, '').replace(',', '.'))
				const parsedMaxPrice = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxPrice >= parsedMinPrice
			}),
		minArea: Yup.string()
			.required(t('common:field_required'))
			.required(t('common:field_required'))
			.test('compare_max_min_area', t('min_area_higher_than_max_validation'), function (val: any) {
				const maxArea = this.resolve(Yup.ref('maxArea')) as any
				if (!maxArea || maxArea === '') return true
				const parsedMaxArea = parseFloat(maxArea.replace(/\s/g, '').replace(',', '.'))
				const parsedMinArea = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxArea >= parsedMinArea
			}),
		maxArea: Yup.string()
			.required(t('common:field_required'))
			.test('compare_min_max_price', t('max_area_lower_than_min_validation'), function (val: any) {
				const minArea = this.resolve(Yup.ref('minArea')) as any
				if (!minArea || minArea === '') return true
				const parsedMinArea = parseFloat(minArea.replace(/\s/g, '').replace(',', '.'))
				const parsedMaxArea = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxArea >= parsedMinArea
			}),
		region: Yup.string().required(t('common:field_required')),
		transactionType: Yup.string().required(t('common:field_required')),
		propertyType: Yup.string().required(t('common:field_required')),
		smsTemplate: Yup.array()
			.test('sms_template_len', t('common:field_required'), function (val: any) {
				const customTemplate = this.resolve(Yup.ref('customTemplate')) as any
				return val.length + (customTemplate != null ? 1 : 0) >= 1
			})
			.required(t('common:field_required')),
		minPricePerArea: Yup.string()
			.test('compare_max_min_price_per_area', t('min_price_per_area_higher_than_max_validation'), function (val: any) {
				const maxPricePerArea = this.resolve(Yup.ref('maxPricePerArea')) as any
				if (!maxPricePerArea || maxPricePerArea === '' || !val || val === true) return true
				const parsedMaxPricePerArea = parseFloat(maxPricePerArea.replace(/\s/g, '').replace(',', '.'))
				const parsedMinPricePerArea = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxPricePerArea >= parsedMinPricePerArea
			}),
		maxPricePerArea: Yup.string()
			.test('compare_min_max_price_per_area', t('max_price_per_area_lower_than_min_validation'), function (val: any) {
				const minPricePerArea = this.resolve(Yup.ref('minPricePerArea')) as any
				if (!minPricePerArea || minPricePerArea === '' || !val || val === true) return true
				const parsedMinPricePerArea = parseFloat(minPricePerArea.replace(/\s/g, '').replace(',', '.'))
				const parsedMaxPricePerArea = parseFloat((val || '').replace(/\s/g, '').replace(',', '.'))
				return parsedMaxPricePerArea >= parsedMinPricePerArea
			}), 
		notificationEmails: Yup.array().of(
			Yup.string()
				.matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
				.matches(email, t("auth:invalid_email_validation"))
		)
	})
}
