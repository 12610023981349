import { Tag } from 'bbcode-to-react'
import { attentionStyle } from './attentionStyle'
import attentionIcon from '../../../../../../assets/images/ic-attention.webp'
import i18n from '../../../../../../i18'

export default class AttentionTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    const classes = attentionStyle()
    
    return (
      <span className={classes.container}>
        <img src={attentionIcon} className={classes.icon} />
        <span>
          <strong>{i18n.t('common:attention_title')}</strong>
          <p>{this.getComponents()}</p>
        </span>
      </span>
    )
  }
}
