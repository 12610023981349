import { makeStyles } from "@material-ui/core"
import { Colors } from "../../../types/colors"

export const bbCodeArticleStyle = makeStyles(() => ({
  bbCodeArticle: {
    margin: "32px",
    fontFamily: "AvenirBook",
    display: "flex",
    flexDirection: "column",
    "& h2": {
      textTransform: "uppercase",
      color: `${Colors.MAIN_FONT_COLOR}`,
      fontWeight: 'normal',
      paddingBottom: "20px",
      fontSize: "16px",
    },
    "& a": {
      "&:visited, &:link": {
        textDecoration: "none",
        color: `${Colors.BLUE_BUTTON_COLOR}`
      },
      "&:hover": {
        color: `${Colors.BLUE_BUTTON_COLOR}`,
        textDecoration: "underline"
      }
    },
    "& > p": {
      color: `${Colors.CONTENT_FAQ_TEXT}`,
      fontSize: "16px",
      padding: "5px 0"
    },
    "& ul": {
      padding: "0 16px",
      color: "#6d7278",
      fontSize: "16px",
      listStyle: "none",
      "& li" : {
        padding: "5px 0",
        "&::before": {
          content: '"\\2022"',
          color: `${Colors.MAIN}`,
          fontWeight: "bold",
          display: "inline-block",
          width: "1em",
          marginLeft: "-1em"
        },
      },
      "& ul li": {
        "&::before": {
          content: "none",
          padding: "8px 0",
        }
      },
      "& ul": {
        paddingLeft: 0,
      }
    },
    "& > img": {
      margin: "25px auto",
      '@media (max-width: 1000px)': {
        maxWidth: '100%'
      }
    },
  }
}))