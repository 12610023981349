import React from 'react'
import DataApi from '../../api/DataApi'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { AsyncCreatableSelectOption } from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'

export const useManageUser = () => {
  const [details, setDetails] = React.useState<any>(null)
  const [regions, setRegions] = React.useState<any[]>([])
  const [userTagList, setUserTagList] = React.useState<AsyncCreatableSelectOption[]>([])

  const fetchAccountDetails = async (uuid: string) => {
    try {
      const data = await DataApi.getUserDetails(uuid)
      setDetails(data)
    } catch (error) {
			throw error
    }
  }

  const fetchRegionList = async () => {
    try {
      const fetchedRegions = await DataApi.getRegionList(true)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

  const fetchUserTags = async (search: string) => {
    try {
      const tags = await DataApi.searchUserTags(search)
      setUserTagList(tags.data.map(e => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
    } catch (error) {
			throw error
    }
  }

	return { 
    details, regions, userTagList,
    fetchAccountDetails, fetchRegionList, fetchUserTags
	}
}