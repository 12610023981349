import React from "react"
import { statTileStyle } from "./statTileStyle"

type Props = {
  label: string
  iconSrc?: string
  value: string
  textColor: string
  backgroundColor: string
  width?: string
  height?: string
}

const StatTile = (props: Props) => {
  const classes = statTileStyle()

  return (
    <div
      style={{
        display: 'flex',
        width: props.width || '100%',
        height: props.height || '82px',
        backgroundColor: props.backgroundColor,
        paddingLeft: '15px',
        paddingRight: '15px',
        color: props.textColor
      }}
    >
      <div
        style={{
          width: '20%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        { props.iconSrc ? <img
          src={props.iconSrc}
        /> : null }
      </div>

      <div
        style={{
          display: 'flex',
          paddingTop: '20px',
          width: '80%',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            fontFamily: 'AvenirMedium',
            letterSpacing: 0,
            fontSize: '30px',
            lineHeight: '30px'
          }}
        >
          {props.value}
        </div>
        <div
          style={{
            textTransform: 'uppercase',
            fontFamily: 'AvenirBook',
            letterSpacing: 1,
            fontSize: '12px',
            textAlign: 'center'
          }}
        >
         {props.label}
        </div>
      </div>
    </div>
  )
}

export default StatTile
