import React from 'react'
import DataApi from '../../api/DataApi'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { SortDirection } from '../CustomTable/util'

export const useSuperEventsList = () => {
  const [regions, setRegions] = React.useState<any[]>([])
  const [config, setConfig] = React.useState<any>(null)
  const [data, setData] = React.useState<any[]>([])
  const [stats, setStats] = React.useState<any>({
    auctionCount: 0,
    messageSentCount: 0,
    responsesCount: 0,
    totalPrice: 0
  })

  const fetchRegionList = async (getAll?: boolean) => {
    try {
      const fetchedRegions = await DataApi.getRegionList(getAll)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

  const fetchStats = async (from?: string, to?: string) => {
    try {
      const count = await DataApi.getSearchParamsStats(from, to)
      setStats({
        auctionCount: count.auctionCount,
        messageSentCount: count.messageSentCount,
        responsesCount: count.responsesCount,
        totalPrice: count.totalPrice
      })
    } catch (error) {
			throw error
    }
  }

  const fetchConfig = async () => {
    try {
      const fetchedConfig = await DataApi.getActiveConfig()
      setConfig(fetchedConfig)
    } catch (error) {
			throw error
    }
  }

  const fetchData = async (sortName?: string, sortDirection?: SortDirection, from?: string, to?: string) => {
    try {
      if (sortName && sortDirection && ['name', 'bidValue', 'active', 'marketType'].includes(sortName)) {
        const fetchedData = await DataApi.getSuperAdminSearchParamsList(sortName, sortDirection, from, to)
        setData(fetchedData)
      } else {
        const fetchedData = await DataApi.getSuperAdminSearchParamsList(undefined, undefined, from, to)
        setData(fetchedData)
      }
    } catch (error) {
			throw error
    }
  }

	return { 
    config, data, stats, regions,
    fetchConfig, fetchData, fetchStats, fetchRegionList
	}
}