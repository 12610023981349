import { Colors } from '../../../types/colors'
import { makeStyles } from '@material-ui/styles'

export type StyleProps = {
  borderColor?: string
  borderColorFocus?: string
}

export const customAsyncCreatableSelectFieldStyle = (props: StyleProps) => makeStyles(() => ({
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '4px 10px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  },
  warningLabel: {
    color: '#faa307',
    textAlign: 'left',
    margin: '4px 10px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  }
}))