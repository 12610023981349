import { ADD_TOKEN, REMOVE_TOKEN } from "./types"
import { AnyAction } from "redux"


export type AuthState = {
    token: string
}

const localStorageAuthenitcated = typeof localStorage.getItem("access_token") === "string" ? localStorage.getItem("access_token") as string : ""

export const initialAuthValue = {
    token: localStorageAuthenitcated
}

export const auth = (state: AuthState = initialAuthValue, action: AnyAction) => {
    switch(action.type) {
        case ADD_TOKEN:
            return { token: action.token }
        case REMOVE_TOKEN:
            return { token: "" }
        default:
            return state
    }
}