import React from 'react'
import AuthRefreshApi from '../../api/AuthRefreshApi'

export const useUserDetails = () => {
  const [details, setDetails] = React.useState<any>(null)

  const fetchAccountDetails = async () => {
    try {
      const data = await AuthRefreshApi.getAccountDetails()
      setDetails(data)
    } catch (error) {
			throw error
    }
  }

	return { 
    details,
    fetchAccountDetails,
	}
}