import React from 'react'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers'
import { checkIfAdmin } from '../../utils/common'

export const useMyAgency = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [usersList, setUsersList] = React.useState<any[]>([])
  const [usersCount, setUsersCount] = React.useState<number>(0)

  const fetchAssumedUser = async (uuid?: string, assumeUuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid, assumeUuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

	const fetchUsersList = async (limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) => {
    try {
      const data = await DataApi.getChildAccountList(limit, offset, sortName, sortDirection, assumeUuid)
      setUsersList(data)
    } catch (error) {
			throw error
    }
  }

	const fetchUsersCount = async (assumeUuid?: string) => {
    try {
      const data = await DataApi.getChildAccountCount(assumeUuid)
      setUsersCount(data.count)
    } catch (error) {
			throw error
    }
  }

	return { 
		usersList, usersCount, assumedUser,
		fetchUsersList, fetchUsersCount, fetchAssumedUser
	}
}