import React from 'react'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import DataApi from '../../api/DataApi'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { SortDirection } from '../CustomTable/util'
import { AdvertType } from '../../types/advert'
import { AsyncCreatableSelectOption } from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import { AsyncSelectOption } from '../common/CustomAsyncSelectField/CustomAsyncSelectField'
import moment from 'moment'

export const useSuperuserSettings = () => {
  const [config, setConfig] = React.useState<any>(null)
  const [usersList, setUsersList] = React.useState<any[]>([])
  const [usersCount, setUsersCount] = React.useState<number>(0)
  const [smsTemplates, setSmsTemplates] = React.useState<any[]>([])
  const [smsTemplatesCount, setSmsTemplatesCount] = React.useState<number>(0)
  const [regions, setRegions] = React.useState<any[]>([])
  const [createUserTagList, setCreateUserTagList] = React.useState<AsyncCreatableSelectOption[]>([])
  const [filterUserTagList, setFilterUserTagList] = React.useState<AsyncSelectOption[]>([])

  
  const [bulkActionsList, setBulkActionsList] = React.useState<any[]>([])
  const [bulkActionsCount, setBulkActionsCount] = React.useState<number>(0)

	const fetchConfig = async () => {
    try {
      const data = await DataApi.getActiveConfig()
      setConfig(data)
    } catch (error) {
			throw error
    }
  }

  const fetchRegionList = async () => {
    try {
      const fetchedRegions = await DataApi.getRegionList(true)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

	const fetchUsersList = async (
    limit?: number, offset?: number, 
    tags?: AsyncSelectOption[],
    dataDateRange?: Date[], 
    sortName?: string, sortDirection?: SortDirection
  ) => {
    try {
      let dateRange: Date[] | undefined
      if (dataDateRange != null && dataDateRange.length === 2) {
        dateRange = [
          dataDateRange[0],
          moment(dataDateRange[1]).endOf('day').toDate()
        ]
      }
      const data = await DataApi.getUsersList(limit, offset, tags?.map(e => e.value), dateRange, sortName, sortDirection)
      setUsersList(data)
    } catch (error) {
			throw error
    }
  }

	const fetchBulkActionsWaitingForApprovalList = async (limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection) => {
    try {
      const data = await DataApi.getBulkActionWaitingForApprovalList(limit, offset, sortName, sortDirection)
      setBulkActionsList(data.data)
      setBulkActionsCount(data.count)
    } catch (error) {
			throw error
    }
  }

	const fetchUsersCount = async (tags?: AsyncSelectOption[]) => {
    try {
      const data = await DataApi.getUsersCount(tags?.map(e => e.value))
      setUsersCount(data.count)
    } catch (error) {
			throw error
    }
  }

	const fetchSmsTemplateList = async (limit?: number, offset?: number, propertyType?: AdvertType) => {
    try {
      const fetchedTemplates = await DataApi.getSmsTemplateList(false, limit, offset, propertyType)
      setSmsTemplates(fetchedTemplates)
    } catch (error) {
			throw error
    }
  }

	const fetchSmsTemplateCount = async (propertyType?: AdvertType) => {
    try {
      const data = await DataApi.getSmsTemplateCount(propertyType)
      setSmsTemplatesCount(data.count)
    } catch (error) {
			throw error
    }
  }

  const fetchCreateUserTags = async (search: string) => {
    try {
      const tags = await DataApi.searchUserTags(search)
      setCreateUserTagList(tags.data.map(e => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
    } catch (error) {
			throw error
    }
  }

  const fetchFilterUserTags = async (search: string) => {
    try {
      const tags = await DataApi.searchUserTags(search)
      setFilterUserTagList(tags.data.map(e => {
        return {
          label: e.name,
          value: e.uuid
        }
      }))
    } catch (error) {
			throw error
    }
  }

	return { 
		config, usersList, usersCount, smsTemplates, smsTemplatesCount, regions, bulkActionsList, bulkActionsCount,
    createUserTagList, filterUserTagList,
		fetchConfig, fetchUsersList, fetchUsersCount, fetchSmsTemplateList, fetchSmsTemplateCount, fetchRegionList, fetchBulkActionsWaitingForApprovalList,
    fetchCreateUserTags, fetchFilterUserTags
	}
}