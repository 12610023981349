import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import authApi from "../../../api/AuthApi"
import { addSuccessMessage } from "../../../redux/messages/actions"
import { onErrorResponseShowMessage } from "../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage"
import { MessageEnum } from '../../Messages/types'

export const useResendActivationEmailRequest = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmitResendActivationEmail = async (values: { email: string }) => {
    setLoading(true)
    try {
      await authApi.resendActivationEmail(values.email)
      dispatch(addSuccessMessage(MessageEnum.ACTIVATION_EMAIL_RESEND_HAS_BEEN_REQUESTED))
      history.push("/login")
    } catch(e) {
      onErrorResponseShowMessage(e, false)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    onSubmitResendActivationEmail
  }
}