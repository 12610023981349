import React from 'react'
import DataApi from '../../api/DataApi'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { SortDirection } from '../CustomTable/util'

export const useEventsDetails = () => {
  const [config, setConfig] = React.useState<any>(null)
  const [blacklistEntriesCount, setBlacklistEntriesCount] = React.useState<number>(0)
  const [blacklistEntriesList, setBlacklistEntriesList] = React.useState<any[]>([])

  const fetchConfig = async () => {
    try {
      const fetchedConfig = await DataApi.getActiveConfig()
      setConfig(fetchedConfig)
    } catch (error) {
			throw error
    }
  }

  const fetchBlacklistEntriesCount = async () => {
    try {
      const count = await DataApi.getBlacklistedPhonesCount()
      setBlacklistEntriesCount(count.count)
    } catch (error) {
			throw error
    }
  }

  const fetchBlacklistEntriesList = async (limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection) => {
    try {
      const list = await DataApi.getBlacklistedPhonesList(limit, offset, sortName, sortDirection)
      setBlacklistEntriesList(list)
    } catch (error) {
			throw error
    }
  }

	return { 
    config, blacklistEntriesCount, blacklistEntriesList, 
    fetchConfig, fetchBlacklistEntriesCount, fetchBlacklistEntriesList
	}
}