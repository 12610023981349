import { CircularProgress } from '@material-ui/core'
import {
  EditOutlined as EditOutlinedIcon,
  OpenInNewOutlined
} from "@material-ui/icons"
import '@szhsin/react-menu/dist/index.css'
import moment from "moment"
import React, { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import ReactTooltip from 'react-tooltip'
import dialogIcon from '../../assets/images/dialog.webp'
import sendIcon from '../../assets/images/send.webp'
import formIcon from '../../assets/images/form.webp'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList } from '../../types/common'
import { clientTypes, smsApiMessageTypes, smsResponseTypes } from '../../types/smsApiMessage'
import { getFromQuery, useQuery, getTimeDiffInString, checkIfAdmin } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import LoadingButton from "../common/LoadingButton/LoadingButton"
import Pagination from '../common/Pagination/Pagination'
import StatTile from "../common/StatTile/StatTile"
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { childAccountDetailsStyle } from './childAccountDetailsStyle'
import { useChildAccountDetails } from './useChildAccountDetails'
import { Formik, Form } from 'formik'
import { addChildAccountValidation } from '../../utils/validations/addChildAccountValidation'
import CloseIcon from '@material-ui/icons/Close'
import BaseInput from '../common/BaseInput/BaseInput'
import DataApi from '../../api/DataApi'
import { useHistory } from 'react-router-dom'

const tableColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '3%'
  },
  {
    label: 'Nazwa\u00A0eventu/paczki',
    name: 'event_name',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  },
  {
    label: 'Data i\u00A0godzina wysyłki',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  },
  {
    label: 'Nr\u00A0telefonu',
    headerAlign: 'left',
    contentAlign: 'left',
    width: 'calc(21%)'
  },
  {
    label: 'Status',
    name: 'statusCode', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  },
  {
    label: 'Odpowiedź użytkownika',
    name: 'responseStatus',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  },
  {
    label: 'Typ\u00A0klienta i\u00A0notka',
    name: 'clientType', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  },
  {
    label: 'Lead\u00A0vs\u00A0odpowiedź agenta',
    name: 'user_reaction_time', 
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }
]

const BID_PER_PAGE = 25 

const emptyFormData = {
  name: '',
  email: ''
}

const ChildAccountDetails = () => {
  const classes = childAccountDetailsStyle()

  const query = useQuery()
  const history = useHistory()

  const t = useTranslation("forms").t
  const tMyAgency = useTranslation('myAgency').t

  const dispatch = useDispatch()
  const { uuid } = useParams<{ uuid: string }>()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [showEditUserDialog, setShowEditUserDialog] = React.useState<boolean>(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [chosenUserName, setChosenUserName] = React.useState<string>('')
  const [chosenUserEmail, setChosenUserEmail] = React.useState<string>('')
  const [editUserInitialValues, setEditUserInitialValues] = React.useState<any>(emptyFormData)

  const { 
    details, wonBidCount, wonBidList, bidStats, assumedUser,
    fetchAccountDetails, fetchWonBidCount, fetchWonBidList,
    editAccountDetails, fetchAssumedUser
	} = useChildAccountDetails()

  const editUserFormRef = useRef() as any

  const setEditUserFormInitialData = () => {
    setChosenUserName(details?.name ?? '')
    setChosenUserEmail(details?.email ?? '')
    setEditUserInitialValues({
      name: details?.name ?? '',
      email: details?.email ?? ''
    })
    if (editUserFormRef.current) {
      editUserFormRef.current.resetForm()
    }
  }

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  React.useEffect(() => {
    if (details) {
      setEditUserFormInitialData()
    }
  }, [details])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 

  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[0]).format() 
      : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, undefined, undefined, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortData.column, sortData.order, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [sortData])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchAccountDetails(uuid, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidCount(uuid, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
          fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, undefined, undefined, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
            setIsLoading(false)
          }).catch(error => { onErrorResponseShowMessage(error)})
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { 
        onErrorResponseShowMessage(error)
        if (error.response?.status === 404) {
          history.push('/')
        }
      })
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])
  
  React.useEffect(() => {
    if (wonBidCount > 0) {
      setPages(Math.ceil(wonBidCount / BID_PER_PAGE))
    } else {
      setPages(1)
    }
  }, [wonBidCount])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [tableData])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
    if (selectedPage > 0) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedPage])

  React.useEffect(() => {
    let status, responseStatus, clientType
    const mapped = wonBidList.map((e, idx) => {
      status = smsApiMessageTypes.find(n => n.code === e.statusCode)
      responseStatus = smsResponseTypes.find(n => n.status === e.responseStatus)
      clientType = clientTypes.find(n => n.type === e.userReaction?.clientType)
      return {
        columns: [
          `${(selectedPage - 1) * BID_PER_PAGE + (idx + 1)}.`,
          e.eventName,
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.phone,
          status 
          ? <div style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={status.color}/>
              </svg></div>
              <span style={{ wordBreak: 'keep-all'}}>{status.label}</span>
            </div> 
          : '-',
          responseStatus
            ? <div style={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'flex-start'
              }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={responseStatus.color}/>
              </svg></div>
              <span {...(e.response != null ? {'data-tip': `Odpowiedź użytkownika: "${e.response}"`} : {})}>
                {responseStatus.label}
              </span>
            </div> 
            : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          clientType
          ? <div style={{
              display: 'flex',
              width: 'auto',
              justifyContent: 'flex-start'
            }}>
            <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="11" r="7" fill={clientType.color}/>
            </svg></div>
            <span {...(e.userReaction != null ? {'data-tip': e.userReaction?.message} : {})}>
              {clientType.label}
            </span>
          </div> 
          : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          e.userReaction
          ? getTimeDiffInString(e.userReactionTime)
          : '-'
        ]
      }
    })
    setTableData(mapped)
  }, [wonBidList])
  
  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowEditUserDialog(false)
      setShowActivateDialog(false)
      setShowDeactivateDialog(false)
    }
  }, [message])

  const closeConfirmDeactivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const closeConfirmActivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const confirmDeactivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_deactivate_child_account_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              marginRight: '5px',
              flexGrow: 0
            }}
            onClick={() => {
              closeConfirmDeactivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.deactivateChildAccount(uuid)
                await fetchAccountDetails(uuid, getFromQuery(query, 'assume'))
                setIsSubmitting(false)
                closeConfirmDeactivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const confirmActivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_activate_child_account_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmActivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.activateChildAccount(uuid)
                await fetchAccountDetails(uuid, getFromQuery(query, 'assume'))
                setIsSubmitting(false)
                closeConfirmActivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                closeConfirmActivateDialog()
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const resetEditUserFormState = () => {
    if (editUserFormRef.current) {
      editUserFormRef.current.resetForm()
      setEditUserFormInitialData()
    }
  }

  const closeEditUserDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowEditUserDialog(false)
  }

  const handleEditUserSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.editChildAccount(uuid, {
        name: data.name.trim()
      })
      await fetchAccountDetails(uuid, getFromQuery(query, 'assume'))
      setIsSubmitting(false)
      closeEditUserDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const editUserDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('edit_child_account_section_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                resetEditUserFormState()
                closeEditUserDialog()
              }}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={editUserFormRef}
          validationSchema={addChildAccountValidation(tMyAgency)}
          enableReinitialize
          initialValues={editUserInitialValues}
          onSubmit={handleEditUserSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
            >
              <div className={classes.rowContainer} style={{
                marginBottom: '15px'
              }}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_form_name_field_label')}
                  </p>
                  <BaseInput
                    count
                    maxLen={120}
                    name="name" 
                    placeholder={t('add_user_form_name_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenUserName}
                    setValue={setChosenUserName}
                    error={
                      formikProps.touched.name &&
                      Boolean(formikProps.errors.name)
                    }
                  />
                </div>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_form_email_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={120}
                    name="email" 
                    disabled={true}
                    placeholder={t('add_user_form_email_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenUserEmail}
                    setValue={setChosenUserEmail}
                    error={
                      formikProps.touched.email &&
                      Boolean(formikProps.errors.email)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('save_button_label')}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <>

      <ReactTooltip 
        multiline
        effect={'solid'} 
        textColor={'black'} 
        backgroundColor={'white'}
        arrowColor={'#ffffff00'}
        className={classes.tooltip}
      /> 
      <CustomDialog 
        hidden={!showEditUserDialog} 
        style={{
          position: 'absolute',
          background: 'white',
          width: '80%',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: '10%'
        }}
      >
        {editUserDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showDeactivateDialog}
      >
        {confirmDeactivateDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showActivateDialog}
      >
        {confirmActivateDialogContent()}
      </CustomDialog>

      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        {
          assumedIdentity
          ? <div className={classes.assumedUserInfo}>
              <div
                style={{
                  marginRight: '10px'
                }}
              >
                {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
              </div>
              <CustomButton 
                textComponent={
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-1px',
                    fontSize: '18px'
                  }}>
                    <OpenInNewOutlined fontSize={'inherit'}/>
                    <span style={{
                      marginLeft: '3px',
                      marginTop: '2px',
                      fontSize: '12px',
                      fontFamily: 'Avenir'
                    }}>{t('close_user_assume_view_button_label')}</span>
                  </div>
                }
                style={{
                  width: '200px',
                  maxWidth: '200px',
                  height: '35px',
                  paddingLeft: '10px',
                  paddingRight: '15px',
                  paddingTop: '7px'
                }}
                onClick={() => {
                  window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                }}
              />
            </div>
          : null
        }
        { details ? <div className={classes.topContainer}>
          <div style={{
            fontFamily: 'Avenir',
            textTransform: 'uppercase',
            marginRight: '15px',
            marginTop: '6px',
            fontSize: '16px',
            wordWrap: 'break-word',
            overflow: 'auto'
          }}>
            {`Konto agenta: ${details?.name ?? '-'}`}
          </div>
          {
            assumedIdentity ? null
            : <div className={classes.topButtonsContainer}>
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-4px',          
                      fontSize: '18px'
                    }}>
                      <EditOutlinedIcon fontSize="inherit" />
                      <span style={{
                        paddingTop: '3px',
                        fontSize: '14px',
                        fontFamily: 'Avenir',
                        marginLeft: '3px'
                      }}>{t('show_user_edit_dialog_button_label')}</span>
                    </div>
                  }
                  style={{
                    maxWidth: '220px',
                    flexShrink: 0,
                    marginRight: '5px',
                    height: '30px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowEditUserDialog(true)
                  }}
                /> 
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-4px',
                      fontSize: '14px'
                    }}>
                      <span style={{
                        paddingTop: '3px',
                        fontSize: '14px',
                        fontFamily: 'Avenir'
                      }}>
                        {
                          details.active 
                          ? t('deactivate_child_account_button_label') 
                          : t('activate_child_account_button_label')
                        }
                      </span>
                    </div>
                  }
                  style={{
                    maxWidth: details.active ? '200px' : '200px',
                    flexShrink: 0,
                    height: '30px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    backgroundColor: details.active ? Colors.RED_BUTTON_COLOR : Colors.BLUE_BUTTON_COLOR
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    details.active ? setShowDeactivateDialog(true) : setShowActivateDialog(true)
                  }}
                />
              </div>
          }
          
        </div> : null }

        <div className={classes.controlRow}>
          <div className={classes.dateRangeField}>
            <div className={classes.dateRangeLabel}>
              {t('date_range_label')}
            </div>
            <div 
              className={classes.clearButton} 
              onClick={() => {
                setSelectedDateRange(null)
              }}
            >
              {t('clear_field_button_label')}
            </div> 
            <Flatpickr
              id={"date-range-picker"}
              data-enable-time
              value={
                selectedDateRange && selectedDateRange.length >= 2
                ? [
                  moment(selectedDateRange[0]).startOf("day").toDate(),
                  moment(selectedDateRange[1]).startOf("day").toDate(),
                ]
                : []
              }
              options={{
                locale: {
                  firstDayOfWeek: 1,
                  rangeSeparator: ' - ',
                  months: {
                    shorthand: monthList,
                    longhand: monthList
                  },
                  weekdays: {
                    shorthand: dayListShorthand,
                    longhand: dayList
                  }
                },
                dateFormat: "d.m.Y",
                mode: 'range',
              }}
              onClose={(date) => {
                if (date && date.length === 2) {
                  setSelectedDateRange(date)
                }
              }}
              render={({ defaultValue, value, ...props }, ref) => {
                return (
                  <CustomFlatpickrInput
                    defaultValue={defaultValue}
                    inputRef={ref}
                    width={'100%'}
                  />
                )
              }}
            />
          </div>
        </div>

        <div className={classes.statTileSection}>
          <div className={classes.msgSentTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F6AE3F'}
              label={'Wysłane wiadomości'}
              iconSrc={sendIcon}
              value={`${bidStats.messageSentCount}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F64A3F'}
              label={'Odpowiedzi'}
              iconSrc={dialogIcon}
              value={`${bidStats.responsesCount}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#438737'}
              label={'Odpowiedzi agenta'}
              iconSrc={formIcon}
              value={`${bidStats.userReactionCount}`}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>
        </div>

        <div style={{
          width: '100%'
        }}>
          <CustomTable 
            columnData={tableColumnData}
            content={tableData}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            reloadingData={isReloadingData}
            placeholder={
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontFamily: 'AvenirMedium',
                  color: Colors.TABLE_HEADER_LABEL,
                  fontSize: '16px'
                }}
              >
                <div>
                  {t('no_data_in_table_placeholder')}
                </div>
              </div>
              }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChildAccountDetails
