import { CircularProgress } from "@material-ui/core"
import React from "react"
import { loadingButtonStyle } from "./loadingButtonStyle"

type Props = {
  text?: string
  children?: React.ReactNode | React.ReactNode[]
  disabled?: boolean
  loading?: boolean
  variant?: "outlined" | "contained"
  className?: string
  circleSize?: number
  type?: "button" | "submit" | "reset" | undefined
  style?: React.CSSProperties
  onClick?: any
}

const LoadingButton = (props: Props) => {
  const classes = loadingButtonStyle()

  return (
    <button
      type={props.type ?? "button"}
      disabled={props.loading || props.disabled}
      style={{ 
        position: "relative",
        height: '46px',
        ...props.style 
      }}
      className={props.className || classes.loadingButton}
      onClick={props.onClick}
    >
      <span
        style={{
          position: "absolute",
          top: "53%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {
          props.loading ? (
            <CircularProgress color='secondary'  thickness={7} size={props.circleSize ?? 25} />
          ) : <>{props.text ?? props.children}</>
        }
      </span>
    </button>
  )
}

export default LoadingButton
