import { ErrorMessage, Field, Formik, FormikProps } from 'formik'
import CustomNumberInputField from '../CustomNumberInputField/CustomNumberInputField'
import { baseNumberInputStyle } from './baseNumberInputStyle'

type Props = {
  name: string
  label?: string
  placeholder?: string
  error?: boolean
  labelStyle?: React.CSSProperties
  labelClassName?: string,
  style?: React.CSSProperties
  integers?: boolean
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  monetaryValue?: boolean
  disabled?: boolean
}

const BaseNumberInput = (props: Props) => {
  const classes = baseNumberInputStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()

  return (
    <div style={{ 
      minHeight: props.label ? "85px" : '62px',
      marginTop: 0,
      ...(props.style || {})
    }}>
      {
        props.label ? <label htmlFor={props.name}>
        <div
          className={props.labelClassName || classes.label}
          style={{
            ...(props.labelStyle || {})
          }}
        >
          {props.label}
        </div>
        </label> : null 
      }
      <Field
        id={props.name}
        name={props.name}
        as={CustomNumberInputField}
        className={classes.textField}
        error={props.error}
        value={props.value}
        disabled={props.disabled}
        setValue={props.setValue}
        placeholder={props.placeholder}
        formikProps={props.formikProps}
        monetaryValue={props.monetaryValue}
        integers={props.integers}
        errorLabel={props.error && <ErrorMessage name={props.name} />}
      />
    </div>
  )
}

export default BaseNumberInput
