import { Tag } from 'bbcode-to-react'

export default class ParagraphTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <p>
        {this.getComponents()}
      </p>
    )
  }
}