import { addBulkActionInfoFormStyle } from './addBulkActionInfoFormStyle'
import { Formik, Form, ErrorMessage } from 'formik'
import React, { useRef } from 'react'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { useAddBulkActionInfoForm } from './useAddBulkActionInfoForm'
import { getFromQuery, useQuery } from '../../../utils/common'
import { useTranslation } from 'react-i18next'
import { AdvertType } from '../../../types/advert'
import LoadingButton from '../LoadingButton/LoadingButton'
import CustomFlatpickrInput from '../CustomFlatpickrInput/CustomFlatpickrInput'
import Flatpickr from 'react-flatpickr'
import { monthList, dayListShorthand, dayList, MSG_SIGNATURE_LEN, TEMPLATE_MSG_LEN, TEMPLATE_MAX_MSG, TEMPLATE_MULTIPLE_MSG_LEN } from '../../../types/common'
import moment from 'moment'
import DataApi from '../../../api/DataApi'
import { CircularProgress } from '@material-ui/core'
import BaseInput from '../BaseInput/BaseInput'
import { smsMessagePattern } from '../../../utils/validations/validationRegexps'
import { evalStringTemplate } from '../../../utils/text'
import Checkbox from '../../../lib/react-custom-checkbox/dist'
import { Colors } from '../../../types/colors'
import { Check, EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import trashIcon from '../../../assets/images/Trash.webp'
import { addNotificationEmail } from '../../../utils/validations/addNotificationEmail'
import CloseIcon from '@material-ui/icons/Close'
import { addBulkActionInfoFormValidation } from '../../../utils/validations/addBulkActionInfoFormValidation'

type Props = {
  reset?: boolean
  propertyType: AdvertType
  estimatedAdvertCount?: number
  onFinish: (info: any) => void
  onBack?: () => void
}

const AddBulkActionInfoForm = (props: Props) => {
  const classes = addBulkActionInfoFormStyle()

  const query = useQuery()
  const t = useTranslation('forms').t
  const tEventList = useTranslation('eventList').t

  const formRef = useRef() as any
  const customTemplateFormRef = useRef() as any
  const emailsFormRef = useRef() as any
  const dateRangeFormRef = useRef() as any

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [chosenName, setChosenName] = React.useState<string>('')
  const [chosenSignature, setChosenSignature] = React.useState<string>("")
  const [chosenSmsTemplates, setChosenSmsTemplates] = React.useState<string[]>([])
  const [chosenCustomSmsTemplate, setChosenCustomSmsTemplate] = React.useState<string>('')
  const [tempCustomSmsTemplate, setTempCustomSmsTemplate] = React.useState<string>('')
  const [chosenNotificationEmail, setChosenNotificationEmail] = React.useState<string>("")
  const [chosenNotificationEmails, setChosenNotificationEmails] = React.useState<string[]>([])
  const [chosenSentAtDate, setChosenSentAtDate] = React.useState<Date | null>(null)

  const [isEditingCustomTemplate, setIsEditingCustomTemplate] = React.useState<boolean>(false)

  const { 
    smsTemplates,
    fetchSmsTemplateList
	} = useAddBulkActionInfoForm()

  const resetFormState = () => {
    setChosenName('')
    setChosenSignature('')
    setChosenSentAtDate(null)
    setChosenNotificationEmail('')
    setChosenNotificationEmails([])
    setChosenCustomSmsTemplate('')
    setIsEditingCustomTemplate(false)
    setTempCustomSmsTemplate('')
    setChosenSmsTemplates([])
    dateRangeFormRef?.current?.flatpickr.clear()
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  React.useEffect(() => {
    resetFormState()
  }, [props.reset])

  React.useEffect(() => {
    fetchSmsTemplateList(getFromQuery(query, 'assume')).then(res => {
      setIsLoading(false)
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  const resetCustomTemplateFormState = () => {
    setTempCustomSmsTemplate('')
    if (customTemplateFormRef.current) {
      customTemplateFormRef.current.resetForm()
    }
  }

  const resetEmailsFormState = () => {
    setChosenNotificationEmail('')
    if (emailsFormRef.current) {
      emailsFormRef.current.resetForm()
    }
  }

  const handleSubmit = async (data) => {
    try {
      setIsSubmitting(true)
      const mappedData = {
        name: data.name === '' ? undefined : data.name.trim(),
        notificationEmails: data.notificationEmails,
        smsMessageUuids: data.smsTemplate,
        customMessage: data.customTemplate === '' ? undefined :data.customTemplate,
        signature: !data.signature || data.signature === '' ? undefined : data.signature,
        sentAt: moment(data.sentAt).format(),
      }
      await DataApi.checkBulkActionName({ name: data.name === '' ? undefined : data.name.trim()})
      props.onFinish(mappedData)
      // resetFormState()
      setIsSubmitting(false)
    } catch (error) {
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }
  
  const handleEmailFormSubmit = (data: any, form: any) => {
    const newArray = [...chosenNotificationEmails]
    newArray.push(data.notificationEmail.trim())
    setChosenNotificationEmails(newArray)
    formRef.current.setFieldValue('notificationEmails', newArray)
    resetEmailsFormState()
  }

  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div 
        className={classes.container}
        style={{
          display: isLoading ? 'none' : 'block'
        }}
      >
        {
          props.estimatedAdvertCount != null
          ? <div className={classes.estimatedAdvertCountContainer}>
              <div className={classes.estimatedAdvertCountBlock}>
                <div className={classes.estimatedAdvertCountTitle}>
                  {t('estimated_advert_count_title')}
                </div>
                <div className={classes.estimatedAdvertCountCount}>
                  {evalStringTemplate(t('estimated_advert_count'), { count: props.estimatedAdvertCount })}
                </div>
              </div>
            </div>
          : null
        }

        {
          props.estimatedAdvertCount > 0
          ? <Formik
              innerRef={formRef}
              validationSchema={addBulkActionInfoFormValidation(tEventList)}
              initialValues={{
                name: '',
                sentAt: null,
                signature: '',
                smsTemplate: [] as string[],
                notificationEmails: [] as string[],
                customTemplate: ''
              }}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <Form
                  onKeyDown={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault()
                    }
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <p className={classes.fieldLabel}>
                      {t('bulk_action_name_field_label')}
                    </p>
                    <BaseInput 
                      count
                      maxLen={240}
                      name='name' 
                      placeholder={t('bulk_action_name_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenName}
                      setValue={setChosenName}
                      error={
                        formikProps.touched.name &&
                        Boolean(formikProps.errors.name)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel} style={{width: '40%', marginBottom: '30px'}}>
                    <p className={classes.fieldLabel}>
                      {t('bulk_action_planned_send_date_field_label')}
                    </p>
                    <div 
                      className={`${classes.clearButton}`} 
                      onClick={() => {
                        setChosenSentAtDate(null)
                        formikProps.setFieldValue('sentAt', null)
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <Flatpickr
                      id={'date-range-picker'}
                      ref={dateRangeFormRef}
                      data-enable-time
                      value={
                        chosenSentAtDate
                        ? [chosenSentAtDate]
                        : null
                      }
                      options={{
                        locale: {
                          firstDayOfWeek: 1,
                          rangeSeparator: ' - ',
                          months: {
                            shorthand: monthList,
                            longhand: monthList
                          },
                          weekdays: {
                            shorthand: dayListShorthand,
                            longhand: dayList
                          }
                        },
                        dateFormat: 'd.m.Y H:i',
                        mode: 'single',
                        minDate: moment().add(1, 'hour').startOf('hour').toDate(),
                        time_24hr: true,
                        enableTime: true
                      }}
                      onClose={(date) => {
                        setChosenSentAtDate(date[0])
                        formikProps.setFieldTouched('sentAt', true)
                        formikProps.setFieldValue('sentAt', date[0])
                      }}
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomFlatpickrInput
                            defaultValue={defaultValue}
                            placeholder={'Wybierz datę i czas'}
                            inputRef={ref}
                            width={'100%'}
                          />
                        )
                      }}
                    />
                    <div className={classes.errorLabel} style={{
                      float: 'left'
                    }}>
                      <ErrorMessage name={'sentAt'} />
                    </div>
                  </div>
                  <div className={classes.sectionLabel}>
                    {t('sms_content_settings_section_label')}
                  </div>
                  <div className={classes.formSectionWarningLabel}>
                    {t('sms_settings_warning_label')}
                  </div>
                  <div 
                    className={classes.fieldWithLabel} 
                    style={{
                      width: '50%'
                    }}
                  >
                    <p className={classes.fieldLabel}>
                      {t('signature_field_label')}
                    </p>
                    <BaseInput 
                      count
                      maxLen={MSG_SIGNATURE_LEN}
                      name="signature" 
                      inputPattern={smsMessagePattern}
                      placeholder={t('signature_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenSignature}
                      setValue={setChosenSignature}
                      error={
                        formikProps.touched.signature &&
                        Boolean(formikProps.errors.signature)
                      }
                    />
                  </div>
                  <div style={{
                    marginTop: '10px',
                  }}>
                    <div className={classes.fieldLabel}>
                      {evalStringTemplate(t('sms_content_field_label'), {
                        cur: formikProps.values['smsTemplate'].length,
                        max: smsTemplates.filter(e => !e.removed && `${e.advertType}` === `${props.propertyType}`).length,
                        custom: chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' ? ' + wiadomość własna' : ''
                      })}
                    </div>
                    <div className={classes.fieldSubLabel} style={{ marginTop: '15px' }}>
                      {t('available_templates_label')}
                    </div>
                    <div style={{ marginBottom: '25px'}}>
                    {
                      smsTemplates.filter(e => !(e.removed  || `${e.advertType}` !== `${props.propertyType}`)).length === 0
                      ? <div
                          style={{
                            height: '30px',
                            marginTop: '10px',
                            marginLeft: '5px',
                            fontFamily: 'AvenirBook',
                            fontSize: '15px',
                            color: 'rgba(109,114,120,0.5)'
                          }}
                        >
                          {t('no_templates_available_label')}
                        </div>
                      : smsTemplates.map(e => (
                        <>
                          {
                            e.removed || `${e.advertType}` !== `${props.propertyType}`
                            ? null
                            : <div style={{
                                marginBottom: '2px',
                              }}>
                                <Checkbox 
                                  name="smsTemplate"
                                  checked={chosenSmsTemplates.includes(e.uuid)}
                                  disabled={!chosenSmsTemplates.includes(e.uuid) && chosenSmsTemplates.length >= smsTemplates.length }
                                  onBlur={() => {
                                    formikProps.setFieldTouched('smsTemplate')
                                  }}
                                  onChange={(checked) => {
                                    formikProps.setFieldTouched('smsTemplate')
                                    if (!checked && formikProps.values['smsTemplate'].includes(e.uuid)) {
                                      const indexToDelete = formikProps.values['smsTemplate'].indexOf(e.uuid)
                                      const newArray = [...formikProps.values['smsTemplate']]
                                      newArray.splice(indexToDelete, 1)
                                      formikProps.setFieldValue('smsTemplate', newArray)
                                      setChosenSmsTemplates(newArray)
                                    } else if (checked  && !formikProps.values['smsTemplate'].includes(e.uuid)) {
                                      formikProps.setFieldValue('smsTemplate', [
                                        ...formikProps.values['smsTemplate'],
                                        e.uuid
                                      ])
                                      setChosenSmsTemplates([
                                        ...formikProps.values['smsTemplate'],
                                        e.uuid
                                      ])
                                    }
                                  }}
                                  borderRadius={3}
                                  size={15}
                                  containerStyle={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    marginTop: '5px'
                                  }}
                                  style={{
                                    marginTop: '2px'
                                  }}
                                  icon={
                                    <div style={{
                                      fontSize: '15px',
                                      borderRadius: 3,
                                      height: '15px',
                                      color: Colors.CHECKBOX_ICON,
                                      backgroundColor: Colors.CHECKBOX_CHECKED,
                                      margin: 0
                                    }}>
                                      <Check fontSize={'inherit'} color={'inherit'}/>
                                    </div>
                                  }
                                  borderColor={Colors.CHECKBOX_BORDER}
                                  labelStyle={{
                                    fontFamily: 'AvenirBook',
                                    color: Colors.LIGHT_GRAY_TEXT,
                                    fontSize: '15px',
                                    paddingLeft: '5px',
                                    whiteSpace: 'break-spaces'
                                  }}
                                  label={
                                    <>
                                      {
                                        e.template
                                      }{
                                        e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                          ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                          : null
                                      }
                                    </>
                                  }
                                  />
                              </div>
                          }
                        </>
                      ))
                    }
                    {
                      formikProps.touched.smsTemplate && Boolean(formikProps.errors.smsTemplate)
                      ? <div className={classes.errorLabel} style={{
                        float: 'left'
                      }}>
                        <ErrorMessage name={'smsTemplate'} />
                      </div> : null
                    }
                    </div>
                    {
                      chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' && !isEditingCustomTemplate
                      ? <>
                          <div className={classes.fieldSubLabel} style={{marginTop: '20px'}}>
                            {t('custom_templates_label')}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              alignItems: 'flex-start'
                            }}
                          >
                            <div
                              style={{
                                width: '90%',
                                fontFamily: 'AvenirBook',
                                color: Colors.LIGHT_GRAY_TEXT,
                                fontSize: '15px',
                                paddingLeft: '5px',
                                whiteSpace: 'break-spaces',
                                marginTop: '3px',
                                wordBreak: 'break-all'
                              }}
                            >
                              {chosenCustomSmsTemplate}{
                                chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                  ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                  : null
                              }
                            </div>
                            <div
                              style={{
                                width: '5%',
                                textAlign: 'center',
                                color: 'blue',
                                fontSize: '22px',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setIsEditingCustomTemplate(true)
                                setTempCustomSmsTemplate(chosenCustomSmsTemplate)
                              }}
                            >
                              <EditOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                            </div>
                            <div
                              style={{
                                width: '5%',
                                textAlign: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setChosenCustomSmsTemplate('')
                                formikProps.setFieldValue('customTemplate', '')
                              }}
                            >
                              <img src={trashIcon} alt="remove-item" />
                            </div>

                          </div>
                        </>
                      : <>
                          <Formik
                            innerRef={customTemplateFormRef}
                            validateOnMount={true}
                            enableReinitialize
                            initialValues={{
                              customTemplate: tempCustomSmsTemplate
                            }}
                            onSubmit={(data) => {
                              setChosenCustomSmsTemplate(data.customTemplate)
                              setIsEditingCustomTemplate(false)
                              formikProps.setFieldValue('customTemplate', data.customTemplate)
                              resetCustomTemplateFormState()
                            }}
                          >
                            {(customTemplateFormikProps) => (
                              <Form
                                onKeyDown={(keyEvent) => {
                                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                    keyEvent.preventDefault()
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  marginTop: '20px'
                                }}
                              >
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}>
                                  <div 
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                    }}
                                  >
                                    <p className={classes.fieldLabel}>
                                      {t('add_custom_template_label')}
                                    </p>
                                    <BaseInput 
                                      count
                                      maxLen={
                                        TEMPLATE_MAX_MSG > 1
                                        ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                                        : TEMPLATE_MAX_MSG
                                      }
                                      rows={4}
                                      name="customTemplate"
                                      placeholder={t('add_custom_template_placeholder')}
                                      formikProps={customTemplateFormikProps}
                                      inputPattern={smsMessagePattern}
                                      value={tempCustomSmsTemplate}
                                      setValue={setTempCustomSmsTemplate}
                                      error={
                                        customTemplateFormikProps.touched.customTemplate &&
                                        Boolean(customTemplateFormikProps.errors.customTemplate)
                                      }
                                    />
                                  </div>
                                </div>
                                <div 
                                  style={{
                                    marginRight: '10%',
                                    fontFamily: 'AvenirBook',
                                    fontSize: '14px',
                                    marginTop: '-15px',
                                    marginBottom: '37px'
                                  }}
                                >
                                  {
                                    tempCustomSmsTemplate.length > 0 ? <>
                                      {`Liczba wiadomości:\u00a0`}{
                                        tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN
                                          ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{Math.ceil((tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)}</span>
                                          : 1
                                      }
                                    </> : '\u00A0'
                                  }
                                </div>
                                <div style={{
                                  float: 'right',
                                  marginTop: '-45px'
                                }}>
                                  <LoadingButton 
                                    children={
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          fontSize: '15px'
                                        }}
                                      >
                                        {
                                          isEditingCustomTemplate 
                                          ? <>
                                              <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                                <EditOutlinedIcon fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Zapisz
                                              </div>
                                            </>
                                          : <>
                                              <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                                <Check fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Dodaj
                                              </div>
                                            </>
                                        }

                                      </div>
                                    }
                                    type='submit'
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      customTemplateFormikProps.submitForm()
                                    }}
                                    style={{
                                      width: '150px',
                                      maxWidth: '150px',
                                      height: '30px',
                                      marginTop: '5px',
                                      marginLeft: '5px',
                                      backgroundColor: '#438737'
                                    }}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </> 
                    }
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <div className={classes.sectionLabel}>
                      {t('email_notifications_search_params_section_label')}
                    </div>
                    <div
                      className={classes.fieldWithLabel} 
                      style={{
                        width: '100%',
                        marginTop: '10px'
                      }}
                    >
                      <p className={classes.fieldLabel}>
                        {t('notifications_emails_list_field_label')}
                      </p>
                      {
                        chosenNotificationEmails.length === 0
                        ? <div
                            style={{
                              height: '30px',
                              marginTop: '10px',
                              marginLeft: '5px',
                              fontFamily: 'AvenirBook',
                              fontSize: '15px',
                              color: 'rgba(109,114,120,0.5)'
                            }}
                          >
                            {t('no_emails_added_label')}
                          </div>
                        : <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap'
                            }}
                          >
                            {
                              chosenNotificationEmails.map((e: string, idx: number) => {
                                return (
                                  <div className={classes.notificationEmail}>
                                    <div>
                                      {e}
                                    </div>
                                    <div 
                                      style={{
                                        marginTop: '7px',
                                        fontSize: '20px'
                                      }}
                                    >
                                      <span 
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                          const newArray = [...chosenNotificationEmails]
                                          newArray.splice(idx, 1)
                                          setChosenNotificationEmails(newArray)
                                          formikProps.setFieldValue('notificationEmails', newArray)
                                        }}
                                      >
                                        <CloseIcon fontSize={'inherit'} color={'inherit'} />
                                      </span>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                      }
                    </div>
                    <div
                      className={classes.fieldWithLabel} 
                      style={{
                        width: '100%',
                        marginTop: '15px'
                      }}
                    >
                      <p className={classes.fieldLabel}>
                        {t('notification_email_field_label')}
                      </p>
                      <Formik
                        innerRef={emailsFormRef}
                        validateOnMount={true}
                        enableReinitialize
                        validationSchema={addNotificationEmail(tEventList, chosenNotificationEmails)}
                        initialValues={{
                          notificationEmail: ''
                        }}
                        onSubmit={handleEmailFormSubmit}
                      >
                        {(emailsFormikProps) => (
                          <Form
                            onKeyDown={(keyEvent) => {
                              if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                keyEvent.preventDefault()
                              }
                            }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: '100%'
                              }}
                            >
                              <div
                                style={{
                                  width: '47%'
                                }}
                              >
                                <BaseInput 
                                  name="notificationEmail" 
                                  placeholder={t('notification_email_placeholder')}
                                  formikProps={emailsFormikProps}
                                  value={chosenNotificationEmail}
                                  setValue={setChosenNotificationEmail}
                                  error={
                                    emailsFormikProps.touched.notificationEmail &&
                                    Boolean(emailsFormikProps.errors.notificationEmail)
                                  }
                                />
                              </div>
                              <LoadingButton 
                                children={
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      fontSize: '15px'
                                    }}
                                  >
                                    <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                      <Check fontSize={'inherit'}/>
                                    </div>
                                    <div>
                                      Dodaj
                                    </div>
                                  </div>
                                }
                                onClick={(ev) => {
                                  ev.preventDefault()
                                  emailsFormikProps.submitForm()
                                }}
                                style={{
                                  width: '150px',
                                  maxWidth: '150px',
                                  height: '30px',
                                  marginTop: '5px',
                                  marginLeft: '5px',
                                  backgroundColor: '#438737'
                                }}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div style={{
                    float: 'right',
                    marginBottom: '30px'
                  }}>
                    <LoadingButton 
                      text={t('next_step_button_label')}
                      type='submit'
                      loading={isSubmitting}
                      style={{
                        width: '150px',
                        maxWidth: '150px',
                        height: '40px'
                      }}
                    />
                  </div>
                  {
                    props.onBack != null
                    ? <div style={{
                        float: 'left',
                        marginBottom: '30px'
                      }}>
                        <LoadingButton 
                          text={t('back_button_label')}
                          type='button'
                          loading={isSubmitting}
                          onClick={() => {
                            resetFormState()
                            props.onBack()
                          }}
                          className={classes.backButton}
                          style={{
                            height: '40px'
                          }}
                        />
                      </div>
                    : null
                  }
                  
                </Form>
              )}
            </Formik>
          : null
        }
        
      </div>
    </>
  )
}

export default AddBulkActionInfoForm