import { ADD_ERROR_MESSAGE, ADD_SUCCESS_MESSAGE, CLEAR_MESSAGES, ADD_CMD_MESSAGE } from './types'
import { AnyAction } from "redux"


export type MessagesState = {
    success: string
    error: string 
    cmd: string 
}


export const initialMessagesValue: MessagesState = {
    success: "",
    error: "",
    cmd: ""
}

export const messages = (state: MessagesState = initialMessagesValue, action: AnyAction) => {
    switch(action.type) {
        case ADD_ERROR_MESSAGE:
            return ({...state, ...action.messages})
        case ADD_SUCCESS_MESSAGE:
            return ({...state, ...action.messages})
        case ADD_CMD_MESSAGE:
            return ({...state, ...action.messages})
        case CLEAR_MESSAGES:
            return ({...state, ...action.messages})
        default:
            return state
    }
}