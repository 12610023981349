import * as Yup from "yup"
import { forbiddenSpecialCharacters, email } from './validationRegexps'

export const addNotificationEmail = (t: (val: string) => string, emailList: string[]) => {
	return Yup.object({
		notificationEmail: Yup.string()
			.required(t('common:field_required'))
			.matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
      .matches(email, t("auth:invalid_email_validation"))
			.test('email_exists', t('common:email_already_added'), function (val: any) {
				return !emailList.includes(val)
			}),
	})
}
