import { useLocation } from 'react-router-dom'
import slugify from 'slugify'
import { PermissionLevel } from '../types/auth'

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getFromQuery(query: URLSearchParams, name: string) {
  return query.get(name) ?? undefined
}

export function getTimeDiffInString (diff: number) {
  const minutes = Math.floor(diff / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  let str = ''
  if (days > 0) {
    str += `${days} dni `
  }
  if (hours % 24 > 0) {
    str += `${hours % 24}h `
  }
  if (minutes % 60 > 0) {
    str += `${minutes % 60}min. `
  }
  if (diff % 60 > 0) {
    str += `${diff % 60}s`
  }
  return str
}

export function preventReturnKey (keyEvent: React.KeyboardEvent<HTMLElement>) {
  if (keyEvent.key === 'Enter' || (keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export function slugifyString(str: string): string {
  return slugify(str.replace(/[-_]/g, ' '), {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true
  })
}

export function checkIfAdmin(permissions: PermissionLevel[]): boolean {
  return permissions.includes(PermissionLevel.ADMIN) || permissions.includes(PermissionLevel.SUPERADMIN)
}
