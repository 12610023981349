import { BASE_DATA } from "./baseRoutes"
import { BaseApiWithRefresh } from './BaseApiWithRefresh'
import { SortDirection } from '../components/CustomTable/util'
import { AdvertType } from '../types/advert'
import moment from 'moment'

// TODO implement response types
class DataApi extends BaseApiWithRefresh {
  constructor() {
    super({
      baseURL: `${BASE_DATA}/api/v1/sms/`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  public getActiveConfig() {
    return this.get<any>(`/auction/config/active`)
  }

  public addNewActiveConfig(data: any) {
    return this.post<any>(`/auction/config/add`, JSON.stringify(data))
  }

  public getRegionList(getAll?: boolean, assumeUuid?: string) {
    const params: string[] = []
    if (getAll != null) {
      params.push(`get_all=${getAll}`)
    }
    return this.get<any>(`/location/region/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSearchParamsList(sortName?: string, sortDirection?: SortDirection, from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/search/params/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSuperAdminSearchParamsList(sortName?: string, sortDirection?: SortDirection, from?: string, to?: string) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/search/params/superadmin/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getSearchParamDetails(uuid: string, assumeUuid?: string) {
    return this.get<any>(`/search/params/${uuid}/details`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSuperAdminSearchParamDetails(uuid: string) {
    return this.get<any>(`/search/params/${uuid}/superadmin/details`)
  }

  public editSearchParamDetails(uuid: string, data: any) { // TODO type
    return this.patch<any>(`/search/params/${uuid}/edit`, JSON.stringify(data))
  }

  public editSuperAdminSearchParamDetails(uuid: string, data: any) { // TODO type
    return this.patch<any>(`/search/params/${uuid}/superadmin/edit`, JSON.stringify(data))
  }

  public getSearchParamsStats(from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/search/params/list/stats${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSearchParamWonBidCount(uuid: string, from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/search/params/${uuid}/bid/won/count${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSearchParamWonBidList(
    uuid: string,
    limit: number = 50,
    offset: number = 0,
    sortName?: string,
    sortDirection?: SortDirection,
    from?: string,
    to?: string,
    assumeUuid?: string
  ) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${encodeURIComponent(sortName)}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${encodeURIComponent(sortDirection)}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/search/params/${uuid}/bid/won/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public addSearchParams(data: any) { // TODO type
    return this.post<any>(`/search/params/add`, JSON.stringify(data))
  }

  public addSuperAdminSearchParams(data: any) { // TODO type
    return this.post<any>(`/search/params/superadmin/add`, JSON.stringify(data))
  }

  public activateSearchParams(uuid: string) {
    return this.patch<any>(`/search/params/${uuid}/active`, JSON.stringify({ state: true }))
  }

  public deactivateSearchParams(uuid: string) {
    return this.patch<any>(`/search/params/${uuid}/active`, JSON.stringify({ state: false }))
  }

  // public removeSearchParams(uuid: string) {
  //   return this.delete<any>(`/search/params/${uuid}/remove`)
  // }

  public getSmsTemplateList(removed?: boolean, limit?: number, offset?: number, propertyType?: AdvertType, assumeUuid?: string) {
    const params: string[] = []
    if (removed != null) {
      params.push(`removed=${removed}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (propertyType != null) {
      params.push(`property_type=${propertyType}`)
    }
    return this.get<any>(`/message/template/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getSmsTemplateCount(propertyType?: AdvertType, assumeUuid?: string) {
    const params: string[] = []
    if (propertyType != null) {
      params.push(`property_type=${propertyType}`)
    }
    return this.get<any>(`/message/template/count${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public addSmsTemplate(data: any) {
    return this.post<any>(`/message/template/add`, JSON.stringify(data))
  }

  public editSmsTemplate(uuid: string, data: any) {
    return this.patch<any>(`/message/template/${uuid}/edit`, JSON.stringify(data))
  }

  public removeSmsTemplate(uuid: string) {
    return this.delete<any>(`/message/template/${uuid}/remove`)
  }

  public getBlacklistedPhonesList(limit: number = 50, offset: number = 0, sortName?: string, sortDirection?: SortDirection) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.get<any>(`/phone/blacklist/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getBlacklistedPhonesCount() {
    return this.get<any>(`/phone/blacklist/count`)
  }

  public addPhoneToBlacklist(data: any) { // TODO type
    return this.post<any>(`/phone/blacklist/add`, JSON.stringify(data))
  }

  public removePhoneToBlacklist(uuid: string) { 
    return this.delete<any>(`/phone/blacklist/${uuid}/remove`)
  }

  public getUsersList(
    limit: number = 50,
    offset: number = 0,
    tagUuids?: string[],
    dataDateRange?: Date[],
    sortName?: string,
    sortDirection?: SortDirection,
  ) { 
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (tagUuids != null && tagUuids.length > 0) {
      params.push(`tags=${tagUuids.join(';')}`)
    }
    if (dataDateRange != null && dataDateRange.length > 0) {
      params.push(`dataFrom=${encodeURIComponent(moment(dataDateRange[0]).format())}`)
      params.push(`dataTo=${encodeURIComponent(moment(dataDateRange[1]).format())}`)
    }
    return this.get<any>(`/users/list${params.length > 0 ? `?${params.join('&')}` : ''}`)
  }

  public getUsersCount(tagUuids?: string[]) { 
    return this.get<any>(`/users/count${tagUuids && tagUuids.length > 0 ? `?tags=${tagUuids.join(';')}` : ''}`)
  }

  public getChildAccountList(limit: number = 50, offset: number = 0, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) { 
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.get<any>(`/users/sub/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getChildAccountCount(assumeUuid?: string) { 
    return this.get<any>(`/users/sub/count`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getUserDetails(uuid: string, assumeUuid?: string) { 
    return this.get<any>(`/users/${uuid}/details`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public addToUserBalance(uuid: string, data: any) { 
    return this.post<any>(`/users/${uuid}/balance/add`, JSON.stringify(data))
  }

  public editUserDetails(uuid: string, data: any) { // TODO type
    return this.patch<any>(`/users/${uuid}/edit`, JSON.stringify(data))
  }

  public activateUser(uuid: string) {
    return this.patch<any>(`/users/${uuid}/active`, JSON.stringify({ state: true }))
  }

  public deactivateUser(uuid: string) {
    return this.patch<any>(`/users/${uuid}/active`, JSON.stringify({ state: false }))
  }

  public getChildAccountDetails(uuid: string, assumeUuid?: string) {
    return this.get<any>(`/users/sub/${uuid}/details`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getChildAccountWonBidCount(uuid: string, from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/message/user/${uuid}/sent/count${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getChildAccountWonBidList(
    uuid: string,
    limit: number = 50,
    offset: number = 0,
    sortName?: string,
    sortDirection?: SortDirection,
    from?: string,
    to?: string,
    assumeUuid?: string
  ) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${encodeURIComponent(sortName)}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${encodeURIComponent(sortDirection)}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/message/user/${uuid}/sent/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public checkIfCanAddReactionToMessage(msgUuid: string, paramUuid: string) {
    return this.get<any>(`/message/${msgUuid}/reaction/check?param=${paramUuid}`)
  }

  public createChildAccount(data: any) {
    return this.post<any>(`/users/sub/create`, JSON.stringify(data))
  }

  public createUserReactionToMessage(uuid: string, data: any) {
    return this.post<any>(`/message/${uuid}/reaction/add`, JSON.stringify(data))
  }

  public editChildAccount(uuid: string, data: any) {
    return this.patch<any>(`/users/sub/${uuid}/edit`, JSON.stringify(data))
  }

  public activateChildAccount(uuid: string) {
    return this.patch<any>(`/users/sub/${uuid}/active`, JSON.stringify({ state: true }))
  }

  public deactivateChildAccount(uuid: string) {
    return this.patch<any>(`/users/sub/${uuid}/active`, JSON.stringify({ state: false }))
  }

  public createBulkAction(data: any) {
    return this.post<any>(`/action/bulk/add`, JSON.stringify(data))
  }

  public calculateAdvertsCount(data: any) {
    return this.post<any>(`/action/bulk/advert/count`, JSON.stringify(data))
  }

  public checkBulkActionName(data: any) {
    return this.post<any>(`/action/bulk/name/check`, JSON.stringify(data))
  }

  public getBulkActionPlannedList(limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.get<any>(`/action/bulk/list/planned${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getBulkActionSentList(limit?: number, offset?: number, from?: string, to?: string, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/action/bulk/list/sent${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getBulkActionSentStats(from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/action/bulk/stats/sent${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getBulkActionWaitingForApprovalList(limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${sortName}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${sortDirection}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    return this.get<any>(`/action/bulk/list/waiting-for-approval${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public acceptBulkAction(uuid: string, newDate?: Date) {
    return this.patch<any>(`/action/bulk/${uuid}/accept`, JSON.stringify(newDate ? {
      newSentAt: moment(newDate).format()
    }: {}))
  }

  public rejectBulkAction(uuid: string) {
    return this.patch<any>(`/action/bulk/${uuid}/reject`, '')
  }

  public cancelBulkAction(uuid: string) {
    return this.patch<any>(`/action/bulk/${uuid}/cancel`, '')
  }

  public getBulkActionDetails(uuid: string, assumeUuid?: string) {
    return this.get<any>(`/action/bulk/${uuid}/details`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getBulkActionStats(uuid: string, from?: string, to?: string, assumeUuid?: string) {
    const params: string[] = []
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/action/bulk/${uuid}/stats${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public getBulkActionMessageList(
    uuid: string,
    limit: number = 50,
    offset: number = 0,
    sortName?: string,
    sortDirection?: SortDirection,
    from?: string,
    to?: string,
    assumeUuid?: string
  ) {
    const params: string[] = []
    if (sortName != null) {
      params.push(`sort_name=${encodeURIComponent(sortName)}`)
    }
    if (sortDirection != null) {
      params.push(`sort_direction=${encodeURIComponent(sortDirection)}`)
    }
    if (limit != null) {
      params.push(`limit=${limit}`)
    }
    if (offset != null) {
      params.push(`offset=${offset}`)
    }
    if (from != null) {
      params.push(`from=${encodeURIComponent(from)}`)
    }
    if (to != null) {
      params.push(`to=${encodeURIComponent(to)}`)
    }
    return this.get<any>(`/action/bulk/${uuid}/message/list${params.length > 0 ? `?${params.join('&')}` : ''}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public searchUserTags(search: string, assumeUuid?: string) {
    return this.get<any>(`/tags/user/search?search=${search}`, {
      headers: {
        ...(assumeUuid ? { 'assume': assumeUuid } : {})
      }
    })
  }

  public sendContactFormData(formData: FormData) {
    return this.post<any>('/faq/contact', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}

export default new DataApi()