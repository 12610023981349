import { makeStyles } from "@material-ui/styles"
import bgImage from "../../../assets/images/login-cover.webp"

export const authCoverStyles = makeStyles(() => ({
  authWrapper: {
    minHeight: "100vh",
    display: "flex",
  },
  authImage: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    minHeight: "100vh",
    width: "100%",
    backgroundPosition: "right",
    '@media (max-width: 1000px)': {
      display: "none"
    }
  },
  authData: {
    minWidth: "47%",
    padding: "80px",
    '@media (max-width: 1000px)': {
      minWidth: "0px",
      width: "100%",
      borderRight: "none",
    }
  },
  title: {
    marginBottom: '20px',
    fontWeight: 200,
    fontSize: "34px",
    opacity: 0.9,
    fontFamily: `"AvenirBook", "Roboto", "Arial", sans-serif`,
    lineHeight: 1.167,
    color: '#2E384D',
    textAlign: 'center'
  },
  subtitle: {
    margin: "0px 0px 40px 0px",
    fontSize: "15px",
    opacity: 0.6,
    fontFamily: `"AvenirBook", "Roboto", "Arial", sans-serif`,
    color: '#2E384D',
    lineHeight: 1.5,
    textAlign: 'center'
  },
  midTitle: {
    marginBottom: '20px',
    marginTop: '-17px',
    fontWeight: 200,
    fontSize: "28px",
    opacity: 0.9,
    fontFamily: `"AvenirBook", "Roboto", "Arial", sans-serif`,
    lineHeight: 1.167,
    color: '#2E384D',
    textAlign: 'center'
  }
}))
