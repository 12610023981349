import React from 'react'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'

export const useSuperEventsDetails = () => {
  const [regions, setRegions] = React.useState<any[]>([])
  const [details, setDetails] = React.useState<any>(null)
  const [wonBidCount, setWonBidCount] = React.useState<number>(0)
  const [bidStats, setBidStats] = React.useState<any>({
    auctionCount: 0,
    messageSentCount: 0,
    responsesCount: 0,
    totalPrice: 0
  })
  const [wonBidList, setWonBidList] = React.useState<any[]>([])

  const fetchRegionList = async (getAll?: boolean) => {
    try {
      const fetchedRegions = await DataApi.getRegionList(getAll)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

  const fetchDetails = async (uuid: string) => {
    try {
      const fetchedDetails = await DataApi.getSuperAdminSearchParamDetails(uuid)
      setDetails(fetchedDetails)
    } catch (error) {
			throw error
    }
  }

  const fetchWonBidCount = async (uuid: string, from?: string, to?: string) => {
    try {
      const count = await DataApi.getSearchParamWonBidCount(uuid, from, to)
      setWonBidCount(count.count)
      setBidStats({
        auctionCount: count.auctionCount,
        messageSentCount: count.messageSentCount,
        responsesCount: count.responsesCount,
        totalPrice: count.totalPrice,
        userReactionCount: count.userReactionCount ?? 0
      })
    } catch (error) {
			throw error
    }
  }

  const fetchWonBidList = async (uuid: string, limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, from?: string, to?: string) => {
    try {
      const list = await DataApi.getSearchParamWonBidList(uuid, limit, offset, sortName, sortDirection, from, to)
      setWonBidList(list)
    } catch (error) {
			throw error
    }
  }

	return { 
    regions, details, wonBidCount, wonBidList, bidStats,
    fetchRegionList, fetchDetails, fetchWonBidCount, fetchWonBidList
	}
}