import * as Yup from "yup"
import { forbiddenSomeSpecialCharactersPassword, passwordRules, englishCharactersAllowedPassword } from "./validationRegexps"

export const setFirstPasswordValidation = (t: (val: string) => string) => {
  return Yup.object({
    password: Yup.string()
      .matches(
        forbiddenSomeSpecialCharactersPassword,
        t("special_characters_password_validation")
      )
      .matches(englishCharactersAllowedPassword, t("only_english_letters_allowed_validation"))
      .matches(passwordRules, t("password_rules_validation"))
      .min(8, t("common:min_8_signs_validation"))
      .max(32, t("common:max_32_signs_validation"))
      .required(t("common:field_required")),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t("different_passwords_validation"))
      .required(t("common:field_required"))
  })
}

