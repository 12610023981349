import React from 'react'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers'
import { checkIfAdmin } from '../../utils/common'

export const useBulkActionsDetails = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [details, setDetails] = React.useState<any>(null)
  const [messageCount, setMessageCount] = React.useState<number>(0)
  const [stats, setStats] = React.useState<any>({
    count: 0,
    responses: 0,
    reactions: 0
  })
  const [messageList, setMessageList] = React.useState<any[]>([])

  const fetchAssumedUser = async (uuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

  const fetchDetails = async (uuid: string, assumeUuid?: string) => {
    try {
      const fetchedDetails = await DataApi.getBulkActionDetails(uuid, assumeUuid)
      setDetails(fetchedDetails)
    } catch (error) {
			throw error
    }
  }

  const fetchStats = async (uuid: string, from?: string, to?: string, assumeUuid?: string) => {
    try {
      const count = await DataApi.getBulkActionStats(uuid, from, to, assumeUuid)
      setMessageCount(count.count)
      setStats({
        count: count.count,
        responses: count.responses,
        reactions: count.reactions
      })
    } catch (error) {
			throw error
    }
  }

  const fetchMessageList = async (uuid: string, limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, from?: string, to?: string, assumeUuid?: string) => {
    try {
      const list = await DataApi.getBulkActionMessageList(uuid, limit, offset, sortName, sortDirection, from, to, assumeUuid)
      setMessageList(list)
    } catch (error) {
			throw error
    }
  }
	return { 
    details, messageCount, messageList, stats, assumedUser,
    fetchDetails, fetchStats, fetchMessageList, fetchAssumedUser
	}
}