import * as Yup from "yup"
import { email, forbiddenSpecialCharacters } from './validationRegexps'

export const addBulkActionInfoFormValidation = (t: (val: string) => string, config?: any) => {
	return Yup.object({
		name: Yup.string().required(t('common:field_required')),
		smsTemplate: Yup.array()
			.test('sms_template_len', t('common:field_required'), function (val: any) {
				const customTemplate = this.resolve(Yup.ref('customTemplate')) as any
				return val.length + (customTemplate != null ? 1 : 0) >= 1
			})
			.required(t('common:field_required')),
		notificationEmails: Yup.array().of(
			Yup.string()
				.matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
				.matches(email, t("auth:invalid_email_validation"))
		),
		sentAt: Yup.date().nullable().required(t('common:field_required'))
	})
}
