import logo from '../../../assets/images/logo.webp'
import { authCoverStyles } from './authCoverStyles'

type Props = {
  children: JSX.Element | JSX.Element[]
  title?: string
  midTitle?: string
  subTitle?: string
}

const AuthCover = (props: Props) => {
  const classes = authCoverStyles()

  return (
    <div className={classes.authWrapper}>
      <div className={classes.authData}>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <img src={logo} alt="Ofertodom SMS logo" />
          </div>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ 
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              {props.title ? (
                <h1 className={classes.title}>
                  {props.title}
                </h1>
              ) : null}
              {props.subTitle ? (
                <h2 className={classes.midTitle}>
                  {props.midTitle}
                </h2>
              ) : null}
              {props.subTitle ? (
                <div className={classes.subtitle}>
                  {props.subTitle}
                </div>
              ) : null}
              <div style={{ maxWidth: "370px", width: '100%' }}>
                {props.children}
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={classes.authImage}></div>
    </div>
  )
}

export default AuthCover
