import { FormikProps } from 'formik';
import React from 'react';
import Switch from 'react-switch';

type Props = {
  id?: string
  name: string
  boxShadow?: string
  activeBoxShadow?: string
  disabled?: boolean
  checked?: boolean
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>
  formikProps?: FormikProps<any>
  className?: string
  handleDiameter?: number
  onColor?: string
  offColor?: string
  onHandleColor?: string
  offHandleColor?: string
  height?: number
  width?: number
}

const CustomSwitch = (props: Props) => {
  const [checked, setChecked] = React.useState<boolean>(props.checked ?? false)

  return (
    <Switch
      id={props.id}
      name={props.name}
      className={props.className}
      height={props.height ?? 15}
      width={props.width ?? 35}
      checked={props.checked ?? checked}
      disabled={props.disabled}
      onChange={(val) => {
        props.setChecked ? props.setChecked(val) : setChecked(val)
        props.formikProps?.setFieldValue(
          props.name,
          val
        )
      }}
      onColor={props.onColor ?? '#859bd5'}
      onHandleColor={props.onHandleColor ?? '#4566C0'}
      offColor={props.offColor ?? '#E9ECEF'}
      offHandleColor={props.offHandleColor ?? '#F1F1F1'}
      handleDiameter={props.handleDiameter ?? 18}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow={props.boxShadow ?? '0px 1px 5px rgba(0, 0, 0, 0.6)'}
      activeBoxShadow={props.activeBoxShadow ?? '0px 0px 1px 5px rgba(0, 0, 0, 0.2)'}
    />
  )
}

export default CustomSwitch
