import React from 'react'
import { bulkActionDetailsStyle } from './bulkActionDetailsStyle'
import { useBulkActionDetails } from './useBulkActionDetails'
import { getFromQuery, useQuery } from '../../../utils/common'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { propertyTypes, transactionTypes, plotTypes } from '../../../types/advert'
import CustomButton from '../CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'

type Props = {
  details: any
  reset?: boolean
  warning?: string
  editableDate?: boolean
  onEditSentDateChange?: (enabled: boolean) => void
  onEditSendDateCancel?: () => void
}

// TODO string

const BulkActionDetails = (props: Props) => {
  const classes = bulkActionDetailsStyle()
  const query = useQuery()
  const texts = useTranslation().t

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isTemplateArrDone, setIsTemplateArrDone] = React.useState<boolean>(false)
  const [isSentDateEditActive, setIsSentDateEditActive] = React.useState<boolean>(false)
  const [isLocStrDone, setIsLocStrDone] = React.useState<boolean>(false)
  const [locString, setLocString] = React.useState<string>('')
  const [typeString, setTypeString] = React.useState<string>('')
  const [templates, setTemplates] = React.useState<string[]>([])

  const {
    smsTemplates, regions,
    fetchSmsTemplateList, fetchRegionList
  } = useBulkActionDetails()

  React.useEffect(() => {
    fetchRegionList().then(res => {
      fetchSmsTemplateList(getFromQuery(query, 'assume')).then(res => {
        setIsLoading(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    setIsSentDateEditActive(false)
  }, [props.reset])

  React.useEffect(() => {
    setIsLoading(true)
    fetchRegionList().then(res => {
      fetchSmsTemplateList(getFromQuery(query, 'assume')).then(res => {
        if (props.details) {
          const transactionType = transactionTypes.find((e: any) => e.value === `${props.details.params.offerType}`)
          const propertyType = propertyTypes.find((e: any) => e.value === `${props.details.params.advertType}`)
          let plotType
          if (props.details.params.advertSubType) {
            plotType = plotTypes.find((e: any) => e.value === `${props.details.params.advertSubType}`)
          }
          setTypeString(
            `${propertyType.label}${plotType ? `, ${plotType.label}` : ''}, ${transactionType.label}`
          )
        }
        setIsLoading(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [props.details])

  React.useEffect(() => {
    if (!props.details) {
      return 
    }
    if (smsTemplates.length > 0) {
      const tmpArr: string [] = []
      let tmp: any
      for (const template of props.details.smsMessageUuids ?? []) {
        tmp = smsTemplates.find((e: any) => e.uuid === template)
        if (tmp) {
          tmpArr.push(tmp.template)
        }
      }
      if (props.details.customMessage) {
        tmpArr.push(props.details.customMessage)
      }
      setTemplates([...tmpArr])
      setIsTemplateArrDone(true)
    }
  }, [smsTemplates])

  React.useEffect(() => {
    if (!props.details) {
      return 
    }
    if (props.details.regionName != null) {
      setLocString(`${props.details.regionName}${props.details.subregionName ? `; ${props.details.subregionName}` : ''}${props.details.townNames ? `; ${props.details.townNames}` : ''}${props.details.districtNames ? `; ${props.details.districtNames}` : ''}`)
      setIsLocStrDone(true)
    } else if (regions.length > 0) {
      let tmpStr = ''
      let tmpRegion: any, tmpSubregion: any
      let tmpTowns: any[] = []
      tmpRegion = regions.find((e: any) => e.uuid === props.details.params.regionUuid)
      if (tmpRegion) {
        tmpStr += tmpRegion.name
      }
      if (props.details.params.subRegionUuid) {
        tmpSubregion = tmpRegion.subRegions.find((e: any) => e.uuid === props.details.params.subRegionUuid)
        if (tmpRegion) {
          tmpStr += `; ${tmpRegion.name}`
        }
      }
      if (props.details.params.townUuids && tmpSubregion) {
        let tmpTown: any
        for (const town of props.details.params.townUuids) {
          tmpTown = tmpSubregion.towns.find((e: any) => e.uuid === town)
          if (tmpTown) {
            tmpTowns.push(tmpTown)
          }
        }
      } else if (props.details.params.townUuids) {
        let tmpTown: any
        for (const town of props.details.params.townUuids) {
          for (const subRegion of tmpRegion.subRegions) {
            tmpTown = subRegion.towns.find((e: any) => e.uuid === town)
            if (tmpTown) {
              tmpTowns.push(tmpTown)
            }
          }
        }
      }

      if (tmpTowns.length > 0) {
        tmpStr += `; ${tmpTowns.map((e: any) => e.name).join(', ')}`
        if (props.details.params.districtUuids) {
          let tmpDistricts = []
          let tmpDistrict: any
          for (const district of props.details.params.districtUuids) {
            for (const town of tmpTowns) {
              tmpDistrict = town.districts.find((e: any) => e.uuid === district)
              if (tmpDistrict) {
                tmpDistricts.push(tmpDistrict)
              }
            }
          }
          if (tmpDistricts.length > 0) {
            tmpStr += `; ${tmpDistricts.map((e: any) => e.name).join(', ')}`
          }
        }
      }      
      setLocString(tmpStr)
      setIsLocStrDone(true)
    }
  }, [regions])

  if (!props.details) {
    return (<></>)
  }
  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading || !isLocStrDone || !isTemplateArrDone ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div 
        className={classes.container}
        style={{
          display: isLoading || !isLocStrDone || !isTemplateArrDone  ? 'none' : 'block'
        }}
      >
        <div className={classes.title}>
          Podsumowanie
        </div>
        {
          props.details.name
          ? <div className={classes.field}>
              <span className={classes.fieldTitle}>Nazwa paczki: </span>
              {props.details.name}
            </div>
          : null
        }
        <div className={classes.field}>
          <span className={classes.fieldTitle}>Lokalizacja: </span>
          {locString}
        </div>
        <div className={classes.field}>
          <span className={classes.fieldTitle}>Typ nieruchomości oraz transakcji: </span>
          {typeString}
        </div>
        <div className={classes.field}>
          <span className={classes.fieldTitle}>Cena (PLN): </span>
          {`${props.details.params.minPrice} - ${props.details.params.maxPrice}`}
        </div>
        <div className={classes.field}>
          <span className={classes.fieldTitle}>Powierzchnia (m&sup2;): </span>
          {`${props.details.params.minArea} - ${props.details.params.maxArea}`}
        </div>
        {
          props.details.params.maxPricePerArea != null && props.details.params.minPricePerArea != null
          ? <div className={classes.field}>
              <span className={classes.fieldTitle}>Cena/m&sup2; (PLN): </span>
              {`${props.details.params.minPricePerArea} - ${props.details.params.maxPricePerArea}`}
            </div>
          : null
        }
        {
          props.details.params.maxPricePerArea != null && props.details.params.minPricePerArea == null
          ? <div className={classes.field}>
              <span className={classes.fieldTitle}>Cena/m&sup2; (PLN): </span>
              {`do ${props.details.params.maxPricePerArea}`}
            </div>
          : null
        }
        {
          props.details.params.maxPricePerArea == null && props.details.params.minPricePerArea != null
          ? <div className={classes.field}>
              <span className={classes.fieldTitle}>Cena/m&sup2; (PLN): </span>
              {`od ${props.details.params.minPricePerArea}`}
            </div>
          : null
        }
        <div className={classes.field}>
          <span className={classes.fieldTitle}>Oferta dodana: </span>
          {`${moment(props.details.params.start).format('DD.MM.YYYY')} - ${moment(props.details.params.end).format('DD.MM.YYYY')}`}
        </div>
        {
          props.details.signature 
            ? <div className={classes.field}>
                <span className={classes.fieldTitle}>Podpis: </span>
                {props.details.signature}
              </div> 
            : null
        }
        {
          props.details.smsMessageUuids != null || props.details.customMessage != null
          ? <div className={`${classes.field} ${classes.templatesSection}`}>
              <div>
                <span className={classes.fieldTitle} style={{ flexShrink: 0, marginRight: '5px' }}>Treści SMS: </span>
              </div>
              <div>
                {
                  templates.map(e => (<div>{`- ${e}`}</div>))
                }
              </div>
            </div>
          : null
        }
        {
          props.details.notificationEmails && props.details.notificationEmails.length > 0 
            ? <div className={classes.field}><span className={classes.fieldTitle}>Adresy e-mail do wysyłki powiadomień: </span>{props.details.notificationEmails.join(', ')}</div> 
            : null
        }
        {
          props.details.estimatedCount != null 
          ? <div className={classes.field}>
              <span className={classes.fieldTitle}>Estymowana wielkość paczki: </span>
              {`ok. ${props.details.estimatedCount} ofert`}
            </div>
          : null
        }
        {
          props.details.sentAt
          ? <><div className={classes.field} style={{ lineHeight: '28px'}}>
              <span className={classes.fieldTitle}>Planowana data rozpoczęcia wysyłki: </span>
              {`${moment(props.details.sentAt).format('DD.MM.YYYY HH:mm')}`}
              {
                props.editableDate
                ? <>
                    <div className={classes.changeDateButtonContainer}>
                      <CustomButton
                        className={[
                          classes.baseChangeDateButton,
                          isSentDateEditActive ? classes.cancelChangeDateButton : classes.activateChangeDateButton
                        ].join(' ')}
                        textComponent={
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '-2px',
                            fontSize: '18px'
                          }}>
                            <span style={{
                              fontSize: '12px',
                              fontFamily: 'AvenirMedium'
                            }}>
                              {
                                isSentDateEditActive
                                ? texts('bulkActions:cancel_change_button_label')
                                : texts('bulkActions:change_date_button_label')
                              }
                            </span>
                          </div>
                        }
                        style={{
                          width: '160px',
                          maxWidth: '200px',
                          height: '28px',
                          paddingLeft: '10px',
                          paddingRight: '15px',
                          paddingTop: '7px'
                        }}
                        onClick={() => {
                          if(isSentDateEditActive) {
                            props.onEditSendDateCancel?.()
                          }
                          setIsSentDateEditActive(!isSentDateEditActive)
                          props.onEditSentDateChange?.(!isSentDateEditActive)
                        }}
                      />
                    </div>
                  </>
                : null
              }
            </div>
            {
              props.editableDate && moment().isAfter(moment(props.details.sentAt))
              ? <div className={classes.sentDateErrorContainer}>
                  {texts('bulkActions:sent_date_in_past_error')}
                </div>
              : null
            }</>
          : null
        }
        

        {
          props.warning != null
          ? <div className={classes.warningLabel}>{props.warning}</div>
          : null
        }
      </div>
    </>
  )
}

export default BulkActionDetails