import React from 'react'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers'
import { checkIfAdmin } from '../../utils/common'

export const useChildAccountDetails = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [details, setDetails] = React.useState<any>(null)
  const [wonBidCount, setWonBidCount] = React.useState<number>(0)
  const [bidStats, setBidStats] = React.useState<any>({
    auctionCount: 0,
    messageSentCount: 0,
    responsesCount: 0,
    totalPrice: 0
  })
  const [wonBidList, setWonBidList] = React.useState<any[]>([])

  const fetchAssumedUser = async (uuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

  const fetchAccountDetails = async (uuid: string, assumeUuid?: string) => {
    try {
      const fetchedDetails = await DataApi.getChildAccountDetails(uuid, assumeUuid)
      setDetails(fetchedDetails)
    } catch (error) {
			throw error
    }
  }

  const fetchWonBidCount = async (uuid: string, from?: string, to?: string, assumeUuid?: string) => {
    try {
      const count = await DataApi.getChildAccountWonBidCount(uuid, from, to, assumeUuid)
      setWonBidCount(count.count)
      setBidStats({
        auctionCount: count.auctionCount,
        messageSentCount: count.messageSentCount,
        responsesCount: count.responsesCount,
        totalPrice: count.totalPrice,
        userReactionCount: count.userReactionCount ?? 0
      })
    } catch (error) {
			throw error
    }
  }

  const fetchWonBidList = async (uuid: string, limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, from?: string, to?: string, assumeUuid?: string) => {
    try {
      const list = await DataApi.getChildAccountWonBidList(uuid, limit, offset, sortName, sortDirection, from, to, assumeUuid)
      setWonBidList(list)
    } catch (error) {
			throw error
    }
  }

  const editAccountDetails = async (uuid: string, data: any) => {
    // await DataApi.editSearchParamDetails(uuid, data)
  }

	return { 
    details, wonBidCount, wonBidList, bidStats, assumedUser,
    fetchAccountDetails, fetchWonBidCount, fetchWonBidList,
    editAccountDetails, fetchAssumedUser
	}
}