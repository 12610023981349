import LoadingButton from '../../common/LoadingButton/LoadingButton'
import CustomDialog from '../../CustomDialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styles from './styles.module.scss'

type Props = {
  hidden: boolean
  onClose: () => void
  onConfirm: (() => void) | (() => Promise<void>)
}

const DeactivateEventDialog = (props: Props) => {
  const t = useTranslation('forms').t

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  
  return (
    <CustomDialog 
      style={{
        position: 'fixed',
        background: 'white',
        maxWidth: '650px',
        minHeight: '100px',
        border: '1px solid #979797',
        borderRadius: '1px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      hidden={props.hidden}
    >
      <div className={styles.container}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            {t('confirm_deactivate_label')}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              props.onClose()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              setIsSubmitting(true)
              await props.onConfirm()
              setIsSubmitting(false)
              props.onClose()
            }}
          />
        </div>
      </div>
    </CustomDialog>
    
  )
}

export default DeactivateEventDialog
