import { makeStyles } from "@material-ui/core";
import { Colors } from "../../../types/colors";

export const faqCardStyle = makeStyles(() => ({
  card: {
    border: `solid 1px ${Colors.BORDER_FAQ_CARD}`,
    minHeight: "100%",
  },
  cardHeader: {
    border: `solid 1px ${Colors.BORDER_FAQ_CARD}`,
    backgroundColor: "rgba(69, 102, 192, 0.05)",
    minHeight: "70px",
  },
  cardHeaderTitle: {
    fontSize: "20px",
    color: `${Colors.MAIN}`,
    fontWeight: 900,
    letterSpacing: "0.36px",
  },
  cardHeaderSubheader: {
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0.32px",
    color: "#7d86a9",
  },
  cardContent: {
    padding: "20px",
    fontSize: "20px",
    color: `${Colors.CONTENT_FAQ_TEXT}`,
    height: "100%",
  },
}))