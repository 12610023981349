import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { resetPasswordValidation } from "../../../utils/validations/resetPasswordValidation"
import BaseInput from "../../common/BaseInput/BaseInput"
import LoadingButton from "../../common/LoadingButton/LoadingButton"
import AuthCover from "../AuthCover/AuthCover"
import { commonAuthStyle } from "../commonAuthStyle"
import { useResetPasswordRequest } from "./useResetPasswordRequest"

const ResetPasswordRequest = () => {
  const { t } = useTranslation("auth")
  const { loading, onSubmitResetPassword } = useResetPasswordRequest()
  const commonAuthClasses = commonAuthStyle()

  return (
    <AuthCover
      title={t("reset_password_request_title")}
      subTitle={t("subtitle_password_reset")}
    >
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={onSubmitResetPassword}
        validationSchema={resetPasswordValidation(t)}
      >
        {(formikProps) => (
          <Form
            style={{
              minWidth: "360px",
            }}
          >
            <BaseInput
              name="email"
              label={t("email_address")}
              error={
                Boolean(formikProps.errors.email) &&
                formikProps.touched.email
              }
              formikProps={formikProps}
            />
            <LoadingButton
              text={t("send")}
              type="submit"
              className={commonAuthClasses.loadingButton}
              loading={loading}
              disabled={
                !formikProps.isValid || !formikProps.dirty
              }
              style={{ marginTop: "10px" }}
            />
            <div
              style={{ 
                marginTop: "25px",
                textAlign: 'center'
              }}
            >
              <Link
                to="/login"
                className={commonAuthClasses.link}
              >
                {t("back_to_login")}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </AuthCover>
  )
}

export default ResetPasswordRequest
