export function evalStringTemplate(template: string, data: object): string {
  for (const [key, value] of Object.entries(data)) {
    template = template.replace(new RegExp(`\\\${${key}}`, 'g'), value)
  }
  return template
}

export function normalizePhoneNumber(phone?: string) : string {
  const mappedPhone = phone?.trim().replace(/^(\+)|\D/g, '')
  if (mappedPhone?.length === 9) {
    return `48${mappedPhone}`
  }
  return mappedPhone
}

export function numberWithSpaces(x) {
  var parts = x.toString().split(",");
  parts[0] = parseFloat(parts[0]).toLocaleString('en-US').replace(/,/g, ' ');
  return `${parts.join(".")}${x.endsWith('.') ? '.' : ''}`;
}

export function formatNumber(num: number, fraction: boolean = false, monetary: boolean = false): string {
  let str = fraction ? `${num.toFixed(2)}`.replace('.', ',') : `${num}`.replace('.', ',')
  if (monetary) {
    // const start = str.replace(/\s/g, '')
    return numberWithSpaces(str.replace(/\s/g, '')).replace('.', ',')
    // if (fraction) {
    //   return tmp.indexOf(',') > -1 ? tmp : `${tmp},00`
    // } else {
    // return tmp
    // }
  }
  return str
}