import { Visibility, VisibilityOff } from '@material-ui/icons'
import { customPasswordFieldStyle } from './customPasswordFieldStyle'
import { FormikProps } from 'formik'
import React from 'react'

type Props = {
  name: string
  id?: string
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  type?: string
  error?: boolean
  errorLabel?: JSX.Element
  showPassword?: boolean
  formikProps?: FormikProps<any>
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  showPasswordOnClick?: () => void
}

const CustomPasswordField = (props: Props) => {
  const [value, setValue] = React.useState<string>("")
  
  const classes = customPasswordFieldStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()
  return (
    <div>
      <div style={{
        width: '100%',
        position: 'relative'
      }}>
        <input
          {...props}
          className={`${(props.className || classes.input)}`}
          style={{
            paddingRight: '40px'
          }}
          onChange={(ev) => {
            props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
            props.formikProps?.setFieldValue(
              props.name,
              ev.target.value
            )
          }}
        />
        <span 
          style={{
            position: 'absolute',
            right: '13px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
          onClick={props.showPasswordOnClick}
          className="material-icons"
        >
          {props.showPassword ? <Visibility/> : <VisibilityOff/>}
        </span>
      </div>
      {
        props.error && props.errorLabel ? <div className={props.errorLabelClassName || classes.errorLabel}>
          {props.errorLabel}
        </div> : null
      }
    </div>
  )
}

export default CustomPasswordField
