export const forbiddenSpecialCharacters = /^[^<>%$'?';:/|{}`()~#%()^&*\]+=*\\[]*$/
export const forbiddenSomeSpecialCharactersPassword = /^[^-<>%'?';:./|@{}`()~%()^*\]+=*\\[]*$/
export const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z$!&#_\d]{1,}$/
export const englishCharactersAllowedPassword = /^[A-Za-z0-9$!&#_]*$/ 
// eslint-disable-next-line
// export const email = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const email = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i
// const numberPattern = /^-?([0-9]\d*(\.|\,)\d*|0?(\.|\,)\d*[0-9]\d*|[0-9]\d*)$/
export const numberPattern = /^-?(\d*(\.|\,)\d*|[0-9]\d*)$/
export const integerValuePattern = /^-?\d*$/
export const monetaryValuePattern = /^-?(\d*(\.|\,)\d{0,2}|[0-9]\d*)$/
export const smsMessagePattern =/^[a-zA-Z0-9\,\.!?"-\s_+=():'@&\s]*$/