import { Form, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { contactFormValidation } from "../../../utils/validations/contactFormValidation"
import BaseInput from "../BaseInput/BaseInput"
import FileUpload from "../FileUpload/FileUpload"
import { contactFormStyle } from "./contactFormStyle"
import LoadingButton from "../LoadingButton/LoadingButton"
import DataApi from "../../../api/DataApi"
import { onErrorResponseShowMessage } from "../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage"
import { ContactFormValues } from "../../../types/faq"
import { FILE_SIZE_MAX, FILE_SUPPORTED_FORMATS } from '../../../types/common'

const ContactForm = () => {
  const { t } = useTranslation(["faq"])

  const classes = contactFormStyle()
  const [question, setQuestion] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)

  const handleSubmit = async (values: ContactFormValues) => {
    const formData = new FormData()
    Object.keys(values).map((name) => formData.append(name, values[name]))

    setLoading(true)
    try {
      await DataApi.sendContactFormData(formData)
      setIsSent(true)
    } catch (e) {
      onErrorResponseShowMessage(e, false)
    } finally {
      setLoading(false)
    }
  }

  return (
    isSent
    ? <p className={classes.isSentMessage}>{t("contact_form_sent")}</p>
    : <Formik
        validationSchema={contactFormValidation(t)}
        initialValues={{
          question: "",
          message: "",
          file: null,
        }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <BaseInput
              name="question"
              label={t("contact_form_question")}
              formikProps={formikProps}
              placeholder={t("contact_form_question_placeholder")}
              labelClassName={classes.inputLabel}
              fieldClassName={classes.inputField}
              errorFieldClassName={classes.errorField}
              value={question}
              setValue={setQuestion}
              maxLen={120}
              error={
                formikProps.touched.question &&
                Boolean(formikProps.errors.question)
              }
            />
            <BaseInput
              name="message"
              label={t("contact_form_message")}
              formikProps={formikProps}
              placeholder={t("contact_form_message_placeholder")}
              labelClassName={classes.inputLabel}
              fieldClassName={classes.inputField}
              errorFieldClassName={classes.errorField}
              rows={5}
              value={message}
              setValue={setMessage}
              maxLen={600}
              error={
                formikProps.touched.message &&
                Boolean(formikProps.errors.message)
              }
            />
            <FileUpload
              name="file"
              formikProps={formikProps}
              titleClassName={classes.inputLabel}
              title={t("contact_form_file_title")}
              additionalComment={t("contact_form_additional_comment")}
              error={
                formikProps.touched.file && Boolean(formikProps.errors.file)
              }
              fileSizeMax={FILE_SIZE_MAX}
              fileSupportedFormats={FILE_SUPPORTED_FORMATS}
            />
            <LoadingButton
              style={{
                width: "200px",
                height: "37px",
                marginLeft: "auto",
                display: "flex",
              }}
              text={t("send")}
              type="submit"
              loading={loading}
            />
          </Form>
        )}
      </Formik>
  )
}

export default ContactForm
