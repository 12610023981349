import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon, Close as CloseIcon, OpenInNewOutlined
} from '@material-ui/icons'
import 'intro.js/introjs.css'
import moment from 'moment'
import React from 'react'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import DataApi from '../../api/DataApi'
import dialogIcon from '../../assets/images/dialog.webp'
import sendIcon from '../../assets/images/send.webp'
import trashIcon from '../../assets/images/Trash.webp'
import Checkbox from '../../lib/react-custom-checkbox/dist'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import { AdvertType, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { PermissionLevel } from '../../types/auth'
import { bulkActionStatusTypes } from '../../types/bulkAction'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList } from '../../types/common'
import { checkIfAdmin, getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { sortRowData } from '../../utils/sort'
import { evalStringTemplate } from '../../utils/text'
import AddBulkActionInfoForm from '../common/AddBulkActionInfoForm/AddBulkActionInfoForm'
import BulkActionDetails from '../common/BulkActionDetails/BulkActionDetails'
import CalculateAdvertsForm from '../common/CalculateAdvertsForm/CalculateAdvertsForm'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import StatTile from '../common/StatTile/StatTile'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { bulkActionPageStyle } from './bulkActionPageStyle'
import { useBulkActionPage } from './useBulkActionPage'

const waitingActionsTableColumnData: ColumnData[] = [
  {
    label: 'Konto',
    name: 'account',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  },
  {
    label: 'Data\u00A0i\u00A0godzina dodania',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      right: '10px'
    },
    width: '10%'
  }, {
    label: 'Planowany start\u00A0wysyłki',
    name: 'sentAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: 'Nazwa\u00A0paczki',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    name: 'parameters',
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '32%'
  }, {
    label: 'Status paczki',
    name: 'status',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '10%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '8%'
  }
]

const sentActionsTableColumnData: ColumnData[] = [
  {
    label: 'Konto',
    name: 'account',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Start\u00A0wysyłki',
    name: 'sentAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: 'Nazwa\u00A0paczki',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    name: 'parameters',
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '20%'
  }, {
    label: 'Wysłane SMS',
    name: 'sent_msg_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Liczba odpowiedzi',
    name: 'response_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Konwersja',
    name: 'conversion',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Status paczki',
    name: 'status',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '8%'
  }
]

const tabs = [
  {
    id: 'planned',
    label: 'Planowane do wysyłki'
  },
  {
    id: 'sent',
    label: 'Paczki przetworzone'
  }
]

const ELEM_PER_PAGE = 25

const BulkActionPage = () => {

  const query = useQuery()
  const history = useHistory()
  const permissions = useSelector((e: State) => e.permissions)

  const tForms = useTranslation('forms').t
  const tBulkActions = useTranslation('bulkActions').t
  const classes = bulkActionPageStyle()
  const dispatch = useDispatch()

  const { 
    assumedUser, plannedActionsList, plannedActionsListCount, sentActionsList, sentActionsListCount, sentActionsListStats, userSettings,
    fetchAssumedUser, fetchPlannedList, fetchSentList, fetchSentListStats, fetchUserSettings
	} = useBulkActionPage()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)

  const [isReloadingWaitingActionList, setIsReloadingWaitingActionList] = React.useState<boolean>(false)
  const [isReloadingSentActionList, setIsReloadingSentActionList] = React.useState<boolean>(false)

  const [waitingActionsSelectedPage, setWaitingActionsSelectedPage] = React.useState<number>(1)
  const [waitingActionsPages, setWaitingActionsPages] = React.useState<number>(1)
  const [sentActionsSelectedPage, setSentActionsSelectedPage] = React.useState<number>(1)
  const [sentActionsPages, setSentActionsPages] = React.useState<number>(1)

  const [waitingDataParams, setWaitingDataParams] = React.useState<any>(null)
  const [waitingDataInfo, setWaitingDataInfo] = React.useState<any>(null)
  const [estimatedAdvertCount, setEstimatedAdvertCount] = React.useState<number | null>(null)
  const [newBulkDetails, setNewBulkDetails] = React.useState<any>(null)

  const [showAddBulkActionDialog, setShowAddBulkActionDialog] = React.useState<boolean>(false)
  const [bulkActionDialogStep, setBulkActionDialogStep] = React.useState<number>(1)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [waitingActionsTableData, setWaitingActionsTableData] = React.useState<any[]>([])
  const [waitingActionsSortData, setWaitingActionsSortData] = React.useState<SortData | null>(null)
  const [sentActionsTableData, setSentActionsTableData] = React.useState<any[]>([])
  const [sentActionsSortData, setSentActionsSortData] = React.useState<SortData | null>(null)
  const [tabIndex, setTabIndex] = React.useState<number>(tabs.findIndex(e => e.id === getFromQuery(query, 'tab')) > -1 ? tabs.findIndex(e => e.id === getFromQuery(query, 'tab')) : 0)

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchUserSettings().then(res => {
        fetchPlannedList(ELEM_PER_PAGE, 0, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
          fetchSentList(ELEM_PER_PAGE, 0, undefined, undefined, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
            fetchSentListStats(undefined, undefined, getFromQuery(query, 'assume')).then(res => {
              setIsLoading(false)
            }).catch(error => { onErrorResponseShowMessage(error)})
          }).catch(error => { onErrorResponseShowMessage(error)})
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (!isLoading && waitingActionsSortData && waitingActionsSortData.default) {
      setIsReloadingWaitingActionList(true)
      fetchPlannedList(ELEM_PER_PAGE, 0, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
        setWaitingActionsSelectedPage(1)
        setIsReloadingWaitingActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (!isLoading && waitingActionsSortData && isNaN(parseInt(waitingActionsSortData.column))) {
      setIsReloadingWaitingActionList(true)
      fetchPlannedList(ELEM_PER_PAGE, 0, waitingActionsSortData.column, waitingActionsSortData.order, getFromQuery(query, 'assume')).then(res => {
        setWaitingActionsSelectedPage(1)
        setIsReloadingWaitingActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (!isLoading && waitingActionsSortData && !isNaN(parseInt(waitingActionsSortData.column))) {
      const tmpData = [...waitingActionsTableData]
      sortRowData(tmpData, waitingActionsSortData)
      setWaitingActionsTableData([...tmpData])
    }
  }, [waitingActionsSortData])

  React.useEffect(() => {
    if (!isReloadingWaitingActionList && waitingActionsSelectedPage > 0) {
      const sortName = waitingActionsSortData?.column
      const sortDirection = waitingActionsSortData?.order
      setIsReloadingWaitingActionList(true)
      fetchPlannedList(ELEM_PER_PAGE, (waitingActionsSelectedPage - 1) * ELEM_PER_PAGE, sortName, sortDirection, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingWaitingActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingWaitingActionList && waitingActionsSelectedPage === 1) {
      setIsReloadingWaitingActionList(false)
    }
  }, [waitingActionsSelectedPage])

  React.useEffect(() => {
    if (plannedActionsListCount > 0) {
      setWaitingActionsPages(Math.ceil(plannedActionsListCount / ELEM_PER_PAGE))
    } else {
      setWaitingActionsPages(1)
    }
  }, [plannedActionsListCount])

  React.useEffect(() => {
    let params = '', status
    const mapped = plannedActionsList.map((e, idx) => {
      params = e.regionName
      if (e.subregionName) {
        params += `; Powiat:\u00A0${e.subregionName}`
      }
      if (e.townNames) {
        params += `; Miejscowości:\u00A0${e.townNames}`
      }
      if (e.districtNames) {
        params += `; Dzielnice:\u00A0${e.districtNames}`
      }
      params += `; 
      ${
        propertyTypes.find(el => el.value === `${e.params.advertType}`).label
      }${
        e.params.advertType === AdvertType.PLOT && e.params.advertSubType != null 
        ? ` - ${plotTypes.find(el => el.value === `${e.params.advertSubType}`).label}` 
        : ''}`
      params += `; ${transactionTypes.find(el => el.value === `${e.params.offerType}`).label}`
      params += `; Pow.:\u00A0${e.params.minArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2 - ${e.params.maxArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2`
      params += `; Cena:\u00A0${e.params.minPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł - ${e.params.maxPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł`

      status = bulkActionStatusTypes.find(el => el.value === `${e.status}`)

      return {
        columns: [
          e.user.parent ? e.user.email : 'KONTO GŁÓWNE',
          moment(e.createdAt).format('DD.MM.YYYY, HH:mm'),
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm'),
          e.name,
          params,
          <div
            style={{ color: status.color }}
          >
            {status.label}
          </div>,
          <Link to={`/action/bulk/details/${e.uuid}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} className={classes.manageLink}>
            {'Zobacz'}
          </Link>
        ]
      }
    })
    setWaitingActionsTableData([...mapped])
  }, [plannedActionsList])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[0]).format() 
      : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (!isLoading && sentActionsSortData && sentActionsSortData.default) {
      setIsReloadingSentActionList(true)
      fetchSentList(ELEM_PER_PAGE, 0, undefined, undefined, from, to, getFromQuery(query, 'assume')).then(res => {
        setSentActionsSelectedPage(1)
        setIsReloadingSentActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (!isLoading && sentActionsSortData && isNaN(parseInt(sentActionsSortData.column))) {
      setIsReloadingSentActionList(true)
      fetchSentList(ELEM_PER_PAGE, 0, sentActionsSortData.column, sentActionsSortData.order, from, to, getFromQuery(query, 'assume')).then(res => {
        setSentActionsSelectedPage(1)
        setIsReloadingSentActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (!isLoading && sentActionsSortData && !isNaN(parseInt(sentActionsSortData.column))) {
      const tmpData = [...sentActionsTableData]
      sortRowData(tmpData, sentActionsSortData)
      setSentActionsTableData([...tmpData])
    }
  }, [sentActionsSortData])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sentActionsSortData?.column || undefined
      const sortOrder = sentActionsSortData?.order || undefined
      setIsReloadingSentActionList(true)
      fetchSentList(ELEM_PER_PAGE, 0, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchSentListStats(from, to, getFromQuery(query, 'assume')).then(res => {
          setSentActionsSelectedPage(1)
          setIsReloadingSentActionList(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 
  }, [selectedDateRange])

  React.useEffect(() => {
    if (!isReloadingSentActionList && sentActionsSelectedPage > 0) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sentActionsSortData?.column
      const sortDirection = sentActionsSortData?.order
      setIsReloadingSentActionList(true)
      fetchSentList(ELEM_PER_PAGE, (sentActionsSelectedPage - 1) * ELEM_PER_PAGE, sortName, sortDirection, from, to, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingSentActionList(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingSentActionList && sentActionsSelectedPage === 1) {
      setIsReloadingSentActionList(false)
    }
  }, [sentActionsSelectedPage])

  React.useEffect(() => {
    if (sentActionsListCount > 0) {
      setSentActionsPages(Math.ceil(sentActionsListCount / ELEM_PER_PAGE))
    } else {
      setSentActionsPages(1)
    }
  }, [sentActionsListCount])

  React.useEffect(() => {
    let params = '', status
    const mapped = sentActionsList.map((e, idx) => {
      params = e.regionName
      if (e.subregionName) {
        params += `; Powiat:\u00A0${e.subregionName}`
      }
      if (e.townNames) {
        params += `; Miejscowości:\u00A0${e.townNames}`
      }
      if (e.districtNames) {
        params += `; Dzielnice:\u00A0${e.districtNames}`
      }
      params += `; 
      ${
        propertyTypes.find(el => el.value === `${e.params.advertType}`).label
      }${
        e.params.advertType === AdvertType.PLOT && e.params.advertSubType != null 
        ? ` - ${plotTypes.find(el => el.value === `${e.params.advertSubType}`).label}` 
        : ''}`
      params += `; ${transactionTypes.find(el => el.value === `${e.params.offerType}`).label}`
      params += `; Pow.:\u00A0${e.params.minArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2 - ${e.params.maxArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2`
      params += `; Cena:\u00A0${e.params.minPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł - ${e.params.maxPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł`

      status = bulkActionStatusTypes.find(el => el.value === `${e.status}`)

      return {
        columns: [
          e.user.parent ? e.user.email : 'KONTO GŁÓWNE',
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm'),
          e.name,
          params,
          e.sentMsgCount,
          e.responseCount,
          e.responseCount === 0 ? '0,00%' : `${(e.responseCount/e.sentMsgCount*100).toFixed(2)}%`,
          <div
            style={{ color: status.color }}
          >
            {status.label}
          </div>,
          <Link to={`/action/bulk/details/${e.uuid}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} className={classes.manageLink}>
            {'Zobacz'}
          </Link>
        ]
      }
    })
    setSentActionsTableData([...mapped])
  }, [sentActionsList])

  const addActionDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tBulkActions('sent_package_dialog_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeAddBulkActionDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <div className={classes.addActionDialogStepLabel}>
          {evalStringTemplate(tBulkActions('sent_package_step_title'), { step: bulkActionDialogStep })}
        </div>
        <div className={`${bulkActionDialogStep === 1 ? '' : classes.hideElement}`}>
          <CalculateAdvertsForm
            reset={showAddBulkActionDialog}
            onFinish={(params, count) => {
              setWaitingDataParams(params)
              setEstimatedAdvertCount(count)
              setBulkActionDialogStep(2)
            }}
          />
        </div>
        <div className={`${bulkActionDialogStep === 2 ? '' : classes.hideElement}`}>
          <AddBulkActionInfoForm
            propertyType={waitingDataParams?.advertType ?? AdvertType.UNDEFINED}
            reset={showAddBulkActionDialog}
            estimatedAdvertCount={estimatedAdvertCount}
            onFinish={(info) => {
              info.params = waitingDataParams
              setWaitingDataInfo(info)
              setNewBulkDetails({
                ...info,
                params: waitingDataParams,
                estimatedCount: estimatedAdvertCount
              })
              setBulkActionDialogStep(3)
            }}
            onBack={() => {
              setBulkActionDialogStep(1)
            }}
          />
          {
            estimatedAdvertCount === 0
            ? <>
                <div className={classes.noAdvertCountWarning}>
                  Dla wybranych parametrów nie wyszukano ofert. Spróbuj wybrać inne parametry
                </div>
                <div 
                  style={{
                    float: 'left',
                    marginBottom: '30px'
                  }}
                >
                  <LoadingButton 
                    text={tForms('back_button_label')}
                    type='button'
                    loading={isSubmitting}
                    onClick={() => {
                      setBulkActionDialogStep(1)
                    }}
                    className={classes.backButton}
                    style={{
                      height: '40px'
                    }}
                  />
                </div>
                <div style={{
                  float: 'right',
                  marginBottom: '30px'
                }}>
                  <LoadingButton 
                    text={tForms('close_button_label')}
                    type='button'
                    onClick={() => {
                      closeAddBulkActionDialog()
                    }}
                    style={{
                      width: '350px',
                      maxWidth: '350px',
                      height: '40px'
                    }}
                  />
                </div>
            </>
            : null
          }
        </div>
        <div className={`${bulkActionDialogStep === 3 && newBulkDetails != null ? '' : classes.hideElement}`}>
          <BulkActionDetails
            details={newBulkDetails}
            warning={'Jeśli wszystkie parametry się zgadzają, możesz zgłosić paczkę do wysyłki. Wówczas skontaktujemy się celem ustalenia dalszych kroków.'}
          />
          <div 
            style={{
              float: 'left',
              marginBottom: '30px'
            }}
          >
            <LoadingButton 
              text={tForms('back_button_label')}
              type='button'
              loading={isSubmitting}
              onClick={() => {
                setBulkActionDialogStep(2)
              }}
              className={classes.backButton}
              style={{
                height: '40px'
              }}
            />
          </div>
          <div 
            style={{
              float: 'right',
              marginBottom: '30px'
            }}
          >
            <LoadingButton 
              text={tForms('add_bulk_action_button_label')}
              type='button'
              loading={isSubmitting}
              onClick={handleSubmit}
              style={{
                width: '350px',
                maxWidth: '350px',
                height: '40px'
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const resetDialogData = () => {
    setWaitingDataParams(null)
    setWaitingDataInfo(null)
    setBulkActionDialogStep(1)
  }

  const closeAddBulkActionDialog = () => {
    resetDialogData()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddBulkActionDialog(false)
  }

  const handleSubmit = async () => { 
    try {
      setIsSubmitting(true)
      await DataApi.createBulkAction(newBulkDetails)
      const sortName = waitingActionsSortData?.column
      const sortDirection = waitingActionsSortData?.order
      setIsReloadingWaitingActionList(true)
      await fetchPlannedList(ELEM_PER_PAGE, (waitingActionsSelectedPage - 1) * ELEM_PER_PAGE, sortName, sortDirection, getFromQuery(query, 'assume'))
      setIsReloadingWaitingActionList(false)
      resetDialogData()
      setIsSubmitting(false)
      closeAddBulkActionDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <CustomDialog hidden={!showAddBulkActionDialog}>
        {addActionDialogContent()}
      </CustomDialog>

      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>

      <div  style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        {
          assumedIdentity 
          ? <div className={classes.assumedUserInfo}>
              <div
                style={{
                  marginRight: '10px'
                }}
              >
                {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
              </div>
              <CustomButton 
                textComponent={
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-1px',
                    fontSize: '18px'
                  }}>
                    <OpenInNewOutlined fontSize={'inherit'}/>
                    <span style={{
                      marginLeft: '3px',
                      marginTop: '2px',
                      fontSize: '12px',
                      fontFamily: 'Avenir'
                    }}>{tForms('close_user_assume_view_button_label')}</span>
                  </div>
                }
                style={{
                  width: '200px',
                  maxWidth: '200px',
                  height: '35px',
                  paddingLeft: '10px',
                  paddingRight: '15px',
                  paddingTop: '7px'
                }}
                onClick={() => {
                  window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                }}
              />
            </div>
          : null
        }
        <div style={{
          textTransform: 'uppercase',
          fontSize: '16px',
          fontFamily: 'AvenirMedium',
          letterSpacing: '1.49px',
          lineHeight: '19px',
          marginTop: '20px',
          marginBottom: '20px',
          marginRight: '15px',
        }}>
          {tBulkActions('page_title')}
        </div>
        <div>
          <Tabs 
            selectedIndex={tabIndex} 
            onSelect={(index: number) => {
              setTabIndex(index)
              history.replace(`/action/bulk?tab=${tabs[index].id}${assumedIdentity ? `&assume=${assumedUser.uuid}` : ''}`)
            }}
          >
            <TabList className={classes.tabList}>
              <Tab className={classes.tab} selectedClassName={classes.tabSelected}>
                {tabs[0].label}
              </Tab>
              <Tab className={classes.tab} selectedClassName={classes.tabSelected}>
                {tabs[1].label}
              </Tab>
            </TabList>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px'
                }}
              >
                {
                  assumedIdentity || !userSettings.settings.allowedSendingSms || userSettings.isSuperAdminChild || permissions.permissions.includes(PermissionLevel.SUPERADMIN) ? null 
                  : <CustomButton 
                      textComponent={
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '-1px'
                        }}>
                          <AddIcon />
                          <span style={{
                            marginTop: '1px'
                          }}>
                            {tBulkActions('sent_package_button_label')}
                          </span>
                        </div>
                      }
                      style={{
                        maxWidth: '170px',
                        height: '35px',
                        paddingLeft: '10px',
                        paddingRight: '15px',
                        paddingTop: '7px',
                        marginBottom: '20px'
                      }}
                      onClick={() => {
                        dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                        setShowAddBulkActionDialog(true)
                      }}
                    />
                }
                <CustomTable 
                  columnData={waitingActionsTableColumnData}
                  content={waitingActionsTableData}
                  sort={true}
                  headerFontSize={'12px'}
                  contentFontSize={'12px'}
                  sortData={waitingActionsSortData}
                  sortSetter={setWaitingActionsSortData}
                  reloadingData={isReloadingWaitingActionList}
                  placeholder={
                    <div 
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        fontFamily: 'AvenirMedium',
                        color: Colors.TABLE_HEADER_LABEL,
                        fontSize: '16px'
                      }}
                    >
                      <div>
                        {tForms('no_data_in_table_placeholder')}
                      </div>
                    </div>
                  }
                />
                <div style={{
                  display: waitingActionsPages <= 1 ? 'none' : 'flex',
                  justifyContent: 'center'
                }}>
                  <div>
                    <Pagination 
                      pages={waitingActionsPages}
                      selectedPage={waitingActionsSelectedPage}
                      setSelectedPage={setWaitingActionsSelectedPage}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px'
                }}
              >
                <div className={classes.controlRow}>
                  <div className={classes.dateRangeField}>
                    <div className={classes.dateRangeLabel}>
                      {tForms('date_range_label')}
                    </div>
                    <div 
                      className={classes.clearButton} 
                      onClick={() => {
                        setSelectedDateRange(null)
                      }}
                    >
                      {tForms('clear_field_button_label')}
                    </div> 
                    <Flatpickr
                      id={"date-range-picker"}
                      data-enable-time
                      value={
                        selectedDateRange && selectedDateRange.length >= 2
                        ? [
                          moment(selectedDateRange[0]).startOf("day").toDate(),
                          moment(selectedDateRange[1]).startOf("day").toDate(),
                        ]
                        : []
                      }
                      options={{
                        locale: {
                          firstDayOfWeek: 1,
                          rangeSeparator: ' - ',
                          months: {
                            shorthand: monthList,
                            longhand: monthList
                          },
                          weekdays: {
                            shorthand: dayListShorthand,
                            longhand: dayList
                          }
                        },
                        dateFormat: "d.m.Y",
                        mode: 'range',
                      }}
                      onClose={(date) => {
                        if (date && date.length === 2) {
                          setSelectedDateRange(date)
                        }
                      }}
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomFlatpickrInput
                            defaultValue={defaultValue}
                            inputRef={ref}
                            width={'100%'}
                          />
                        )
                      }}
                    />
                  </div>
                 
                </div>
                <div className={classes.statTileSection}>
                  <div className={classes.msgSentTile}>
                    <StatTile
                      textColor={'#FFFFFF'}
                      backgroundColor={'#F6AE3F'}
                      label={'Wysłane wiadomości'}
                      iconSrc={sendIcon}
                      value={`${sentActionsListStats.count}`}
                    />
                  </div>

                  <div className={classes.responsesCountTile}>
                    <StatTile
                      textColor={'#FFFFFF'}
                      backgroundColor={'#F64A3F'}
                      label={'Odpowiedzi'}
                      iconSrc={dialogIcon}
                      value={`${sentActionsListStats.responses}`}
                    />
                  </div>

                  <div className={classes.responsesCountTile}>
                    <StatTile
                      textColor={'#FFFFFF'}
                      backgroundColor={'#FFFFFF'}
                      label={''}
                      iconSrc={''}
                      value={''}
                    />
                  </div>

                  <div className={classes.emptySpaceTile}>
                    <StatTile
                      textColor={'#FFFFFF'}
                      backgroundColor={'#FFFFFF'}
                      label={''}
                      iconSrc={''}
                      value={''}
                    />
                  </div>

                  <div className={classes.emptySpaceTile}>
                    <StatTile
                      textColor={'#FFFFFF'}
                      backgroundColor={'#FFFFFF'}
                      label={''}
                      iconSrc={''}
                      value={''}
                    />
                  </div>
                </div>
                <CustomTable 
                  columnData={sentActionsTableColumnData}
                  content={sentActionsTableData}
                  sort={true}
                  headerFontSize={'12px'}
                  contentFontSize={'12px'}
                  sortData={sentActionsSortData}
                  sortSetter={setSentActionsSortData}
                  reloadingData={isReloadingSentActionList}
                  placeholder={
                    <div 
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        fontFamily: 'AvenirMedium',
                        color: Colors.TABLE_HEADER_LABEL,
                        fontSize: '16px'
                      }}
                    >
                      <div>
                        {tForms('no_data_in_table_placeholder')}
                      </div>
                    </div>
                  }
                />
                <div style={{
                  display: sentActionsPages <= 1 ? 'none' : 'flex',
                  justifyContent: 'center'
                }}>
                  <div>
                    <Pagination 
                      pages={sentActionsPages}
                      selectedPage={sentActionsSelectedPage}
                      setSelectedPage={setSentActionsSelectedPage}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default BulkActionPage
