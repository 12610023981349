import * as Yup from "yup"
import { ClientType } from '../../types/smsApiMessage'

export const addUserReactionValidation = (t: (val: string) => string) => {
	return Yup.object({
		clientType: Yup.number()
			.required(t('common:field_required'))
			.test('not_undefined', t('common:field_required'), function (val: any) {
				return val != ClientType.UNDEFINED
			}),
			message: Yup.string()
			.required(t('common:field_required'))
			.min(25, t('common:min_25_char_error')),
	})
}
