import CloseIcon from '@material-ui/icons/Close'
import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DataApi from '../../../api/DataApi'
import { ClientType, clientTypes } from '../../../types/smsApiMessage'
import { preventReturnKey } from '../../../utils/common'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { addUserReactionValidation } from '../../../utils/validations/addUserReactionValidation'
import BaseInput from '../../common/BaseInput/BaseInput'
import CustomSelectField from '../../common/CustomSelectField/CustomSelectField'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import CustomDialog from '../../CustomDialog/CustomDialog'
import styles from './styles.module.scss'

type Props = {
  hidden: boolean
  messageUuid: string
  onClose: () => void
  afterSubmit: () => void
}

const initialFormValues = {
  clientType: ClientType.UNDEFINED,
  message: '',
}

const AddUserReactionDialog = (props: Props) => {
  const t = useTranslation('forms').t

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  
  const formRef = useRef() as any

  const handleSubmit = async (data) => {
    try {
      setIsSubmitting(true)
      await DataApi.createUserReactionToMessage(props.messageUuid, {
        clientType: data.clientType,
        message: data.message
      })
      setIsSubmitting(false)
    } catch (error) {
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
    props.afterSubmit()
    resetForm()
    props.onClose()
  }

  const resetForm = () => {
    formRef?.current?.resetForm()
  }

  return (
    <CustomDialog 
      style={{
        position: 'fixed',
        background: 'white',
        width: '650px',
        minHeight: '100px',
        border: '1px solid #979797',
        borderRadius: '1px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      hidden={props.hidden}
    >
      <div className={styles.container}>
        <div className={styles.titleRow}>
          <div className={styles.title}>
            {t('add_user_reaction_dialog_label')}
          </div>
          <div className={styles.closeButtonContainer}>
            <span 
              className={styles.closeButton}
              onClick={() => {
                resetForm()
                props.onClose()
              }}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={formRef}
          validationSchema={addUserReactionValidation(t)}
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form onKeyDown={preventReturnKey}>
              <div className={styles.rowContainer}>
                <div className={styles.selectField} >
                  <p className={styles.fieldLabel}>
                    {t('add_user_reaction_client_type_field_label')}
                  </p>
                  <CustomSelectField
                    instanceId={'role-field'}
                    name={'clientType'}
                    options={clientTypes.map(e => {
                      return {
                        label: e.name,
                        value: e.type
                      }
                    })}
                    placeholder={t('add_user_reaction_client_type_placeholder')}
                    value={formikProps.values.clientType}
                    formikProps={formikProps}
                    error={formikProps.touched.clientType && Boolean(formikProps.errors.clientType)}
                  />
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.messageField}>
                  <p className={styles.fieldLabel}>
                    {t('add_user_reaction_message_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={240}
                    rows={5}
                    name={'message'}
                    placeholder={t('add_user_reaction_message_placeholder')}
                    formikProps={formikProps}
                    value={formikProps.values.message}
                    error={formikProps.touched.message && Boolean(formikProps.errors.message)}
                  />
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <LoadingButton 
                  text={t('save_button_label')}
                  type={'submit'}
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CustomDialog>
    
  )
}

export default AddUserReactionDialog
