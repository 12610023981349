import * as Yup from "yup"
import { timeSelectHoursOptions } from '../../types/common'

export const editConfigValidation = (t: (val: string) => string) => {
  return Yup.object({
    weekdaysTimetableTo: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('weekdaysTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('earlier_than_from', t('earlier_than_from'), function (val: any) {
        const enabled = this.resolve(Yup.ref('weekdaysTimetableEnabled')) as any
        const fromTime = this.resolve(Yup.ref('weekdaysTimetableFrom')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === fromTime)

        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
    }),
    weekdaysTimetableFrom: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('weekdaysTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('later_than_to', t('later_than_to'), function (val: any) {
        const enabled = this.resolve(Yup.ref('weekdaysTimetableEnabled')) as any
        const toTime = this.resolve(Yup.ref('weekdaysTimetableTo')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === toTime)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        
        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
      }),
    saturdayTimetableTo: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('saturdayTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('earlier_than_from', t('earlier_than_from'), function (val: any) {
        const enabled = this.resolve(Yup.ref('saturdayTimetableEnabled')) as any
        const fromTime = this.resolve(Yup.ref('saturdayTimetableFrom')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === fromTime)

        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
    }),
    saturdayTimetableFrom: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('saturdayTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('later_than_to', t('later_than_to'), function (val: any) {
        const enabled = this.resolve(Yup.ref('saturdayTimetableEnabled')) as any
        const toTime = this.resolve(Yup.ref('saturdayTimetableTo')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === toTime)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        
        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
      }),
    sundayTimetableTo: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('sundayTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('earlier_than_from', t('earlier_than_from'), function (val: any) {
        const enabled = this.resolve(Yup.ref('sundayTimetableEnabled')) as any
        const fromTime = this.resolve(Yup.ref('sundayTimetableFrom')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === fromTime)

        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
      }),
    sundayTimetableFrom: Yup.string()
      .test('field_required', t("common:field_required"), function (val: any) {
        const enabled = this.resolve(Yup.ref('sundayTimetableEnabled')) as any
        if (enabled && !val) {
          return false
        }
        return true
      })
      .test('later_than_to', t('later_than_to'), function (val: any) {
        const enabled = this.resolve(Yup.ref('sundayTimetableEnabled')) as any
        const toTime = this.resolve(Yup.ref('sundayTimetableTo')) as any

        const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === toTime)
        const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
        
        return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
      }),
    timetableExceptions: Yup.array().of(
      Yup.object().shape({
        to: Yup.string()
          .test('field_required', t("common:field_required"), function (val: any) {
            const enabled = this.resolve(Yup.ref('enabled')) as any
            if (enabled && !val) {
              return false
            }
            return true
          })
          .test('earlier_than_from', t('earlier_than_from'), function (val: any) {
            const enabled = this.resolve(Yup.ref('enabled')) as any
            const fromTime = this.resolve(Yup.ref('from')) as any

            const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
            const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === fromTime)

            return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
          }),
        from: Yup.string()
          .test('field_required', t("common:field_required"), function (val: any) {
            const enabled = this.resolve(Yup.ref('enabled')) as any
            if (enabled && !val) {
              return false
            }
            return true
          })
          .test('later_than_to', t('later_than_to'), function (val: any) {
            const enabled = this.resolve(Yup.ref('enabled')) as any
            const toTime = this.resolve(Yup.ref('to')) as any

            const auctionPeriodToIdx = timeSelectHoursOptions.findIndex(e => e.value === toTime)
            const auctionPeriodFromIdx = timeSelectHoursOptions.findIndex(e => e.value === val)
            
            return !enabled || auctionPeriodFromIdx < auctionPeriodToIdx
          })
      })
    ),
    nextSendDelay: Yup.string()
      .required(t("common:field_required"))
      .test('nextSendDelay_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    activeSearchParamsLimit: Yup.string().required(t("common:field_required")),
    // primaryMarketMinBidValueFlat: Yup.string()
    //   .required(t("common:field_required"))
		// 	.test('flat_min_price', t('min_value_message_price_error'), (val) => {
		// 		return parseFloat((val || '0').replace(',', '.')) >= 0.16
		// 	}),
    // primaryMarketMinBidValuePlot: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('plot_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // primaryMarketMinBidValueHouse: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('house_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // primaryMarketMinBidValueGarage: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('garage_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // primaryMarketMinBidValueWarehouse: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('warehouse_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // primaryMarketMinBidValueServicePremise: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('service_premise_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValueFlat: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('flat_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValuePlot: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('plot_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValueHouse: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('house_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValueGarage: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('garage_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValueWarehouse: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('warehouse_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    // secondaryMarketMinBidValueServicePremise: Yup.string()
    //   .required(t("common:field_required"))
    //   .test('service_premise_min_price', t('min_value_message_price_error'), (val) => {
    //     return parseFloat((val || '0').replace(',', '.')) >= 0.16
    //   }),
    secondaryMarketIntervalFlat: Yup.string()
      .required(t("common:field_required"))
      .test('flat_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    secondaryMarketIntervalPlot: Yup.string()
      .required(t("common:field_required"))
      .test('plot_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    secondaryMarketIntervalHouse: Yup.string()
      .required(t("common:field_required"))
      .test('house_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    secondaryMarketIntervalGarage: Yup.string()
      .required(t("common:field_required"))
      .test('garage_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    secondaryMarketIntervalWarehouse: Yup.string()
      .required(t("common:field_required"))
      .test('warehouse_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      }),
    secondaryMarketIntervalServicePremise: Yup.string()
      .required(t("common:field_required"))
      .test('service_premise_min_price', t('min_value_message_interval_error'), (val) => {
        return parseFloat((val || '0').replace(',', '.')) >= 1
      })
  })
}

