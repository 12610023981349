import React from 'react'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import DataApi from '../../api/DataApi'

export const useEditSuperEventDialog = () => {
  const [regions, setRegions] = React.useState<any[]>([])
  const [smsTemplates, setSmsTemplates] = React.useState<any[]>([])
  const [account, setAccount] = React.useState<any>(null)

  const fetchRegionList = async (getAll?: boolean) => {
    try {
      const fetchedRegions = await DataApi.getRegionList(getAll)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

  const fetchSmsTemplateList = async (assumeUuid?: string) => {
    try {
      const fetchedTemplates = await DataApi.getSmsTemplateList(true, undefined, undefined, undefined, assumeUuid)
      setSmsTemplates(fetchedTemplates)
    } catch (error) {
			throw error
    }
  }  

  const editDetails = async (uuid: string, data: any) => {
    await DataApi.editSuperAdminSearchParamDetails(uuid, data)
  }

  const fetchAccountDetails = async () => {
    try {
      const data = await AuthRefreshApi.getAccountDetails()
      setAccount(data)
    } catch (error) {
			throw error
    }
  }

	return { 
    regions, smsTemplates, account,
    fetchRegionList, fetchSmsTemplateList, editDetails, fetchAccountDetails
	}
}