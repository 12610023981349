import React from 'react'
import DataApi from '../../../api/DataApi'

export const useBulkActionDetails = () => {
  const [regions, setRegions] = React.useState<any[]>([])
  const [smsTemplates, setSmsTemplates] = React.useState<any[]>([])

  const fetchSmsTemplateList = async (assumeUuid?: string) => {
    try {
      const fetchedTemplates = await DataApi.getSmsTemplateList(true, undefined, undefined, undefined, assumeUuid)
      setSmsTemplates(fetchedTemplates)
    } catch (error) {
			throw error
    }
  }

  const fetchRegionList = async () => {
    try {
      const fetchedRegions = await DataApi.getRegionList(true)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

	return { 
    smsTemplates, regions,
    fetchSmsTemplateList, fetchRegionList
	}
}