import { Colors } from "./../../../types/colors"
import { makeStyles, Theme } from "@material-ui/core"

export const paginationStyle = makeStyles((theme: Theme) => ({
    paginationElement: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontFamily: "AvenirMedium, Roboto, Arial, sans-serif",
        fontSize: "14px",
        padding: "1px 6px",
        borderRadius: "3px",
        margin: "1px 4px",
        display: "inline-block",
    },
    selectedOption: {
        color: theme.palette.common.white,
        backgroundColor: Colors.MAIN,
    },
}))
