import * as Yup from "yup"
import { email, forbiddenSpecialCharacters } from "./validationRegexps"


export const editUserDataValidation = (t: (val: string) => string) => {
  return Yup.object({
    name: Yup.string().required(t("common:field_required")),
    email: Yup.string()
      .required(t("common:field_required"))
      .matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
      .matches(email, t("invalid_email_validation"))
  })
}

