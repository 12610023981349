import React from 'react'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'
import { DefaultSmsUserSettings, SmsUserSettingsResponse } from '../../types/common'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers'
import { checkIfAdmin } from '../../utils/common'

export const useBulkActionPage = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [userSettings, setUserSettings] = React.useState<SmsUserSettingsResponse>({ settings: DefaultSmsUserSettings })
  const [plannedActionsList, setPlannedActionsList] = React.useState<any[]>([])
  const [plannedActionsListCount, setPlannedActionsListCount] = React.useState<number>(0)
  const [sentActionsList, setSentActionsList] = React.useState<any[]>([])
  const [sentActionsListCount, setSentActionsListCount] = React.useState<number>(0)
  const [sentActionsListStats, setSentActionsListStats] = React.useState<any>({
    count: 0,
    responses: 0
  })

  const fetchAssumedUser = async (uuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

  const fetchUserSettings = async () => {
    try {
      const data = await AuthRefreshApi.getAccountSettings()
      setUserSettings(data)
    } catch (error) {
			throw error
    }
  }

  const fetchPlannedList = async (limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, assumeUuid?: string) => {
    try {
      if (sortName && sortDirection) {
        const fetchedData = await DataApi.getBulkActionPlannedList(limit, offset, sortName, sortDirection, assumeUuid)
        setPlannedActionsListCount(fetchedData.count)
        setPlannedActionsList(fetchedData.data)
      } else {
        const fetchedData = await DataApi.getBulkActionPlannedList(limit, offset, undefined, undefined, assumeUuid)
        setPlannedActionsListCount(fetchedData.count)
        setPlannedActionsList(fetchedData.data)
      }
    } catch (error) {
			throw error
    }
  }

  const fetchSentList = async (limit?: number, offset?: number, sortName?: string, sortDirection?: SortDirection, from?: string, to?: string, assumeUuid?: string) => {
    try {
      if (sortName && sortDirection) {
        const fetchedData = await DataApi.getBulkActionSentList(limit, offset, from, to, sortName, sortDirection, assumeUuid)
        setSentActionsListCount(fetchedData.count)
        setSentActionsList(fetchedData.data)
      } else {
        const fetchedData = await DataApi.getBulkActionSentList(limit, offset, from, to, undefined, undefined, assumeUuid)
        setSentActionsListCount(fetchedData.count)
        setSentActionsList(fetchedData.data)
      }
    } catch (error) {
			throw error
    }
  }

  const fetchSentListStats = async (from?: string, to?: string, assumeUuid?: string) => {
    try {
      const fetchedData = await DataApi.getBulkActionSentStats(from, to, assumeUuid)
      setSentActionsListStats(fetchedData)
    } catch (error) {
			throw error
    }
  }

	return { 
    assumedUser, plannedActionsList, plannedActionsListCount, sentActionsList, sentActionsListCount, sentActionsListStats, userSettings,
    fetchAssumedUser, fetchPlannedList, fetchSentList, fetchSentListStats, fetchUserSettings
	}
}