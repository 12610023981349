export enum Colors {
  MAIN = "#4566C0",
  BACKGROUND = "#F4F6FC",
  SECONDARY = "#B0BAC9",
  MAIN_LIGHT = "#4566C033",
  BORDER_OFFER_CARD = "rgba(46, 91, 255, 0.08)",
  GREY = "#E4E4E4",
  AUTH_INPUT_BACKGROUND_GREY = "rgba(224, 231, 255, 0.2)",
  AUTH_INPUT_BACKGROUND_BORDER_GREY = "#E0E7FF",
  LIGHT_BLUE = "#6f86f4",
  LIGHT_GREY = "#F4F6FC",
  MAIN_FONT_COLOR = "#2E384D",

  MENU_SELECTED_TEXT = '#4566C0AA',
  MENU_HOVERED = '#4566C011',

  LIGHT_GRAY_TEXT = '#8798AD',
  CHECKBOX_BORDER = '#BBBBBB',
  CHECKBOX_CHECKED = '#4566C0',
  CHECKBOX_CHECKED_REMOVED = '#E4E4E4',
  CHECKBOX_ICON = '#FFFFFF',
  SELECT_OPTION_HOVER = '#4566C033',
  SELECT_OPTION_DEFAULT_TEXT = '#000000',
  SELECT_OPTION_DEFAULT_PLACEHOLDER = 'rgba(255,255,255,0.35)',
  SELECT_LIST_BACKGROUND = '#ffffff',
  CLEAR_FIELD_BUTTON = '#BFC5D2',
  TABLE_HEADER_LABEL = '#BFC5D2',
  TABLE_ROW_BORDER = 'rgba(46,91,255,0.08)',
  TABLE_MANAGE_CELL_TEXT = '#4566C0',
  SELECT_FIELD_SCROLLBAR = '#4566C066',

  INPUT_BORDER = '#E0E7FF',
  INPUT_BACKGROUND = 'rgba(224, 231, 255, 0.2)',
  INPUT_BORDER_FOCUS = '#4566C0',
  INPUT_BORDER_ERROR = 'red',
  AUTH_INPUT_BORDER = '#E0E7FF',
  AUTH_INPUT_BACKGROUND = 'rgba(224, 231, 255, 0.2)',
  AUTH_INPUT_BORDER_FOCUS = '#4566C0',
  AUTH_BUTTON_COLOR = '#4566C0',
  AUTH_HOVER_BUTTON_COLOR = '#4566C0DD',
  AUTH_DISABLED_BUTTON_COLOR = '#4566C099',
  GREEN_BUTTON_COLOR = 'rgb(99, 176, 107)',
  RED_BUTTON_COLOR = '#F64A3F',
  BLUE_BUTTON_COLOR = '#4566C0',
  ACTIVE_LABEL = '#30A53B',
  INACTIVE_LABEL = '#E02020',
  WAITING_FOR_ACTIVATION_LABEL = '#4566C0',

  EDIT_CONFIG_TEXT = '#6D7278',

  MESSAGE_COUNT_LABEL = '#ffa500',

  BORDER_FAQ_CARD = "rgba(46, 91, 255, 0.08)",
  CONTENT_FAQ_TEXT = '#6D7278',
}
