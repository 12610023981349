import { makeStyles } from "@material-ui/styles"
import { Colors } from '../../../types/colors'

export const customButtonStyle = makeStyles(() => ({
  loadingButton: {
    flexGrow: 1,
    textTransform: "none",
    width: "100%",
    padding: "10px 0px",
    fontSize: "15px",
    fontWeight: "normal",
    marginTop: "5px",
    fontFamily: "AvenirMedium, Robot, Arial, sans-serif",
    backgroundColor: Colors.AUTH_BUTTON_COLOR,
    border: 0,
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    '@media (max-width: 1000px)': {
      marginTop: "10px",
    },
    '&:hover': {
      backgroundColor: Colors.AUTH_HOVER_BUTTON_COLOR,
    },
    '&:disabled': {
      backgroundColor: Colors.AUTH_DISABLED_BUTTON_COLOR,
      opacity: 0.5,
      cursor: 'default'
    }
  }
}))
