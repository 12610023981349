import { Colors } from "./../../../types/colors"
import { makeStyles, Theme } from "@material-ui/core"

export type StyleProps = {
  borderColor?: string
  borderColorFocus?: string
}

export const passwordInputStyle =  (props: StyleProps) => makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
    backgroundColor: Colors.AUTH_INPUT_BACKGROUND,
    paddingTop: '9px',
    paddingBottom: '9px',
    paddingLeft: '14px',
    paddingRight: '14px',
    border: `solid 1px ${props.borderColor || Colors.AUTH_INPUT_BORDER}`,
    borderRadius: '5px',
    fontSize: '15px',
    fontFamily: '"AvenirBook", "Roboto", "Arial", sans-serif',
    '&:focus': {
      border: `solid 2px ${props.borderColorFocus || Colors.AUTH_INPUT_BORDER_FOCUS}`,
      outline: 'none !important',
      marginBottom: "-1px",
      marginTop: "-1px",
    }
  },
  label: {
    fontSize: "12px",
    textTransform: "uppercase",
    marginBottom: "3px",
    letterSpacing: "1.13px",
    fontFamily: "AvenirMedium, Roboto, Arial, sans-serif",
    color: '#B0BAC9'
  }
}))
