import { Parser } from 'bbcode-to-react'
import LiTag from './tags/basic/li'
import ParagraphTag from './tags/basic/p'
import ImageTag from './tags/basic/image'
import SpanTag from './tags/basic/span'
import AttentionTag from './tags/basic/attention'
import SupTag from './tags/basic/sup'

const basicTags = [
  'b', 'i', 'u', 's', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre', 'table', 'thead', 'tbody',
  'tr', 'th', 'td', 'code', 'img', 'hr', 'size', 'center', 'right', 'color', 'list',
  '\'*\'', 'url', 'link', 'email']

class BBCodeParser {
  private basicInstance: Parser

  constructor() {
    this.basicInstance = new Parser(basicTags)
    this.basicInstance.registerTag('li', LiTag)
    this.basicInstance.registerTag('p', ParagraphTag)
    this.basicInstance.registerTag('span', SpanTag)
    this.basicInstance.registerTag('image', ImageTag)
    this.basicInstance.registerTag('attention', AttentionTag)
    this.basicInstance.registerTag('sup', SupTag)
  }
  
  toReact(input: string): React.ReactNode {
    return this.basicInstance.toReact(input)
  }
}

export default new BBCodeParser()