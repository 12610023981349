import { customInputFieldStyles } from './customInputFieldStyles'
import React from 'react'
import { FormikProps } from 'formik'

type Props = {
  name: string
  rows?: number
  id?: string
  placeholder?: string
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  countClassName?: string
  type?: string
  error?: boolean
  count?: boolean
  maxLen?: number
  byteLen?: boolean
  errorLabel?: JSX.Element
  disabled?: boolean
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  inputPattern?: RegExp
}

const CustomInputField = (props: Props) => {
  const [value, setValue] = React.useState<string>("")
  const [curLen, setCurLen] = React.useState<number>(0)

  const classes = customInputFieldStyles(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()

  React.useEffect(() => {
    if (props.value != null) {
      setCurLen(props.value.length)
    }
  }, [props.value])

  return (
    <>
      {
        props.rows != null
        ? <textarea
            disabled={props.disabled}
            rows={props.rows}
            {...props}
            style={{
              resize: 'none'
            }}
            className={`${(props.className || classes.input)}`}
            value={props.value != null ? props.value : value}
            {...(props.byteLen ? {} : { maxLength: props.maxLen })}
            onChange={(ev) => {
              if (props.inputPattern && !props.inputPattern.test(ev.target.value)) {
                return
              }
              const len = props.byteLen ? Buffer.byteLength(ev.target.value, 'utf8') : ev.target.value?.length
              if (props.byteLen && len > props.maxLen) {
                return
              }
              if (props.count) {
                setCurLen(len || 0)
              } 
              props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
              props.formikProps?.setFieldValue(
                props.name,
                ev.target.value
              )
            }}
          />
        : <input
          {...props}
          disabled={props.disabled}
          className={`${(props.className || classes.input)}`}
          value={props.value != null ? props.value : value}
          {...(props.byteLen ? {} : { maxLength: props.maxLen })}
          onChange={(ev) => {
            if (props.inputPattern && !props.inputPattern.test(ev.target.value)) {
              return
            }
            const len = props.byteLen ? Buffer.byteLength(ev.target.value, 'utf8') : ev.target.value?.length
            if (props.byteLen && len > props.maxLen) {
              return
            }
            if (props.count) {
              setCurLen(len || 0)
            } 
            props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
            props.formikProps?.setFieldValue(
              props.name,
              ev.target.value
            )
          }}
        />
      }
      {
        props.error && props.errorLabel ? <div className={props.errorLabelClassName || classes.errorLabel} style={{
          float: 'left'
        }}>
          {props.errorLabel}
        </div> : null
      }
      {
        props.count && props.maxLen ? <div className={props.countClassName || classes.countLabel} style={{
          float: 'right'
        }}>
          {`${curLen}/${props.maxLen}`}
        </div> : null
      }
    </>
  )
}

export default CustomInputField
