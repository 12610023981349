import React from 'react'
import { getFromQuery, useQuery } from '../../utils/common'
import EventDetails from '../EventDetails/EventDetails'
import SuperEventDetails from '../SuperEventDetails/SuperEventDetails'

const SuperEventDetailsSelector = () => {
  const query = useQuery()

  return (
    <>
      {
        getFromQuery(query, 'assume') != null
        ? <EventDetails />
        : <SuperEventDetails />
      }
    </>
  )
}

export default SuperEventDetailsSelector
