import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

export const customTableStyle = makeStyles((theme: Theme) => ({
  tableHeaderCell: {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontFamily: 'AvenirMedium',
    textTransform: 'uppercase',
    fontWeight: 'normal',
    color: Colors.TABLE_HEADER_LABEL
  }, 
  tableDataCell: {
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '10px'
  }
}))
