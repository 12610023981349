import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

export const myAgencyStyle = makeStyles((theme: Theme) => ({
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: 'black',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  fieldWithLabel: {
    position: 'relative'
  },
  rowContainer: {
    display: 'flex'
  },
  manageLink: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    wordBreak: 'keep-all',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  sectionLabel: {
    fontFamily: 'AvenirBook',
    fontWeight: 'bold',
    color: Colors.EDIT_CONFIG_TEXT,
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  assumedUserInfo: {
    marginBottom: '20px',
    display: 'flex',
    fontFamily: 'AvenirBook',
    fontSize: '16px',
    alignItems: 'center'
  }
}))
