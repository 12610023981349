import { AuthData, Login } from "../types/auth"
import { Success } from "../types/common"
import { BASE_AUTH } from "./baseRoutes"
import { BaseAuthApi } from './BaseAuthApi'

class AuthApi extends BaseAuthApi {
  constructor() {
    super({
      baseURL: `${BASE_AUTH}/api/v1/sms/auth`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  public resendActivationEmail(email: string) {
    return this.post<Success>(`/email/email-verification/resend`, JSON.stringify({ email }))
  }

  public activateUser(password: string, token: string | null) {
    return this.post<Success>(`/email/verify?token=${token}`, JSON.stringify({ password }))
  }

  public checkActivateUserToken(token: string | null) {
    return this.post<Success>(`/email/verify/check?token=${token}`)
  }

  public verifyEmailChangeToken(token: string | null) {
    return this.post<Success>(`/email/change/verify?token=${token}`)
  }

  public sendResetPasswordRequest(email: string) {
    return this.post<Success>("/password/reset/start", JSON.stringify({ email }))
  }

  public changePassword(password: string, token: string | null) {
    return this.post<Success>(`/password/reset/finish?token=${token}`, JSON.stringify({ password }))
  }

  public checkChangePasswordToken(token: string | null) {
    return this.post<Success>(`/password/reset/check?token=${token}`)
  }

  public login(data: Login) {
    return this.post<AuthData>(`/login`, JSON.stringify(data))
  }

  public checkSession() {
    return this.post<Partial<AuthData>>(`/session/check`)
  }
}

export default new AuthApi()