import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Redirect, useParams } from "react-router-dom"
import { State } from "../../redux/reducers"
import { FAQ_QUESTIONS } from "../../types/faq"
import { slugifyString } from "../../utils/common"
import faqBBCode from '../../assets/bbCode/faqBBCode.json'
import BBCodeArticle from "../common/BBCodeArticle/BBCodeArticle"

const FAQAnswerPage = () => {
  const { slug } = useParams<{ slug: string }>()
  const { t } = useTranslation("faq")
  const permissions = useSelector((e: State) => e.permissions)
  const currentQuestion = FAQ_QUESTIONS.find((question) => slugifyString(t(question.id)) === slug)

  return !permissions.permissions.some((permission) => currentQuestion.permissions.includes(permission))
  ? <Redirect to={'/faq'}/>
  : <BBCodeArticle bbcode={faqBBCode[currentQuestion.id]}/>
}

export default FAQAnswerPage