import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon
} from "@material-ui/icons"
import { FieldArray, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from "react"
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux"
import { Link, useHistory } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import 'react-tabs/style/react-tabs.css'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import DataApi from '../../api/DataApi'
import trashIcon from '../../assets/images/Trash.webp'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { AdvertType, propertyTypes, transactionTypes, plotTypes } from '../../types/advert'
import { PermissionLevel, UserRoles } from '../../types/auth'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList, MSG_SIGNATURE_LEN, TEMPLATE_FIRST_MSG_LEN, TEMPLATE_MAX_MSG, TEMPLATE_MSG_LEN, TEMPLATE_MULTIPLE_MSG_LEN, timeSelectHoursOptions } from '../../types/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { sortRowData } from '../../utils/sort'
import { addTemplateValidation } from '../../utils/validations/addTemplateValidation'
import { addUserValidation } from '../../utils/validations/addUserValidation'
import { editConfigValidation } from '../../utils/validations/editConfigValidation'
import { editTemplateValidation } from '../../utils/validations/editTemplateValidation'
import { smsMessagePattern } from '../../utils/validations/validationRegexps'
import BaseInput from '../common/BaseInput/BaseInput'
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import CustomSelectField from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { superuserSettingsStyle } from './superuserSettingsStyle'
import { useSuperuserSettings } from './useSuperuserSettings'
import CalculateAdvertsForm from '../common/CalculateAdvertsForm/CalculateAdvertsForm'
import { evalStringTemplate, formatNumber } from '../../utils/text'
import { bulkActionStatusTypes } from '../../types/bulkAction'
import BulkActionDetails from '../common/BulkActionDetails/BulkActionDetails'
import CustomAsyncCreatableSelectField from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import { AsyncCreatableSelectOption } from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import { AsyncSelectOption } from '../common/CustomAsyncSelectField/CustomAsyncSelectField'
import CustomAsyncSelectField from '../common/CustomAsyncSelectField/CustomAsyncSelectField'
import { getFromQuery, useQuery } from '../../utils/common'

const usersColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '4%'
  },{
    label: 'Data\u00A0i\u00A0godzina dodania',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '10%'
  }, {
    label: 'Lokalizacje',
    name: 'location',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '11%'
  }, 
  {
    label: 'Imię\u00A0i\u00A0nazwisko',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '10%'
  }, {
    label: 'Adres\u00A0e\u2011mail',
    name: 'email',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '10%'
  }, {
    label: 'Abonament',
    name: 'subscription_value',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Liczba kont agentów',
    name: 'child_account_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Wysłane SMSy',
    name: 'won_bid_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Liczba odpowiedzi',
    name: 'responses_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Konwersja',
    name: 'conversion',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Rola',
    name: 'role',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    width: '8%'
  }, {
    label: 'Tagi',
    name: 'tags',
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '10%'
  }, {
    label: 'Status',
    name: 'status',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '7%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      right: '10px'
    },
    width: '5%'
  }
]

const templatesColumnData: ColumnData[] = [
  {
    label: 'Lp',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '5%'
  },{
    label: 'Treść\u00A0wiadomości',
    headerAlign: 'left',
    contentAlign: 'left',
    additionalContentStyle: {
      whiteSpace: 'break-spaces'
    },
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '55%'
  }, {
    label: 'Liczba\u00A0znaków',
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: 'Liczba\u00A0SMS',
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }
]

const bulkActionsTableColumnData: ColumnData[] = [
  {
    label: 'Konto',
    name: 'account',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  },
  {
    label: 'Data\u00A0i\u00A0godzina dodania',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      right: '10px'
    },
    width: '10%'
  }, {
    label: 'Planowany start\u00A0wysyłki',
    name: 'sentAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    width: '10%'
  }, {
    label: 'Nazwa\u00A0paczki',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    name: 'parameters',
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '32%'
  }, {
    label: 'Estymowana wielkość paczki',
    name: 'estimatedAdvertCount',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '10%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '8%'
  }
]

const emptyEditConfigFormData = {
  activeSearchParamsLimit: '',
  // primaryMarketMinBidValueFlat: '',
  // primaryMarketMinBidValuePlot: '',
  // primaryMarketMinBidValueHouse: '',
  // primaryMarketMinBidValueGarage: '',
  // primaryMarketMinBidValueWarehouse: '',
  // primaryMarketMinBidValueServicePremise: '',
  // secondaryMarketMinBidValueFlat: '',
  // secondaryMarketMinBidValuePlot: '',
  // secondaryMarketMinBidValueHouse: '',
  // secondaryMarketMinBidValueGarage: '',
  // secondaryMarketMinBidValueWarehouse: '',
  // secondaryMarketMinBidValueServicePremise: '',
  // secondaryMarketIntervalFlat: '',
  // secondaryMarketIntervalPlot: '',
  // secondaryMarketIntervalHouse: '',
  // secondaryMarketIntervalGarage: '',
  // secondaryMarketIntervalWarehouse: '',
  // secondaryMarketIntervalServicePremise: '',
  timetableExceptions:[],
  weekdaysTimetableEnabled: false,
  weekdaysTimetableFrom: '',
  weekdaysTimetableTo: '',
  saturdayTimetableEnabled: false,
  saturdayTimetableFrom: '',
  saturdayTimetableTo: '',
  sundayTimetableEnabled: false,
  sundayTimetableFrom: '',
  sundayTimetableTo: '',
}

const emptyEditTemplateFormData = {
  template: ''
}

const tabs = [
  {
    id: 'users',
    label: 'Użytkownicy systemu'
  },
  {
    id: 'bulk-to-send',
    label: 'Paczki do wysyłki'
  },
  {
    id: 'offer-calculator',
    label: 'Kalkulator ofert'
  },
  {
    id: 'msg-templates',
    label: 'Szablony wiadomości'
  },
  {
    id: 'system-settings',
    label: 'Ustawienia systemu'
  }
]

const ELEM_PER_PAGE = 20
const DAY_IN_SECONDS = 24 * 60 * 60

const SuperuserSettings = () => {
  const classes = superuserSettingsStyle()
  const tForms = useTranslation("forms").t
  const tSuperuserSettings = useTranslation("superuserSettings").t
  const tBulkActions = useTranslation("bulkActions").t
  const query = useQuery()
  
  const dispatch = useDispatch()
  const history = useHistory()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [isReloadingUserData, setIsReloadingUserData] = React.useState<boolean>(true)
  const [isReloadingTemplateData, setIsReloadingTemplateData] = React.useState<boolean>(true)
  const [isReloadingBulkActionData, setIsReloadingBulkActionData] = React.useState<boolean>(true)

  const [estimatedAdvertCount, setEstimatedAdvertCount] = React.useState<number | null>(null)

  const [selectedUsersPage, setSelectedUsersPage] = React.useState<number>(1)
  const [selectedUsersTags, setSelectedUsersTags] = React.useState<AsyncSelectOption[]>([])
  const [selectedUsersDataRange, setSelectedUsersDataRange] = React.useState<Date[] | null>(null)
  const [usersPages, setUsersPages] = React.useState<number>(1)

  const [selectedTemplatesPage, setSelectedTemplatesPage] = React.useState<number>(1)
  const [templatesPages, setTemplatesPages] = React.useState<number>(1)

  const [selectedBulkActionsPage, setSelectedBulkActionsPage] = React.useState<number>(1)
  const [bulkActionsPages, setBulkActionsPages] = React.useState<number>(1)
  const [isEditSentDateActive, setIsEditSentDateActive] = React.useState<boolean>(false)
  const [newSentDate, setNewSentDate] = React.useState<Date | null>(null)
  const newSentDateRef = useRef() as any

  const [usersTableData, setUsersTableData] = React.useState<any[]>([])
  const [usersSortData, setUsersSortData] = React.useState<SortData | null>(null)
  const [templatesTableData, setTemplatesTableData] = React.useState<any[]>([])

  const [bulkActionsTableData, setBulkActionsTableData] = React.useState<any[]>([])
  const [bulkActionsSortData, setBulkActionsSortData] = React.useState<SortData | null>(null)

  const [chosenBulkAction, setChosenBulkAction] = React.useState<any | null>(null)

  const [tabIndex, setTabIndex] = React.useState<number>(tabs.findIndex(e => e.id === getFromQuery(query, 'tab')) > -1 ? tabs.findIndex(e => e.id === getFromQuery(query, 'tab')) : 0)


  // offer calculater
  const [offerCalculatorFormData, setOfferCalculatorFormData] = React.useState<any | null>(null)
//     const [offerCalculatorFormData, setOfferCalculatorFormData] = React.useState<any | null>({ params: {
//     "advertType": 2,
//     "offerType": 2,
//     "regionUuid": "95688680-2b8f-4605-b1b5-7e832dea4cff",
//     "townUuids": [],
//     "districtUuids": [],
//     "minArea": 0,
//     "maxArea": 111111111,
//     "minPrice": 0,
//     "maxPrice": 111111111,
//     "start": "2019-12-01T00:00:00+01:00",
//     "end": "2021-12-31T00:00:00+01:00"
// }})

  // add user
  const [chosenName, setChosenName] = React.useState<string>('')
  const [chosenEmail, setChosenEmail] = React.useState<string>('')
  const [chosenSubscriptionValue, setChosenSubscriptionValue] = React.useState<string>('')
  const [chosenRole, setChosenRole] = React.useState<string>(UserRoles[0].value)
  const [chosenTags, setChosenTags] = React.useState<AsyncCreatableSelectOption[]>([])
  const [chosenAllowedRegion, setChosenAllowedRegion] = React.useState<string>('')
  const [chosenAllowedTowns, setChosenAllowedTowns] = React.useState<string[]>([])
  // const [chosenBalance, setChosenBalance] = React.useState<string>('')
  const [sendEmailNotification, setSendEmailNotification] = React.useState<boolean>(false)
  const [allowedSendingSms, setAllowedSendingSms] = React.useState<boolean>(true)

  const [townList, setTownList] = React.useState<any[]>([])

  // add template
  const [chosenNewTemplateMessage, setChosenNewTemplateMessage] = React.useState<string>('')

  // edit template
  const [chosenTemplateToEdit, setChosenTemplateToEdit] = React.useState<string>('')
  const [chosenTemplateMessage, setChosenTemplateMessage] = React.useState<string>('')
  const [chosenTemplatePropertyType, setChosenTemplatePropertyType] = React.useState<string>('')
  const [editTemplateFormInitialValues, setEditTemplateFormInitialValues] = React.useState<any>(emptyEditTemplateFormData)

  // remove template
  const [chosenTemplateToRemove, setChosenTemplateToRemove] = React.useState<string>('')

  // edit config
  const [editConfigFormInitialValues, setEditConfigFormInitialValues] = React.useState<any>(emptyEditConfigFormData)

  const [newTimetableExceptionFormEnabled, setNewTimetableExceptionFormEnabled] = React.useState<boolean>(false)
  const [newTimetableExceptionDate, setNewTimetableExceptionDate] = React.useState<Date>(null)
  const [newTimetableExceptionEnabled, setNewTimetableExceptionEnabled] = React.useState<boolean>(false)
  const [newTimetableExceptionFrom, setNewTimetableExceptionFrom] = React.useState<string>('')
  const [newTimetableExceptionTo, setNewTimetableExceptionTo] = React.useState<string>('')

  const [editConfigActiveSearchParamsLimit, setEditConfigActiveSearchParamsLimit] = React.useState<string>('')

  // const [editConfigPrimaryMarketMinBidValueFlat, setEditConfigPrimaryMarketMinBidValueFlat] = React.useState<string>('')
  // const [editConfigPrimaryMarketMinBidValuePlot, setEditConfigPrimaryMarketMinBidValuePlot] = React.useState<string>('')
  // const [editConfigPrimaryMarketMinBidValueHouse, setEditConfigPrimaryMarketMinBidValueHouse] = React.useState<string>('')
  // const [editConfigPrimaryMarketMinBidValueGarage, setEditConfigPrimaryMarketMinBidValueGarage] = React.useState<string>('')
  // const [editConfigPrimaryMarketMinBidValueWarehouse, setEditConfigPrimaryMarketMinBidValueWarehouse] = React.useState<string>('')
  // const [editConfigPrimaryMarketMinBidValueServicePremise, setEditConfigPrimaryMarketMinBidValueServicePremise] = React.useState<string>('')
  
  // const [editConfigSecondaryMarketMinBidValueFlat, setEditConfigSecondaryMarketMinBidValueFlat] = React.useState<string>('')
  // const [editConfigSecondaryMarketMinBidValuePlot, setEditConfigSecondaryMarketMinBidValuePlot] = React.useState<string>('')
  // const [editConfigSecondaryMarketMinBidValueHouse, setEditConfigSecondaryMarketMinBidValueHouse] = React.useState<string>('')
  // const [editConfigSecondaryMarketMinBidValueGarage, setEditConfigSecondaryMarketMinBidValueGarage] = React.useState<string>('')
  // const [editConfigSecondaryMarketMinBidValueWarehouse, setEditConfigSecondaryMarketMinBidValueWarehouse] = React.useState<string>('')
  // const [editConfigSecondaryMarketMinBidValueServicePremise, setEditConfigSecondaryMarketMinBidValueServicePremise] = React.useState<string>('')
  
  const [editConfigSecondaryMarketIntervalFlat, setEditConfigSecondaryMarketIntervalFlat] = React.useState<string>('')
  const [editConfigSecondaryMarketIntervalPlot, setEditConfigSecondaryMarketIntervalPlot] = React.useState<string>('')
  const [editConfigSecondaryMarketIntervalHouse, setEditConfigSecondaryMarketIntervalHouse] = React.useState<string>('')
  const [editConfigSecondaryMarketIntervalGarage, setEditConfigSecondaryMarketIntervalGarage] = React.useState<string>('')
  const [editConfigSecondaryMarketIntervalWarehouse, setEditConfigSecondaryMarketIntervalWarehouse] = React.useState<string>('')
  const [editConfigSecondaryMarketIntervalServicePremise, setEditConfigSecondaryMarketIntervalServicePremise] = React.useState<string>('')

  const [showAddNewTimetableExceptionError, setShowAddNewTimetableExceptionError] = React.useState<boolean>(false)

  // dialog controls
  const [showAddUserDialog, setShowAddUserDialog] = React.useState<boolean>(false)
  const [showOfferCalculatorResultDialog, setShowOfferCalculatorResultDialog] = React.useState<boolean>(false)
  const [showAddTemplateDialog, setShowAddTemplateDialog] = React.useState<boolean>(false)
  const [showEditTemplateDialog, setShowEditTemplateDialog] = React.useState<boolean>(false)
  const [showConfirmRemoveTemplateDialog, setConfirmRemoveTemplateDialog] = React.useState<boolean>(false)
  const [showBulkActionsDetailsDialog, setShowBulkActionsDetailsDialog] = React.useState<boolean>(false)
  const [showRejectBulkActionsDetailsDialog, setShowRejectBulkActionsDetailsDialog] = React.useState<boolean>(false)
  const [showAcceptBulkActionsDetailsDialog, setShowAcceptBulkActionsDetailsDialog] = React.useState<boolean>(false)

  // dialog refs
  const formAddUserRef = useRef() as any
  const formAddTemplateRef = useRef() as any
  const formEditTemplateRef = useRef() as any
  const formEditConfigRef = useRef() as any
    
  const { 
    config, usersList, usersCount, smsTemplates, smsTemplatesCount, regions, bulkActionsList, bulkActionsCount,
    createUserTagList, filterUserTagList,
		fetchConfig, fetchUsersList, fetchUsersCount, fetchSmsTemplateList, fetchSmsTemplateCount, fetchRegionList, fetchBulkActionsWaitingForApprovalList,
    fetchCreateUserTags, fetchFilterUserTags
	} = useSuperuserSettings()

  React.useEffect(() => {
    if ((usersSortData && usersSortData.default) || !usersSortData) {
      setIsReloadingUserData(true)
      setSelectedUsersPage(1)
      fetchUsersCount(selectedUsersTags).then(res => {
        fetchUsersList(ELEM_PER_PAGE, 0, selectedUsersTags, selectedUsersDataRange).then(res => {
          // setSelectedUsersPage(1)
          setIsReloadingUserData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (usersSortData && isNaN(parseInt(usersSortData.column))) {
      setIsReloadingUserData(true)
      setSelectedUsersPage(1)
      fetchUsersCount(selectedUsersTags).then(res => {
        fetchUsersList(ELEM_PER_PAGE, 0, selectedUsersTags, selectedUsersDataRange, usersSortData.column, usersSortData.order).then(res => {
          // setSelectedUsersPage(1)
          setIsReloadingUserData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (usersSortData && !isNaN(parseInt(usersSortData.column))) {
      const tmpData = [...usersTableData]
      sortRowData(tmpData, usersSortData)
      setUsersTableData([...tmpData])
    }
  }, [usersSortData, selectedUsersTags, selectedUsersDataRange])

  React.useEffect(() => {
    if (showBulkActionsDetailsDialog) {
      setNewSentDate(null)
    }
  }, [showBulkActionsDetailsDialog])

  React.useEffect(() => {
    if (bulkActionsSortData && bulkActionsSortData.default) {
      setIsReloadingBulkActionData(true)
      fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, 0).then(res => {
        setSelectedBulkActionsPage(1)
        setIsReloadingBulkActionData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (bulkActionsSortData && isNaN(parseInt(bulkActionsSortData.column))) {
      setIsReloadingBulkActionData(true)
      fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, 0, bulkActionsSortData.column, bulkActionsSortData.order).then(res => {
        setSelectedBulkActionsPage(1)
        setIsReloadingBulkActionData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (bulkActionsSortData && !isNaN(parseInt(bulkActionsSortData.column))) {
      const tmpData = [...bulkActionsTableData]
      sortRowData(tmpData, bulkActionsSortData)
      setBulkActionsTableData([...tmpData])
    }
  }, [bulkActionsSortData])

  React.useEffect(() => {
    if (config) {
      setInitialEditConfigFormData()
    }
  }, [config])

  React.useEffect(() => {
    fetchConfig().then(res => {
      fetchRegionList().then(res => {
        fetchCreateUserTags('').then(res => {
          fetchFilterUserTags('').then(res => {
            fetchUsersCount().then(res => {
              fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE).then(res => {
                fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE).then(res => {
                  setIsLoading(false)
                }).catch(error => { 
                  onErrorResponseShowMessage(error)
                  if (error.response?.status === 403 || error.response?.status === 404) {
                    history.push('/')
                  }
                })
              }).catch(error => { 
                onErrorResponseShowMessage(error)
                if (error.response?.status === 403 || error.response?.status === 404) {
                  history.push('/')
                }
              })
            }).catch(error => { 
              onErrorResponseShowMessage(error)
              if (error.response?.status === 403 || error.response?.status === 404) {
                history.push('/')
              }
            })
          }).catch(error => { 
            onErrorResponseShowMessage(error)
            if (error.response?.status === 403 || error.response?.status === 404) {
              history.push('/')
            }
          })
        }).catch(error => { 
          onErrorResponseShowMessage(error)
          if (error.response?.status === 403 || error.response?.status === 404) {
            history.push('/')
          }
        })
      }).catch(error => { 
        onErrorResponseShowMessage(error)
        if (error.response?.status === 403 || error.response?.status === 404) {
          history.push('/')
        }
      })
    }).catch(error => { 
      onErrorResponseShowMessage(error)
      if (error.response?.status === 403 || error.response?.status === 404) {
        history.push('/')
      }
    })
  }, [])

  React.useEffect(() => {
    if (usersCount > 0) {
      setUsersPages(Math.ceil(usersCount / ELEM_PER_PAGE))
    } else {
      setUsersPages(1)
    }
  }, [usersCount])

  React.useEffect(() => {
    if (usersCount > 0) {
      setBulkActionsPages(Math.ceil(bulkActionsCount / ELEM_PER_PAGE))
    } else {
      setBulkActionsPages(1)
    }
  }, [bulkActionsCount])

  React.useEffect(() => {
    if (!isReloadingUserData && selectedUsersPage > 0) {
      const sortName = usersSortData?.column
      const sortDirection = usersSortData?.order
      setIsReloadingUserData(true)
      fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE, selectedUsersTags, selectedUsersDataRange, sortName, sortDirection).then(res => {
        setIsReloadingUserData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingUserData && selectedUsersPage === 1) {
      setIsReloadingUserData(false)
    }
  }, [selectedUsersPage])

  React.useEffect(() => {
    if (!isReloadingBulkActionData && selectedBulkActionsPage > 0) {
      const sortName = bulkActionsSortData?.column
      const sortDirection = bulkActionsSortData?.order
      setIsReloadingBulkActionData(true)
      fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE, sortName, sortDirection).then(res => {
        setIsReloadingBulkActionData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingBulkActionData && selectedBulkActionsPage === 1) {
      setIsReloadingBulkActionData(false)
    }
  }, [selectedBulkActionsPage])

  // TODO strings
  React.useEffect(() => {
    let locLabel = '-'
    const mapped = usersList.map((e, idx) => {
      locLabel = '-'
      if (e.allowedRegions && e.allowedRegions.length > 0) {
        locLabel = ''
        for (const [idx, reg] of e.allowedRegions.entries()) {
          if (idx > 0) locLabel += ', '
          locLabel += `${reg.name}`
        }
        if (e.allowedTowns && e.allowedTowns.length > 0) {
          locLabel += '; '
          for (const [idx, town] of e.allowedTowns.entries()) {
            if (idx > 0) locLabel += ', '
            locLabel += `${town.name}`
          }
        }
      }
      return {
        active: e.active,
        rawData: [
          idx + 1,
          moment(e.createdAt).unix(),
          locLabel,
          e.name,
          e.email,
          e.subscriptionValue,
          e.childAccountCount ?? 0,
          e.wonBidCount,
          e.responseCount,
          e.responseCount !== 0 ? e.responseCount/e.wonBidCount : 0,
          e.roles[0],
          // e.balance,
          e.active,
          '-'
        ],
        columns: [
          `${(selectedUsersPage - 1) * ELEM_PER_PAGE + (idx + 1)}.`,
          moment(e.createdAt).format('DD.MM.YYYY, HH:mm:ss'),
          locLabel,
          e.name,
          e.email,
          e.subscriptionValue != null ? `${formatNumber(e.subscriptionValue, true, true)} zł` : '-',
          e.childAccountCount ?? 0,
          e.wonBidCount,
          e.responseCount,
          `${e.responseCount !== 0 ? `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%` : '0,00%'}`,
          e.roles[0] === PermissionLevel.ADMIN
          ? <div style={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontFamily: 'AvenirHeavy',
              letterSpacing: '1.49px',
              lineHeight: '12px',
              color: 'white',
              backgroundColor: e.active ? '#2E384D' : '#E4E4E4',
              padding: '4px 6px'
            }}>
              Administrator
            </div>
          : <div style={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontFamily: 'AvenirHeavy',
              letterSpacing: '1.49px',
              lineHeight: '12px',
              color: 'white',
              backgroundColor: e.active ? '#4566C0' : '#E4E4E4',
              padding: '4px 6px'
            }}>
              Konto główne
            </div>,
          // `${e.balance.toFixed(2).replace('.', ',')} zł`,
          e.tags && e.tags.length > 0 
          ? <div className={classes.tagLabelsContainer}>
              {
                e.tags.map(tag => (
                  <div className={classes.tagLabel}>
                    {tag.label}
                  </div>
                ))
              }
            </div>
          : '-',
          e.active
          ? (
            !e.emailVerified
            ? <span style={{ color: Colors.WAITING_FOR_ACTIVATION_LABEL, wordBreak: 'keep-all' }}>{tSuperuserSettings('waiting_for_activation_label')}</span>
            : <span style={{ color: Colors.ACTIVE_LABEL, wordBreak: 'keep-all' }}>{tSuperuserSettings('active_label')}</span>
          )
          : <span style={{ color: Colors.INACTIVE_LABEL, wordBreak: 'keep-all' }}>{tSuperuserSettings('inactive_label')}</span>,
          <Link to={`/admin/user/${e.uuid}`} className={classes.manageLink}>{tForms('manage_label')}</Link>
        ]
      }
    })
    setUsersTableData(mapped)
  }, [usersList])

  React.useEffect(() => {
    let params = ''
    const mapped = bulkActionsList.map((e, idx) => {
      params = e.regionName
      if (e.subregionName) {
        params += `; Powiat:\u00A0${e.subregionName}`
      }
      if (e.townNames) {
        params += `; Miejscowości:\u00A0${e.townNames}`
      }
      if (e.districtNames) {
        params += `; Dzielnice:\u00A0${e.districtNames}`
      }
      params += `; 
      ${
        propertyTypes.find(el => el.value === `${e.params.advertType}`).label
      }${
        e.params.advertType === AdvertType.PLOT && e.params.advertSubType != null 
        ? ` - ${plotTypes.find(el => el.value === `${e.params.advertSubType}`).label}` 
        : ''}`
      params += `; ${transactionTypes.find(el => el.value === `${e.params.offerType}`).label}`
      params += `; Pow.:\u00A0${e.params.minArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2 - ${e.params.maxArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2`
      params += `; Cena:\u00A0${e.params.minPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł - ${e.params.maxPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł`

      return {
        columns: [
          e.user.parent ? `${e.user.parent.email} (${e.user.email})` : e.user.email,
          moment(e.createdAt).format('DD.MM.YYYY, HH:mm'),
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm'),
          e.name,
          params,
          evalStringTemplate(tForms('estimated_advert_count'), { count: e.estimatedCount }),
          <div
            className={classes.seeActionDetails}
            onClick={() => {
              setChosenBulkAction(e)
              setIsEditSentDateActive(false)
              newSentDateRef?.current?.flatpickr.clear()
              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
              setShowBulkActionsDetailsDialog(true)
            }}
          >
            Zobacz
          </div>
        ]
      }
    })
    setBulkActionsTableData([...mapped])
  }, [bulkActionsList])

  React.useEffect(() => {
    if (smsTemplatesCount > 0) {
      const pages = Math.ceil(smsTemplatesCount / ELEM_PER_PAGE)
      if (selectedTemplatesPage > pages) {
        setSelectedTemplatesPage(pages)
      }
      setTemplatesPages(Math.ceil(smsTemplatesCount / ELEM_PER_PAGE))
    } else {
      setTemplatesPages(1)
    }
  }, [smsTemplatesCount])

  React.useEffect(() => {
    if (!isReloadingTemplateData && selectedTemplatesPage > 0 && chosenTemplatePropertyType != null && chosenTemplatePropertyType != '') {
      setIsReloadingTemplateData(true)
      fetchSmsTemplateList(ELEM_PER_PAGE, (selectedTemplatesPage - 1) * ELEM_PER_PAGE, parseInt(chosenTemplatePropertyType)).then(res => {
        setIsReloadingTemplateData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingTemplateData && selectedTemplatesPage === 1) {
      setIsReloadingTemplateData(false)
    }
  }, [selectedTemplatesPage])

  React.useEffect(() => {
    if (chosenTemplatePropertyType != null && chosenTemplatePropertyType != '') {
      switch(selectedTemplatesPage) {
        case 1:
          fetchSmsTemplateCount(parseInt(chosenTemplatePropertyType)).then(res => {
            fetchSmsTemplateList(ELEM_PER_PAGE, 0, parseInt(chosenTemplatePropertyType)).catch(error => { onErrorResponseShowMessage(error)})
          }).catch(error => { onErrorResponseShowMessage(error)})
        default: 
          fetchSmsTemplateCount(parseInt(chosenTemplatePropertyType)).then(res => {
            setSelectedTemplatesPage(1)
          }).catch(error => { onErrorResponseShowMessage(error)})
      }
    }
  }, [chosenTemplatePropertyType])

  React.useEffect(() => {
    let propertyType: any
    const mapped = smsTemplates.map((e, idx) => {
      propertyType = propertyTypes.find(el => el.value === `${e.advertType}`)
      return {
        columns: [
          `${(selectedTemplatesPage - 1) * ELEM_PER_PAGE + (idx + 1)}.`,
          e.template,
          `${e.template.length}/${
            TEMPLATE_MAX_MSG > 1
            ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
            : TEMPLATE_MAX_MSG
          }`,
          e.template.length > TEMPLATE_MSG_LEN
            ? Math.ceil(e.template.length / TEMPLATE_MULTIPLE_MSG_LEN)
            : e.template.length > TEMPLATE_FIRST_MSG_LEN
              ? '1 lub 2'
              : 1,
          <span 
            className={classes.editTemplateLink}
            onClick={() => {
              setChosenTemplateToEdit(e.uuid)
              setInitialEditTemplateFormData(e)
              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
              setShowEditTemplateDialog(true)
            }}
          >
            {'Edytuj'}
          </span>,
          <div
            style={{
              cursor: 'pointer'
            }}
            onClick={() => {
              setChosenTemplateToRemove(e.uuid)
              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
              setConfirmRemoveTemplateDialog(true)
            }}
          >
            <img width={16} height={22} src={trashIcon}/>
          </div>
        ]
      }
    })
    setTemplatesTableData(mapped)
  }, [smsTemplates])

  React.useEffect(() => {
  }, [chosenTemplateMessage])

  React.useEffect(() => {
    if (chosenAllowedRegion !== '') {
      const selRegion = regions.find(e => e.uuid === chosenAllowedRegion)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenAllowedTowns.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      setChosenAllowedTowns(selectedTowns.map(e => e.uuid))
    } else {
      setTownList([])
    }
  }, [chosenAllowedRegion])

  const setInitialEditTemplateFormData = (template: any) => {
    setChosenTemplateMessage(template.template)
    setEditTemplateFormInitialValues({
      template: template.template,
      advertType: template.advertType
    })
    if (formEditTemplateRef.current) {
      formEditTemplateRef.current.resetForm()
    }
  }

  const setInitialEditConfigFormData = () => {
    setEditConfigActiveSearchParamsLimit(`${config.config.activeSearchParamsLimit}`)

    // setEditConfigPrimaryMarketMinBidValueFlat(`${config.config.primaryMarketMinBidValue.flat.toFixed(2).replace('.', ',')}`)
    // setEditConfigPrimaryMarketMinBidValuePlot(`${config.config.primaryMarketMinBidValue.plot.toFixed(2).replace('.', ',')}`)
    // setEditConfigPrimaryMarketMinBidValueHouse(`${config.config.primaryMarketMinBidValue.house.toFixed(2).replace('.', ',')}`)
    // setEditConfigPrimaryMarketMinBidValueGarage(`${config.config.primaryMarketMinBidValue.garage.toFixed(2).replace('.', ',')}`)
    // setEditConfigPrimaryMarketMinBidValueWarehouse(`${config.config.primaryMarketMinBidValue.warehouse.toFixed(2).replace('.', ',')}`)
    // setEditConfigPrimaryMarketMinBidValueServicePremise(`${config.config.primaryMarketMinBidValue.servicePremise.toFixed(2).replace('.', ',')}`)

    // setEditConfigSecondaryMarketMinBidValueFlat(`${config.config.secondaryMarketMinBidValue.flat.toFixed(2).replace('.', ',')}`)
    // setEditConfigSecondaryMarketMinBidValuePlot(`${config.config.secondaryMarketMinBidValue.plot.toFixed(2).replace('.', ',')}`)
    // setEditConfigSecondaryMarketMinBidValueHouse(`${config.config.secondaryMarketMinBidValue.house.toFixed(2).replace('.', ',')}`)
    // setEditConfigSecondaryMarketMinBidValueGarage(`${config.config.secondaryMarketMinBidValue.garage.toFixed(2).replace('.', ',')}`)
    // setEditConfigSecondaryMarketMinBidValueWarehouse(`${config.config.secondaryMarketMinBidValue.warehouse.toFixed(2).replace('.', ',')}`)
    // setEditConfigSecondaryMarketMinBidValueServicePremise(`${config.config.secondaryMarketMinBidValue.servicePremise.toFixed(2).replace('.', ',')}`)

    setEditConfigSecondaryMarketIntervalFlat(`${config.config.secondaryMarketInterval.flat / DAY_IN_SECONDS}`)
    setEditConfigSecondaryMarketIntervalPlot(`${config.config.secondaryMarketInterval.plot / DAY_IN_SECONDS}`)
    setEditConfigSecondaryMarketIntervalHouse(`${config.config.secondaryMarketInterval.house / DAY_IN_SECONDS}`)
    setEditConfigSecondaryMarketIntervalGarage(`${config.config.secondaryMarketInterval.garage / DAY_IN_SECONDS}`)
    setEditConfigSecondaryMarketIntervalWarehouse(`${config.config.secondaryMarketInterval.warehouse / DAY_IN_SECONDS}`)
    setEditConfigSecondaryMarketIntervalServicePremise(`${config.config.secondaryMarketInterval.servicePremise / DAY_IN_SECONDS}`)

    /*
      config: {
        nextSendDelay: 111111111,
        timetable: {
          weekdays: {
            enabled: true
            from: "00:00"
            to: "11:11"
          },
          saturday: {},
          sunday: {},
          exceptions: [
            {
              date: ""
              enabled: true
              from: "00:00"
              to: "11:11"
            } 
          ]
        }
        ...
      }
    */

    setEditConfigFormInitialValues({
      weekdaysTimetableEnabled: config.config.timetable?.weekdays?.enabled ?? false,
      weekdaysTimetableFrom: config.config.timetable?.weekdays?.from ?? '',
      weekdaysTimetableTo: config.config.timetable?.weekdays?.to ?? '',
      saturdayTimetableEnabled: config.config.timetable?.saturday?.enabled ?? false,
      saturdayTimetableFrom: config.config.timetable?.saturday?.from ?? '',
      saturdayTimetableTo: config.config.timetable?.saturday?.to ?? '',
      sundayTimetableEnabled: config.config.timetable?.sunday?.enabled ?? false,
      sundayTimetableFrom: config.config.timetable?.sunday?.from ?? '',
      sundayTimetableTo: config.config.timetable?.sunday?.to ?? '',
      timetableExceptions: config.config.timetable?.exceptions?.map(e => {
          return {
            date: moment(e.date, "DD.MM.YYYY").toDate(),
            enabled: e.enabled,
            from: e.from,
            to: e.to
          }
        }) ?? [],
      nextSendDelay: config.config.nextSendDelay != null ? `${config.config.nextSendDelay / DAY_IN_SECONDS}` : '',
      activeSearchParamsLimit: `${config.config.activeSearchParamsLimit}`,
      // primaryMarketMinBidValueFlat: `${config.config.primaryMarketMinBidValue.flat.toFixed(2).replace('.', ',')}`,
      // primaryMarketMinBidValuePlot: `${config.config.primaryMarketMinBidValue.plot.toFixed(2).replace('.', ',')}`,
      // primaryMarketMinBidValueHouse: `${config.config.primaryMarketMinBidValue.house.toFixed(2).replace('.', ',')}`,
      // primaryMarketMinBidValueGarage: `${config.config.primaryMarketMinBidValue.garage.toFixed(2).replace('.', ',')}`,
      // primaryMarketMinBidValueWarehouse: `${config.config.primaryMarketMinBidValue.warehouse.toFixed(2).replace('.', ',')}`,
      // primaryMarketMinBidValueServicePremise: `${config.config.primaryMarketMinBidValue.servicePremise.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValueFlat: `${config.config.secondaryMarketMinBidValue.flat.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValuePlot: `${config.config.secondaryMarketMinBidValue.plot.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValueHouse: `${config.config.secondaryMarketMinBidValue.house.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValueGarage: `${config.config.secondaryMarketMinBidValue.garage.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValueWarehouse: `${config.config.secondaryMarketMinBidValue.warehouse.toFixed(2).replace('.', ',')}`,
      // secondaryMarketMinBidValueServicePremise: `${config.config.secondaryMarketMinBidValue.servicePremise.toFixed(2).replace('.', ',')}`,
      secondaryMarketIntervalFlat: `${config.config.secondaryMarketInterval.flat / DAY_IN_SECONDS}`,
      secondaryMarketIntervalPlot: `${config.config.secondaryMarketInterval.plot / DAY_IN_SECONDS}`,
      secondaryMarketIntervalHouse: `${config.config.secondaryMarketInterval.house / DAY_IN_SECONDS}`,
      secondaryMarketIntervalGarage: `${config.config.secondaryMarketInterval.garage / DAY_IN_SECONDS}`,
      secondaryMarketIntervalWarehouse: `${config.config.secondaryMarketInterval.warehouse / DAY_IN_SECONDS}`,
      secondaryMarketIntervalServicePremise: `${config.config.secondaryMarketInterval.servicePremise / DAY_IN_SECONDS}`
    })
    if (formEditConfigRef.current) {
      formEditConfigRef.current.resetForm()
    }
  }

  const resetAddUserFormState = () => {
    setChosenName('')
    setChosenEmail('')
    setChosenRole(UserRoles[0].value)
    // setChosenBalance('')
    setChosenTags([])
    setChosenSubscriptionValue('')
    setChosenAllowedRegion('')
    setSendEmailNotification(false)
    setAllowedSendingSms(true)
    setChosenAllowedTowns([])
    if (formAddUserRef.current) {
      formAddUserRef.current.resetForm()
    }
  }

  const handleAddUserSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await AuthRefreshApi.addNewAccount({
        name: data.name.trim(),
        email: data.email.trim(),
        privateMarketEmailNotifications: data.sendEmailNotifications,
        allowedSendingSms: data.allowedSendingSms,
        role: parseInt(data.role),
        // balance: data.balance && data.balance !== '' ? parseFloat(data.balance) : 0,
        allowedRegions: chosenAllowedRegion !== '' ? [chosenAllowedRegion] : [],
        allowedTowns: chosenAllowedTowns ?? [],
        tags: chosenTags?.map((tag: AsyncCreatableSelectOption) => {
          return {
            label: tag.label,
            uuid: tag.value,
            isNew: tag.__isNew__,
          }
        }) ?? [],
        subscriptionValue: data.subscriptionValue ? parseFloat(data.subscriptionValue) : undefined

      })
      setSelectedUsersTags([])
      await fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE)
      await fetchUsersCount()
      await fetchCreateUserTags('')
      await fetchFilterUserTags('')
      resetAddUserFormState()
      setIsSubmitting(false)
      closeAddUserDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const closeAddUserDialog = () => {
    resetAddUserFormState()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddUserDialog(false)
  }

  const resetAddTemplateFormState = () => {
    setChosenNewTemplateMessage('')
    if (formAddTemplateRef.current) {
      formAddTemplateRef.current.resetForm()
    }
  }

  const handleAddTemplateSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.addSmsTemplate({
        template: data.template,
        advertType: parseInt(chosenTemplatePropertyType)
      })
      await fetchSmsTemplateList(ELEM_PER_PAGE, (selectedTemplatesPage - 1) * ELEM_PER_PAGE, parseInt(chosenTemplatePropertyType))
      await fetchSmsTemplateCount(parseInt(chosenTemplatePropertyType))
      resetAddTemplateFormState()
      setIsSubmitting(false)
      closeAddTemplateDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleEditConfigSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.addNewActiveConfig({
        config: {
          takeFreeBids: false,
          nextSendDelay: data.nextSendDelay !== '' ? parseFloat(data.nextSendDelay.replace(',', '.')) * DAY_IN_SECONDS : 0,
          timetable: {
            tz: 'Europe/Warsaw',
            weekdays: {
              enabled: data.weekdaysTimetableEnabled,
              from: data.weekdaysTimetableEnabled && data.weekdaysTimetableFrom != '' ? data.weekdaysTimetableFrom : undefined,
              to: data.weekdaysTimetableEnabled && data.weekdaysTimetableTo != '' ? data.weekdaysTimetableTo : undefined
            },
            saturday: {
              enabled: data.saturdayTimetableEnabled,
              from: data.saturdayTimetableEnabled && data.saturdayTimetableFrom != '' ? data.saturdayTimetableFrom : undefined,
              to: data.saturdayTimetableEnabled && data.saturdayTimetableTo != '' ? data.saturdayTimetableTo : undefined
            },
            sunday: {
              enabled: data.sundayTimetableEnabled,
              from: data.sundayTimetableEnabled && data.sundayTimetableFrom != '' ? data.sundayTimetableFrom : undefined,
              to: data.sundayTimetableEnabled && data.sundayTimetableTo != '' ? data.sundayTimetableTo : undefined
            },
            exceptions: data.timetableExceptions?.map((e: any) => {
              return {
                date: moment(e.date).format('DD.MM.YYYY'),
                enabled: e.enabled,
                from: e.enabled && e.from != '' ? e.from : undefined,
                to: e.enabled && e.to != '' ? e.to : undefined
              }
            }) ?? []
          },
          activeSearchParamsLimit: data.activeSearchParamsLimit !== '' ? parseInt(data.activeSearchParamsLimit.replace(',', '.')) : 0,
          // primaryMarketMinBidValue: {
          //   flat: 1,
          //   plot: 1,
          //   house: 1,
          //   garage: 1,
          //   warehouse: 1,
          //   servicePremise: 1
          // },
          // secondaryMarketMinBidValue: {
          //   flat: 1,
          //   plot: 1,
          //   house: 1,
          //   garage: 1,
          //   warehouse: 1,
          //   servicePremise: 1
          // },
          secondaryMarketInterval: {
            flat: data.secondaryMarketIntervalFlat !== '' ? parseFloat(data.secondaryMarketIntervalFlat.replace(',', '.')) * DAY_IN_SECONDS : 0,
            plot: data.secondaryMarketIntervalPlot !== '' ? parseFloat(data.secondaryMarketIntervalPlot.replace(',', '.')) * DAY_IN_SECONDS : 0,
            house: data.secondaryMarketIntervalHouse !== '' ? parseFloat(data.secondaryMarketIntervalHouse.replace(',', '.')) * DAY_IN_SECONDS : 0,
            garage: data.secondaryMarketIntervalGarage !== '' ? parseFloat(data.secondaryMarketIntervalGarage.replace(',', '.')) * DAY_IN_SECONDS : 0,
            warehouse: data.secondaryMarketIntervalWarehouse !== '' ? parseFloat(data.secondaryMarketIntervalWarehouse.replace(',', '.')) * DAY_IN_SECONDS : 0,
            servicePremise: data.secondaryMarketIntervalServicePremise !== '' ? parseFloat(data.secondaryMarketIntervalServicePremise.replace(',', '.')) * DAY_IN_SECONDS : 0
          }
        }
      })
      await fetchConfig()
      dispatch(addSuccessMessage(MessageEnum.CONFIG_HAS_BEEN_UPDATED))
      setIsSubmitting(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const closeAddTemplateDialog = () => {
    resetAddTemplateFormState()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddTemplateDialog(false)
  }

  const resetEditTemplateFormState = () => {
    setChosenTemplateMessage('')
    setChosenTemplateToEdit('')
    if (formEditTemplateRef.current) {
      formEditTemplateRef.current.resetForm()
    }
  }

  const handleEditTemplateSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.editSmsTemplate(chosenTemplateToEdit, {
        template: data.template,
        // advertType: parseInt(chosenTemplatePropertyType)
      })
      await fetchSmsTemplateList(ELEM_PER_PAGE, (selectedTemplatesPage - 1) * ELEM_PER_PAGE, parseInt(chosenTemplatePropertyType))
      await fetchSmsTemplateCount(parseInt(chosenTemplatePropertyType))
      resetEditTemplateFormState()
      setIsSubmitting(false)
      closeEditTemplateDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const closeEditTemplateDialog = () => {
    resetEditTemplateFormState()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowEditTemplateDialog(false)
  }

  const closeConfirmRemoveTemplateDialog = () => {
    setChosenTemplateToRemove('')
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setConfirmRemoveTemplateDialog(false)
  }

  // TODO strings
  const addUserDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tForms('add_new_user_section_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeAddUserDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={formAddUserRef}
          validationSchema={addUserValidation(tSuperuserSettings)}
          enableReinitialize
          initialValues={{
            name: "",
            email: '',
            role: UserRoles[0].value,
            balance: '',
            allowedRegion: '',
            allowedTowns: [],
            sendEmailNotifications: false,
            allowedSendingSms: true,
            subscriptionValue: '',
            tags: []
          }}
          onSubmit={handleAddUserSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
            >
              <div className={classes.rowContainer} style={{
                marginBottom: '15px'
              }}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_form_name_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={120}
                    name="name" 
                    placeholder={tForms('add_user_form_name_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenName}
                    setValue={setChosenName}
                    error={
                      formikProps.touched.name &&
                      Boolean(formikProps.errors.name)
                    }
                  />
                </div>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_form_email_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={120}
                    name="email" 
                    placeholder={tForms('add_user_form_email_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenEmail}
                    setValue={setChosenEmail}
                    error={
                      formikProps.touched.email &&
                      Boolean(formikProps.errors.email)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {'Województwo'}
                  </p>
                  <div 
                    className={`${classes.clearButton}`}
                    style={{
                      paddingRight: '10px',
                      ...(chosenAllowedRegion === '' ? { display: 'none' } : {})
                    }}
                    onClick={() => {
                      setChosenAllowedRegion('')
                      setChosenAllowedTowns([])
                      formikProps.setFieldValue('allowedRegion', '')
                      formikProps.setFieldValue('allowedTowns', [])
                    }}
                  >
                    {tForms('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'region-field'}
                    name="allowedRegion"
                    placeholder={'Województwo'}
                    options={regions.map(e => {
                      return {
                        value: e.uuid,
                        label: e.name
                      }
                    }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenAllowedRegion}
                    setValue={setChosenAllowedRegion}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.allowedRegion &&
                      Boolean(formikProps.errors.allowedRegion)
                    }
                  />
                </div>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%',
                    // paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {'Miejscowości'}
                  </p>
                  <div 
                    className={`${classes.clearButton}`}
                    style={{
                      paddingRight: '10px',
                      ...(chosenAllowedRegion === '' || chosenAllowedTowns.length === 0 ? { display: 'none' } : {})
                    }}
                    onClick={() => {
                      setChosenAllowedTowns([])
                      formikProps.setFieldValue('allowedTowns', [])
                    }}
                  >
                    {tForms('clear_field_button_label')}
                  </div>
                  <CustomSelectField
                    instanceId={'town-field'}
                    disabled={chosenAllowedRegion === ''}
                    name="allowedTowns"
                    isMulti
                    placeholder={'Miasto/Miejscowość'}
                    options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                    value={chosenAllowedTowns}
                    setValue={setChosenAllowedTowns}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.allowedTowns &&
                      Boolean(formikProps.errors.allowedTowns)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '30%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_form_role_field_label')}
                  </p>
                  <CustomSelectField
                    instanceId={'role-field'}
                    name="role"
                    options={UserRoles}
                    value={chosenRole}
                    setValue={setChosenRole}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.role &&
                      Boolean(formikProps.errors.role)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '29%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('subscription_value_field_label')}
                  </p>
                  <BaseNumberInput
                    monetaryValue
                    name="subscriptionValue" 
                    placeholder={tForms('subscription_value_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenSubscriptionValue}
                    setValue={setChosenSubscriptionValue}
                    error={
                      formikProps.touched.subscriptionValue &&
                      Boolean(formikProps.errors.subscriptionValue)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '30%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_tag_field_label')}
                  </p>
                  <div 
                    className={`${classes.clearButton}`}
                    style={{
                      paddingRight: '10px',
                      ...(chosenTags.length === 0 ? { display: 'none' } : {})
                    }}
                    onClick={() => {
                      setChosenTags([])
                    }}
                  >
                    {tForms('clear_field_button_label')}
                  </div>
                  <CustomAsyncCreatableSelectField
                    instanceId={'tags-field'}
                    name="tags"
                    loadOptions={async (inputValue: string) => {
                      const tags = await DataApi.searchUserTags(inputValue)
                      return tags.data.map(e => {
                        return {
                          label: e.name,
                          value: e.uuid
                        }
                      })
                    }}
                    placeholder={tForms('add_user_tag_field_placeholder')}
                    isMulti
                    options={[
                      ...chosenTags,
                      ...createUserTagList.filter(e => !chosenTags.find(n => n.value === e.value)),
                    ]}
                    value={chosenTags}
                    setValue={setChosenTags}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.tags &&
                      Boolean(formikProps.errors.tags)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '100%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('send_mail_notifications_switch_label')}
                  </p>
                  <div
                    style={{
                      marginTop: '15px',
                      marginBottom: '10px',
                      display: 'flex',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <div>
                      <CustomSwitch 
                        name={'sendEmailNotifications'}
                        checked={sendEmailNotification}
                        setChecked={setSendEmailNotification}
                        formikProps={formikProps}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        fontFamily: 'AvenirBook',
                        fontSize: '15px',
                        color: '#6D7278',
                        marginTop: '-1px'
                      }}
                    >
                      {
                        sendEmailNotification
                        ? 'wysyłka włączona'
                        : 'wysyłka wyłączona'
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '100%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('allowed_sending_sms_switch_label')}
                  </p>
                  <div
                    style={{
                      marginTop: '15px',
                      marginBottom: '10px',
                      display: 'flex',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <div>
                      <CustomSwitch 
                        name={'allowedSendingSms'}
                        checked={allowedSendingSms}
                        setChecked={setAllowedSendingSms}
                        formikProps={formikProps}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '10px',
                        fontFamily: 'AvenirBook',
                        fontSize: '15px',
                        color: '#6D7278',
                        marginTop: '-1px'
                      }}
                    >
                      {
                        allowedSendingSms
                        ? 'wysyłka włączona'
                        : 'wysyłka wyłączona'
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={tForms('save_button_label')}
                  type='submit'
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  const addAddTemplateDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
          marginBottom: '20px'
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tForms('add_new_template_dialog_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeAddTemplateDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={formAddTemplateRef}
          validationSchema={addTemplateValidation(tForms)}
          enableReinitialize
          initialValues={{
            template: ''
          }}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleAddTemplateSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div 
                  style={{
                    width: '80%',
                    position: 'relative'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_new_template_form_template_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={
                      TEMPLATE_MAX_MSG > 1
                      ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                      : TEMPLATE_MAX_MSG
                    }
                    rows={4}
                    name="template"
                    placeholder={tForms('add_new_template_form_template_field_placeholder')}
                    formikProps={formikProps}
                    inputPattern={smsMessagePattern}
                    value={chosenNewTemplateMessage}
                    setValue={setChosenNewTemplateMessage}
                    error={
                      formikProps.touched.template &&
                      Boolean(formikProps.errors.template)
                    }
                  />
                </div>
              </div>
              <div 
                style={{
                  marginLeft: '10%',
                  marginRight: '10%',
                  fontFamily: 'AvenirBook',
                  fontSize: '16px'
                }}
              >
                {`Liczba wiadomości:\u00a0`}{
                  chosenNewTemplateMessage.length > TEMPLATE_MSG_LEN
                    ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{Math.ceil(chosenNewTemplateMessage.length / TEMPLATE_MULTIPLE_MSG_LEN)}</span>
                    : chosenNewTemplateMessage.length > TEMPLATE_FIRST_MSG_LEN
                      ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{'1 lub 2 (w zależności od długości podpisu)'}</span>
                      : 1
                }
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={tForms('save_button_label')}
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  const addEditTemplateDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
          marginBottom: '20px'
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tForms('edit_template_dialog_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeEditTemplateDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={formEditTemplateRef}
          enableReinitialize
          validationSchema={editTemplateValidation(tForms)}
          initialValues={editTemplateFormInitialValues}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleEditTemplateSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div 
                  style={{
                    width: '80%',
                    position: 'relative'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_new_template_form_template_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={
                      TEMPLATE_MAX_MSG > 1
                      ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                      : TEMPLATE_MAX_MSG
                    }
                    rows={4}
                    name="template" 
                    placeholder={tForms('add_new_template_form_template_field_placeholder')}
                    formikProps={formikProps}
                    inputPattern={smsMessagePattern}
                    value={chosenTemplateMessage}
                    setValue={setChosenTemplateMessage}
                    error={
                      formikProps.touched.template &&
                      Boolean(formikProps.errors.template)
                    }
                  />
                </div>
              </div>
              <div 
                style={{
                  marginLeft: '10%',
                  marginRight: '10%',
                  fontFamily: 'AvenirBook',
                  fontSize: '16px'
                }}
              >
                {`Liczba wiadomości:\u00a0`}{
                  chosenTemplateMessage.length > TEMPLATE_MSG_LEN
                    ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{Math.ceil(chosenTemplateMessage.length / TEMPLATE_MULTIPLE_MSG_LEN)}</span>
                    : chosenTemplateMessage.length > TEMPLATE_FIRST_MSG_LEN
                      ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{'1 lub 2 (w zależności od długości podpisu)'}</span>
                      : 1
                }
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={tForms('save_button_label')}
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  const confirmRemoveTemplateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tForms('confirm_template_removal_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmRemoveTemplateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.removeSmsTemplate(chosenTemplateToRemove)
                await fetchSmsTemplateCount(parseInt(chosenTemplatePropertyType))
                await fetchSmsTemplateList(ELEM_PER_PAGE, (selectedTemplatesPage - 1) * ELEM_PER_PAGE, parseInt(chosenTemplatePropertyType))
                closeConfirmRemoveTemplateDialog()
                setIsSubmitting(false)
              } catch (error) {
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const closeBulkActionDetailsDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowBulkActionsDetailsDialog(false)
    setChosenBulkAction(null)
  }

  const bulkActionDetailsContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tBulkActions('sent_package_dialog_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeBulkActionDetailsDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <div>
          <BulkActionDetails
            details={chosenBulkAction}
            editableDate
            reset={showBulkActionsDetailsDialog}
            onEditSentDateChange={(enabled) => setIsEditSentDateActive(enabled)}
            onEditSendDateCancel={() => {
              setNewSentDate(null)
            }}
          />
          {
            isEditSentDateActive
            ? <div className={classes.newSentDateFieldContainer}>
                <p className={classes.fieldLabel}>
                  {tForms('set_new_sent_date_label')}
                </p>
                <div 
                  className={`${classes.clearButton}`}
                  style={{
                    paddingRight: '10px',
                    ...(newSentDate ? {} : { display: 'none' })
                  }}
                  onClick={() => {
                    setNewSentDate(null)
                    newSentDateRef?.current?.flatpickr.clear()
                  }}
                >
                  {tForms('clear_field_button_label')}
                </div>
                <Flatpickr
                  id={"date-range-timetable-picker"}
                  ref={newSentDateRef}
                  value={
                    newSentDate
                    ? [newSentDate]
                    : null
                  }
                  options={{
                    locale: {
                      firstDayOfWeek: 1,
                      rangeSeparator: ' - ',
                      months: {
                        shorthand: monthList,
                        longhand: monthList
                      },
                      weekdays: {
                        shorthand: dayListShorthand,
                        longhand: dayList
                      }
                    },
                    dateFormat: 'd.m.Y H:i',
                    mode: 'single',
                    minDate: moment().add(1, 'hour').startOf('hour').toDate(),
                    time_24hr: true,
                    enableTime: true
                  }}
                  onClose={(date) => {
                    setNewSentDate(date[0])
                  }}
                  render={({ defaultValue, value, ...props }, ref) => {
                    return (
                      <CustomFlatpickrInput
                        defaultValue={defaultValue}
                        placeholder={tForms('set_new_sent_date_placeholder')}
                        inputRef={ref}
                        width={'100%'}
                      />
                    )
                  }}
                />
              </div>
            : null
          }
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <div 
              style={{
                marginRight: '30px'
              }}
            >
              <LoadingButton 
                text={tForms('reject_button_label')}
                type='button'
                loading={isSubmitting}
                onClick={() => {
                  dispatch(addCmdMessage(MessageEnum.ENABLE_DIALOG_COVER))
                  setShowRejectBulkActionsDetailsDialog(true)
                }}
                style={{
                  width: '250px',
                  maxWidth: '250px',
                  height: '40px',
                  backgroundColor: '#f64a3f'
                }}
              />
            </div>
            <div 
              style={{
                marginBottom: '30px'
              }}
            >
              <LoadingButton 
                text={tForms('accept_button_label')}
                disabled={chosenBulkAction == null || (moment().isAfter(moment(chosenBulkAction.sentAt)) && (newSentDate == null || moment().isAfter(moment(newSentDate))))}
                type='button'
                loading={isSubmitting}
                onClick={() => {
                  dispatch(addCmdMessage(MessageEnum.ENABLE_DIALOG_COVER))
                  setShowAcceptBulkActionsDetailsDialog(true)
                }}
                style={{
                  width: '250px',
                  maxWidth: '250px',
                  height: '40px',
                  backgroundColor: '#438737'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const closeConfirmRejectBulkActionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
    setShowRejectBulkActionsDetailsDialog(false)
  }

  const confirmRejectBulkActionDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tForms('confirm_bulk_action_reject_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmRejectBulkActionDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.rejectBulkAction(chosenBulkAction.uuid)
                if (bulkActionsSortData && bulkActionsSortData.default) {
                  await fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE)
                } else {
                  await fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE, bulkActionsSortData?.column, bulkActionsSortData?.order)
                }
                closeConfirmRejectBulkActionDialog()
                setIsSubmitting(false)
                closeBulkActionDetailsDialog()
                setChosenBulkAction(null)
              } catch (error) {
                closeConfirmRejectBulkActionDialog()
                setIsSubmitting(false)
                onErrorResponseShowMessage(error, true, {bulkActionReject: true})
              } 

              
            }}
          />
        </div>
      </div>
    )
  }

  const closeConfirmAcceptBulkActionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
    setShowAcceptBulkActionsDetailsDialog(false)
  }

  const confirmAcceptBulkActionDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tForms('confirm_bulk_action_accept_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmAcceptBulkActionDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.acceptBulkAction(chosenBulkAction.uuid, isEditSentDateActive && newSentDate != null ? newSentDate : undefined)
                if (bulkActionsSortData && bulkActionsSortData.default) {
                  await fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE)
                } else {
                  await fetchBulkActionsWaitingForApprovalList(ELEM_PER_PAGE, (selectedBulkActionsPage - 1) * ELEM_PER_PAGE, bulkActionsSortData?.column, bulkActionsSortData?.order)
                }
                closeConfirmAcceptBulkActionDialog()
                setIsSubmitting(false)
                closeBulkActionDetailsDialog()
                setChosenBulkAction(null)
              } catch (error) {
                closeConfirmAcceptBulkActionDialog()
                setIsSubmitting(false)
                onErrorResponseShowMessage(error, true, {bulkActionAccept: true})
              }
            }}
          />
        </div>
      </div>
    )
  }

  const closeOfferCalculatorResultDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowOfferCalculatorResultDialog(false)
    setOfferCalculatorFormData(null)
  }

  const offerCalculatorResultDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tBulkActions('offer_calculator_result_dialog_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeOfferCalculatorResultDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <div>
          <BulkActionDetails
            details={offerCalculatorFormData}
          />
          {
            estimatedAdvertCount != null
            ? <div className={classes.estimatedAdvertCountContainer}>
                <div className={classes.estimatedAdvertCountBlock}>
                  <div className={classes.estimatedAdvertCountTitle}>
                    {tForms('estimated_advert_count_title')}
                  </div>
                  <div className={classes.estimatedAdvertCountCount}>
                    {evalStringTemplate(tForms('estimated_advert_count'), { count: estimatedAdvertCount })}
                  </div>
                </div>
              </div>
            : null
          }
          <div style={{
            float: 'right',
            marginBottom: '30px'
          }}>
            <LoadingButton 
              text={tSuperuserSettings('offer_calculator_result_button_label')}
              type={'button'}
              onClick={closeOfferCalculatorResultDialog}
              style={{
                backgroundColor: '#438737',
                width: '200px',
                maxWidth: '200px',
                height: '40px'
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomDialog hidden={!showBulkActionsDetailsDialog}>
        {bulkActionDetailsContent()}
      </CustomDialog>

      <CustomDialog hidden={!showOfferCalculatorResultDialog}>
        {offerCalculatorResultDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showRejectBulkActionsDetailsDialog}
        zIndex={3001}
      >
        {confirmRejectBulkActionDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        zIndex={3001}
        hidden={!showAcceptBulkActionsDetailsDialog}
      >
        {confirmAcceptBulkActionDialogContent()}
      </CustomDialog>

      <CustomDialog 
        hidden={!showAddUserDialog} 
        style={{
          position: 'absolute',
          background: 'white',
          width: '80%',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: '10%'
        }}
      >
        {addUserDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          width: '50%',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showAddTemplateDialog}
      >
        {addAddTemplateDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showConfirmRemoveTemplateDialog}
      >
        {confirmRemoveTemplateDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          width: '50%',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showEditTemplateDialog}
      >
        {addEditTemplateDialogContent()}
      </CustomDialog>
      
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        padding: '15px 20px',
        display: isLoading ? 'none' : 'block'
      }}>
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '19px',
            marginTop: '20px',
            marginBottom: '20px',
            marginRight: '15px',
          }}>
            {tForms('system_config_label')}
          </div>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirHeavy',
            letterSpacing: '1.49px',
            lineHeight: '19px',
            marginTop: '16px',
            marginBottom: '16px',
            color: 'white',
            backgroundColor: '#2E384D',
            padding: '4px 6px'
          }}>
            Administrator
          </div>
        </div>
        <div>
          <Tabs 
            selectedIndex={tabIndex} 
            onSelect={(index: number) => {
              setTabIndex(index)
              history.replace(`/admin?tab=${tabs[index].id}`)
            }}
          >
            <TabList className={classes.tabList}>
              {
                tabs.map(tab => (
                  <Tab className={classes.tab} selectedClassName={classes.tabSelected}>
                    {tab.label}
                  </Tab>
                ))
              }
            </TabList>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px'
                }}
              >
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-1px'
                    }}>
                      <AddIcon />
                      <span style={{
                        marginTop: '1px'
                      }}>
                        {tForms('add_new_user_button_label')}
                      </span>
                    </div>
                  }
                  style={{
                    maxWidth: '170px',
                    height: '35px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    marginBottom: '20px'
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowAddUserDialog(true)
                  }}
                />
                <div className={classes.rowContainer} style={{width: '100%'}}>
                  <div 
                    className={classes.userListControlFieldWithLabel}
                    style={{
                      width: '300px'
                    }}
                  >
                    <p className={classes.fieldLabel}>
                      {tForms('date_range')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setSelectedUsersDataRange(null)
                    }}>
                      {tForms('clear_field_button_label')}
                    </div>
                    <Flatpickr
                      id={'user-list-date-range-picker'}
                      data-enable-time
                      value={selectedUsersDataRange}
                      options={{
                        locale: {
                          firstDayOfWeek: 1,
                          rangeSeparator: ' - ',
                          months: {
                            shorthand: monthList,
                            longhand: monthList
                          },
                          weekdays: {
                            shorthand: dayListShorthand,
                            longhand: dayList
                          }
                        },
                        dateFormat: 'd.m.Y',
                        mode: 'range',
                      }}
                      onClose={(date) => {
                        if (date && date.length === 2) {
                          setSelectedUsersDataRange(date)
                        }
                      }}
                      render={({ defaultValue, value, ...props }, ref) => {
                        return (
                          <CustomFlatpickrInput
                            defaultValue={defaultValue}
                            placeholder={'Wybierz przedział dat'}
                            inputRef={ref}
                            width={'100%'}
                          />
                        )
                      }}
                    />
                  </div>
                  <div 
                    className={classes.userListControlFieldWithLabel}
                    style={{
                      width: '300px'
                    }}
                  >
                    <p className={classes.fieldLabel}>
                      {tForms('user_tags_filter_field_label')}
                    </p>
                    <div 
                      className={`${classes.clearButton}`}
                      style={{
                        ...(selectedUsersTags.length === 0 ? { display: 'none' } : {})
                      }}
                      onClick={() => {
                        setSelectedUsersTags([])
                      }}
                    >
                      {tForms('clear_field_button_label')}
                    </div>
                    <CustomAsyncSelectField
                      instanceId={'user-filter-tags-field'}
                      name="users-filter-tags"
                      loadOptions={async (inputValue: string) => {
                        const tags = await DataApi.searchUserTags(inputValue)
                        return tags.data.map(e => {
                          return {
                            label: e.name,
                            value: e.uuid
                          }
                        })
                      }}
                      placeholder={tForms('user_tags_filter_field_placeholder')}
                      isMulti
                      options={[
                        ...selectedUsersTags,
                        ...filterUserTagList.filter(e => !selectedUsersTags.find(n => n.value === e.value)),
                      ]}
                      value={selectedUsersTags}
                      setValue={setSelectedUsersTags}
                    />
                  </div>
                </div>
                
                <CustomTable 
                  columnData={usersColumnData}
                  content={usersTableData}
                  sort={true}
                  headerFontSize={'12px'}
                  contentFontSize={'12px'}
                  sortData={usersSortData}
                  sortSetter={setUsersSortData}
                  reloadingData={isReloadingUserData}
                  placeholder={
                    <div 
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        fontFamily: 'AvenirMedium',
                        color: Colors.TABLE_HEADER_LABEL,
                        fontSize: '16px'
                      }}
                    >
                      <div>
                        {tForms('no_data_in_table_placeholder')}
                      </div>
                    </div>
                  }
                />
                <div style={{
                  display: usersPages <= 1 ? 'none' : 'flex',
                  justifyContent: 'center'
                }}>
                  <div>
                    <Pagination 
                      pages={usersPages}
                      selectedPage={selectedUsersPage}
                      setSelectedPage={setSelectedUsersPage}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px'
                }}
              >
                <CustomTable 
                  columnData={bulkActionsTableColumnData}
                  content={bulkActionsTableData}
                  sort={true}
                  headerFontSize={'12px'}
                  contentFontSize={'12px'}
                  sortData={bulkActionsSortData}
                  sortSetter={setBulkActionsSortData}
                  reloadingData={isReloadingBulkActionData}
                  placeholder={
                    <div 
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        fontFamily: 'AvenirMedium',
                        color: Colors.TABLE_HEADER_LABEL,
                        fontSize: '16px'
                      }}
                    >
                      <div>
                        {tForms('no_data_in_table_placeholder')}
                      </div>
                    </div>
                  }
                />
                <div style={{
                  display: bulkActionsPages <= 1 ? 'none' : 'flex',
                  justifyContent: 'center'
                }}>
                  <div>
                    <Pagination 
                      pages={bulkActionsPages}
                      selectedPage={selectedBulkActionsPage}
                      setSelectedPage={setSelectedBulkActionsPage}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className={classes.calculateAdvertsContainer}>
                <CalculateAdvertsForm 
                  // reset={showOfferCalculatorResultDialog}
                  allLocations
                  resetButton
                  beforeSubmit={() => {
                    setEstimatedAdvertCount(null)
                  }}
                  onFinish={(params, count) => {
                    setOfferCalculatorFormData({ params: params })
                    setEstimatedAdvertCount(count)
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowOfferCalculatorResultDialog(true)
                  }}
                />
              </div>              
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px'
                }}
              >
                
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '30px'
                }}>
                  <div className={classes.fieldLabel}>
                    {tForms('property_type_field_label')}
                  </div>
                  <div 
                    style={{
                      width: '300px',
                      marginLeft: '10px',
                      position: 'relative'
                    }}
                  >
                      <CustomSelectField
                        instanceId={'advert-type-field'}
                        name="advertType"
                        placeholder={'Wybierz typ nieruchomości'}
                        options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                        value={chosenTemplatePropertyType}
                        setValue={setChosenTemplatePropertyType}
                        noErrorLabel
                      />
                  </div>
                </div>
                {
                  chosenTemplatePropertyType && chosenTemplatePropertyType != ''
                  ? <>
                      <CustomButton 
                        textComponent={
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '-1px'
                          }}>
                            <AddIcon />
                            <span style={{
                              marginTop: '1px'
                            }}>{tForms('add_new_template_button_label')}</span>
                          </div>
                        }
                        style={{
                          maxWidth: '170px',
                          height: '35px',
                          paddingLeft: '10px',
                          paddingRight: '15px',
                          paddingTop: '7px',
                          marginBottom: '20px'
                        }}
                        onClick={() => {
                          dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                          setShowAddTemplateDialog(true)
                        }}
                      />
                      <CustomTable 
                        columnData={templatesColumnData}
                        content={templatesTableData}
                        reloadingData={isReloadingTemplateData}
                        placeholder={
                          <div 
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              fontFamily: 'AvenirMedium',
                              color: Colors.TABLE_HEADER_LABEL,
                              fontSize: '16px'
                            }}
                          >
                            <div>
                              {tForms('no_data_in_table_placeholder')}
                            </div>
                          </div>
                        }
                      />
                      <div style={{
                        display: templatesPages <= 1 ? 'none' : 'flex',
                        justifyContent: 'center'
                      }}>
                        <div>
                          <Pagination 
                            pages={templatesPages}
                            selectedPage={selectedTemplatesPage}
                            setSelectedPage={setSelectedTemplatesPage}
                          />
                        </div>
                      </div>
                    </>
                  : null
                }
              </div>
            </TabPanel>

            <TabPanel>
              <div
                style={{
                  marginTop: '20px',
                  maxWidth: '80%'
                }}
              >
                <Formik
                  innerRef={formEditConfigRef}
                  validationSchema={editConfigValidation(tSuperuserSettings)}
                  enableReinitialize
                  initialValues={editConfigFormInitialValues}
                  validateOnBlur={true}
                  onSubmit={handleEditConfigSubmit}
                >
                  {(formikProps) => (
                    <Form
                      onKeyDown={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault()
                        }
                      }}
                    >
                      <div className={classes.editConfigForm}>
                        {/* <div style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '20px'
                        }}>
                          <div style={{
                            width: '50%'
                          }}>
                            <div className={classes.sectionLabel}>
                              {tForms('edit_config_sms_price_primary_section')}
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_houses_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValueHouse" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValueHouse}
                                    setValue={setEditConfigPrimaryMarketMinBidValueHouse}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValueHouse &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValueHouse)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_flats_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValueFlat" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValueFlat}
                                    setValue={setEditConfigPrimaryMarketMinBidValueFlat}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValueFlat &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValueFlat)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>
                            
                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_garages_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValueGarage" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValueGarage}
                                    setValue={setEditConfigPrimaryMarketMinBidValueGarage}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValueGarage &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValueGarage)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_plots_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValuePlot" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValuePlot}
                                    setValue={setEditConfigPrimaryMarketMinBidValuePlot}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValuePlot &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValuePlot)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_service_premises_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValueServicePremise" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValueServicePremise}
                                    setValue={setEditConfigPrimaryMarketMinBidValueServicePremise}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValueServicePremise &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValueServicePremise)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_warehouses_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="primaryMarketMinBidValueWarehouse" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigPrimaryMarketMinBidValueWarehouse}
                                    setValue={setEditConfigPrimaryMarketMinBidValueWarehouse}
                                    error={
                                      formikProps.touched.primaryMarketMinBidValueWarehouse &&
                                      Boolean(formikProps.errors.primaryMarketMinBidValueWarehouse)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{
                            width: '50%'
                          }}>
                            <div className={classes.sectionLabel}>
                              {tForms('edit_config_sms_price_secondary_section')}
                            </div>
                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_houses_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValueHouse" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValueHouse}
                                    setValue={setEditConfigSecondaryMarketMinBidValueHouse}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValueHouse &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValueHouse)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>
                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_flats_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValueFlat" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValueFlat}
                                    setValue={setEditConfigSecondaryMarketMinBidValueFlat}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValueFlat &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValueFlat)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>
                            
                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_garages_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValueGarage" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValueGarage}
                                    setValue={setEditConfigSecondaryMarketMinBidValueGarage}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValueGarage &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValueGarage)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_plots_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValuePlot" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValuePlot}
                                    setValue={setEditConfigSecondaryMarketMinBidValuePlot}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValuePlot &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValuePlot)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_service_premises_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValueServicePremise" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValueServicePremise}
                                    setValue={setEditConfigSecondaryMarketMinBidValueServicePremise}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValueServicePremise &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValueServicePremise)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>

                            <div className={classes.editConfigFormPrices}>
                              <div className={classes.editConfigFormPricesRow}>
                                <div className={classes.editConfigFormPricesRowLabel}>
                                  {tForms('edit_config_warehouses_label')}
                                </div>
                                <div style={{
                                  width: '50%'
                                }}>
                                  <BaseNumberInput
                                    name="secondaryMarketMinBidValueWarehouse" 
                                    placeholder={tForms('sms_price_config_placeholder')}
                                    monetaryValue
                                    formikProps={formikProps}
                                    value={editConfigSecondaryMarketMinBidValueWarehouse}
                                    setValue={setEditConfigSecondaryMarketMinBidValueWarehouse}
                                    error={
                                      formikProps.touched.secondaryMarketMinBidValueWarehouse &&
                                      Boolean(formikProps.errors.secondaryMarketMinBidValueWarehouse)
                                    }
                                  />
                                </div>
                                <div className={classes.editConfigFormPricesRowUnit}>
                                  {tForms('sms_price_config_unit_label')}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className={classes.sectionLabel}>
                          {tForms('edit_config_params_limit_section')}
                        </div>

                        <div className={classes.editConfigParamsSection} style={{
                          marginBottom: '20px'
                        }}>
                          <BaseNumberInput 
                            integers
                            name={'activeSearchParamsLimit'}
                            placeholder={'15'}
                            value={editConfigActiveSearchParamsLimit}
                            setValue={setEditConfigActiveSearchParamsLimit}
                            formikProps={formikProps}
                            style={{
                              width: '50%',
                            }}
                            error={
                              formikProps.touched.activeSearchParamsLimit &&
                              Boolean(formikProps.errors.activeSearchParamsLimit)
                            }
                          />
                        </div>

                        <div style={{
                          width: '50%'
                        }}>
                          <div className={classes.sectionLabel}>
                            {tForms('edit_config_sms_interval_secondary_section')}
                          </div>

                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_houses_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalHouse" 
                                  placeholder={tForms('sms_price_config_placeholder')}
                                  integers
                                  formikProps={formikProps}
                                  value={editConfigSecondaryMarketIntervalHouse}
                                  setValue={setEditConfigSecondaryMarketIntervalHouse}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalHouse &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalHouse)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>

                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_flats_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalFlat" 
                                  placeholder={tForms('interval_config_placeholder')}
                                  formikProps={formikProps}
                                  integers
                                  value={editConfigSecondaryMarketIntervalFlat}
                                  setValue={setEditConfigSecondaryMarketIntervalFlat}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalFlat &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalFlat)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>
                          
                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_garages_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalGarage" 
                                  placeholder={tForms('interval_config_placeholder')}
                                  formikProps={formikProps}
                                  integers
                                  value={editConfigSecondaryMarketIntervalGarage}
                                  setValue={setEditConfigSecondaryMarketIntervalGarage}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalGarage &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalGarage)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>

                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_plots_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalPlot" 
                                  placeholder={tForms('interval_config_placeholder')}
                                  formikProps={formikProps}
                                  integers
                                  value={editConfigSecondaryMarketIntervalPlot}
                                  setValue={setEditConfigSecondaryMarketIntervalPlot}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalPlot &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalPlot)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>

                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_service_premises_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalServicePremise" 
                                  placeholder={tForms('interval_config_placeholder')}
                                  formikProps={formikProps}
                                  integers
                                  value={editConfigSecondaryMarketIntervalServicePremise}
                                  setValue={setEditConfigSecondaryMarketIntervalServicePremise}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalServicePremise &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalServicePremise)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>

                          <div className={classes.editConfigFormPrices}>
                            <div className={classes.editConfigFormPricesRow}>
                              <div className={classes.editConfigFormPricesRowLabel}>
                                {tForms('edit_config_warehouses_label')}
                              </div>
                              <div style={{
                                width: '50%'
                              }}>
                                <BaseNumberInput
                                  name="secondaryMarketIntervalWarehouse" 
                                  placeholder={tForms('interval_config_placeholder')}
                                  integers
                                  formikProps={formikProps}
                                  value={editConfigSecondaryMarketIntervalWarehouse}
                                  setValue={setEditConfigSecondaryMarketIntervalWarehouse}
                                  error={
                                    formikProps.touched.secondaryMarketIntervalWarehouse &&
                                    Boolean(formikProps.errors.secondaryMarketIntervalWarehouse)
                                  }
                                />
                              </div>
                              <div className={classes.editConfigFormPricesRowUnit}>
                                {tForms('interval_config_unit_label')}
                              </div>
                            </div>
                          </div>
                        </div>
                      
                        <div className={classes.sectionLabel} style={{
                          marginTop: '15px',
                          marginBottom: '15px'
                        }}>
                          {tForms('edit_config_timetable_section')}
                        </div>
                        <div className={classes.editTimetableFormRow}>
                          <div
                            style={{
                              width: '25%',
                              paddingLeft: '7.5%',
                              textAlign: 'left',
                              marginBottom: '25px',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            {tForms('config_timetable_weekdays_label')}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              marginBottom: '20px',
                              display: 'flex',
                              alignItems: 'center',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: '25%'
                              }}
                            >
                              <CustomSwitch 
                                name={'weekdaysTimetableEnabled'}
                                checked={formikProps?.values.weekdaysTimetableEnabled}
                                formikProps={formikProps}
                              />
                            </div>
                            {
                              formikProps?.values.weekdaysTimetableEnabled
                              ? <div
                                  style={{
                                    paddingBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_enabled_label')}
                                </div>
                              : <div
                                  style={{
                                    marginBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_disabled_label')}
                                </div>
                            }
                          </div>
                          {
                            formikProps?.values.weekdaysTimetableEnabled
                            ? <>
                                <div className={classes.editConfigTimeSection} style={{
                                  marginBottom: '20px'
                                }}>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_from_label')}
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'weekdaysTimetableFrom'}
                                      instanceId={'weekdays-timetable-from-select'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      options={timeSelectHoursOptions}
                                      formikProps={formikProps}
                                      value={formikProps.values.weekdaysTimetableFrom ?? ''}
                                      error={
                                        formikProps.touched.weekdaysTimetableFrom &&
                                        Boolean(formikProps.errors.weekdaysTimetableFrom)
                                      }
                                    />
                                  </div>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_to_label')} 
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'weekdaysTimetableTo'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      instanceId={'weekdays-timetable-to-select'}
                                      value={formikProps.values.weekdaysTimetableTo ?? ''}
                                      options={timeSelectHoursOptions}
                                      formikProps={formikProps}
                                      error={
                                        formikProps.touched.weekdaysTimetableTo &&
                                        Boolean(formikProps.errors.weekdaysTimetableTo)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            : null
                          }
                        </div>
                        
                        <div className={classes.editTimetableFormRow}>
                          <div
                            style={{
                              width: '25%',
                              paddingLeft: '7.5%',
                              textAlign: 'left',
                              marginBottom: '25px',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            {tForms('config_timetable_saturday_label')}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              marginBottom: '20px',
                              display: 'flex',
                              alignItems: 'center',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: '25%'
                              }}
                            >
                              <CustomSwitch 
                                name={'saturdayTimetableEnabled'}
                                checked={formikProps?.values.saturdayTimetableEnabled}
                                formikProps={formikProps}
                              />
                            </div>
                            {
                              formikProps?.values.saturdayTimetableEnabled
                              ? <div
                                  style={{
                                    paddingBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_enabled_label')}
                                </div>
                              : <div
                                  style={{
                                    marginBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_disabled_label')}
                                </div>
                            }
                          </div>
                          {
                            formikProps?.values.saturdayTimetableEnabled
                            ? <>
                                <div className={classes.editConfigTimeSection} style={{
                                  marginBottom: '20px'
                                }}>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_from_label')}
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'saturdayTimetableFrom'}
                                      instanceId={'weekdays-timetable-from-select'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      options={timeSelectHoursOptions}
                                      formikProps={formikProps}
                                      value={formikProps.values.saturdayTimetableFrom ?? ''}
                                      error={
                                        formikProps.touched.saturdayTimetableFrom &&
                                        Boolean(formikProps.errors.saturdayTimetableFrom)
                                      }
                                    />
                                  </div>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_to_label')} 
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'saturdayTimetableTo'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      instanceId={'weekdays-timetable-to-select'}
                                      options={timeSelectHoursOptions}
                                      value={formikProps.values.saturdayTimetableTo ?? ''}
                                      formikProps={formikProps}
                                      error={
                                        formikProps.touched.saturdayTimetableTo &&
                                        Boolean(formikProps.errors.saturdayTimetableTo)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            : null
                          }
                        </div>
                        
                        <div className={classes.editTimetableFormRow}>
                          <div
                            style={{
                              width: '25%',
                              paddingLeft: '7.5%',
                              textAlign: 'left',
                              marginBottom: '25px',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            {tForms('config_timetable_sunday_label')}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              marginBottom: '20px',
                              display: 'flex',
                              alignItems: 'center',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            <div
                              style={{
                                width: '25%'
                              }}
                            >
                              <CustomSwitch 
                                name={'sundayTimetableEnabled'}
                                checked={formikProps?.values.sundayTimetableEnabled}
                                formikProps={formikProps}
                              />
                            </div>
                            {
                              formikProps?.values.sundayTimetableEnabled
                              ? <div
                                  style={{
                                    paddingBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_enabled_label')}
                                </div>
                              : <div
                                  style={{
                                    marginBottom: '5px'
                                  }}
                                >
                                  {tForms('config_timetable_disabled_label')}
                                </div>
                            }
                          </div>
                          {
                            formikProps?.values.sundayTimetableEnabled
                            ? <>
                                <div className={classes.editConfigTimeSection} style={{
                                  marginBottom: '20px'
                                }}>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_from_label')}
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'sundayTimetableFrom'}
                                      instanceId={'weekdays-timetable-from-select'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      options={timeSelectHoursOptions}
                                      value={formikProps.values.sundayTimetableFrom ?? ''}
                                      formikProps={formikProps}
                                      error={
                                        formikProps.touched.sundayTimetableFrom &&
                                        Boolean(formikProps.errors.sundayTimetableFrom)
                                      }
                                    />
                                  </div>
                                  <div className={classes.editConfigTimeLabel}>
                                    {tForms('edit_config_time_to_label')} 
                                  </div>
                                  <div className={classes.editConfigTimeSelect}>
                                    <CustomSelectField 
                                      name={'sundayTimetableTo'}
                                      placeholder={tForms('edit_config_time_placeholder')}
                                      instanceId={'weekdays-timetable-to-select'}
                                      options={timeSelectHoursOptions}
                                      value={formikProps.values.sundayTimetableTo ?? ''}
                                      formikProps={formikProps}
                                      error={
                                        formikProps.touched.sundayTimetableTo &&
                                        Boolean(formikProps.errors.sundayTimetableTo)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            : null
                          }
                        </div>

                        <div className={classes.sectionLabel} style={{
                          paddingLeft: '7.5%',
                          marginTop: '15px',
                          marginBottom: '15px'
                        }}>
                          {tForms('timetable_exceptions_section_label')}
                        </div>
                        <FieldArray 
                          name={'timetableExceptions'}
                          render={
                            (arrayHelpers) => {
                              return (
                                <>
                                  {
                                    formikProps?.values.timetableExceptions?.map((e, idx) => (
                                      <div className={classes.editTimetableFormRow}>
                                        <div
                                          style={{
                                            width: '25%',
                                            paddingLeft: '7.5%',
                                            textAlign: 'left',
                                            marginBottom: '25px',
                                            fontFamily: 'AvenirBook',
                                            color: Colors.EDIT_CONFIG_TEXT,
                                            fontSize: '15px'
                                          }}
                                        >
                                          {moment(e.date).format('DD.MM.YYYY')}
                                        </div>
                                        <div
                                          style={{
                                            width: '20%',
                                            marginBottom: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'AvenirBook',
                                            color: Colors.EDIT_CONFIG_TEXT,
                                            fontSize: '15px'
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '25%'
                                            }}
                                          >
                                            <CustomSwitch 
                                              name={`timetableExceptions[${idx}].enabled`}
                                              checked={formikProps?.values.timetableExceptions[idx].enabled}
                                              formikProps={formikProps}
                                            />
                                          </div>
                                          {
                                            formikProps?.values.timetableExceptions && formikProps?.values.timetableExceptions[idx]?.enabled
                                            ? <div
                                                style={{
                                                  paddingBottom: '5px'
                                                }}
                                              >
                                                {tForms('config_timetable_enabled_label')}
                                              </div>
                                            : <div
                                                style={{
                                                  marginBottom: '5px'
                                                }}
                                              >
                                                {tForms('config_timetable_disabled_label')}
                                              </div>
                                          }
                                        </div>
                                        {
                                          formikProps?.values.timetableExceptions && formikProps?.values.timetableExceptions[idx]?.enabled
                                          ? <>
                                              <div className={classes.editConfigTimeSection} style={{
                                                marginBottom: '20px'
                                              }}>
                                                <div className={classes.editConfigTimeLabel}>
                                                  {tForms('edit_config_time_from_label')}
                                                </div>
                                                <div className={classes.editConfigTimeSelect}>
                                                  <CustomSelectField 
                                                    name={`timetableExceptions[${idx}].from`}
                                                    instanceId={'weekdays-timetable-from-select'}
                                                    placeholder={tForms('edit_config_time_placeholder')}
                                                    options={timeSelectHoursOptions}
                                                    formikProps={formikProps}
                                                    value={
                                                      formikProps.values.timetableExceptions 
                                                      ? formikProps.values.timetableExceptions[idx]?.from ?? ''
                                                      : ''
                                                    }
                                                    error={
                                                      formikProps.touched.timetableExceptions && formikProps.touched.timetableExceptions[idx]?.from &&
                                                      Boolean(formikProps.errors.timetableExceptions && formikProps.errors.timetableExceptions[idx]?.from)
                                                    }
                                                  />
                                                </div>
                                                <div className={classes.editConfigTimeLabel}>
                                                  {tForms('edit_config_time_to_label')} 
                                                </div>
                                                <div className={classes.editConfigTimeSelect}>
                                                  <CustomSelectField 
                                                    name={`timetableExceptions[${idx}].to`}
                                                    placeholder={tForms('edit_config_time_placeholder')}
                                                    instanceId={'weekdays-timetable-to-select'}
                                                    value={
                                                      formikProps.values.timetableExceptions 
                                                      ? formikProps.values.timetableExceptions[idx]?.to ?? ''
                                                      : ''
                                                    }
                                                    options={timeSelectHoursOptions}
                                                    formikProps={formikProps}
                                                    error={
                                                      formikProps.touched.timetableExceptions && formikProps.touched.timetableExceptions[idx]?.to &&
                                                      Boolean(formikProps.errors.timetableExceptions && formikProps.errors.timetableExceptions[idx]?.to)
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          : null
                                        }
                                        <div
                                          style={{
                                            display: 'flex',
                                            cursor: 'pointer',
                                            fontFamily: 'AvenirBook',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                            justifyContent: 'flex-start',
                                            color: Colors.RED_BUTTON_COLOR,
                                            alignItems: 'center',
                                            marginBottom: '35px',
                                            marginLeft: formikProps?.values.timetableExceptions && formikProps?.values.timetableExceptions[idx]?.enabled ? '10px' : '510px'
                                          }}
                                          onClick={() => arrayHelpers.remove(idx)}
                                        >
                                          <div>
                                            <img width={16} height={22} src={trashIcon}/>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: '10px'
                                            }}
                                          >
                                            {tSuperuserSettings('remove_timetable_exception_label')}
                                          </div>
                                          
                                        </div>
                                      </div>
                                    ))
                                  }
                                  {
                                    newTimetableExceptionFormEnabled
                                    ? <div className={classes.editTimetableFormRow}>
                                        <div
                                          style={{
                                            width: '25%',
                                            paddingLeft: '7.5%',
                                            textAlign: 'left',
                                            marginBottom: '25px',
                                            fontFamily: 'AvenirBook',
                                            color: Colors.EDIT_CONFIG_TEXT,
                                            fontSize: '15px'
                                          }}
                                        >
                                          <Flatpickr
                                            id={"date-range-timetable-picker"}
                                            value={
                                              newTimetableExceptionDate
                                              ? [newTimetableExceptionDate]
                                              : null
                                            }
                                            options={{
                                              locale: {
                                                firstDayOfWeek: 1,
                                                rangeSeparator: ' - ',
                                                months: {
                                                  shorthand: monthList,
                                                  longhand: monthList
                                                },
                                                weekdays: {
                                                  shorthand: dayListShorthand,
                                                  longhand: dayList
                                                }
                                              },
                                              dateFormat: "d.m.Y",
                                            }}
                                            onClose={(date) => {
                                              if (formikProps.values.timetableExceptions?.findIndex(e => moment(e.date).format('DD.MM.YYYY') === moment(date[0]).format('DD.MM.YYYY')) === -1) {
                                                setNewTimetableExceptionDate(date[0])
                                                setShowAddNewTimetableExceptionError(false)
                                              } else {
                                                setShowAddNewTimetableExceptionError(true)
                                              }
                                            }}
                                            render={({ defaultValue, value, ...props }, ref) => {
                                              return (
                                                <CustomFlatpickrInput
                                                  defaultValue={defaultValue}
                                                  placeholder={'Wybierz datę'}
                                                  inputRef={ref}
                                                  width={'90%'}
                                                />
                                              )
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            width: '20%',
                                            marginBottom: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'AvenirBook',
                                            color: Colors.EDIT_CONFIG_TEXT,
                                            fontSize: '15px'
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '25%'
                                            }}
                                          >
                                            <CustomSwitch 
                                              name={'exceptionEnabled'}
                                              checked={newTimetableExceptionEnabled}
                                              setChecked={setNewTimetableExceptionEnabled}
                                            />
                                          </div>
                                          {
                                            newTimetableExceptionEnabled
                                            ? <div
                                                style={{
                                                  paddingBottom: '5px'
                                                }}
                                              >
                                                {tForms('config_timetable_enabled_label')}
                                              </div>
                                            : <div
                                                style={{
                                                  marginBottom: '5px'
                                                }}
                                              >
                                                {tForms('config_timetable_disabled_label')}
                                              </div>
                                          }
                                        </div>
                                        {
                                            newTimetableExceptionEnabled
                                            ? <div className={classes.editConfigTimeSection} style={{
                                                marginBottom: '20px'
                                              }}>
                                                <div className={classes.editConfigTimeLabel}>
                                                  {tForms('edit_config_time_from_label')}
                                                </div>
                                                <div className={classes.editConfigTimeSelect}>
                                                  <CustomSelectField 
                                                    name={`newTimetableExceptionFrom`}
                                                    instanceId={'newTimetableExceptionFrom-select'}
                                                    placeholder={tForms('edit_config_time_placeholder')}
                                                    options={timeSelectHoursOptions}
                                                    formikProps={formikProps}
                                                    value={newTimetableExceptionFrom}
                                                    setValue={setNewTimetableExceptionFrom}
                                                  />
                                                </div>
                                                <div className={classes.editConfigTimeLabel}>
                                                  {tForms('edit_config_time_to_label')} 
                                                </div>
                                                <div className={classes.editConfigTimeSelect}>
                                                  <CustomSelectField 
                                                    name={`newTimetableExceptionTo`}
                                                    placeholder={tForms('edit_config_time_placeholder')}
                                                    instanceId={'newTimetableExceptionTo-select'}
                                                    value={newTimetableExceptionTo}
                                                    setValue={setNewTimetableExceptionTo}
                                                    options={timeSelectHoursOptions}
                                                  />
                                                </div>
                                              </div>
                                            : null
                                        }
                                        <CustomButton
                                          textComponent={
                                            <div style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              marginTop: '-1px',
                                              fontSize: '18px'
                                            }}>
                                              <span style={{
                                                marginLeft: '3px',
                                                marginTop: '2px',
                                                fontSize: '15px',
                                                fontFamily: 'Avenir'
                                              }}>
                                                {'Dodaj'}
                                              </span>
                                            </div>
                                          }
                                          style={{
                                            width: '125px',
                                            maxWidth: '125px',
                                            height: '35px',
                                            paddingLeft: '10px',
                                            paddingRight: '15px',
                                            paddingTop: '7px',
                                            ...(
                                              newTimetableExceptionEnabled
                                              ? {
                                                  marginBottom: '45px',
                                                  marginLeft: '15px',
                                                }
                                              : {
                                                  marginBottom: '30px'
                                                }
                                            ),
                                            backgroundColor: Colors.GREEN_BUTTON_COLOR
                                          }}
                                          onClick={() => {
                                            if (newTimetableExceptionDate != null && !showAddNewTimetableExceptionError) {
                                              arrayHelpers.push({ 
                                                date: newTimetableExceptionDate,
                                                enabled: newTimetableExceptionEnabled,
                                                from: newTimetableExceptionFrom,
                                                to: newTimetableExceptionTo 
                                              })
                                              setNewTimetableExceptionFrom('')
                                              setNewTimetableExceptionTo('')
                                              setNewTimetableExceptionFormEnabled(false)
                                              setNewTimetableExceptionEnabled(false)
                                              setNewTimetableExceptionDate(null)
                                            }
                                          }}
                                        />
                                        {
                                          showAddNewTimetableExceptionError
                                          ? <div
                                              style={{
                                                fontSize: '15px',
                                                marginBottom: '15px',
                                                marginLeft: '10px',
                                                color: Colors.RED_BUTTON_COLOR,
                                                fontFamily: 'Avenir'
                                              }}
                                            >
                                              {'Wybrana data istnieje już na liście wyjątków'}
                                            </div>
                                          : null
                                        }
                                      </div>
                                    : <div
                                        style={{
                                          cursor: 'pointer',
                                          paddingLeft: '7.5%',
                                          fontFamily: 'AvenirBook',
                                          fontSize: '15px',
                                          fontWeight: 'bold',
                                          color: Colors.BLUE_BUTTON_COLOR,
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '25px'
                                        }}
                                        onClick={() => {
                                          setNewTimetableExceptionFormEnabled(true)
                                        }}
                                      > 
                                        <div
                                          style={{
                                            marginLeft: '-5px'
                                          }}
                                        >
                                          <AddIcon />
                                        </div>
                                        <div
                                          style={{
                                            marginTop: '-3px'
                                          }}
                                        >
                                          {tSuperuserSettings('add_timetable_exception_label')}
                                        </div>
                                      </div>
                                  }
                                </>
                              )
                            }
                          }
                        />

                        <div className={classes.sectionLabel} style={{
                          marginTop: '15px',
                          marginBottom: '15px'
                        }}>
                          {tForms('Parametry wysyłki wiadomości')}
                        </div>
                        <div className={classes.editConfigFormSending} style={{
                          alignItems: 'center'
                        }}>
                          <div 
                            style={{
                              paddingLeft: '7.5%',
                              textAlign: 'left',
                              marginBottom: '22px',
                              marginRight: '10px',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            {'Po jakim czasie do użytkownika może ponownie zostać wysłana wiadomość SMS'}
                          </div>
                          <div
                            style={{
                              width: '260px',
                              maxWidth: '260px'
                            }}
                          >
                            <BaseNumberInput
                              name="nextSendDelay" 
                              placeholder={tForms('min. 1')}
                              formikProps={formikProps}
                              integers
                              value={formikProps.values.nextSendDelay}
                              error={
                                formikProps.touched.nextSendDelay &&
                                Boolean(formikProps.errors.nextSendDelay)
                              }
                            />
                          </div>
                          <div 
                            style={{
                              paddingLeft: '10px',
                              textAlign: 'left',
                              marginBottom: '22px',
                              marginRight: '10px',
                              fontFamily: 'AvenirBook',
                              color: Colors.EDIT_CONFIG_TEXT,
                              fontSize: '15px'
                            }}
                          >
                            {'dni'}
                          </div>
                        </div>
                      </div>
                      <div style={{
                        float: 'right',
                        marginBottom: '30px'
                      }}>
                        <LoadingButton 
                          text={tForms('save_button_label')}
                          // disabled={!formikProps.isValid || !formikProps.dirty}
                          type='submit'
                          loading={isSubmitting}
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            height: '40px'
                          }}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      
    </>
  )
}

export default SuperuserSettings
