import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

export const bulkActionPageStyle = makeStyles((theme: Theme) => ({
  controlRow: {
    display: 'flex',
    marginBottom: '10px'
  },
  dateRangeField: {
    width: '250px',
    marginRight: '2%',
    position: 'relative'
  },
  dateRangeLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.EDIT_CONFIG_TEXT,
    marginBottom: '5px',
    marginLeft: '2px'
  },
  auctionCountTile: {
    width: '230px',
    flexShrink: 0,
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  msgSentTile: {
    width: '230px',
    flexShrink: 0,
    // marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  responsesCountTile: {
    width: '230px',
    flexShrink: 0,
    marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  emptySpaceTile: {
    width: 'calc(96% - 920px)',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      display: 'none'
    }
  },
  assumedUserInfo: {
    marginBottom: '20px',
    display: 'flex',
    fontFamily: 'AvenirBook',
    fontSize: '16px',
    alignItems: 'center'
  },
  tableHeaderCell: {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontFamily: 'AvenirMedium',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'normal',
    color: Colors.TABLE_HEADER_LABEL
  }, 
  tableDataCell: {
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '10px'
  }, 
  manageLink: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    wordBreak: 'keep-all',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  fieldSubLabel: {
    fontFamily: 'AvenirHeavy',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    marginLeft: '7px'
  },
  formSectionWarningLabel: {
    color: '#faa307',
    textAlign: 'left',
    margin: '0px 2px 10px',
    fontFamily: "AvenirBook",
    fontSize: '15px'
  },
  tabList: {
    border: 0
  },
  tab: {
    borderBottom: '1px solid #F1F6FF',
    display: 'inline-block',
    padding: '6px 12px',
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    }
  },
  tabSelected: {
    borderBottom: '2px solid #4566C0',
    display: 'inline-block',
    padding: '6px 12px',
    paddingBottom: '5px',
    color: '#4566C0',
    fontFamily: 'AvenirHeavy',
    fontSize: '15px'
  },
  addActionDialogStepLabel: {
    color: '#4566c0',
    fontFamily: 'AvenirMedium',
    fontSize: '20px',
    marginBottom: '20px'
  },
  hideElement: {
    display: 'none'
  },
  noAdvertCountWarning: {
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '20px',
    fontFamily: 'AvenirMedium',
    fontSize: '20px',
    color: '#f6ae3f'
  },
  statTileSection: {
    width: '100%',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    '@media (max-width: 1035px)': {
      flexWrap: "wrap",
      justifyContent: 'space-evenly'
    }
  },
  backButton: {
    flexGrow: 1,
    textTransform: "none",
    padding: "10px 0px",
    fontSize: "15px",
    marginTop: "5px",
    fontFamily: "AvenirMedium",
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '350px',
    maxWidth: '350px',
    height: '36px',
    backgroundColor: 'transparent',
    color: '#4566C0',
    border: 'solid 2px #4566C0',
    cursor: 'pointer',
    '@media (max-width: 1000px)': {
      marginTop: "10px",
    },
    '&:disabled': {
      backgroundColor: Colors.AUTH_DISABLED_BUTTON_COLOR,
      opacity: 0.5,
      cursor: 'default'
    }
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  }
}))
