import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

export const phoneBlacklistStyle = makeStyles((theme: Theme) => ({
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '0px 14px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px',
  },
  sectionLabel: {
    textTransform: 'uppercase',
    fontFamily: 'AvenirBook',
    fontSize: '13px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.LIGHT_GRAY_TEXT,
    marginBottom: '10px',
    marginLeft: '2px'
  },
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: 'black',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  fieldWithLabel: {
    width: '80%',
    position: 'relative'
  },
  fieldWithoutLabel: {
    width: '23.5%',
    marginRight: '2%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  lastFieldWithLabel: {
    width: '23.5%',
    position: 'relative'
  },
  lastFieldWithoutLabel: {
    width: '23.5%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  rowContainer: {
    display: 'flex'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  listCount: {
    position: 'absolute',
    top: 25,
    right: 15,
    fontFamily: 'AvenirBook',
    fontSize: '16px'
  },
  tableHeaderCell: {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontFamily: 'AvenirMedium',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'normal',
    color: Colors.TABLE_HEADER_LABEL
  }, 
  tableDataCell: {
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '10px'
  }, 
  manageLink: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  }
}))
