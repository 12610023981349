export enum AdvertType {
  UNDEFINED,
  FLAT,
  HOUSE,
  SERVICE_PREMISE,
  GARAGE,
  PLOT,
  WAREHOUSE
}

export enum OfferType {
  UNDEFINED,
  SELLING,
  RENTING,
}

export enum MarketType {
  UNDEFINED,
  PRIMARY,
  SECONDARY
}

export enum PlotType {
  UNDEFINED,
  BUILDING,
  COMMERCIAL,
  AGRICULTURAL,
  RECREATIONAL,
  WOODLAND,
  AGRICULTURAL_BUILDING,
  HABITAT,
  OTHER
}

export enum PlotOtherParams {
  NEAR_FOREST = 'las',
  NEAR_LAKE = 'jezioro',
  NEAR_OPEN_SAPCE = 'otwarty teren',
  NEAR_MOUNTAINS = 'góry',
  NEAR_SEA = 'morze'
}

export const marketTypes = [
  {
    value: `${MarketType.PRIMARY}`,
    label: 'Nowe'
  },
  {
    value: `${MarketType.SECONDARY}`,
    label: 'Starsze'
  }
]

export const propertyTypes = [
  {
    value: `${AdvertType.FLAT}`,
    label: 'Mieszkania',
  }, {
    value: `${AdvertType.HOUSE}`,
    label: 'Domy'
  }, {
    value: `${AdvertType.PLOT}`,
    label: 'Działki'
  }, {
    value: `${AdvertType.SERVICE_PREMISE}`,
    label: 'Lokale użytkowe'
  }, {
    value: `${AdvertType.WAREHOUSE}`,
    label: 'Hale i magazyny'
  }, {
    value: `${AdvertType.GARAGE}`,
    label: 'Garaże/miejsca parkingowe'
  }
]





export const plotOtherParams = [
  {
    value: PlotOtherParams.NEAR_FOREST,
    label: 'Przy lesie'
  },
  {
    value: PlotOtherParams.NEAR_LAKE,
    label: 'Prze jeziorze'
  },
  {
    value: PlotOtherParams.NEAR_OPEN_SAPCE,
    label: 'Przy otwartym terenie'
  },
  {
    value: PlotOtherParams.NEAR_MOUNTAINS,
    label: 'Przy górach'
  },
  {
    value: PlotOtherParams.NEAR_SEA,
    label: 'Przy morzu'
  }
]

export const plotTypes = [
  {
    value: `${PlotType.BUILDING}`,
    label: 'Budowlana'
  },
  {
    value: `${PlotType.COMMERCIAL}`,
    label: 'Pod inwestycję'
  },
  {
    value: `${PlotType.AGRICULTURAL}`,
    label: 'Rolna'
  },
  {
    value: `${PlotType.RECREATIONAL}`,
    label: 'Rekreacyjna'
  },
  {
    value: `${PlotType.WOODLAND}`,
    label: 'Leśna'
  },
  {
    value: `${PlotType.AGRICULTURAL_BUILDING}`,
    label: 'Rolno-budowlana'
  },
  {
    value: `${PlotType.HABITAT}`,
    label: 'Siedliskowa'
  },
  {
    value: `${PlotType.OTHER}`,
    label: 'Inna'
  }
]

export const transactionTypes = [
  {
    value: `${OfferType.SELLING}`,
    label: 'Sprzedaż',
  }, {
    value: `${OfferType.RENTING}`,
    label: 'Wynajem'
  }
]

// TODO remove
export const regions = [
  {
    value: 'placeholder1',
    label: 'dolnośląskie'
  },
  {
    value: 'placeholder2',
    label: 'kujawsko-pomorskie'
  },
  {
    value: 'placeholder3',
    label: 'lubelskie'
  },
  {
    value: 'placeholder4',
    label: 'lubuskie'
  },
  {
    value: 'placeholder5',
    label: 'łódzkie'
  },
  {
    value: 'placeholder6',
    label: 'małopolskie'
  },
  {
    value: 'placeholder7',
    label: 'mazowieckie'
  },
  {
    value: 'placeholder8',
    label: 'opolskie'
  },
  {
    value: 'placeholder9',
    label: 'podkarpackie'
  },
  {
    value: 'placeholder11',
    label: 'podlaskie'
  },
  {
    value: 'placeholder12',
    label: 'pomorskie'
  },
  {
    value: 'placeholder13',
    label: 'śląskie'
  },
  {
    value: 'placeholder14',
    label: 'świętokrzyskie'
  },
  {
    value: 'placeholder15',
    label: 'warmińsko-mazurskie'
  },
  {
    value: 'placeholder16',
    label: 'wielkopolskie'
  },
  {
    value: 'placeholder17',
    label: 'zachodniopomorskie'
  }
]