import { makeStyles } from "@material-ui/core"
import { Colors } from "../../../types/colors"

export const contactFormStyle = makeStyles(() => ({
  inputLabel: {
    fontSize: "15px",
    lineHeight: "1.47",
    color: `${Colors.CONTENT_FAQ_TEXT}`,
    paddingBottom: "5px",
    marginTop: "10px",
  },
  inputField: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: "9px",
    marginTop: 0,
    paddingBottom: "9px",
    paddingLeft: "14px",
    paddingRight: "14px",
    border: `solid 1px ${Colors.INPUT_BORDER}`,
    borderRadius: "5px",
    fontSize: "15px",
    fontFamily: '"AvenirBook", "Roboto", "Arial", sans-serif',
    display: "block",
    "&:focus": {
      border: `solid 1px ${Colors.AUTH_INPUT_BORDER_FOCUS}`,
      outline: "none !important",
    },
    "&::placeholder": {
      color: `${Colors.LIGHT_GRAY_TEXT}`,
    },
  },
  isSentMessage: {
    fontSize: "15px",
    color: `${Colors.CONTENT_FAQ_TEXT}`,
  },
  errorField: {
    borderColor: 'red',
    '&:focus': {
      borderColor: 'red',
    },
  }
}))
