import {
    applyMiddleware, createStore
} from "redux"
import { createStateSyncMiddleware, initStateWithPrevTab, withReduxStateSync } from 'redux-state-sync'
import { ADD_CMD_MESSAGE, ADD_ERROR_MESSAGE, ADD_SUCCESS_MESSAGE, CLEAR_MESSAGES } from './redux/messages/types'
import { rootReducer } from "./redux/reducers"

// import { composeWithDevTools } from "redux-devtools-extension"
// import reduxThunk from "redux-thunk"
// const storeEnhancer = composeWithDevTools({ trace: true })

const config = {
    blacklist: [
        ADD_SUCCESS_MESSAGE,
        ADD_ERROR_MESSAGE,
        ADD_CMD_MESSAGE,
        CLEAR_MESSAGES,
    ]

}
const middlewares = [
  createStateSyncMiddleware(config),
]

const store = createStore(
    withReduxStateSync(rootReducer),
    {},
    applyMiddleware(...middlewares)
    // storeEnhancer(
    //     applyMiddleware(reduxThunk)
    // )
)

initStateWithPrevTab(store)

export default store