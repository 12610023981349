import React from 'react'
import DataApi from '../../../api/DataApi'
import { useSelector } from 'react-redux'
import { checkIfAdmin } from '../../../utils/common'
import { State } from '../../../redux/reducers/index'

export const useCalculateAdvertsForm = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [regions, setRegions] = React.useState<any[]>([])

  const fetchAssumedUser = async (uuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

  const fetchRegionList = async (assumeUuid?: string, allLocations?: boolean) => {
    try {
      const fetchedRegions = await DataApi.getRegionList(allLocations, assumeUuid)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

	return { 
    regions, assumedUser,
    fetchRegionList, fetchAssumedUser
	}
}