import { makeStyles, Theme } from "@material-ui/core"
import { relative } from "path"
import { Colors } from '../../types/colors'

export const superuserSettingsStyle = makeStyles((theme: Theme) => ({
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: 'black',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  fieldWithLabel: {
    position: 'relative'
  },
  userListControlFieldWithLabel: {
    position: 'relative',
    marginRight: '10px'
  },
  rowContainer: {
    display: 'flex'
  },
  manageLink: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    wordBreak: 'keep-all',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  editTemplateLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'AvenirMedium',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  tabList: {
    border: 0
  },
  tab: {
    borderBottom: '1px solid #F1F6FF',
    display: 'inline-block',
    padding: '6px 12px',
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none'
    }
  },
  tabSelected: {
    borderBottom: '2px solid #4566C0',
    display: 'inline-block',
    padding: '6px 12px',
    paddingBottom: '5px',
    color: '#4566C0',
    fontFamily: 'AvenirHeavy',
    fontSize: '15px'
  },
  editConfigForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  editTimetableFormRow: {
    width: '100%',
    position: 'relative',
    marginBottom: '5px',
    display: 'flex',
    height: '62px',
    alignItems: 'center'
  },
  editConfigFormPrices: {
    width: '100%',
    position: 'relative',
    display: 'flex'
  },
  editConfigFormSending: {
    width: '100%',
    position: 'relative',
    display: 'flex'
  },
  editConfigFormPricesRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  editConfigFormPricesRowLabel: {
    width: '40%',
    paddingLeft: '15%',
    textAlign: 'left',
    marginBottom: '25px',
    fontFamily: 'AvenirBook',
    color: Colors.EDIT_CONFIG_TEXT,
    fontSize: '15px'
  },
  editConfigFormPricesRowInput: {
    width: '50%'
  },
  editConfigFormPricesRowUnit: {
    width: '10%',
    color: Colors.EDIT_CONFIG_TEXT,
    textAlign: 'left',
    marginBottom: '25px',
    paddingLeft: '3%',
    fontFamily: 'AvenirBook',
    fontSize: '15px'
  },
  sectionLabel: {
    fontFamily: 'AvenirBook',
    fontWeight: 'bold',
    color: Colors.EDIT_CONFIG_TEXT,
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  editConfigTimeLabel: {
    width: '15%',
    color: Colors.EDIT_CONFIG_TEXT,
    textAlign: 'left',
    marginBottom: '5px',
    paddingLeft: '5%',
    fontFamily: 'AvenirBook',
    fontSize: '15px'
  },
  editConfigTimeSelect: {
    width: '200px'
  },
  editConfigTimeSection: {
    display: 'flex',
    width: '500px',
    alignItems: 'center'
  },
  editConfigParamsSection: {
    display: 'flex',
    width: '50%',
    alignItems: 'center'
  },
  calculateAdvertsContainer: {
    padding: '10px',
    marginTop: '20px'
  },
  estimatedAdvertCountContainer: {
    marginBottom: '50px',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    // border: 'solid 1px black'
  },
  estimatedAdvertCountBlock: {
    padding: '10px',
    width: '40%',
    border: `solid 2px rgba(69, 102, 192, 0.1)`,
    backgroundColor: 'rgba(229, 233, 250, 0.25)'
  },
  estimatedAdvertCountTitle: {
    textTransform: 'uppercase',
    marginBottom: '10px',
    fontFamily: 'AvenirMedium',
    fontSize: '14px'
  },
  estimatedAdvertCountCount: {
    textAlign: 'center',
    fontFamily: 'AvenirHeavy',
    fontSize: '18px'
  },
  seeActionDetails: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    wordBreak: 'keep-all',
    cursor: 'pointer',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  tagLabelsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagLabel: {
    color: '#fff',
    backgroundColor: '#69707f',
    marginRight: '2px',
    marginBottom: '2px',
    padding: '2px 5px',
    fontFamily: 'Avenir'
  },
  newSentDateFieldContainer: {
    position: 'relative',
    maxWidth: '450px',
    marginTop: '-10px'
  }
}))
