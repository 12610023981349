import { makeStyles } from '@material-ui/styles'
import { Colors } from '../../../types/colors'

export const addBulkActionInfoFormStyle
 = makeStyles(() => ({
  container: {

  },
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  fieldWithLabel: {
    width: '23.5%',
    marginRight: '2%',
    position: 'relative'
  },
  fieldWithoutLabel: {
    width: '23.5%',
    marginRight: '2%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  lastFieldWithLabel: {
    width: '23.5%',
    position: 'relative'
  },
  lastFieldWithoutLabel: {
    width: '23.5%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  rowContainer: {
    display: 'flex',
    marginBottom: '10px'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  sectionLabel: {
    textTransform: 'uppercase',
    fontFamily: 'AvenirBook',
    fontSize: '13px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.LIGHT_GRAY_TEXT,
    marginBottom: '10px',
    marginLeft: '2px'
  },
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '4px 14px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  },
  fieldSubLabel: {
    fontFamily: 'AvenirHeavy',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    // marginLeft: '7px'
  },
  estimatedAdvertCountContainer: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    // border: 'solid 1px black'
  },
  estimatedAdvertCountBlock: {
    padding: '10px',
    width: '40%',
    border: `solid 2px rgba(69, 102, 192, 0.1)`,
    backgroundColor: 'rgba(229, 233, 250, 0.25)'
  },
  estimatedAdvertCountTitle: {
    textTransform: 'uppercase',
    marginBottom: '10px',
    fontFamily: 'AvenirMedium',
    fontSize: '14px'
  },
  estimatedAdvertCountCount: {
    textAlign: 'center',
    fontFamily: 'AvenirHeavy',
    fontSize: '18px'
  },
  backButton: {
    flexGrow: 1,
    textTransform: "none",
    padding: "10px 0px",
    fontSize: "15px",
    marginTop: "5px",
    fontFamily: "AvenirMedium",
    fontWeight: 'bold',
    borderRadius: '5px',
    width: '350px',
    maxWidth: '350px',
    height: '36px',
    backgroundColor: 'transparent',
    color: '#4566C0',
    border: 'solid 2px #4566C0',
    cursor: 'pointer',
    '@media (max-width: 1000px)': {
      marginTop: "10px",
    },
    '&:disabled': {
      backgroundColor: Colors.AUTH_DISABLED_BUTTON_COLOR,
      opacity: 0.5,
      cursor: 'default'
    }
  },
  notificationEmail: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontFamily: 'AvenirBook',
    height: '30px',
    backgroundColor: 'rgba(216,216,216,0.5)',
    color: '#8798AD',
    marginRight: '5px',
    marginBottom: '3px',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: '5px',
    borderRadius: '5px'
  },
  formSectionWarningLabel: {
    color: '#faa307',
    textAlign: 'left',
    margin: '0px 2px 10px',
    fontFamily: "AvenirBook",
    fontSize: '15px'
  }
}))