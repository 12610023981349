import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import contactIcon from "../../assets/images/ic-contact.webp"
import faqIcon from "../../assets/images/ic-faq.webp"
import manualIcon from "../../assets/images/ic-manual.webp"
import ContactForm from "../common/ContactForm/ContactForm"
import { faqPageStyle } from "./FAQPageStyle"
import { useSelector } from "react-redux"
import { State } from "../../redux/reducers"
import { FAQ_QUESTIONS, QuestionType } from '../../types/faq';
import { slugifyString } from "../../utils/common"
import FAQCard from "../common/FAQCard/FAQCard"

const FAQPage = () => {
  const { t } = useTranslation("faq")
  const manualQuestions = FAQ_QUESTIONS.filter((question) => question.type === QuestionType.MANUAL)
  const faqQuestions = FAQ_QUESTIONS.filter((question) => question.type === QuestionType.FAQ)

  const classes = faqPageStyle()
  const permissions = useSelector((e: State) => e.permissions)

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>{t("faq_label")}</div>
      <div className={classes.cardsContainer}>
        <div className={classes.card}>
          <FAQCard icon={manualIcon} title={t("manual_card_label")}>
            <ul className={classes.questionsList}>
              {manualQuestions.map((question) => (
                  permissions.permissions.some((permission) => question.permissions.includes(permission)) && (
                    <li className={classes.questionsListItem} key={question.id}>
                      <Link to={`/faq/${slugifyString(t(question.id))}`}>{t(question.id)}</Link>
                    </li>
                  )
                )
              )}
            </ul>
          </FAQCard>
        </div>
        <div className={classes.card}>
          <FAQCard icon={faqIcon} title={t("faq_card_label")}>
            <ul className={classes.questionsList}>
              {faqQuestions.map((question) => (
                  permissions.permissions.some((permission) => question.permissions.includes(permission)) && (
                    <li className={classes.questionsListItem} key={question.id}>
                      <Link to={`/faq/${slugifyString(t(question.id))}`}>{t(question.id)}</Link>
                    </li>
                  )
                )
              )}
            </ul>
          </FAQCard>
        </div>
        <div className={classes.cardContactForm}>
          <FAQCard icon={contactIcon} title={t("contact_card_label")} subheader={t("contact_card_subtitle")}>
            <ContactForm />
          </FAQCard>
        </div>
      </div>
    </div>
  )
}

export default FAQPage
