import { CircularProgress } from '@material-ui/core'
import { Check, EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import specialModeIcon from '../../assets/images/specialMode.svg'
import trashIcon from '../../assets/images/Trash.webp'
import Checkbox from '../../lib/react-custom-checkbox/dist'
import { addCmdMessage } from '../../redux/messages/actions'
import { AdvertType, MarketType, plotOtherParams, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { Colors } from '../../types/colors'
import {
  MSG_SIGNATURE_LEN,
  SearchParamVersion,
  TEMPLATE_MAX_MSG,
  TEMPLATE_MSG_LEN,
  TEMPLATE_MULTIPLE_MSG_LEN,
} from '../../types/common'
import { preventReturnKey } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { evalStringTemplate } from '../../utils/text'
import { addNotificationEmail } from '../../utils/validations/addNotificationEmail'
import { editSuperAdminV1ParamsForm, editSuperAdminV2ParamsForm } from '../../utils/validations/superadminSearchParams'
import { smsMessagePattern } from '../../utils/validations/validationRegexps'
import BaseInput from '../common/BaseInput/BaseInput'
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomSelectField, { AllSelectOptionsValue } from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import CustomDialog from '../CustomDialog/CustomDialog'
import { MessageEnum } from '../Messages/types'
import ConfirmLeaveDialog from './ConfirmLeaveDialog/ConfirmLeaveDialog'
import { DefaultInitialFormValues } from './consts'
import {
  checkIfAllLocations,
  mapDistrictOptions,
  mapSubRegionOptions,
  mapTownOptions,
  sortAndCompareSelectionArrays,
} from './helpers'
import styles from './styles.module.scss'
import { useEditSuperEventDialog } from './useEditSuperEventDialog'

type Props = {
  hidden: boolean
  onClose: () => void
  afterSubmit?: (() => void) | (() => Promise<void>)
  details: any
}

const EditSuperEventDialog = (props: Props) => {
  const dispatch = useDispatch()

  const t = useTranslation('forms').t
  const tEventDetails = useTranslation("eventDetails").t

  const { 
    regions, smsTemplates, account,
    fetchRegionList, fetchSmsTemplateList, editDetails, fetchAccountDetails
	} = useEditSuperEventDialog()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isInitialPropertyTypeSet, setIsInitialPropertyTypeSet] = React.useState<boolean>(false)
  const [isInitialLocationsSet, setIsInitialLocationsSet] = React.useState<boolean>(false)

  const [isEditingCustomTemplate, setIsEditingCustomTemplate] = React.useState<boolean>(false)
  const [isCustomTemplateRemoved, setIsCustomTemplateRemoved] = React.useState<boolean>(false)

  const [subRegionList, setSubRegionList] = React.useState<any[]>([])
  const [townList, setTownList] = React.useState<any[]>([])
  const [districtList, setDistrictList] = React.useState<any[]>([])

  const [chosenRegions, setChosenRegions] = React.useState<string[] | string>([])
  const [chosenSubregions, setChosenSubregions] = React.useState<string[] | string>([])
  const [chosenTowns, setChosenTowns] = React.useState<string[]>([])
  const [chosenDistricts, setChosenDistricts] = React.useState<string[]>([])

  const [chosenNotificationEmails, setChosenNotificationEmails] = React.useState<string[]>([])
  const [chosenSmsTemplates, setChosenSmsTemplates] = React.useState<string[]>([])
  const [chosenCustomSmsTemplate, setChosenCustomSmsTemplate] = React.useState<string>('')
  const [tempCustomSmsTemplate, setTempCustomSmsTemplate] = React.useState<string>('')
  const [chosenPropertyTypes, setChosenPropertyTypes] = React.useState<string[] | string>([])

  const [editFormInitialFormValues, setEditFormInitialFormValues] = React.useState<any>(DefaultInitialFormValues)

  const [showLeaveEditDialog, setShowLeaveEditDialog] = React.useState<boolean>(false)

  const [filteredSmsTemplates, setFilteredSmsTemplates] = React.useState<any[]>([])

  const formRef = useRef() as any
  const emailsFormRef = useRef() as any
  const customTemplateFormRef = useRef() as any

  React.useEffect(() => {
    Promise.all([
      fetchRegionList(),
      fetchSmsTemplateList(),
      fetchAccountDetails()
    ]).then(_ => {
      setIsLoading(false)
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (props.details) {
      setInitialData()
    }
  }, [props.details])

  React.useEffect(() => {
    if (isInitialPropertyTypeSet && Array.isArray(chosenPropertyTypes)) {
      setChosenSmsTemplates([])
      formRef.current.setFieldValue('smsTemplate', [])
      setFilteredSmsTemplates(chosenPropertyTypes.includes(AllSelectOptionsValue) ? smsTemplates.filter(e => !e.removed || props.details.smsMessageUuids.includes(e.uuid))  : smsTemplates.filter(e => (!e.removed && chosenPropertyTypes.includes(`${e.advertType}`))))
      if (!chosenPropertyTypes.includes(`${AdvertType.PLOT}`)) {
        formRef?.current?.setFieldValue('propertySubTypes', [])
        formRef?.current?.setFieldValue('propertyOtherParams', [])
      }
    } else if (isInitialPropertyTypeSet) {
      setChosenSmsTemplates([])
      formRef.current.setFieldValue('smsTemplate', [])
      setFilteredSmsTemplates(smsTemplates.filter(e => (!e.removed && `${e.advertType}` === chosenPropertyTypes)))   
      if (chosenPropertyTypes !== `${AdvertType.PLOT}`) {
        formRef?.current?.setFieldValue('propertySubTypes', '')
        formRef?.current?.setFieldValue('propertyOtherParams', [])
      }
    } else if (Array.isArray(chosenPropertyTypes)) {
      setChosenSmsTemplates(props.details ? props.details.smsMessageUuids : [])
      formRef.current?.setFieldValue('smsTemplate', props.details ? props.details.smsMessageUuids : [])
      setFilteredSmsTemplates(chosenPropertyTypes.includes(AllSelectOptionsValue) ? smsTemplates.filter(e => !e.removed || props.details.smsMessageUuids.includes(e.uuid)) : smsTemplates.filter(e => (!e.removed && chosenPropertyTypes.includes(`${e.advertType}`)) || props.details.smsMessageUuids.includes(e.uuid)))
      setIsInitialPropertyTypeSet(true)
    } else if (chosenPropertyTypes !== '') {
      setChosenSmsTemplates(props.details ? props.details.smsMessageUuids : [])
      formRef.current?.setFieldValue('smsTemplate', props.details ? props.details.smsMessageUuids : [])
      setFilteredSmsTemplates(smsTemplates.filter(e => (!e.removed && `${e.advertType}` === chosenPropertyTypes) || props.details.smsMessageUuids.includes(e.uuid)))
      setIsInitialPropertyTypeSet(true)
    }
  }, [chosenPropertyTypes])

  React.useEffect(() => {
    const newSubRegionList = mapSubRegionOptions(chosenRegions, regions)
    const newTownList = mapTownOptions(chosenSubregions, newSubRegionList)
    const newDistrictList = mapDistrictOptions(chosenTowns, newTownList)
    setSubRegionList(newSubRegionList)
    setTownList(newTownList)
    setDistrictList(newDistrictList)
    let newChosenSubregions
    if (Array.isArray(chosenSubregions)) {
      newChosenSubregions = chosenSubregions.filter(e => newSubRegionList.findIndex(t => t.value === e) > -1)
      if (!chosenSubregions.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenSubregions, newChosenSubregions)) {
        setChosenSubregions(newChosenSubregions)
        formRef.current?.setFieldValue('subRegions', newChosenSubregions)
      }
    } else {
      setChosenSubregions(newSubRegionList.findIndex(e => e.value === chosenSubregions) > -1 ? chosenSubregions : '')
      formRef.current?.setFieldValue('subRegions', newSubRegionList.findIndex(e => e.value === chosenSubregions) > -1 ? chosenSubregions : '')
    }
    const newChosenTowns = chosenTowns.filter(e => newTownList.findIndex(t => t.value === e) > -1)
    if (!chosenTowns.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenTowns, newChosenTowns)) {
      setChosenTowns(newChosenTowns)
      formRef.current?.setFieldValue('towns', newChosenTowns)
    }
    const newChosenDistricts = chosenDistricts.filter(e => newDistrictList.findIndex(t => t.value === e) > -1)
    if (!chosenDistricts.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenDistricts, newChosenDistricts)) {
      setChosenDistricts(newChosenDistricts)
      formRef.current?.setFieldValue('districts', newChosenDistricts)
    }
    if (!isInitialLocationsSet) {
      if (
        Array.isArray(chosenRegions) &&
        checkIfAllLocations(regions.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }), chosenRegions)
      ) {
        setChosenRegions([AllSelectOptionsValue])
      }
      if (
        Array.isArray(chosenSubregions) &&
        checkIfAllLocations(newSubRegionList, newChosenSubregions)
      ) {
        setChosenSubregions([AllSelectOptionsValue])
      }
      if (checkIfAllLocations(newTownList, newChosenTowns)) {
        setChosenTowns([AllSelectOptionsValue])
      }
      if (checkIfAllLocations(newDistrictList, newChosenDistricts)) {
        setChosenDistricts([AllSelectOptionsValue])
      }
    }
    setIsInitialLocationsSet(true)
  }, [chosenRegions, chosenSubregions, chosenTowns, chosenDistricts])

  const setInitialData = () => {
    if (props.details.childAccount) {
      setEditFormInitialFormValues({
        name: props.details.name,
        minPrice: `${props.details.params.minPrice}`.replace('.', ','),
        maxPrice: `${props.details.params.maxPrice}`.replace('.', ','),
        minArea: `${props.details.params.minArea}`.replace('.', ','),
        maxArea: `${props.details.params.maxArea}`.replace('.', ','),
        regions: props.details.params.region.uuid,
        subRegions: props.details.params.subregion?.uuid || '',
        towns: props.details.params.town 
          ? [props.details.params.town.uuid]
          : (props.details.params.towns?.map(e => e.uuid) || []),
        districts: props.details.params.district 
          ? [props.details.params.district.uuid]
          : (props.details.params.districts?.map(e => e.uuid) || []),
        transactionTypes: `${props.details.params.offerType}`,
        propertyTypes: `${props.details.params.advertType}`,
        propertySubTypes: props.details.params.advertSubType != null ? `${props.details.params.advertSubType}` : '',
        signature: props.details.signature || '',
        minPricePerArea: `${props.details.params.minPricePerArea ?? ''}`.replace('.', ','),
        maxPricePerArea: `${props.details.params.maxPricePerArea ?? ''}`.replace('.', ','),
        smsTemplate: props.details.smsMessageUuids,
        notificationEmails: props.details.notificationEmails,
        customTemplate: props.details.customMessage ? props.details.customMessage.template : ''
      })
      setChosenPropertyTypes(`${props.details.params.advertType}`)
      setChosenRegions(props.details.params.region.uuid)
      setChosenSubregions(props.details.params.subregion?.uuid ?? '' ) 
      setChosenTowns(props.details.params.town 
        ? [props.details.params.town.uuid]
        : (props.details.params.towns?.map(e => e.uuid) ?? [])) 
      setChosenDistricts(props.details.params.district 
        ? [props.details.params.district.uuid]
        : (props.details.params.districts?.map(e => e.uuid) ?? []))
      setChosenSmsTemplates(props.details.smsMessageUuids)
      setChosenNotificationEmails(props.details.notificationEmails)
      setChosenCustomSmsTemplate(props.details.customMessage ? props.details.customMessage.template : '')
    } else {
      switch (props.details.version) {
        case SearchParamVersion.V_2_0:
          setEditFormInitialFormValues({
            name: props.details.name ?? '',
            minPrice: `${props.details.params.minPrice}`.replace('.', ','),
            maxPrice: `${props.details.params.maxPrice}`.replace('.', ','),
            minArea: `${props.details.params.minArea}`.replace('.', ','),
            maxArea: `${props.details.params.maxArea}`.replace('.', ','),
            regions: props.details.params.regions?.map(e => e.uuid),
            subRegions: props.details.params.subregions?.map(e => e.uuid) ?? [],
            towns: props.details.params.towns?.map(e => e.uuid) ?? [],
            districts: props.details.params.districts?.map(e => e.uuid) ?? [],
            transactionTypes: props.details.params.offerTypes.map(e => `${e}`),
            propertyTypes: props.details.params.advertTypes.map(e => `${e}`),
            propertySubTypes: props.details.params.advertSubTypes.map(e => `${e}`) ?? [],
            propertyOtherParams: props.details.params.advertOtherParams.map(e => `${e}`) ?? [],
            signature: props.details.signature || '',
            minPricePerArea: `${props.details.params.minPricePerArea ?? ''}`.replace('.', ','),
            maxPricePerArea: `${props.details.params.maxPricePerArea ?? ''}`.replace('.', ','),
            smsTemplate: props.details.smsMessageUuids,
            notificationEmails: props.details.notificationEmails,
            customTemplate: props.details.customMessage ? props.details.customMessage.template : '',
            includeAgencyOffers: props.details.params.includeAgencyOffers,
            includeOffersWithNoPhones: props.details.params.includeOffersWithNoPhones,
            enableSpecialMode: props.details.params.specialMode
          })
          setChosenPropertyTypes(props.details.params.advertTypes.map(e => `${e}`))
          setChosenRegions(props.details.params.regions?.map(e => e.uuid))
          setChosenSubregions(props.details.params.subregions?.map(e => e.uuid) ?? [])
          setChosenTowns(props.details.params.towns?.map(e => e.uuid) ?? [])
          setChosenDistricts(props.details.params.districts?.map(e => e.uuid) ?? [])
          setChosenSmsTemplates(props.details.smsMessageUuids)
          setChosenNotificationEmails(props.details.notificationEmails)
          setChosenCustomSmsTemplate(props.details.customMessage ? props.details.customMessage.template : '')
          break
        case SearchParamVersion.V_1_0:
        default:
          setEditFormInitialFormValues({
            name: props.details.name ?? '',
            minPrice: `${props.details.params.minPrice}`.replace('.', ','),
            maxPrice: `${props.details.params.maxPrice}`.replace('.', ','),
            minArea: `${props.details.params.minArea}`.replace('.', ','),
            maxArea: `${props.details.params.maxArea}`.replace('.', ','),
            regions: [props.details.params.region.uuid],
            subRegions: [
              ...(props.details.params.subregion ? [props.details.params.subregion.uuid] : [])
            ],
            towns: props.details.params.town 
            ? [props.details.params.town.uuid]
            : (props.details.params.towns?.map(e => e.uuid) || []),
            districts: props.details.params.district 
            ? [props.details.params.district.uuid]
            : (props.details.params.districts?.map(e => e.uuid) ?? []),
            transactionTypes: [`${props.details.params.offerType}`],
            propertyTypes: [`${props.details.params.advertType}`],
            propertySubTypes: props.details.params.advertSubType != null ? [`${props.details.params.advertSubType}`] : [],
            propertyOtherParams: [],
            signature: props.details.signature || '',
            minPricePerArea: `${props.details.params.minPricePerArea ?? ''}`.replace('.', ','),
            maxPricePerArea: `${props.details.params.maxPricePerArea ?? ''}`.replace('.', ','),
            smsTemplate: props.details.smsMessageUuids,
            notificationEmails: props.details.notificationEmails,
            customTemplate: props.details.customMessage ? props.details.customMessage.template : '',
            includeAgencyOffers: false,
            includeOffersWithNoPhones: false,
            enableSpecialMode: false
          })
          setChosenPropertyTypes([`${props.details.params.advertType}`])
          setChosenRegions([props.details.params.region.uuid])
          setChosenSubregions(props.details.params.subregion ? [props.details.params.subregion.uuid] : [])
          setChosenTowns(props.details.params.town 
            ? [props.details.params.town.uuid]
            : (props.details.params.towns?.map(e => e.uuid) || []))
          setChosenDistricts(props.details.params.district 
            ? [props.details.params.district.uuid]
            : (props.details.params.districts?.map(e => e.uuid) ?? []))
          setChosenSmsTemplates(props.details.smsMessageUuids)
          setChosenNotificationEmails(props.details.notificationEmails)
          setChosenCustomSmsTemplate(props.details.customMessage ? props.details.customMessage.template : '')
      }
    }
    setIsCustomTemplateRemoved(false)
    setIsEditingCustomTemplate(false)
    setIsInitialPropertyTypeSet(false)
    setIsInitialLocationsSet(false)
    setTempCustomSmsTemplate('')
    formRef.current?.resetForm()
  }

  const handleSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      let sendData: any = {
        name: data.name === '' ? undefined : data.name.trim(),
        notificationEmails: data.notificationEmails,
        marketType: MarketType.PRIMARY,
        customMessage: (isCustomTemplateRemoved || data.customMessage == null) && data.customTemplate && data.customTemplate !== '' ? data.customTemplate : undefined,
        smsMessageUuids: data.smsTemplate,
        signature: !data.signature || data.signature === '' ? null : data.signature,
        removeCustomMessage: isCustomTemplateRemoved,
        params: {
          minArea: data.minArea === '' ? undefined : parseFloat(data.minArea.replace(',', '.')),
          maxArea: data.maxArea === '' ? undefined : parseFloat(data.maxArea.replace(',', '.')),
          minPrice: data.minPrice === '' ? undefined : parseFloat(data.minPrice.replace(',', '.')),
          maxPrice: data.maxPrice === '' ? undefined : parseFloat(data.maxPrice.replace(',', '.')),
          minPricePerArea: !data.minPricePerArea || data.minPricePerArea === '' ? undefined : parseFloat(data.minPricePerArea.replace(',', '.')),
          maxPricePerArea: !data.maxPricePerArea || data.maxPricePerArea === '' ? undefined : parseFloat(data.maxPricePerArea.replace(',', '.'))
        }
      }

      if (props.details.childAccount) {
        sendData.params.regionUuid = data.regions === '' ? undefined : data.regions
        sendData.params.subRegionUuid = data.subRegions === '' ? undefined : data.subRegions
        sendData.params.townUuids = data.towns === null ? undefined : data.towns
        sendData.params.districtUuids = data.districts == null ? undefined : data.districts
        sendData.params.advertType = data.propertyTypes === '' ? undefined : parseInt(data.propertyTypes)
        sendData.params.advertSubType = data.propertySubTypes === '' ? undefined : parseInt(data.propertySubTypes)
        sendData.params.offerType = data.transactionTypes === '' ? undefined : parseInt(data.transactionTypes)
      } else {
        sendData.params.regionUuids = data.regions.includes(AllSelectOptionsValue) ? regions.map(e => e.uuid) : data.regions
        sendData.params.subRegionUuids = data.subRegions.includes(AllSelectOptionsValue) ? subRegionList.map(e => e.value) : data.subRegions
        sendData.params.townUuids = data.towns.includes(AllSelectOptionsValue) ? townList.map(e => e.value) : data.towns
        sendData.params.districtUuids = data.districts.includes(AllSelectOptionsValue) ? districtList.map(e => e.value) : data.districts
        sendData.params.advertTypes = data.propertyTypes.includes(AllSelectOptionsValue) ? propertyTypes.map(e => parseInt(e.value)) : data.propertyTypes.map(e => parseInt(e))
        sendData.params.advertSubTypes = data.propertySubTypes.includes(AllSelectOptionsValue) ? plotTypes.map(e => parseInt(e.value)) : data.propertySubTypes.map(e => parseInt(e))
        sendData.params.offerTypes = data.transactionTypes.includes(AllSelectOptionsValue) ? transactionTypes.map(e => parseInt(e.value)) : data.transactionTypes.map(e => parseInt(e))
        sendData.params.advertOtherParams = data.propertyOtherParams.includes(AllSelectOptionsValue) ? plotOtherParams.map(e => e.value) : data.propertyOtherParams.map(e => e)
        sendData.params.includeAgencyOffers = data.includeAgencyOffers ?? false
        sendData.params.includeOffersWithNoPhones = data.includeOffersWithNoPhones ?? false
        sendData.params.specialMode = data.enableSpecialMode ?? false
      }

      await editDetails(props.details.uuid, sendData)
      if (props.afterSubmit != null) {
        await props.afterSubmit()
      }
      setIsSubmitting(false)
      props.onClose()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleEmailFormSubmit = (data: any, form: any) => {
    const newArray = [...chosenNotificationEmails]
    newArray.push(data.notificationEmail.trim())
    setChosenNotificationEmails(newArray)
    formRef.current.setFieldValue('notificationEmails', newArray)
    if (emailsFormRef.current) {
      emailsFormRef.current.resetForm()
    }
  }

  const resetCustomTemplateFormState = () => {
    setTempCustomSmsTemplate('')
    if (customTemplateFormRef.current) {
      customTemplateFormRef.current.resetForm()
    }
  }

  const closeLeaveEditDialog = (confirm: boolean) => {
    if (!confirm) {
      dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
    }
    setShowLeaveEditDialog(false)
    setInitialData()
  }

  return (
    <>
      <ConfirmLeaveDialog
        hidden={!showLeaveEditDialog}
        onClose={closeLeaveEditDialog}
        onConfirm={props.onClose}
      />

      <CustomDialog hidden={props.hidden}>
        {
          isLoading
          ? <div className={styles.loadingContainer}>
              <div className={styles.loading}>
                <CircularProgress size={48}/>
              </div>
            </div>
          : <div className={styles.container}>
              <div className={styles.topBarContainer}>
                <div className={styles.title}>
                  {t('edit_event_section_label')}
                </div>
                <div className={styles.closeButtonContainer}>
                  <span 
                    className={styles.closeButton}
                    onClick={() => {
                      dispatch(addCmdMessage(MessageEnum.ENABLE_DIALOG_COVER))
                      setShowLeaveEditDialog(true)
                    }}
                  >
                    <CloseIcon fontSize={'inherit'}/>
                  </span>
                </div>
              </div>
              <Formik
                innerRef={formRef}
                enableReinitialize
                validationSchema={props.details?.childAccount ? editSuperAdminV1ParamsForm(tEventDetails, smsTemplates) : editSuperAdminV2ParamsForm(tEventDetails, smsTemplates)}
                initialValues={editFormInitialFormValues}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <Form
                    onKeyDown={preventReturnKey}
                    className={styles.form}
                  >
                    <div className={styles.formSection}>
                      <p className={styles.fieldLabel}>
                        {t('event_name_field_label')}
                      </p>
                      <BaseInput 
                        count
                        maxLen={240}
                        name={'name'}
                        placeholder={t('event_name_field_placeholder')}
                        formikProps={formikProps}
                        value={formikProps.values.name}
                        error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                      />
                    </div>
                    <div className={styles.formSection}>
                      <div className={styles.sectionLabel}>
                        {t('advert_search_params_section_label')}
                      </div>
                      <div className={styles.rowContainer}>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('location_field_label')}
                          </p>
                          <div 
                            className={`${styles.clearButton}`} 
                            onClick={() => {
                              setChosenRegions([])
                              formikProps.setFieldValue('regions', '')
                            }}
                          >
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            {
                              ...(
                                !props.details?.childAccount
                                ? {
                                    isMulti: true,
                                    includeAllOption: true,
                                    includeAllOptionLabel: 'Cała Polska'
                                  }
                                : {}
                              )
                            }
                            instanceId={'region-field'}
                            name={'regions'}
                            placeholder={'Województwa'}
                            options={
                              regions.map(e => {
                                return {
                                  value: e.uuid,
                                  label: e.name
                                }
                              }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))
                            }
                            value={chosenRegions}
                            setValue={setChosenRegions}
                            formikProps={formikProps}
                            error={formikProps.touched.regions && Boolean(formikProps.errors.regions)}
                          />
                        </div>
                        <div className={styles.fieldWithoutLabel}>
                          <div 
                            hidden={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            className={`${styles.clearButton}`} 
                            onClick={()=> {
                              setChosenSubregions([])
                              formikProps.setFieldValue('subRegions', '')
                            }}
                          >
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            {
                              ...(
                                !props.details?.childAccount
                                ? {
                                    isMulti: true,
                                    includeAllOption: true,
                                  }
                                : {}
                              )
                            }
                            instanceId={'subRegion-field'}
                            disabled={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            name={'subRegions'}
                            placeholder={'Powiaty'}
                            options={subRegionList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={chosenSubregions}
                            setValue={setChosenSubregions}
                            formikProps={formikProps}
                            warning={chosenSubregions != null && chosenSubregions.length !== 0}
                            warningMessage={t('subregion_warning_label')}
                            error={formikProps.touched.subRegions && Boolean(formikProps.errors.subRegions)}
                          />
                        </div>
                        <div className={styles.fieldWithoutLabel}>
                          <div 
                            hidden={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            className={`${styles.clearButton}`} 
                            onClick={()=> {
                              setChosenTowns([])
                              formikProps.setFieldValue('towns', [])
                            }}
                          >
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            instanceId={'town-field'}
                            disabled={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            name={'towns'}
                            isMulti
                            includeAllOption
                            placeholder={'Miasta/Miejscowości'}
                            options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={chosenTowns}
                            setValue={setChosenTowns}
                            formikProps={formikProps}
                            error={formikProps.touched.towns && Boolean(formikProps.errors.towns)
                            }
                          />
                        </div>
                        <div className={styles.lastFieldWithoutLabel}>
                          <div 
                            hidden={chosenTowns.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            className={`${styles.clearButton}`} 
                            onClick={()=> {
                              setChosenDistricts([])
                              formikProps.setFieldValue('districts', [])
                            }}
                          >
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            instanceId={'district-field'}
                            disabled={chosenTowns.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                            name={'districts'}
                            isMulti
                            includeAllOption
                            placeholder={'Dzielnice (opcjonalnie)'}
                            options={districtList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={chosenDistricts}
                            setValue={setChosenDistricts}
                            formikProps={formikProps}
                            error={formikProps.touched.districts && Boolean(formikProps.errors.districts)}
                          />
                        </div>
                      </div>
                      <div className={styles.rowContainer}>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('transaction_type_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('transactionTypes', !props.details?.childAccount ? [] : '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            {
                              ...(
                                !props.details?.childAccount
                                ? {
                                    isMulti: true,
                                    includeAllOption: true,
                                  }
                                : {}
                              )
                            }
                            instanceId={'transaction-type-field'}
                            name={'transactionTypes'}
                            placeholder={'Wybierz typy transakcji'}
                            options={transactionTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={formikProps.values.transactionTypes}
                            formikProps={formikProps}
                            error={formikProps.touched.transactionTypes && Boolean(formikProps.errors.transactionTypes)}
                          />
                        </div>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('property_type_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            setChosenSmsTemplates([])
                            setChosenPropertyTypes(!props.details?.childAccount ? [] : '')
                            formikProps.setFieldValue('propertyTypes', !props.details?.childAccount ? [] : '')
                            formikProps.setFieldValue('smsTemplate', [])
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            {
                              ...(
                                !props.details?.childAccount
                                ? {
                                    isMulti: true,
                                    includeAllOption: true,
                                  }
                                : {}
                              )
                            }
                            instanceId={'property-type-field'}
                            name={'propertyTypes'}
                            placeholder={'Wybierz typy nieruchomości'}
                            options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={chosenPropertyTypes}
                            setValue={setChosenPropertyTypes}
                            formikProps={formikProps}
                            error={formikProps.touched.propertyTypes && Boolean(formikProps.errors.propertyTypes)}
                          />
                        </div>
                        {
                          (!props.details?.childAccount && (formikProps.values.propertyTypes.includes(`${AdvertType.PLOT}`) || formikProps.values.propertyTypes.includes(AllSelectOptionsValue)))
                          || (props.details?.childAccount && formikProps.values.propertyTypes === `${AdvertType.PLOT}`)
                          ? <>
                              <div className={styles.fieldWithLabel}>
                                <p className={styles.fieldLabel}>
                                  {t('plot_type_field_label')}
                                </p>
                                <div className={`${styles.clearButton}`} onClick={()=> {
                                  formikProps.setFieldValue('propertySubTypes', !props.details?.childAccount ? [] : '')
                                }}>
                                  {t('clear_field_button_label')}
                                </div>
                                <CustomSelectField
                                  {
                                    ...(
                                      !props.details?.childAccount
                                      ? {
                                          isMulti: true,
                                          includeAllOption: true,
                                        }
                                      : {}
                                    )
                                  }
                                  instanceId={'property-subtype-field'}
                                  name={'propertySubTypes'}
                                  placeholder={t('plot_type_field_placeholder')}
                                  options={plotTypes}
                                  value={formikProps.values.propertySubTypes}
                                  formikProps={formikProps}
                                  error={formikProps.touched.propertySubTypes && Boolean(formikProps.errors.propertySubTypes)}
                                />
                              </div>
                              {
                                !props.details?.childAccount
                                ? <div className={styles.lastFieldWithLabel}>
                                    <p className={styles.fieldLabel}>
                                      {t('other_params_label')}
                                    </p>
                                    <div className={`${styles.clearButton}`} onClick={()=> {
                                      formikProps.setFieldValue('propertyOtherParams', [])
                                    }}>
                                      {t('clear_field_button_label')}
                                    </div>
                                    <CustomSelectField
                                      instanceId={'property-otherParams-field'}
                                      name={'propertyOtherParams'}
                                      isMulti
                                      includeAllOption
                                      placeholder={'Wybierz parametry'}
                                      options={plotOtherParams}
                                      value={formikProps.values.propertyOtherParams}
                                      formikProps={formikProps}
                                      error={formikProps.touched.propertyOtherParams && Boolean(formikProps.errors.propertyOtherParams)}
                                    />
                                  </div>
                                : null
                              }
                              
                            </>
                          : <div className={styles.flexSpacer}></div>
                        }
                      </div>
                      <div className={styles.rowContainer}>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('min_price_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('minPrice', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'minPrice'}
                            placeholder={t('min_price_field_placeholder')}
                            formikProps={formikProps}
                            value={formikProps.values.minPrice}
                            monetaryValue
                            error={formikProps.touched.minPrice && Boolean(formikProps.errors.minPrice)}
                          />
                        </div>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('max_price_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('maxPrice', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'maxPrice'}
                            placeholder={t('max_price_field_placeholder')}
                            formikProps={formikProps}
                            value={formikProps.values.maxPrice}
                            monetaryValue
                            error={formikProps.touched.maxPrice && Boolean(formikProps.errors.maxPrice)}
                          />
                        </div>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('min_area_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('minArea', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'minArea'}
                            placeholder={t('min_area_field_placeholder')}
                            formikProps={formikProps}
                            monetaryValue
                            value={formikProps.values.minArea}
                            error={formikProps.touched.minArea && Boolean(formikProps.errors.minArea)}
                          />
                        </div>
                        <div className={styles.lastFieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('max_area_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('maxArea', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'maxArea'}
                            placeholder={t('max_area_field_placeholder')}
                            formikProps={formikProps}
                            monetaryValue
                            value={formikProps.values.maxArea}
                            error={formikProps.touched.maxArea && Boolean(formikProps.errors.maxArea)}
                          />
                        </div>
                      </div>
                      <div className={styles.rowContainer}>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('min_price_per_area_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('minPricePerArea', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'minPricePerArea'}
                            placeholder={t('min_price_per_area_field_placeholder')}
                            formikProps={formikProps}
                            value={formikProps.values.minPricePerArea}
                            monetaryValue
                            error={formikProps.touched.minPricePerArea && Boolean(formikProps.errors.minPricePerArea)}
                          />
                        </div>
                        <div className={styles.fieldWithLabel}>
                          <p className={styles.fieldLabel}>
                            {t('max_price_per_area_field_label')}
                          </p>
                          <div className={`${styles.clearButton}`} onClick={()=> {
                            formikProps.setFieldValue('maxPricePerArea', '')
                          }}>
                            {t('clear_field_button_label')}
                          </div>
                          <BaseNumberInput
                            name={'maxPricePerArea'}
                            placeholder={t('max_price_per_area_field_placeholder')}
                            formikProps={formikProps}
                            value={formikProps.values.maxPricePerArea}
                            monetaryValue
                            error={formikProps.touched.maxPricePerArea && Boolean(formikProps.errors.maxPricePerArea)}
                          />
                        </div>
                      </div>
                      {
                        props.details?.childAccount
                        ? null
                        : <>
                            <div className={[
                              styles.rowContainer,
                              styles.onlySwitchRow
                            ].join(' ')}>
                              <div className={styles.switchContainer}>
                                <CustomSwitch 
                                  name={'includeAgencyOffers'}
                                  checked={formikProps.values.includeAgencyOffers}
                                  formikProps={formikProps}
                                />
                                <div className={styles.switchLabel}>
                                  {t('include_agency_offers_switch_label')}
                                </div>
                              </div>
                            </div>
                            <div className={[
                              styles.rowContainer,
                              styles.onlySwitchRow
                            ].join(' ')}>
                              <div className={styles.switchContainer}>
                                <CustomSwitch 
                                  disabled={account?.allowedSendingSms}
                                  name={'includeOffersWithNoPhones'}
                                  checked={formikProps.values.includeOffersWithNoPhones}
                                  formikProps={formikProps}
                                />
                                <div className={styles.switchLabel}>
                                  {t('include_offers_with_no_phones_switch_label')}
                                  <span className={styles.warningLabel}>
                                    {t('include_offers_with_no_phones_switch_warning_label')}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className={[
                              styles.rowContainer,
                              styles.specialModeRow
                            ].join(' ')}>
                              <div className={styles.specialModeIcon}>
                                <img
                                  src={specialModeIcon}
                                  alt={'special-mode'}
                                  width={50}
                                  height={50}
                                />
                              </div>
                              <div className={styles.switchContainer}>
                                <CustomSwitch 
                                  name={'enableSpecialMode'}
                                  checked={formikProps.values.enableSpecialMode}
                                  formikProps={formikProps}
                                />
                                <div className={styles.specialModeSwitchLabel}>
                                  {t('enable_special_mode_switch_label')}
                                </div>
                              </div>
                            </div>
                          </>
                      }
                      
                    </div>
                    <div className={styles.formSection}>
                      <div className={styles.sectionLabel}>
                        {t('sms_content_settings_section_label')}
                      </div>
                      <div className={styles.formSectionWarningLabel}>
                        {t('sms_settings_warning_label')}
                      </div>
                      <div className={styles.signatureField}>
                        <p className={styles.fieldLabel}>
                          {t('signature_field_label')}
                        </p>
                        <BaseInput 
                          count
                          name={'signature'}
                          maxLen={MSG_SIGNATURE_LEN}
                          inputPattern={smsMessagePattern}
                          placeholder={t('signature_field_placeholder')}
                          formikProps={formikProps}
                          value={formikProps.values.signature}
                          error={formikProps.touched.signature && Boolean(formikProps.errors.signature)}
                        />
                      </div>
                      <div className={styles.messageSection}>
                        <div className={styles.fieldLabel}>
                          {
                            evalStringTemplate(
                              t('sms_content_field_label'), 
                              {
                                cur: formikProps.values['smsTemplate'].length,
                                max: filteredSmsTemplates.length,
                                custom: chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' ? ' + wiadomość własna' : ''
                              }
                            )
                          }
                        </div>
                        <div 
                          className={[
                            styles.fieldSubLabel,
                            styles.availableTemplatesLabelMargin
                          ].join(' ')}
                        >
                          {t('available_templates_label')}
                        </div>
                        {
                          filteredSmsTemplates.length === 0
                          ? <div className={styles.availableTemplatesPlaceholder}>
                              {t('no_templates_available_label')}
                            </div>
                          : filteredSmsTemplates.map(e => (
                            <div className={styles.messageTemplateContainer}>
                              <Checkbox 
                                name={'smsTemplate'}
                                checked={chosenSmsTemplates.includes(e.uuid)}
                                disabled={!chosenSmsTemplates.includes(e.uuid) && (chosenSmsTemplates.length >= filteredSmsTemplates.length || e.removed || (Array.isArray(chosenPropertyTypes) && (!chosenPropertyTypes.includes(`${e.advertType}`) && !chosenPropertyTypes.includes(AllSelectOptionsValue))) || (!Array.isArray(chosenPropertyTypes) && `${e.advertType}` !== chosenPropertyTypes))}
                                onBlur={() => {
                                  formikProps.setFieldTouched('smsTemplate')
                                }}
                                onChange={(checked) => {
                                  formikProps.setFieldTouched('smsTemplate')
                                  if (!checked && formikProps.values['smsTemplate'].includes(e.uuid)) {
                                    const indexToDelete = formikProps.values['smsTemplate'].indexOf(e.uuid)
                                    const newArray = [...formikProps.values['smsTemplate']]
                                    newArray.splice(indexToDelete, 1)
                                    formikProps.setFieldValue('smsTemplate', newArray)
                                    setChosenSmsTemplates(newArray)
                                  } else if (checked  && !formikProps.values['smsTemplate'].includes(e.uuid)) {
                                    formikProps.setFieldValue('smsTemplate', [
                                      ...formikProps.values['smsTemplate'],
                                      e.uuid
                                    ])
                                    setChosenSmsTemplates([
                                      ...formikProps.values['smsTemplate'],
                                      e.uuid
                                    ])
                                  }
                                }}
                                borderRadius={3}
                                size={15}
                                containerStyle={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  marginTop: '5px'
                                }}
                                style={{
                                  marginTop: '2px'
                                }}
                                icon={
                                  <div className={styles.templateCheckboxIcon}>
                                    <Check fontSize={'inherit'} color={'inherit'}/>
                                  </div>
                                }
                                borderColor={Colors.CHECKBOX_BORDER}
                                labelStyle={{
                                  fontFamily: 'AvenirBook',
                                  color: Colors.LIGHT_GRAY_TEXT,
                                  fontSize: '15px',
                                  paddingLeft: '5px',
                                  whiteSpace: 'break-spaces'
                                }}
                                label={
                                  <>
                                    {e.template}{
                                      e.template.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                        ? <span className={styles.messageCountLabel}>
                                            {` (${Math.ceil((e.template.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}
                                          </span> 
                                        : null
                                    }
                                  </>
                                }
                                />
                            </div>
                          ))
                        }
                        {
                          formikProps.touched.smsTemplate && Boolean(formikProps.errors.smsTemplate)
                          ? <div 
                              className={[
                                styles.errorLabel,
                                styles.errorLabelFloat
                              ].join(' ')}
                            >
                              <ErrorMessage name={'smsTemplate'} />
                            </div> 
                          : null
                        }
                        {
                          chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' && !isEditingCustomTemplate
                          ? <>
                              <div 
                                className={[
                                  styles.fieldSubLabel,
                                  styles.customTemplatesLabelMargin
                                ].join(' ')}
                              >
                                {t('custom_templates_label')}
                              </div>
                              <div className={styles.customTemplateContainer}>
                                <div className={styles.customTemplateText}>
                                  {chosenCustomSmsTemplate}{
                                    chosenCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                      ? <span className={styles.messageCountLabel}>
                                          {` (${Math.ceil((chosenCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}
                                        </span> 
                                      : null
                                  }
                                </div>
                                <div
                                  className={styles.editCustomTemplate}
                                  onClick={() => {
                                    setIsEditingCustomTemplate(true)
                                    setTempCustomSmsTemplate(chosenCustomSmsTemplate)
                                  }}
                                >
                                  <EditOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                                </div>
                                <div
                                  className={styles.removeCustomTemplate}
                                  onClick={() => {
                                    const templateUuid = props.details?.customMessage?.uuid
                                    if (!isCustomTemplateRemoved && templateUuid != null && templateUuid !== '') {
                                      setIsCustomTemplateRemoved(true)
                                    }
                                    setChosenCustomSmsTemplate('')
                                    formikProps.setFieldValue('customTemplate', '')
                                  }}
                                >
                                  <img src={trashIcon} alt={'remove-item'} />
                                </div>
                              </div>
                            </>
                          : <>
                              <Formik
                                innerRef={customTemplateFormRef}
                                validateOnMount={true}
                                enableReinitialize
                                initialValues={{
                                  customTemplate: tempCustomSmsTemplate
                                }}
                                onSubmit={(data) => {
                                  setChosenCustomSmsTemplate(data.customTemplate)
                                  setIsEditingCustomTemplate(false)
                                  formikProps.setFieldValue('customTemplate', data.customTemplate)
                                  resetCustomTemplateFormState()
                                }}
                              >
                                {(customTemplateFormikProps) => (
                                  <Form
                                    onKeyDown={preventReturnKey}
                                    className={styles.customTemplateForm}
                                  >
                                    <div className={styles.customTemplateFormFields}>
                                      <div className={styles.customTempalteTextField}>
                                        <p className={styles.fieldLabel}>
                                          {t('add_custom_template_label')}
                                        </p>
                                        <BaseInput 
                                          count
                                          maxLen={
                                            TEMPLATE_MAX_MSG > 1
                                            ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                                            : TEMPLATE_MAX_MSG
                                          }
                                          rows={4}
                                          name={'customTemplate'}
                                          placeholder={t('add_custom_template_placeholder')}
                                          formikProps={customTemplateFormikProps}
                                          inputPattern={smsMessagePattern}
                                          value={tempCustomSmsTemplate}
                                          setValue={setTempCustomSmsTemplate}
                                          error={customTemplateFormikProps.touched.customTemplate && Boolean(customTemplateFormikProps.errors.customTemplate)}
                                        />
                                      </div>
                                    </div>
                                    <div className={styles.customTemplateFormMessageCount}>
                                      {
                                        tempCustomSmsTemplate.length > 0 
                                        ? <>
                                            {`Liczba wiadomości:\u00a0`}{
                                              tempCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN
                                                ? <span className={styles.messageCountLabel}>
                                                    {Math.ceil((tempCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)}
                                                  </span>
                                                : 1
                                            }
                                          </> 
                                        : '\u00A0'
                                      }
                                    </div>
                                    <div className={styles.customTemplateFormButtonContainer}>
                                      <LoadingButton 
                                        type='submit'
                                        onClick={(ev) => {
                                          ev.preventDefault()
                                          customTemplateFormikProps.submitForm()
                                        }}
                                        style={{
                                          width: '150px',
                                          maxWidth: '150px',
                                          height: '30px',
                                          marginTop: '5px',
                                          marginLeft: '5px',
                                          backgroundColor: '#438737'
                                        }}
                                      >
                                        <div className={styles.customTemplateFormButtonContent}>
                                          {
                                            isEditingCustomTemplate 
                                            ? <>
                                                <div className={styles.customTemplateFormButtonIcon}>
                                                  <EditOutlinedIcon fontSize={'inherit'}/>
                                                </div>
                                                <div>
                                                  Zapisz
                                                </div>
                                              </>
                                            : <>
                                                <div className={styles.customTemplateFormButtonIcon}>
                                                  <Check fontSize={'inherit'}/>
                                                </div>
                                                <div>
                                                  Dodaj
                                                </div>
                                              </>
                                          }
                                        </div>
                                      </LoadingButton>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </> 
                        }
                      </div>
                    </div>
                    <div className={styles.emailNotificationSection}>
                      <div className={styles.sectionLabel}>
                        {t('email_notifications_search_params_section_label')}
                      </div>
                      <div className={styles.emailNotificationListLabel}>
                        <p className={styles.fieldLabel}>
                          {t('notifications_emails_list_field_label')}
                        </p>
                        {
                          chosenNotificationEmails.length === 0
                          ? <div className={styles.emailNotificationListPlaceholder}>
                              {t('no_emails_added_label')}
                            </div>
                          : <div className={styles.emailNotificationList}>
                              {
                                chosenNotificationEmails.map((e: string, idx: number) => {
                                  return (
                                    <div className={styles.emailNotificationListTile}>
                                      <div>
                                        {e}
                                      </div>
                                      <div className={styles.emailNotificationListTileButtonContainer}>
                                        <span 
                                          className={styles.emailNotificationListTileButton}
                                          onClick={() => {
                                            const newArray = [...chosenNotificationEmails]
                                            newArray.splice(idx, 1)
                                            setChosenNotificationEmails(newArray)
                                            formikProps.setFieldValue('notificationEmails', newArray)
                                          }}
                                        >
                                          <CloseIcon fontSize={'inherit'} color={'inherit'} />
                                        </span>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                        }
                      </div>
                      <div className={styles.emailNotificationField}>
                        <p className={styles.fieldLabel}>
                          {t('notification_email_field_label')}
                        </p>
                        <Formik
                          innerRef={emailsFormRef}
                          validateOnMount={true}
                          enableReinitialize
                          validationSchema={addNotificationEmail(tEventDetails, chosenNotificationEmails)}
                          initialValues={{
                            notificationEmail: ''
                          }}
                          onSubmit={handleEmailFormSubmit}
                        >
                          {(emailsFormikProps) => (
                            <Form
                              onKeyDown={preventReturnKey}
                              className={styles.emailNotificationForm}
                            >
                              <div className={styles.emailNotificationFormContent}>
                                <div className={styles.emailNotificationFormField}>
                                  <BaseInput 
                                    name={'notificationEmail'}
                                    placeholder={t('notification_email_placeholder')}
                                    formikProps={emailsFormikProps}
                                    value={emailsFormikProps.values.notificationEmail}
                                    error={emailsFormikProps.touched.notificationEmail && Boolean(emailsFormikProps.errors.notificationEmail)}
                                  />
                                </div>
                                <LoadingButton 
                                  children={
                                    <div className={styles.emailNotificationFormButton}>
                                      <div className={styles.emailNotificationFormButtonIcon}>
                                        <Check fontSize={'inherit'}/>
                                      </div>
                                      <div>
                                        Dodaj
                                      </div>
                                    </div>
                                  }
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    emailsFormikProps.submitForm()
                                  }}
                                  style={{
                                    width: '150px',
                                    maxWidth: '150px',
                                    height: '30px',
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    backgroundColor: '#438737'
                                  }}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    <div className={styles.submitButtonContainer}>
                      <LoadingButton 
                        text={t('save_button_label')}
                        type='submit'
                        loading={isSubmitting}
                        style={{
                          width: '150px',
                          maxWidth: '150px',
                          height: '40px'
                        }}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
        }
      </CustomDialog>
    </>
  )
}

export default EditSuperEventDialog
