import { ErrorMessage, FormikProps } from 'formik'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Colors } from '../../../types/colors'
import { customAsyncCreatableSelectFieldStyle } from './customAsyncSelectFieldStyle'

export type AsyncSelectOption = {
  label: string
  value: string
}

type Props = {
  id?: string
  instanceId: string
  name: string
  disabled?: boolean
  isMulti?: boolean
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  warningLabelClassName?: string
  warningMessage?: string
  warning?: boolean
  error?: boolean
  value?: AsyncSelectOption | AsyncSelectOption[]
  setValue?: React.Dispatch<React.SetStateAction<AsyncSelectOption[]>> | React.Dispatch<React.SetStateAction<AsyncSelectOption>>
  options: any[]
  placeholder?: string
  formikProps?: FormikProps<any>
  customSelectStyles?: object
  noErrorLabel?: boolean
  loadOptions: (inputValue: string) => Promise<{label: string, value: string}[]> 
}

const renderSelectMenuListScrollbar = (props: any) => {
  const len = props.children.length
  return (
    <div style={{ 
      height: len ? (len < 10 ? `${len * 33}px` : '300px') : '33px', 
      backgroundColor: Colors.SELECT_LIST_BACKGROUND,
      color: Colors.SELECT_OPTION_DEFAULT_TEXT
    }}>
      <Scrollbars 
        universal={true} 
        renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: Colors.SELECT_FIELD_SCROLLBAR, width: '4px', opacity: '0.5'}}/>}
      >
        {props.children}
      </Scrollbars>
    </div>
  )
}

const MultiOption = props => {
  const t = useTranslation("forms").t
  return (
    <div>
      <components.Option {...props}>
        {
          !props.data.label.startsWith(t('new_position_message'))
          ? <>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />{" "}
            </> 
         : null
        }
        
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const label = (getValue() as any[]).map(e => e.label).join(', ')
  return (
    <components.ValueContainer {...props as any}>
      <div style={{
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}>
      {`${nbValues > 1 ? `(${nbValues}) ` : ''}${label}`}
      </div>
      {nbValues === 0 ? children.slice(-2, -1) : null}
      {children.slice(-1)}
    </components.ValueContainer>
  )
}

const CustomAsyncSelectField = (props: Props) => {
  const [value, setValue] = React.useState<AsyncSelectOption | AsyncSelectOption[]>(null)

  const t = useTranslation("forms").t
  const classes = customAsyncCreatableSelectFieldStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()

  const customSelectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      flex: 1,
      "@media (max-width: 600px)": {
        width: '100%'
      }
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      borderRadius: 100
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 100
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      width: 0
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      fontSize: '15px',
      padding: 0,
      paddingLeft: 14,
      paddingTop: 8,
      paddingBottom: 8,
      fontFamily: 'AvenirBook',
      ...(props.isMulti ? { maxWidth: 'calc(100% - 40px)', flexWrap: 'nowrap' } : {})
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: Colors.SELECT_LIST_BACKGROUND,
      color: state.data.color || Colors.SELECT_OPTION_DEFAULT_TEXT,
      padding: 5,
      paddingLeft: state.data.level ? state.data.level * 20 + 5 : 5,
      fontFamily: 'AvenirBook',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: Colors.SELECT_OPTION_HOVER
      }
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      opacity: state.isDisabled ? 0.4 : 1,
      backgroundColor: state.isDisabled ? 'rgba(142,156,212,0.01)' : 'rgba(142,156,212,0.1)',
      borderRadius: '5px',
      border: `1px solid ${props.error ? 'red' : Colors.INPUT_BORDER}`,    
      boxShadow: 'none'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.placeholder ? Colors.SELECT_OPTION_DEFAULT_PLACEHOLDER : Colors.SELECT_OPTION_DEFAULT_TEXT,
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      fontSize: '15px',
      fontFamily: 'AvenirBook',
      // ...(props.isMulti ? { marginLeft: '5px' } : {})
    })
  }

  const renderNoOptionMessage = (props: any) => {
    return (
      <div style={{
        fontFamily: 'AvenirBook',
        textAlign: 'center',
        color: Colors.LIGHT_GRAY_TEXT,
        paddingTop: '6px'
      }}>
        {t('no_options_select_message')}
      </div>
    )
  }

  return (
    <div style={{ 
      minHeight: props.noErrorLabel ? '43px' : '62px',
      marginTop: 0
    }}>
      <AsyncSelect 
        onBlur={()=> {
          props.formikProps?.setFieldTouched(props.name)
        }}
        instanceId={props.instanceId}
        backspaceRemovesValue={false}
        name={props.name}
        defaultOptions={props.options}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        loadOptions={props.loadOptions}
        closeMenuOnSelect={props.isMulti != null ? !props.isMulti : true}
        value={props.value ?? value}
        formatCreateLabel={(inputValue) => `${t('new_position_message')} ${inputValue}`}
        onChange={(sel: AsyncSelectOption | AsyncSelectOption[]) => {
          props.formikProps?.setFieldTouched(props.name)
          if (Array.isArray(sel)) {
            props.formikProps?.setFieldValue(props.name, sel ?? [])
            if (props.setValue) {
              ;(props.setValue as React.Dispatch<React.SetStateAction<AsyncSelectOption[]>>)(sel ?? [])
            } else {
              ;(setValue as React.Dispatch<React.SetStateAction<AsyncSelectOption[]>>)(sel ?? [])
            }
          } else {
            props.formikProps?.setFieldValue(props.name, sel)
            if (props.setValue) {
              ;(props.setValue as React.Dispatch<React.SetStateAction<AsyncSelectOption>>)(sel)
            } else {
              ;(setValue as React.Dispatch<React.SetStateAction<AsyncSelectOption>>)(sel)
            }
          }
          
        }}
        placeholder={props.placeholder}
        styles={props.customSelectStyles || customSelectStyles}
        components={{
            MenuList: renderSelectMenuListScrollbar,
            NoOptionsMessage: renderNoOptionMessage,
            ...(props.isMulti ? { Option: MultiOption, ValueContainer: ValueContainer } : {})
        }}
    />
      {
        !props.error && props.warning && props.warningMessage ? <div className={props.warningLabelClassName || classes.warningLabel} style={{
          float: 'left'
        }}>
          {props.warningMessage}
        </div> : null
      }
      {
        props.error && props.name ? <div className={props.errorLabelClassName || classes.errorLabel} style={{
          float: 'left'
        }}>
          <ErrorMessage name={props.name} />
        </div> : null
      }
    </div>
  )
}

export default CustomAsyncSelectField
