import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { version } from "../package.json"
import "./App.css"
import AccountActivation from './components/auth/AccountActivation/AccountActivation'
import ResetPasswordFinish from "./components/auth/ResetPassword/ResetPasswordFinish"
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest/ResetPasswordRequest"
import SignIn from "./components/auth/SignIn/SignIn"
import VerifyEmailChange from './components/auth/VerifyEmailChange/VerifyEmailChange'
import Dashboard from './components/Dashboard/Dashboard'
import Messages from './components/Messages/Messages'
import { Colors } from "./types/colors"
import ResendActivationEmail from './components/auth/ResendActivationEmail/ResendActivationEmail'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.MAIN,
      light: Colors.MAIN_LIGHT,
    },
    secondary: {
      main: Colors.SECONDARY,
    },
    background: {
      default: Colors.BACKGROUND,
    },
  },
  typography: {
    fontFamily: `"AvenirBook", "Roboto", "Arial", sans-serif`,
    allVariants: {
      color: Colors.MAIN_FONT_COLOR,
    },
    h1: {
      fontSize: "40px",
    },
    h2: {
      fontSize: "27px",
    },
  },
})

export const ROUTER_BASE_NAME = "/"

function App() {
  React.useEffect(() => {
    console.log(`SMS Serwis v. ${version}`)
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={ROUTER_BASE_NAME}>
          <Switch>
            <Route path="/login" exact component={SignIn} />
            <Route
              path="/password/reset/finish"
              component={ResetPasswordFinish}
            />
            <Route
              path="/password/reset"
              component={ResetPasswordRequest}
            />
            <Route
              path="/account/activation/resend"
              component={ResendActivationEmail}
            />
            <Route
              path="/account/activate"
              component={AccountActivation}
            />
            <Route
              path="/account/email/verify"
              component={VerifyEmailChange}
            />
            <Route path="/" component={Dashboard} />
          </Switch>
          <Messages />
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
