import CloseIcon from '@material-ui/icons/Close'
import LoadingButton from '../../common/LoadingButton/LoadingButton'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../CustomDialog/CustomDialog'

type Props = {
  hidden?: boolean
  onClose: (boolean) => void
  onConfirm: () => void
}

const ConfirmLeaveDialog = (props: Props) => {
  const t = useTranslation('forms').t

  return (
    <CustomDialog
      hidden={props.hidden}
      style={{
        position: 'fixed',
        background: 'white',
        maxWidth: '650px',
        minHeight: '100px',
        border: '1px solid #979797',
        borderRadius: '1px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      zIndex={3001}
    >
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_leave_edit_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '30px',
            marginTop: '-2px',
            marginLeft: '30px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                props.onClose(false)
              }}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 30px',
            fontFamily: 'AvenirBook',
            fontSize: '16px'
          }}
        >
          {t('confirm_leave_edit_description')}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              props.onClose(false)
            }}
          />
          <LoadingButton 
            text={'Tak,\u00A0wyjdź'}
            style={{
              height: '35px',
              width: '130px',
              flexGrow: 0
            }}
            onClick={() => {
              props.onConfirm()
              props.onClose(true)
            }}
          />
        </div>
      </div>
    </CustomDialog>
    
  )
}

export default ConfirmLeaveDialog
