import { ColumnData } from '../CustomTable/CustomTable'

export const tableColumnData: ColumnData[] = [
  {
    label: 'Konto',
    name: '0',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  },
  {
    label: 'Nazwa\u00A0eventu',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    headerAlign: 'left',
    contentAlign: 'left',
    width: '33%'
  }, {
    label: 'Wysłane\u00A0SMSy',
    name: '3',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Liczba odpowiedzi',
    name: '4',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Konwersja',
    name: '5',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Status\u00A0eventu',
    name: 'active',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '5%'
  }
]

export const childAccountTableColumnData: ColumnData[] = [
  {
    label: 'Nazwa\u00A0eventu',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    headerAlign: 'left',
    contentAlign: 'left',
    width: '48%'
  }, {
    label: 'Wysłane\u00A0SMSy',
    name: '2',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Liczba odpowiedzi',
    name: '3',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Konwersja',
    name: '4',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Status\u00A0eventu',
    name: 'active',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '5%'
  }
]