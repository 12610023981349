import { Colors } from '../../types/colors'
import { customTableStyle } from './customTableStyle'
import { CircularProgress } from '@material-ui/core'
import { SortData, SortDirection } from './util'
import { RowData } from '../../utils/sort'
import sortIcon from '../../assets/images/sort.webp'
import sortAscIcon from '../../assets/images/sort_asc.webp'
import sortDescIcon from '../../assets/images/sort_desc.webp'

// TODO add sort icon to table headers

export type ColumnData = {
  label: string
  name?: string
  sortable?: boolean
  headerAlign: 'center' | 'left' | 'right'
  contentAlign: 'center' | 'left' | 'right'
  contentPadding?: {
    left?: string
    right?: string
    top?: string
    bottom?: string
  }
  width: string
  additionalContentStyle?: React.CSSProperties
}

type Props = {
  reloadingData?: boolean
  columnData?: ColumnData[]
  contentFontSize?: string
  headerFontSize?: string
  content: RowData[]
  placeholder?: React.ReactNode
  sort?: boolean
  sortData?: SortData | null
  sortSetter?: React.Dispatch<React.SetStateAction<SortData | null>>
}

const CustomTable = (props: Props) => {
  const classes = customTableStyle()
  
  return (
    <div style={{
      position: 'relative'
    }}>
      {
        props.reloadingData ? <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.3)'
        }}>
          <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white'
          }}>
            <CircularProgress size={48} color={'inherit'}/>
          </div>
        </div> : null
      }
      <table style={{
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0 5px',
        marginTop: '5px'
      }}>
        <thead>
          <tr>
            {
              props.columnData.map((e, idx) => (
                <th 
                  className={classes.tableHeaderCell} 
                  style={{
                    width: e.width,
                    textAlign: (e.headerAlign || 'center') as 'center' | 'left' | 'right',
                    paddingLeft: idx === 0 ? '15px' : (e.sortable && e.headerAlign === 'center' ? '10px' : 0),
                    fontSize: props.headerFontSize ?? '13px',
                    paddingRight: '2px',
                    ...(props.sort && e.sortable ? { cursor: 'pointer' } : {})
                  }}
                  onClick={() => {
                    if (e.sortable && props.sortSetter) {
                      if ((props.sortData && props.sortData.column !== e.name) || props.sortData === null) {
                        props.sortSetter({
                          column: e.name,
                          order: SortDirection.ASC,
                        })
                      } else if (props.sortData) {
                        props.sortSetter(props.sortData.order === SortDirection.ASC ? {
                          column: e.name,
                          order: SortDirection.DESC
                        } : { column: '', order: SortDirection.NONE, default: true})
                      }
                    }
                  }}
                >
                  <div 
                    style={{
                      ...(
                        props.sort && e.sortable 
                        ? {
                            display: 'flex',
                            justifyContent: (e.headerAlign === 'center' ? 'center' : e.headerAlign === 'left' ? 'flex-star ': 'flex-end'),
                            alignItems: 'center',
                          } 
                        : {}
                      )
                    }}
                  >
                    <div
                      style={{
                        marginTop: '4px',
                        flexBasis: 0,
                        whiteSpace: 'break-spaces'
                        // whiteSpace: 'nowrap'
                      }}
                    >
                      {e.label}
                    </div>
                    {
                      props.sort && e.sortable
                      ? <div style={{
                          width: '9px',
                          height: '12px',
                          marginLeft: '5px'
                        }}>
                          <img 
                            src={
                              props.sortData?.column !== e.name 
                              ? sortIcon
                              : props.sortData?.order === SortDirection.ASC
                                ? sortAscIcon
                                : props.sortData?.order === SortDirection.DESC
                                  ? sortDescIcon
                                  : sortIcon
                            }
                            width={'10px'}
                            height={'16px'}
                          />
                        </div>
                      : null
                    }
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            props.content.length > 0 ? props.content.map(e => (
              <tr style={{
                paddingLeft: '10px',
                height: '65px',
                fontFamily: 'AvenirBook',
                fontSize: props.contentFontSize ?? '15px',
                backgroundColor: e.active == null || e.active ? 'white' : 'rgba(69,102,192,0.05)',
                color: e.active == null || e.active ? 'black' : 'rgba(109,114,120,0.5)',
                wordBreak: 'break-word'
              }}>
                {
                  e.columns.map((col, idx) => (
                    <td className={classes.tableDataCell} style={{
                      width: props.columnData[idx].width,
                      border: `1px solid ${Colors.TABLE_ROW_BORDER}`,
                      ...(idx > 0 ? { borderLeft: 0 } : {}),
                      ...(idx < props.columnData.length - 1 ? { borderRight: 0 } : {}),
                      ...(props.columnData[idx].contentPadding?.left ? {paddingLeft: props.columnData[idx].contentPadding?.left} : {}),
                      ...(props.columnData[idx].contentPadding?.top ? {paddingTop: props.columnData[idx].contentPadding?.top} : {}),
                      ...(props.columnData[idx].contentPadding?.bottom ? {paddingBottom: props.columnData[idx].contentPadding?.bottom} : {}),
                      ...(props.columnData[idx].contentPadding?.right ? {paddingRight: props.columnData[idx].contentPadding?.right} : {}),
                      textAlign: props.columnData[idx].contentAlign || 'center',
                      ...(props.columnData[idx].additionalContentStyle || {})
                    }}>
                      {col}
                    </td>
                  ))
                }
              </tr>
            ))
            :  <tr>
                <td colSpan={props.columnData.length} style={{
                  border: `1px solid ${Colors.TABLE_ROW_BORDER}`,
                }}>
                  {props.placeholder}
                </td>
              </tr>
          }
        </tbody>
      </table>
    </div>
  )
}


export default CustomTable