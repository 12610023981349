import { Colors } from "./../../types/colors"
import { makeStyles, Theme } from "@material-ui/core"

export const commonAuthStyle = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "none",
    fontSize: "15px",
    color: '#202020',
    fontFamily: "AvenirBook",
  },
  loadingButton: {
    flexGrow: 1,
    textTransform: "none",
    width: "100%",
    padding: "10px 0px",
    fontSize: "15px",
    fontWeight: "normal",
    marginTop: "5px",
    fontFamily: "AvenirMedium, Robot, Arial, sans-serif",
    backgroundColor: Colors.AUTH_BUTTON_COLOR,
    border: 0,
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    '@media (max-width: 1000px)': {
      marginTop: "10px",
    },
    '&:hover': {
      backgroundColor: Colors.AUTH_HOVER_BUTTON_COLOR,
    },
    '&:disabled': {
      backgroundColor: Colors.AUTH_DISABLED_BUTTON_COLOR,
      opacity: 0.5,
      cursor: 'default'
    }
  }
}))
