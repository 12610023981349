import { Tag } from 'bbcode-to-react'

export default class SpanTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <span>
        {this.getComponents()}
      </span>
    )
  }
}