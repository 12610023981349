import { Form, Formik } from "formik"
import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import AuthApi from "../../../api/AuthApi"
import AuthRefreshApi from "../../../api/AuthRefreshApi"
import { addToken } from '../../../redux/auth/actions'
import { addPermissions } from '../../../redux/permissions/actions'
import { State } from '../../../redux/reducers/index'
import { addUserName } from '../../../redux/user/actions'
import { Login } from '../../../types/auth'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../../utils/token/StorageService'
import { signInValidation } from "../../../utils/validations/signInValidation"
import BaseInput from "../../common/BaseInput/BaseInput"
import LoadingButton from "../../common/LoadingButton/LoadingButton"
import PasswordInput from "../../common/PasswordInput/PasswordInput"
import AuthCover from "../AuthCover/AuthCover"
import { commonAuthStyle } from "../commonAuthStyle"

const SignIn = () => {
	const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(["auth", "common"])
  const token = useSelector((e: State) => e.auth.token)
  const commonAuthClasses = commonAuthStyle()

	const [loading, setLoading] = React.useState<boolean>(false)

	const [initialValues, setInitialValues] = React.useState<Login>({ login: "", password: "" })

	const [login, setLogin] = React.useState<string>('')
	const [password, setPassword] = React.useState<string>('')

  React.useEffect(() => {
    if (token) {
      const checkToken = async () => {
        try {
          const res = await AuthRefreshApi.checkSession()
          StorageService.getInstance().setUserName(res.name)
          dispatch(addUserName(res.name))
          dispatch(addPermissions(res.permissionLevels))
          history.replace("/")
        } catch (e) {
          return
        }
      }
      checkToken()
    }
  }, [token])

  const handleSubmitLogin = async (values: Login, form: any) => {
		setLoading(true)
		try {
			const authData = await AuthApi.login(values)
			StorageService.getInstance().setTokens(authData.sessionToken, authData.refreshToken)
			StorageService.getInstance().setUserName(authData.name)
      dispatch(addToken(authData.sessionToken))
			dispatch(addUserName(authData.name))
			history.push("/")
		} catch (e) {
			onErrorResponseShowMessage(e, false)
			setInitialValues({ ...values, password: "" })
      setPassword('')
			form.resetForm()
		} finally {
			setLoading(false)
		}
	}

  return (
    <AuthCover title={t("sign_in_title")} subTitle={t("sign_in_subtitle")}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmitLogin}
        validationSchema={signInValidation(t)}
      >
        {(formikProps) => (
          <Form
            style={{
              minWidth: "360px",
            }}
          >
            <BaseInput
              name="login"
              label={t("email_address")}
              value={login}
              setValue={setLogin}
              error={
                formikProps.touched.login &&
                Boolean(formikProps.errors.login)
              }
              formikProps={formikProps}
            />
            <PasswordInput
              name="password"
              value={password}
              setValue={setPassword}
              label={t("password")}
              formikProps={formikProps}
              error={
                formikProps.touched.password &&
                Boolean(formikProps.errors.password)
              }
            />
            <LoadingButton
              text={t("sign_in")}
              type="submit"
              className={commonAuthClasses.loadingButton}
              disabled={
                !formikProps.isValid || !formikProps.dirty
              }
              loading={loading}
              style={{ marginTop: "10px" }}
            />
          </Form>
        )}
      </Formik>
      <div
        style={{ 
          marginTop: "10px",
          textAlign: 'right'
        }}
      >
        <Link
          to="/password/reset/request"
          className={commonAuthClasses.link}
        >
          {t("remind_password")}
        </Link>
      </div>
      <div
        style={{ 
          textAlign: 'right',
          marginTop: '10px'
        }}
      >
        <Link
          to="/account/activation/resend"
          className={commonAuthClasses.link}
        >
          {t("resend_activation_email")}
        </Link>
      </div>
      <div
        style={{ 
          textAlign: 'center',
          marginTop: '50px'
        }}
      >
        <a
          href="/regulamin.pdf"
          className={commonAuthClasses.link}
        >
          {t("terms")}
        </a>
      </div>
    </AuthCover>
  )
}

export default SignIn
