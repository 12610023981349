export class StorageService {
    private static instance: StorageService
    private static ACCESS_TOKEN = "access_token"
    private static REFRESH_TOKEN = "refresh_token"
    private static USER_NAME = "user_name"

    private constructor() { }

    public static getInstance(): StorageService {
        if (!StorageService.instance) {
            StorageService.instance = new StorageService()
        }

        return StorageService.instance
    }

    public setTokens(token: string, refreshToken: string) {
        localStorage.setItem(StorageService.ACCESS_TOKEN, token)
        localStorage.setItem(StorageService.REFRESH_TOKEN, refreshToken)
    }

    public setUserName(name: string) {
        localStorage.setItem(StorageService.USER_NAME, name)
    }

    public clearToken() {
        localStorage.removeItem(StorageService.ACCESS_TOKEN)
        localStorage.removeItem(StorageService.REFRESH_TOKEN)
    }

    public clearUserName() {
        localStorage.removeItem(StorageService.USER_NAME)
    }

    public setAccessToken(token: string) {
        localStorage.setItem(StorageService.ACCESS_TOKEN, token)
    }

    public getAccessToken(): string | null {
        return localStorage.getItem(StorageService.ACCESS_TOKEN)
    }
    
    public getRefreshToken(): string | null {
        return localStorage.getItem(StorageService.REFRESH_TOKEN)
    }

    public clearUserCache() {
        this.clearToken()
        this.clearUserName()
    }
       
}