import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

export const eventsListStyle = makeStyles((theme: Theme) => ({
  controlRow: {
    display: 'flex',
    marginTop: '15px',
    marginBottom: '10px'
  },
  dateRangeField: {
    width: '250px',
    marginRight: '2%',
    position: 'relative'
  },
  dateRangeLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.EDIT_CONFIG_TEXT,
    marginBottom: '5px',
    marginLeft: '2px'
  },
  statTileSection: {
    width: '100%',
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    '@media (max-width: 1035px)': {
      flexWrap: "wrap",
      justifyContent: 'space-evenly'
    }
  },
  auctionCountTile: {
    width: '230px',
    flexShrink: 0,
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  msgSentTile: {
    width: '230px',
    flexShrink: 0,
    marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  responsesCountTile: {
    width: '230px',
    flexShrink: 0,
    marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%',
      marginBottom: '10px',
    }
  },
  emptySpaceTile: {
    width: 'calc(96% - 920px)',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    '@media (max-width: 1035px)': {
      display: 'none'
    }
  },
  totalPriceTile: {
    width: '230px',
    flexShrink: 0,
    display: 'none',
    marginLeft: '0.5%',
    '@media (max-width: 1035px)': {
      width: '40%',
      marginLeft: 0,
      marginRight: 0
    },
    '@media (max-width: 600px)': {
      width: '80%'
    }
  },
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '0px 14px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px',
  },
  sectionLabel: {
    textTransform: 'uppercase',
    fontFamily: 'AvenirBook',
    fontSize: '13px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.LIGHT_GRAY_TEXT,
    marginBottom: '10px',
    marginLeft: '2px'
  },
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  fieldWithLabel: {
    width: '23.5%',
    marginRight: '2%',
    position: 'relative'
  },
  fieldWithoutLabel: {
    width: '23.5%',
    marginRight: '2%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  lastFieldWithLabel: {
    width: '23.5%',
    position: 'relative'
  },
  lastFieldWithoutLabel: {
    width: '23.5%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  rowContainer: {
    display: 'flex',
    marginBottom: '10px'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  listCount: {
    position: 'absolute',
    top: 25,
    right: 15,
    fontFamily: 'AvenirBook',
    fontSize: '16px'
  },
  assumedUserInfo: {
    marginBottom: '20px',
    display: 'flex',
    fontFamily: 'AvenirBook',
    fontSize: '16px',
    alignItems: 'center'
  },
  tableHeaderCell: {
    display: 'table-cell',
    verticalAlign: 'bottom',
    fontFamily: 'AvenirMedium',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'normal',
    color: Colors.TABLE_HEADER_LABEL
  }, 
  tableDataCell: {
    display: 'table-cell',
    verticalAlign: 'top',
    paddingTop: '10px'
  }, 
  manageLink: {
    textDecoration: 'none',
    fontFamily: 'AvenirMedium',
    wordBreak: 'keep-all',
    color: Colors.TABLE_MANAGE_CELL_TEXT
  },
  fieldSubLabel: {
    fontFamily: 'AvenirHeavy',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    marginLeft: '7px'
  },
  formSectionWarningLabel: {
    color: '#faa307',
    textAlign: 'left',
    margin: '0px 2px 10px',
    fontFamily: "AvenirBook",
    fontSize: '15px'
  }
}))
