import React from 'react'
import BBCodeParser from './parser/parser'
import { bbCodeArticleStyle } from './bbCodeArticleStyle'

type Props = {
  bbcode: string
}

const BBCodeArticle = (props: Props) => {
  const classes = bbCodeArticleStyle()

  return (
    <div className={classes.bbCodeArticle}>
      {BBCodeParser.toReact(props.bbcode)}
    </div>
  )
}

export default BBCodeArticle