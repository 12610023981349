export type SortData = {
  column: string
  order: SortDirection
  default?: boolean
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = ''
}