import React from 'react'
import DataApi from '../../../api/DataApi'

export const useAddBulkActionInfoForm = () => {
  const [smsTemplates, setSmsTemplates] = React.useState<any[]>([])

  const fetchSmsTemplateList = async (assumeUuid?: string) => {
    try {
      const fetchedTemplates = await DataApi.getSmsTemplateList(true, undefined, undefined, undefined, assumeUuid)
      setSmsTemplates(fetchedTemplates)
    } catch (error) {
			throw error
    }
  }

	return { 
    smsTemplates,
    fetchSmsTemplateList
	}
}