import { Colors } from './../../../types/colors'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core';

export type StyleProps = {
  borderColor?: string
  borderColorFocus?: string
}

export const baseInputStyles = (props: StyleProps) => makeStyles(() => ({
  textField: {
    width: '100%',
    backgroundColor: Colors.AUTH_INPUT_BACKGROUND,
    paddingTop: '9px',
    marginTop: 0,
    paddingBottom: '9px',
    paddingLeft: '14px',
    paddingRight: '14px',
    border: `solid 1px ${props.borderColor || Colors.AUTH_INPUT_BORDER}`,
    borderRadius: '5px',
    fontSize: '15px',
    fontFamily: '"AvenirBook", "Roboto", "Arial", sans-serif',
    '&:focus': {
      border: `solid 2px ${props.borderColorFocus || Colors.AUTH_INPUT_BORDER_FOCUS}`,
      paddingLeft: '13px',
      paddingRight: '13px',
      paddingBottom: '8px',
      paddingTop: '8px',
      outline: 'none !important',
    }
  },
  textFieldError: {
    borderColor: props.borderColor,
    '&:focus': {
      borderColor: props.borderColorFocus,
    },
  },
  label: {
    fontSize: "12px",
    textTransform: "uppercase",
    marginBottom: "3px",
    letterSpacing: "1.13px",
    fontFamily: "AvenirMedium, Roboto, Arial, sans-serif",
    color: '#B0BAC9'
  }
}))
