export enum BulkActionStatus {
  WAITING,
  SCHEDULED,
  CANCELLED,
  REJECTED,
  ERROR,
  STARTED
}

export const bulkActionStatusTypes = [
  {
    value: `${BulkActionStatus.WAITING}`,
    label: 'oczekuje',
    color: '#F9C74F'
  },
  {
    value: `${BulkActionStatus.SCHEDULED}`,
    label: 'zaplanowana',
    color: '#38B000'
  },
  {
    value: `${BulkActionStatus.CANCELLED}`,
    label: 'anulowana',
    color: '#2e384d'
  },
  {
    value: `${BulkActionStatus.REJECTED}`,
    label: 'odrzucona',
    color: '#F94144'
  },
  {
    value: `${BulkActionStatus.ERROR}`,
    label: 'błąd',
    color: '#F94144'
  },
  {
    value: `${BulkActionStatus.STARTED}`,
    label: 'wysłana',
    color: '#38B000'
  }
]