import { CircularProgress } from '@material-ui/core'
import { Check, EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from 'react'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import DataApi from '../../api/DataApi'
import specialModeIcon from '../../assets/images/specialMode.svg'
import trashIcon from '../../assets/images/Trash.webp'
import Checkbox from '../../lib/react-custom-checkbox/dist'
import { AdvertType, MarketType, plotOtherParams, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList, MSG_SIGNATURE_LEN, TEMPLATE_MAX_MSG, TEMPLATE_MSG_LEN, TEMPLATE_MULTIPLE_MSG_LEN } from '../../types/common'
import { preventReturnKey } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { evalStringTemplate } from '../../utils/text'
import { addNotificationEmail } from '../../utils/validations/addNotificationEmail'
import { addParamsForm } from '../../utils/validations/addParamsForm'
import { smsMessagePattern } from '../../utils/validations/validationRegexps'
import BaseInput from '../common/BaseInput/BaseInput'
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import CustomSelectField, { AllSelectOptionsValue } from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import CustomDialog from '../CustomDialog/CustomDialog'
import { mapDistrictOptions, mapSubRegionOptions, mapTownOptions, sortAndCompareSelectionArrays } from './helpers'
import styles from './styles.module.scss'
import { useAddSuperEventDialog } from './useAddSuperEventDialog'
import { addSuperAdminParamsForm } from '../../utils/validations/superadminSearchParams'

type Props = {
  hidden?: boolean
  onClose: () => void
  afterSubmit?: (() => void) | (() => Promise<void>)
}

const defaultInitialFormValues = {
  name: '',
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  regions: [],
  subRegions: [],
  towns: [],
  districts: [],
  transactionTypes: [],
  propertyTypes: [],
  propertySubTypes: [],
  propertyOtherParams: [],
  signature: '',
  minPricePerArea: '',
  maxPricePerArea: '',
  notificationEmails: [] as string[],
  smsTemplate: [] as string[],
  customTemplate: '',
  includeAgencyOffers: false,
  includeOffersWithNoPhones: false,
  enableSpecialMode: false
}

const AddSuperEventDialog = (props: Props) => {
  const t = useTranslation('forms').t
  const tEventList = useTranslation('eventList').t

  const { 
    regions, smsTemplates, account,
    fetchRegionList, fetchSmsTemplateList, fetchAccountDetails
	} = useAddSuperEventDialog()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [isEditingCustomTemplate, setIsEditingCustomTemplate] = React.useState<boolean>(false)

  const [subRegionList, setSubRegionList] = React.useState<any[]>([])
  const [townList, setTownList] = React.useState<any[]>([])
  const [districtList, setDistrictList] = React.useState<any[]>([])

  const [chosenRegions, setChosenRegions] = React.useState<string[]>([])
  const [chosenSubregions, setChosenSubregions] = React.useState<string[]>([])
  const [chosenTowns, setChosenTowns] = React.useState<string[]>([])
  const [chosenDistricts, setChosenDistricts] = React.useState<string[]>([])

  const [chosenNotificationEmails, setChosenNotificationEmails] = React.useState<string[]>([])
  const [chosenSmsTemplates, setChosenSmsTemplates] = React.useState<string[]>([])
  const [chosenCustomSmsTemplate, setChosenCustomSmsTemplate] = React.useState<string>('')
  const [tempCustomSmsTemplate, setTempCustomSmsTemplate] = React.useState<string>('')
  const [chosenPropertyTypes, setChosenPropertyTypes] = React.useState<string[] | string>([])

  const [addFormInitialFormValues, setAddFormInitialFormValues] = React.useState<any>(defaultInitialFormValues)

  const formRef = useRef() as any
  const emailsFormRef = useRef() as any
  const customTemplateFormRef = useRef() as any

  React.useEffect(() => {
    Promise.all([
      fetchRegionList(),
      fetchSmsTemplateList(),
      fetchAccountDetails()
    ]).then(res => {
      setIsLoading(false)
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])


  React.useEffect(() => {
    setChosenSmsTemplates([])
    formRef.current?.setFieldValue('smsTemplate', [])
    if (!chosenPropertyTypes.includes(`${AdvertType.PLOT}`)) {
      formRef?.current?.setFieldValue('propertySubTypes', [])
      formRef?.current?.setFieldValue('propertyOtherParams', [])
    }
  }, [chosenPropertyTypes])

  React.useEffect(() => {
    const newSubRegionList = mapSubRegionOptions(chosenRegions, regions)
    const newTownList = mapTownOptions(chosenSubregions, newSubRegionList)
    const newDistrictList = mapDistrictOptions(chosenTowns, newTownList)
    setSubRegionList(newSubRegionList)
    setTownList(newTownList)
    setDistrictList(newDistrictList)
    const newChosenSubregions = chosenSubregions.filter(e => newSubRegionList.findIndex(t => t.value === e) > -1)
    const newChosenTowns = chosenTowns.filter(e => newTownList.findIndex(t => t.value === e) > -1)
    const newChosenDistricts = chosenDistricts.filter(e => newDistrictList.findIndex(t => t.value === e) > -1)
    if (!chosenSubregions.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenSubregions, newChosenSubregions)) {
      setChosenSubregions(newChosenSubregions)
      formRef.current.setFieldValue('subRegions', newChosenSubregions)
    }
    if (!chosenTowns.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenTowns, newChosenTowns)) {
      setChosenTowns(newChosenTowns)
      formRef.current.setFieldValue('towns', newChosenTowns)
    }
    if (!chosenDistricts.includes(AllSelectOptionsValue) && !sortAndCompareSelectionArrays(chosenDistricts, newChosenDistricts)) {
      setChosenDistricts(newChosenDistricts)
      formRef.current.setFieldValue('districts', newChosenDistricts)
    }
  }, [chosenRegions, chosenSubregions, chosenTowns])

  const resetForm = () => {
    setChosenRegions([])
    setChosenSubregions([])
    setChosenTowns([])
    setChosenDistricts([])
    setChosenPropertyTypes([])
    setChosenNotificationEmails([])
    setChosenCustomSmsTemplate('')
    setIsEditingCustomTemplate(false)
    setChosenSmsTemplates([])
    setTempCustomSmsTemplate('')
    formRef.current?.resetForm()
  }

  const handleSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        name: data.name === '' ? undefined : data.name.trim(),
        notificationEmails: data.notificationEmails,
        marketType: MarketType.PRIMARY,
        customMessage: data.customTemplate === '' ? undefined :data.customTemplate,
        smsMessageUuids: data.smsTemplate,
        signature: !data.signature || data.signature === '' ? undefined : data.signature,
        params: {
          minArea: data.minArea === '' ? undefined : parseFloat(data.minArea.replace(',', '.')),
          maxArea: data.maxArea === '' ? undefined : parseFloat(data.maxArea.replace(',', '.')),
          minPrice: data.minPrice === '' ? undefined : parseFloat(data.minPrice.replace(',', '.')),
          maxPrice: data.maxPrice === '' ? undefined : parseFloat(data.maxPrice.replace(',', '.')),
          minPricePerArea: !data.minPricePerArea || data.minPricePerArea === '' ? undefined : parseFloat(data.minPricePerArea.replace(',', '.')),
          maxPricePerArea: !data.maxPricePerArea || data.maxPricePerArea === '' ? undefined : parseFloat(data.maxPricePerArea.replace(',', '.')),
          regionUuids: data.regions.includes(AllSelectOptionsValue) ? regions.map(e => e.uuid) : data.regions,
          subRegionUuids: data.subRegions.includes(AllSelectOptionsValue) ? subRegionList.map(e => e.value) : data.subRegions,
          townUuids: data.towns.includes(AllSelectOptionsValue) ? townList.map(e => e.value) : data.towns,
          districtUuids: data.districts.includes(AllSelectOptionsValue) ? districtList.map(e => e.value) : data.districts,
          advertTypes: data.propertyTypes.includes(AllSelectOptionsValue) ? propertyTypes.map(e => parseInt(e.value)) : data.propertyTypes.map(e => parseInt(e)),
          advertSubTypes: data.propertySubTypes.includes(AllSelectOptionsValue) ? plotTypes.map(e => parseInt(e.value)) : data.propertySubTypes.map(e => parseInt(e)),
          offerTypes: data.transactionTypes.includes(AllSelectOptionsValue) ? transactionTypes.map(e => parseInt(e.value)) : data.transactionTypes.map(e => parseInt(e)),
          advertOtherParams: data.propertyOtherParams.includes(AllSelectOptionsValue) ? plotOtherParams.map(e => e.value) : data.propertyOtherParams.map(e => e),
          includeAgencyOffers: data.includeAgencyOffers ?? false,
          includeOffersWithNoPhones: data.includeOffersWithNoPhones ?? false,
          specialMode: data.enableSpecialMode ?? false
        }
      }
      await DataApi.addSuperAdminSearchParams(mappedData)
      resetForm()
      if (props.afterSubmit != null) {
        await props.afterSubmit()
      }
      setIsSubmitting(false)
      props.onClose()
    } catch (error) {
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleEmailFormSubmit = (data: any, form: any) => {
    const newArray = [...chosenNotificationEmails]
    newArray.push(data.notificationEmail.trim())
    setChosenNotificationEmails(newArray)
    formRef.current.setFieldValue('notificationEmails', newArray)
    if (emailsFormRef.current) {
      emailsFormRef.current.resetForm()
    }
  }

  const resetCustomTemplateFormState = () => {
    setTempCustomSmsTemplate('')
    if (customTemplateFormRef.current) {
      customTemplateFormRef.current.resetForm()
    }
  }

  return (
    <CustomDialog hidden={props.hidden}>
      {
        isLoading
        ? <div className={styles.loadingContainer}>
            <div className={styles.loading}>
              <CircularProgress size={48}/>
            </div>
          </div>
        : <div className={styles.container}>
            <div className={styles.topBarContainer}>
              <div className={styles.title}>
                {t('add_new_event_section_label')}
              </div>
              <div className={styles.closeButtonContainer}>
                <span 
                  className={styles.closeButton}
                  onClick={() => {
                    resetForm()
                    props.onClose()
                  }}
                >
                  <CloseIcon fontSize={'inherit'}/>
                </span>
              </div>
            </div>
            <Formik
              innerRef={formRef}
              validationSchema={addSuperAdminParamsForm(tEventList)}
              initialValues={addFormInitialFormValues}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <Form
                  onKeyDown={preventReturnKey}
                  className={styles.form}
                >
                  <div className={styles.formSection}>
                    <p className={styles.fieldLabel}>
                      {t('event_name_field_label')}
                    </p>
                    <BaseInput 
                      count
                      maxLen={240}
                      name={'name'}
                      placeholder={t('event_name_field_placeholder')}
                      formikProps={formikProps}
                      value={formikProps.values.name}
                      error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                    />
                  </div>
                  <div className={styles.formSection}>
                    <div className={styles.sectionLabel}>
                      {t('advert_search_params_section_label')}
                    </div>
                    <div className={styles.rowContainer}>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('location_field_label')}
                        </p>
                        <div 
                          className={`${styles.clearButton}`} 
                          onClick={() => {
                            setChosenRegions([])
                            formikProps.setFieldValue('regions', '')
                          }}
                        >
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'region-field'}
                          isMulti
                          name={'regions'}
                          placeholder={'Województwa'}
                          options={
                            regions.map(e => {
                              return {
                                value: e.uuid,
                                label: e.name
                              }
                            }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))
                          }
                          includeAllOption
                          includeAllOptionLabel={'Cała Polska'}
                          value={chosenRegions}
                          setValue={setChosenRegions}
                          formikProps={formikProps}
                          error={formikProps.touched.regions && Boolean(formikProps.errors.regions)}
                        />
                      </div>
                      <div className={styles.fieldWithoutLabel}>
                        <div 
                          hidden={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          className={`${styles.clearButton}`} 
                          onClick={()=> {
                            setChosenSubregions([])
                            formikProps.setFieldValue('subRegions', '')
                          }}
                        >
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'subRegion-field'}
                          disabled={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          name={'subRegions'}
                          placeholder={'Powiaty'}
                          isMulti
                          includeAllOption
                          options={subRegionList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                          value={chosenSubregions}
                          setValue={setChosenSubregions}
                          formikProps={formikProps}
                          warning={chosenSubregions != null && chosenSubregions.length !== 0}
                          warningMessage={t('subregion_warning_label')}
                          error={formikProps.touched.subRegions && Boolean(formikProps.errors.subRegions)}
                        />
                      </div>
                      <div className={styles.fieldWithoutLabel}>
                        <div 
                          hidden={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          className={`${styles.clearButton}`} 
                          onClick={()=> {
                            setChosenTowns([])
                            formikProps.setFieldValue('towns', [])
                          }}
                        >
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'town-field'}
                          disabled={chosenRegions.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          name={'towns'}
                          isMulti
                          includeAllOption
                          placeholder={'Miasta/Miejscowości'}
                          options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                          value={chosenTowns}
                          setValue={setChosenTowns}
                          formikProps={formikProps}
                          error={formikProps.touched.towns && Boolean(formikProps.errors.towns)
                          }
                        />
                      </div>
                      <div className={styles.lastFieldWithoutLabel}>
                        <div 
                          hidden={chosenTowns.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          className={`${styles.clearButton}`} 
                          onClick={()=> {
                            setChosenDistricts([])
                            formikProps.setFieldValue('districts', [])
                          }}
                        >
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'district-field'}
                          disabled={chosenTowns.length === 0 || chosenRegions.includes(AllSelectOptionsValue)}
                          name={'districts'}
                          isMulti
                          includeAllOption
                          placeholder={'Dzielnice (opcjonalnie)'}
                          options={districtList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                          value={chosenDistricts}
                          setValue={setChosenDistricts}
                          formikProps={formikProps}
                          error={formikProps.touched.districts && Boolean(formikProps.errors.districts)}
                        />
                      </div>
                    </div>
                    <div className={styles.rowContainer}>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('transaction_type_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('transactionTypes', [])
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'transaction-type-field'}
                          name={'transactionTypes'}
                          isMulti
                          includeAllOption
                          placeholder={'Wybierz typy transakcji'}
                          options={transactionTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                          value={formikProps.values.transactionTypes}
                          formikProps={formikProps}
                          error={formikProps.touched.transactionTypes && Boolean(formikProps.errors.transactionTypes)}
                        />
                      </div>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                        {t('property_type_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          setChosenPropertyTypes([])
                          setChosenSmsTemplates([])
                          formikProps.setFieldValue('propertyTypes', [])
                          formikProps.setFieldValue('smsTemplate', [])
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <CustomSelectField
                          instanceId={'property-type-field'}
                          name={'propertyTypes'}
                          isMulti
                          includeAllOption
                          placeholder={'Wybierz typy nieruchomości'}
                          options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                          value={chosenPropertyTypes}
                          setValue={setChosenPropertyTypes}
                          formikProps={formikProps}
                          error={formikProps.touched.propertyTypes && Boolean(formikProps.errors.propertyTypes)}
                        />
                      </div>
                      {
                        formikProps.values.propertyTypes.includes(`${AdvertType.PLOT}`) || formikProps.values.propertyTypes.includes(AllSelectOptionsValue)
                        ? <>
                            <div className={styles.fieldWithLabel}>
                              <p className={styles.fieldLabel}>
                                {t('plot_type_field_label')}
                              </p>
                              <div className={`${styles.clearButton}`} onClick={()=> {
                                formikProps.setFieldValue('propertySubTypes', [])
                              }}>
                                {t('clear_field_button_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'property-subtype-field'}
                                name={'propertySubTypes'}
                                isMulti
                                includeAllOption
                                placeholder={t('plot_type_field_placeholder')}
                                options={plotTypes}
                                value={formikProps.values.propertySubTypes}
                                formikProps={formikProps}
                                error={formikProps.touched.propertySubTypes && Boolean(formikProps.errors.propertySubTypes)}
                              />
                            </div>
                            <div className={styles.lastFieldWithLabel}>
                              <p className={styles.fieldLabel}>
                                {t('other_params_label')}
                              </p>
                              <div className={`${styles.clearButton}`} onClick={()=> {
                                formikProps.setFieldValue('propertyOtherParams', [])
                              }}>
                                {t('clear_field_button_label')}
                              </div>
                              <CustomSelectField
                                instanceId={'property-otherParams-field'}
                                name={'propertyOtherParams'}
                                isMulti
                                includeAllOption
                                placeholder={'Wybierz parametry'}
                                options={plotOtherParams}
                                value={formikProps.values.propertyOtherParams}
                                formikProps={formikProps}
                                error={formikProps.touched.propertyOtherParams && Boolean(formikProps.errors.propertyOtherParams)}
                              />
                            </div>
                          </>
                        : <div className={styles.flexSpacer}></div>
                      }
                    </div>
                    <div className={styles.rowContainer}>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('min_price_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('minPrice', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'minPrice'}
                          placeholder={t('min_price_field_placeholder')}
                          formikProps={formikProps}
                          value={formikProps.values.minPrice}
                          monetaryValue
                          error={formikProps.touched.minPrice && Boolean(formikProps.errors.minPrice)}
                        />
                      </div>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('max_price_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('maxPrice', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'maxPrice'}
                          placeholder={t('max_price_field_placeholder')}
                          formikProps={formikProps}
                          value={formikProps.values.maxPrice}
                          monetaryValue
                          error={formikProps.touched.maxPrice && Boolean(formikProps.errors.maxPrice)}
                        />
                      </div>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('min_area_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('minArea', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'minArea'}
                          placeholder={t('min_area_field_placeholder')}
                          formikProps={formikProps}
                          monetaryValue
                          value={formikProps.values.minArea}
                          error={formikProps.touched.minArea && Boolean(formikProps.errors.minArea)}
                        />
                      </div>
                      <div className={styles.lastFieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('max_area_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('maxArea', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'maxArea'}
                          placeholder={t('max_area_field_placeholder')}
                          formikProps={formikProps}
                          monetaryValue
                          value={formikProps.values.maxArea}
                          error={formikProps.touched.maxArea && Boolean(formikProps.errors.maxArea)}
                        />
                      </div>
                    </div>
                    <div className={styles.rowContainer}>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('min_price_per_area_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('minPricePerArea', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'minPricePerArea'}
                          placeholder={t('min_price_per_area_field_placeholder')}
                          formikProps={formikProps}
                          value={formikProps.values.minPricePerArea}
                          monetaryValue
                          error={formikProps.touched.minPricePerArea && Boolean(formikProps.errors.minPricePerArea)}
                        />
                      </div>
                      <div className={styles.fieldWithLabel}>
                        <p className={styles.fieldLabel}>
                          {t('max_price_per_area_field_label')}
                        </p>
                        <div className={`${styles.clearButton}`} onClick={()=> {
                          formikProps.setFieldValue('maxPricePerArea', '')
                        }}>
                          {t('clear_field_button_label')}
                        </div>
                        <BaseNumberInput
                          name={'maxPricePerArea'}
                          placeholder={t('max_price_per_area_field_placeholder')}
                          formikProps={formikProps}
                          value={formikProps.values.maxPricePerArea}
                          monetaryValue
                          error={formikProps.touched.maxPricePerArea && Boolean(formikProps.errors.maxPricePerArea)}
                        />
                      </div>
                    </div>
                    <div className={[
                      styles.rowContainer,
                      styles.onlySwitchRow
                    ].join(' ')}>
                      <div className={styles.switchContainer}>
                        <CustomSwitch 
                          name={'includeAgencyOffers'}
                          checked={formikProps.values.includeAgencyOffers}
                          formikProps={formikProps}
                        />
                        <div className={styles.switchLabel}>
                          {t('include_agency_offers_switch_label')}
                        </div>
                      </div>
                    </div>
                    <div className={[
                      styles.rowContainer,
                      styles.onlySwitchRow
                    ].join(' ')}>
                      <div className={styles.switchContainer}>
                        <CustomSwitch 
                          disabled={account?.allowedSendingSms}
                          name={'includeOffersWithNoPhones'}
                          checked={formikProps.values.includeOffersWithNoPhones}
                          formikProps={formikProps}
                        />
                        <div className={styles.switchLabel}>
                          {t('include_offers_with_no_phones_switch_label')}
                          <span className={styles.warningLabel}>
                            {t('include_offers_with_no_phones_switch_warning_label')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={[
                      styles.rowContainer,
                      styles.specialModeRow
                    ].join(' ')}>
                      <div className={styles.specialModeIcon}>
                        <img
                          src={specialModeIcon}
                          alt={'special-mode'}
                          width={50}
                          height={50}
                        />
                      </div>
                      <div className={styles.switchContainer}>
                        <CustomSwitch 
                          name={'enableSpecialMode'}
                          checked={formikProps.values.enableSpecialMode}
                          formikProps={formikProps}
                        />
                        <div className={styles.specialModeSwitchLabel}>
                          {t('enable_special_mode_switch_label')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.formSection}>
                    <div className={styles.sectionLabel}>
                      {t('sms_content_settings_section_label')}
                    </div>
                    <div className={styles.formSectionWarningLabel}>
                      {t('sms_settings_warning_label')}
                    </div>
                    <div className={styles.signatureField}>
                      <p className={styles.fieldLabel}>
                        {t('signature_field_label')}
                      </p>
                      <BaseInput 
                        count
                        name={'signature'}
                        maxLen={MSG_SIGNATURE_LEN}
                        inputPattern={smsMessagePattern}
                        placeholder={t('signature_field_placeholder')}
                        formikProps={formikProps}
                        value={formikProps.values.signature}
                        error={formikProps.touched.signature && Boolean(formikProps.errors.signature)}
                      />
                    </div>
                    <div className={styles.messageSection}>
                      <div className={styles.fieldLabel}>
                        {
                          evalStringTemplate(
                            t('sms_content_field_label'), 
                            {
                              cur: formikProps.values['smsTemplate'].length,
                              max: smsTemplates.filter(e => !e.removed && (formikProps.values.propertyTypes.includes(`${e.advertType}`) || formikProps.values.propertyTypes.includes(AllSelectOptionsValue))).length,
                              custom: chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' ? ' + wiadomość własna' : ''
                            }
                          )
                        }
                      </div>
                      <div 
                        className={[
                          styles.fieldSubLabel,
                          styles.availableTemplatesLabelMargin
                        ].join(' ')}
                      >
                        {t('available_templates_label')}
                      </div>
                      {
                        smsTemplates.filter(e => formikProps.values.propertyTypes.includes(AllSelectOptionsValue) || !(e.removed  || !formikProps.values.propertyTypes.includes(`${e.advertType}`))).length === 0
                        ? <div className={styles.availableTemplatesPlaceholder}>
                            {t('no_templates_available_label')}
                          </div>
                        : smsTemplates.map(e => (
                          <>
                            {
                              e.removed || (!formikProps.values.propertyTypes.includes(`${e.advertType}`) && !formikProps.values.propertyTypes.includes(AllSelectOptionsValue))
                              ? null
                              : <div className={styles.messageTemplateContainer}>
                                  <Checkbox 
                                    name={'smsTemplate'}
                                    checked={chosenSmsTemplates.includes(e.uuid)}
                                    disabled={!chosenSmsTemplates.includes(e.uuid) && chosenSmsTemplates.length >= smsTemplates.length }
                                    onBlur={() => {
                                      formikProps.setFieldTouched('smsTemplate')
                                    }}
                                    onChange={(checked) => {
                                      formikProps.setFieldTouched('smsTemplate')
                                      if (!checked && formikProps.values['smsTemplate'].includes(e.uuid)) {
                                        const indexToDelete = formikProps.values['smsTemplate'].indexOf(e.uuid)
                                        const newArray = [...formikProps.values['smsTemplate']]
                                        newArray.splice(indexToDelete, 1)
                                        formikProps.setFieldValue('smsTemplate', newArray)
                                        setChosenSmsTemplates(newArray)
                                      } else if (checked  && !formikProps.values['smsTemplate'].includes(e.uuid)) {
                                        formikProps.setFieldValue('smsTemplate', [
                                          ...formikProps.values['smsTemplate'],
                                          e.uuid
                                        ])
                                        setChosenSmsTemplates([
                                          ...formikProps.values['smsTemplate'],
                                          e.uuid
                                        ])
                                      }
                                    }}
                                    borderRadius={3}
                                    size={15}
                                    containerStyle={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                      marginTop: '5px'
                                    }}
                                    style={{
                                      marginTop: '2px'
                                    }}
                                    icon={
                                      <div className={styles.templateCheckboxIcon}>
                                        <Check fontSize={'inherit'} color={'inherit'}/>
                                      </div>
                                    }
                                    borderColor={Colors.CHECKBOX_BORDER}
                                    labelStyle={{
                                      fontFamily: 'AvenirBook',
                                      color: Colors.LIGHT_GRAY_TEXT,
                                      fontSize: '15px',
                                      paddingLeft: '5px',
                                      whiteSpace: 'break-spaces'
                                    }}
                                    label={
                                      <>
                                        {e.template}{
                                          e.template.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                            ? <span className={styles.messageCountLabel}>
                                                {` (${Math.ceil((e.template.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}
                                              </span> 
                                            : null
                                        }
                                      </>
                                    }
                                    />
                                </div>
                            }
                          </>
                        ))
                      }
                      {
                        formikProps.touched.smsTemplate && Boolean(formikProps.errors.smsTemplate)
                        ? <div 
                            className={[
                              styles.errorLabel,
                              styles.errorLabelFloat
                            ].join(' ')}
                          >
                            <ErrorMessage name={'smsTemplate'} />
                          </div> 
                        : null
                      }
                      {
                        chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' && !isEditingCustomTemplate
                        ? <>
                            <div 
                              className={[
                                styles.fieldSubLabel,
                                styles.customTemplatesLabelMargin
                              ].join(' ')}
                            >
                              {t('custom_templates_label')}
                            </div>
                            <div className={styles.customTemplateContainer}>
                              <div className={styles.customTemplateText}>
                                {chosenCustomSmsTemplate}{
                                  chosenCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                    ? <span className={styles.messageCountLabel}>
                                        {` (${Math.ceil((chosenCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}
                                      </span> 
                                    : null
                                }
                              </div>
                              <div
                                className={styles.editCustomTemplate}
                                onClick={() => {
                                  setIsEditingCustomTemplate(true)
                                  setTempCustomSmsTemplate(chosenCustomSmsTemplate)
                                }}
                              >
                                <EditOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                              </div>
                              <div
                                className={styles.removeCustomTemplate}
                                onClick={() => {
                                  setChosenCustomSmsTemplate('')
                                  formikProps.setFieldValue('customTemplate', '')
                                }}
                              >
                                <img src={trashIcon} alt={'remove-item'} />
                              </div>
                            </div>
                          </>
                        : <>
                            <Formik
                              innerRef={customTemplateFormRef}
                              validateOnMount={true}
                              enableReinitialize
                              initialValues={{
                                customTemplate: tempCustomSmsTemplate
                              }}
                              onSubmit={(data) => {
                                setChosenCustomSmsTemplate(data.customTemplate)
                                setIsEditingCustomTemplate(false)
                                formikProps.setFieldValue('customTemplate', data.customTemplate)
                                resetCustomTemplateFormState()
                              }}
                            >
                              {(customTemplateFormikProps) => (
                                <Form
                                  onKeyDown={preventReturnKey}
                                  className={styles.customTemplateForm}
                                >
                                  <div className={styles.customTemplateFormFields}>
                                    <div className={styles.customTempalteTextField}>
                                      <p className={styles.fieldLabel}>
                                        {t('add_custom_template_label')}
                                      </p>
                                      <BaseInput 
                                        count
                                        maxLen={
                                          TEMPLATE_MAX_MSG > 1
                                          ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                                          : TEMPLATE_MAX_MSG
                                        }
                                        rows={4}
                                        name={'customTemplate'}
                                        placeholder={t('add_custom_template_placeholder')}
                                        formikProps={customTemplateFormikProps}
                                        inputPattern={smsMessagePattern}
                                        value={tempCustomSmsTemplate}
                                        setValue={setTempCustomSmsTemplate}
                                        error={customTemplateFormikProps.touched.customTemplate && Boolean(customTemplateFormikProps.errors.customTemplate)}
                                      />
                                    </div>
                                  </div>
                                  <div className={styles.customTemplateFormMessageCount}>
                                    {
                                      tempCustomSmsTemplate.length > 0 
                                      ? <>
                                          {`Liczba wiadomości:\u00a0`}{
                                            tempCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN
                                              ? <span className={styles.messageCountLabel}>
                                                  {Math.ceil((tempCustomSmsTemplate.length + formikProps.values.signature.length + (formikProps.values.signature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)}
                                                </span>
                                              : 1
                                          }
                                        </> 
                                      : '\u00A0'
                                    }
                                  </div>
                                  <div className={styles.customTemplateFormButtonContainer}>
                                    <LoadingButton 
                                      type='submit'
                                      onClick={(ev) => {
                                        ev.preventDefault()
                                        customTemplateFormikProps.submitForm()
                                      }}
                                      style={{
                                        width: '150px',
                                        maxWidth: '150px',
                                        height: '30px',
                                        marginTop: '5px',
                                        marginLeft: '5px',
                                        backgroundColor: '#438737'
                                      }}
                                    >
                                      <div className={styles.customTemplateFormButtonContent}>
                                        {
                                          isEditingCustomTemplate 
                                          ? <>
                                              <div className={styles.customTemplateFormButtonIcon}>
                                                <EditOutlinedIcon fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Zapisz
                                              </div>
                                            </>
                                          : <>
                                              <div className={styles.customTemplateFormButtonIcon}>
                                                <Check fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Dodaj
                                              </div>
                                            </>
                                        }
                                      </div>
                                    </LoadingButton>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </> 
                      }
                    </div>
                  </div>
                  <div className={styles.emailNotificationSection}>
                    <div className={styles.sectionLabel}>
                      {t('email_notifications_search_params_section_label')}
                    </div>
                    <div className={styles.emailNotificationListLabel}>
                      <p className={styles.fieldLabel}>
                        {t('notifications_emails_list_field_label')}
                      </p>
                      {
                        chosenNotificationEmails.length === 0
                        ? <div className={styles.emailNotificationListPlaceholder}>
                            {t('no_emails_added_label')}
                          </div>
                        : <div className={styles.emailNotificationList}>
                            {
                              chosenNotificationEmails.map((e: string, idx: number) => {
                                return (
                                  <div className={styles.emailNotificationListTile}>
                                    <div>
                                      {e}
                                    </div>
                                    <div className={styles.emailNotificationListTileButtonContainer}>
                                      <span 
                                        className={styles.emailNotificationListTileButton}
                                        onClick={() => {
                                          const newArray = [...chosenNotificationEmails]
                                          newArray.splice(idx, 1)
                                          setChosenNotificationEmails(newArray)
                                          formikProps.setFieldValue('notificationEmails', newArray)
                                        }}
                                      >
                                        <CloseIcon fontSize={'inherit'} color={'inherit'} />
                                      </span>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                      }
                    </div>
                    <div className={styles.emailNotificationField}>
                      <p className={styles.fieldLabel}>
                        {t('notification_email_field_label')}
                      </p>
                      <Formik
                        innerRef={emailsFormRef}
                        validateOnMount={true}
                        enableReinitialize
                        validationSchema={addNotificationEmail(tEventList, chosenNotificationEmails)}
                        initialValues={{
                          notificationEmail: ''
                        }}
                        onSubmit={handleEmailFormSubmit}
                      >
                        {(emailsFormikProps) => (
                          <Form
                            onKeyDown={preventReturnKey}
                            className={styles.emailNotificationForm}
                          >
                            <div className={styles.emailNotificationFormContent}>
                              <div className={styles.emailNotificationFormField}>
                                <BaseInput 
                                  name={'notificationEmail'}
                                  placeholder={t('notification_email_placeholder')}
                                  formikProps={emailsFormikProps}
                                  value={emailsFormikProps.values.notificationEmail}
                                  error={emailsFormikProps.touched.notificationEmail && Boolean(emailsFormikProps.errors.notificationEmail)}
                                />
                              </div>
                              <LoadingButton 
                                children={
                                  <div className={styles.emailNotificationFormButton}>
                                    <div className={styles.emailNotificationFormButtonIcon}>
                                      <Check fontSize={'inherit'}/>
                                    </div>
                                    <div>
                                      Dodaj
                                    </div>
                                  </div>
                                }
                                onClick={(ev) => {
                                  ev.preventDefault()
                                  emailsFormikProps.submitForm()
                                }}
                                style={{
                                  width: '150px',
                                  maxWidth: '150px',
                                  height: '30px',
                                  marginTop: '5px',
                                  marginLeft: '5px',
                                  backgroundColor: '#438737'
                                }}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  <div className={styles.submitButtonContainer}>
                    <LoadingButton 
                      text={t('save_button_label')}
                      type='submit'
                      loading={isSubmitting}
                      style={{
                        width: '150px',
                        maxWidth: '150px',
                        height: '40px'
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
      }
    </CustomDialog>
  )
}

export default AddSuperEventDialog
