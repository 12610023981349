import { SortData, SortDirection } from '../components/CustomTable/util'

export type RowData = {
  [key: string]: any,
  rawData?: (string | number)[]
  columns: (string | number | React.ReactNode)[]
}

export const sortRowData = (data: RowData[], sortData: SortData | null) => {
  if (sortData && !isNaN(parseInt(sortData.column)) && data.length > 0) {
    const col = parseInt(sortData.column)
    switch (typeof data[0].rawData[col]) {
      case 'number':
        data.sort((a: any, b: any) => {
          switch (sortData.order) {
            case SortDirection.ASC:
              return a.rawData[col] - b.rawData[col]
            case SortDirection.DESC:
              return b.rawData[col] - a.rawData[col]
            default: 
              return 0
          }
        })
        break
      case 'string':
        data.sort((a: any, b: any) => {
          switch (sortData.order) {
            case SortDirection.ASC:
              return (a.rawData[col] || '').localeCompare(b.rawData[col] || '', 'pl')
            case SortDirection.DESC:
              return (b.rawData[col] || '').localeCompare(a.rawData[col] || '', 'pl')
            default: 
              return 0
          }
        })
        break
    }
  }
}