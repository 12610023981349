import { AllSelectOptionsValue } from '../common/CustomSelectField/CustomSelectField'

export function mapSubRegionOptions (chosenRegions: string[], regions: any[]) {
  const options = []
  if (chosenRegions.includes(AllSelectOptionsValue)) {
    return options
  }
  for (let region of regions) {
    if (chosenRegions.includes(region.uuid)) {
      options.push(...region.subRegions.filter((e: any) => !e.doNotShow).map((e: any) => {
        return {
          name: e.name,
          label: `${e.name}${chosenRegions.length > 1 ? ` (${region.name})` : ''}`,
          value: e.uuid,
          towns: e.towns.filter((e: any) => !e.doNotShow)
        }
      }))
    }
  }

  return options
}

export function mapTownOptions (chosenSubRegions: string[], subRegions: any[]) {
  const options = []
  if (chosenSubRegions.length === 0 || chosenSubRegions.includes(AllSelectOptionsValue)) {
    for (let subRegion of subRegions) {
      options.push(...subRegion.towns.filter((e: any) => !e.doNotShow).map((e: any) => {
        return {
          name: e.name,
          label: `${e.name} (${subRegion.name})`,
          value: e.uuid,
          districts: e.districts
        }
      }))
    }
  } else {
    for (let subRegion of subRegions) {
      if (chosenSubRegions.includes(subRegion.value)) {
        options.push(...subRegion.towns.filter((e: any) => !e.doNotShow).map((e: any) => {
          return {
            name: e.name,
            label: `${e.name}${chosenSubRegions.length > 1 ? ` (${subRegion.name})` : ''}`,
            value: e.uuid,
            districts: e.districts
          }
        }))
      }
    }
  }

  return options
}

export function mapDistrictOptions (chosenTowns: string[], towns: any[]) {
  const options = []
  for (let town of towns) {
    if (chosenTowns.includes(town.value) || chosenTowns.includes(AllSelectOptionsValue)) {
      options.push(...town.districts.filter((e: any) => !e.doNotShow).map((e: any) => {
        return {
          name: e.name,
          label: `${e.name}${chosenTowns.length > 1 ? ` (${town.name})` : ''}`,
          value: e.uuid
        }
      }))
    }
  }

  return options
}

export function sortAndCompareSelectionArrays (arr1: string[], arr2: string[]): boolean {
  const arr2sorted = arr2.slice().sort();
  return arr1.length === arr2sorted.length && arr1.slice().sort().every((value, index) =>  value === arr2sorted[index])
}