import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon,
  Check, Close as CloseIcon,
  EditOutlined as EditOutlinedIcon, OpenInNewOutlined
} from "@material-ui/icons"
import { ErrorMessage, Form, Formik } from 'formik'
import 'intro.js/introjs.css'
import moment from 'moment'
import React, { useRef } from "react"
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from 'react-router-dom'
import DataApi from "../../api/DataApi"
import dialogIcon from '../../assets/images/dialog.webp'
import sendIcon from '../../assets/images/send.webp'
import trashIcon from '../../assets/images/Trash.webp'
import Checkbox from '../../lib/react-custom-checkbox'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { AdvertType, MarketType, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { PermissionLevel } from '../../types/auth'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList, MSG_SIGNATURE_LEN, TEMPLATE_MAX_MSG, TEMPLATE_MSG_LEN, TEMPLATE_MULTIPLE_MSG_LEN } from '../../types/common'
import { checkIfAdmin, getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { sortRowData } from '../../utils/sort'
import { evalStringTemplate } from '../../utils/text'
import { addNotificationEmail } from '../../utils/validations/addNotificationEmail'
import { addParamsForm } from '../../utils/validations/addParamsForm'
import { smsMessagePattern } from '../../utils/validations/validationRegexps'
import BaseInput from "../common/BaseInput/BaseInput"
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import CustomSelectField from "../common/CustomSelectField/CustomSelectField"
import LoadingButton from "../common/LoadingButton/LoadingButton"
import Pagination from '../common/Pagination/Pagination'
import StatTile from '../common/StatTile/StatTile'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData, SortDirection } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { eventsListStyle } from './eventsListStyle'
import { useEventsList } from './useEventsList'

const tableColumnData: ColumnData[] = [
  {
    label: 'Konto',
    name: '0',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  },
  {
    label: 'Nazwa\u00A0eventu',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    headerAlign: 'left',
    contentAlign: 'left',
    width: '33%'
  }, {
    label: 'Wysłane\u00A0SMSy',
    name: '3',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Liczba odpowiedzi',
    name: '4',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Konwersja',
    name: '5',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Status\u00A0eventu',
    name: 'active',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '5%'
  }
]

const childAccountTableColumnData: ColumnData[] = [
  {
    label: 'Nazwa\u00A0eventu',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '15px',
      right: '10px'
    },
    width: '15%'
  }, {
    label: 'Główne\u00A0parametry',
    headerAlign: 'left',
    contentAlign: 'left',
    width: '48%'
  }, {
    label: 'Wysłane\u00A0SMSy',
    name: '2',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Liczba odpowiedzi',
    name: '3',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Konwersja',
    name: '4',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: 'Status\u00A0eventu',
    name: 'active',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '8%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      bottom: '8px',
      right: '10px'
    },
    width: '5%'
  }
]

const EVENTS_PER_PAGE = 25

const EventsList = () => {

  const query = useQuery()
  const history = useHistory()

  const t = useTranslation("forms").t
  const tEventList = useTranslation("eventList").t
  const classes = eventsListStyle()
  const dispatch = useDispatch()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)

  const { 
    config, regions, data, smsTemplates, stats, assumedUser,
    fetchConfig, fetchRegionList, fetchData, fetchSmsTemplateList, fetchStats, fetchAssumedUser
	} = useEventsList()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [isEditingCustomTemplate, setIsEditingCustomTemplate] = React.useState<boolean>(false)

  const [subRegionList, setSubRegionList] = React.useState<any[]>([])
  const [townList, setTownList] = React.useState<any[]>([])
  const [districtList, setDistrictList] = React.useState<any[]>([])

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [chosenName, setChosenName] = React.useState<string>("")

  const [chosenRegion, setChosenRegion] = React.useState<string>("")
  const [chosenSubregion, setChosenSubregion] = React.useState<string>("")
  const [chosenTown, setChosenTown] = React.useState<string[]>([])
  const [chosenDistrict, setChosenDistrict] = React.useState<string[]>([])

  const [chosenTransactionType, setChosenTransactionType] = React.useState<string>("")
  const [chosenPropertyType, setChosenPropertyType] = React.useState<string>("")
  const [chosenPropertySubType, setChosenPropertySubType] = React.useState<string>("")

  const [chosenMinPrice, setChosenMinPrice] = React.useState<string>("")
  const [chosenMaxPrice, setChosenMaxPrice] = React.useState<string>("")
  const [chosenMinArea, setChosenMinArea] = React.useState<string>("")
  const [chosenMaxArea, setChosenMaxArea] = React.useState<string>("")
  const [chosenMinPricePerArea, setChosenMinPricePerArea] = React.useState<string>("")
  const [chosenMaxPricePerArea, setChosenMaxPricePerArea] = React.useState<string>("")

  // const [chosenMarketType, setChosenMarketType] = React.useState<string>("")
  const [chosenNotificationEmail, setChosenNotificationEmail] = React.useState<string>("")
  const [chosenNotificationEmails, setChosenNotificationEmails] = React.useState<string[]>([])
  // const [chosenSmsPrice, setChosenSmsPrice] = React.useState<string>("")
  const [chosenSmsTemplates, setChosenSmsTemplates] = React.useState<string[]>([])
  const [chosenCustomSmsTemplate, setChosenCustomSmsTemplate] = React.useState<string>('')
  const [tempCustomSmsTemplate, setTempCustomSmsTemplate] = React.useState<string>('')
  const [chosenSignature, setChosenSignature] = React.useState<string>("")

  const formRef = useRef() as any
  const emailsFormRef = useRef() as any
  const customTemplateFormRef = useRef() as any

  const resetFormState = () => {
    setChosenName('')
    setChosenRegion('')
    setChosenSubregion('')
    setChosenTown([])
    setChosenDistrict([])
    setChosenTransactionType('')
    setChosenPropertyType('')
    setChosenPropertySubType('')
    setChosenMinPrice('')
    setChosenMaxPrice('')
    setChosenMinArea('')
    setChosenMaxArea('')
    // setChosenSmsPrice('')
    setChosenSignature('')
    setChosenSmsTemplates([])
    setChosenMinPricePerArea('')
    setChosenMaxPricePerArea('')
    // setChosenMarketType('')
    setChosenNotificationEmail('')
    setChosenNotificationEmails([])
    setChosenCustomSmsTemplate('')
    setIsEditingCustomTemplate(false)
    setTempCustomSmsTemplate('')
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const resetEmailsFormState = () => {
    setChosenNotificationEmail('')
    if (emailsFormRef.current) {
      emailsFormRef.current.resetForm()
    }
  }

  const resetCustomTemplateFormState = () => {
    setTempCustomSmsTemplate('')
    if (customTemplateFormRef.current) {
      customTemplateFormRef.current.resetForm()
    }
  }

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchStats(from, to, getFromQuery(query, 'assume')).then(res => {
        fetchData(sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 

  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchData(undefined, undefined, from, to, getFromQuery(query, 'assume')).then(res => {
        setSelectedPage(1)
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      switch(sortData.column) {
        case 'name':
        case 'bidValue':
        case 'active':
        case 'marketType':
          setIsReloadingData(true)
          fetchData(sortData.column, sortData.order, from, to, getFromQuery(query, 'assume')).then(res => {
            setIsReloadingData(false)
          }).catch(error => { onErrorResponseShowMessage(error)})
        default: 
          const tmpData = [...tableData]
          sortRowData(tmpData, sortData)
          setTableData([...tmpData])  
      }
      setSelectedPage(1)
    }
  }, [sortData])

  React.useEffect(() => {
    setChosenSmsTemplates([])
    formRef.current.setFieldValue('smsTemplate', [])
    if (chosenPropertyType !== `${AdvertType.PLOT}`) {
      setChosenPropertySubType('')
      formRef?.current?.setFieldValue('propertySubType', '')
    }
  }, [chosenPropertyType])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchConfig().then(res => {
        fetchRegionList(getFromQuery(query, 'assume')).then(res => {
          fetchSmsTemplateList(getFromQuery(query, 'assume')).then(res => {
            fetchStats(undefined, undefined, getFromQuery(query, 'assume')).then(res => {
              fetchData(undefined, undefined, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
                setIsLoading(false)
              }).catch(error => { onErrorResponseShowMessage(error)})
            }).catch(error => { onErrorResponseShowMessage(error)})
          }).catch(error => { onErrorResponseShowMessage(error)})
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
  }, [selectedPage])

  React.useEffect(() => {
    const tmp = data.map((e, idx) => {
      let params = e.params.region.name
      if (e.params.subregion) {
        params += `; Powiat: ${e.params.subregion.name}`
      }
      if (e.params.town) {
        params += `; Miejscowość: ${e.params.town.name}`
      }
      if (e.params.district) {
        params += `; Dzielnica: ${e.params.district.name}`
      }
      if (e.params.towns) {
        params += '; Miejscowości: '
        for (const [idx, town] of e.params.towns.entries()) {
          if (idx > 0) params += ', '
          params += `${town.name}`
        }
      }
      if (e.params.districts) {
        params += '; Dzielnice: '
        for (const [idx, district] of e.params.districts.entries()) {
          if (idx > 0) params += ', '
          params += `${district.name}${e.params.towns.length > 1 ? ` (${district.town.name})` : ''}`
        }

      }

      params += `; 
      ${
        propertyTypes.find(el => el.value === `${e.params.advertType}`).label
      }${
        e.params.advertType === AdvertType.PLOT && e.params.advertSubType != null 
        ? ` - ${plotTypes.find(el => el.value === `${e.params.advertSubType}`).label}` 
        : ''}`
      params += `; ${transactionTypes.find(el => el.value === `${e.params.offerType}`).label}`
      params += `; Pow.: ${e.params.minArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2 - ${e.params.maxArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2`
      params += `; Cena: ${e.params.minPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł - ${e.params.maxPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł`

      if (permissions.permissions.includes(PermissionLevel.CHILD)) {
        return {
          uuid: e.uuid,
          active: e.active,
          rawData: [
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? 0 : e.responseCount/e.wonBidCount*100,
            e.active,
            ''
          ],
          columns: [
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? '0,00%' : `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%`,
            e.active
            ? <span style={{ color: Colors.ACTIVE_LABEL, wordBreak: 'keep-all' }}>{tEventList('active_label')}</span>
            : <span style={{ color: Colors.INACTIVE_LABEL, wordBreak: 'keep-all' }}>{tEventList('inactive_label')}</span>,
            <Link to={`/event/details/${e.uuid}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} className={classes.manageLink}>
              {assumedIdentity ? t('see_label') : t('manage_label')}
            </Link>
          ]
        }
      } else {
        return {
          uuid: e.uuid,
          active: e.active,
          rawData: [
            e.mainAccount ? 'KONTO GŁÓWNE' : e.userEmail,
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? 0 : e.responseCount/e.wonBidCount*100,
            e.active,
            ''
          ],
          columns: [
            e.mainAccount ? 'KONTO GŁÓWNE' : e.userEmail,
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? '0,00%' : `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%`,
            e.active
            ? <span style={{ color: Colors.ACTIVE_LABEL, wordBreak: 'keep-all' }}>{tEventList('active_label')}</span>
            : <span style={{ color: Colors.INACTIVE_LABEL, wordBreak: 'keep-all' }}>{tEventList('inactive_label')}</span>,
            <Link to={`/event/details/${e.uuid}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} className={classes.manageLink}>
              {assumedIdentity ? t('see_label') : t('manage_label')}
            </Link>
          ]
        }
      }

      
    })
    if (sortData!= null && !['name', 'bidValue', 'active', 'marketType'].includes(sortData.column)) {
      sortRowData(tmp, sortData)
      setTableData(tmp)
    } else {
      setTableData(tmp)
    }
    setPages(Math.ceil(tmp.length / EVENTS_PER_PAGE))
  }, [data])

  React.useEffect(() => {
    if (regions) {
      setChosenRegion('')
      formRef.current.setFieldValue('region', '')
    }
  }, [regions])

  React.useEffect(() => {
    if (chosenRegion !== '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const subRegions = selRegion.subRegions.filter(e => !e.doNotShow).map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setSubRegionList(subRegions)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      if (!(subRegions.findIndex(e => e.value === chosenSubregion) > -1)) {
        setChosenSubregion('')
        formRef.current.setFieldValue('subRegion', '')
      }
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion === '') {
      setSubRegionList([])
      setTownList([])
      setDistrictList([])
      setChosenSubregion('')
      setChosenTown([])
      setChosenDistrict([])
      formRef.current.setFieldValue('subRegion', '')
      formRef.current.setFieldValue('town', [])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenRegion])

  React.useEffect(() => {
    if (chosenRegion !== '' && chosenSubregion !== '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const towns = selSubregion.towns.map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setTownList(towns)
      const selectedTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion !== '' && chosenSubregion === '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    }
  }, [chosenSubregion])

  React.useEffect(() => {
    if (chosenRegion !== '' && chosenSubregion === '' && chosenTown.length > 0) {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregions = selRegion.subRegions.filter(e => e.towns.findIndex(el => chosenTown.includes(el.uuid)) > -1)
      const selTowns: any[] = []
      for (const subregion of selSubregions) {
        selTowns.push(...subregion.towns.filter(e => chosenTown.includes(e.uuid)))
      }
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: selTowns.length > 1 ? `${e.name} (${selTown.name})` : e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion !== '' && chosenTown.length > 0) {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const selTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion !== '' && chosenTown.length === 0) {
      setDistrictList([])
      setChosenDistrict([])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenTown])

  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowAddDialog(false)
    }
  }, [message])

  // React.useEffect(() => {
  //   if (!isLoading) {
  //     introJs().setOptions({
  //       nextLabel: 'Następny',
  //       prevLabel: 'Poprzedni',
  //       // skipLabel: 'Pomiń',
  //       doneLabel: 'Zakończ',
  //       tooltipPosition: 'auto'
  //     }).start()
  //   }
  // }, [isLoading])

  const closeAddDialog = () => {
    resetFormState()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddDialog(false)
  }

  const handleEmailFormSubmit = (data: any, form: any) => {
    const newArray = [...chosenNotificationEmails]
    newArray.push(data.notificationEmail.trim())
    setChosenNotificationEmails(newArray)
    formRef.current.setFieldValue('notificationEmails', newArray)
    resetEmailsFormState()
  }

  const handleSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        name: data.name === '' ? undefined : data.name.trim(),
        // bidValue: data.smsPrice === '' ? undefined : parseFloat(data.smsPrice.replace(',', '.')),
        params: {
          advertType: data.propertyType === '' ? undefined : parseInt(data.propertyType),
          advertSubType: data.propertySubType === '' ? undefined : parseInt(data.propertySubType),
          offerType: data.transactionType === '' ? undefined : parseInt(data.transactionType),
          regionUuid: data.region === '' ? undefined : data.region,
          subRegionUuid: data.subRegion === '' ? undefined : data.subRegion,
          townUuids: data.town === null ? undefined : data.town,
          districtUuids: data.district == null ? undefined : data.district,
          minArea: data.minArea === '' ? undefined : parseFloat(data.minArea.replace(',', '.')),
          maxArea: data.maxArea === '' ? undefined : parseFloat(data.maxArea.replace(',', '.')),
          minPrice: data.minPrice === '' ? undefined : parseFloat(data.minPrice.replace(',', '.')),
          maxPrice: data.maxPrice === '' ? undefined : parseFloat(data.maxPrice.replace(',', '.')),
          minPricePerArea: !data.minPricePerArea || data.minPricePerArea === '' ? undefined : parseFloat(data.minPricePerArea.replace(',', '.')),
          maxPricePerArea: !data.maxPricePerArea || data.maxPricePerArea === '' ? undefined : parseFloat(data.maxPricePerArea.replace(',', '.'))
        },
        notificationEmails: data.notificationEmails,
        // TODO commented temporary
        // marketType: data.marketType === '' ? undefined : parseInt(data.marketType),
        marketType: MarketType.PRIMARY,
        smsMessageUuids: data.smsTemplate,
        customMessage: data.customTemplate === '' ? undefined :data.customTemplate,
        signature: !data.signature || data.signature === '' ? undefined : data.signature
      }
      await DataApi.addSearchParams(mappedData)
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      await fetchData(undefined, undefined, from, to)
      setSortData({column: '', order: SortDirection.DESC, default: true})
      resetFormState()
      setIsSubmitting(false)
      closeAddDialog()
    } catch (error) {
      if (error.response?.status === 409 && error.response?.data?.code === 200 && error.response?.data?.subcode === 218) {
        try {
          await fetchRegionList()
        } catch (error) {
			    onErrorResponseShowMessage(error)
        }
      } else if (error.response?.status === 409 && error.response?.data?.code === 200 && error.response?.data?.subcode === 219) {
        formRef.current.setFieldTouched('town', true, false)
        formRef.current.setFieldError('town', t('common:field_required'))
      }
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const addDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('add_new_event_section_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeAddDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={formRef}
          validationSchema={addParamsForm(tEventList)}
          initialValues={{
            name: "",
            minPrice: "",
            maxPrice: "",
            minArea: "",
            maxArea: "",
            // smsPrice: "",
            region: "",
            subRegion: "",
            town: [],
            district: [],
            transactionType: "",
            propertyType: "",
            propertySubType: "",
            signature: "",
            minPricePerArea: "",
            maxPricePerArea: "",
            // TODO commented temporary
            // marketType: "",
            notificationEmails: [] as string[],
            smsTemplate: [] as string[],
            customTemplate: ''
          }}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                borderBottom: '1px solid #BFC5D2'
              }}>
                <p className={classes.fieldLabel}>
                  {t('event_name_field_label')}
                </p>
                <BaseInput 
                  count
                  maxLen={240}
                  name="name" 
                  placeholder={t('event_name_field_placeholder')}
                  formikProps={formikProps}
                  value={chosenName}
                  setValue={setChosenName}
                  error={
                    formikProps.touched.name &&
                    Boolean(formikProps.errors.name)
                  }
                />
              </div>
              <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('advert_search_params_section_label')}
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('location_field_label')}
                    </p>
                    <div 
                      className={`${classes.clearButton}`} 
                      onClick={() => {
                        setChosenRegion("")
                        formikProps.setFieldValue('region', '')
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'region-field'}
                      name="region"
                      placeholder={'Województwo'}
                      options={regions.map(e => {
                        return {
                          value: e.uuid,
                          label: e.name
                        }
                      }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenRegion}
                      setValue={setChosenRegion}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.region &&
                        Boolean(formikProps.errors.region)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithoutLabel}>
                    <div 
                      hidden={chosenRegion === ''}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenSubregion("")
                        formikProps.setFieldValue('subRegion', '')
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'subRegion-field'}
                      disabled={chosenRegion === ''}
                      name="subRegion"
                      placeholder={'Powiat'}
                      options={subRegionList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenSubregion}
                      setValue={setChosenSubregion}
                      formikProps={formikProps}
                      warning={
                        chosenSubregion != null && chosenSubregion != ''
                      }
                      warningMessage={t('subregion_warning_label')}
                      error={
                        formikProps.touched.subRegion &&
                        Boolean(formikProps.errors.subRegion)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithoutLabel}>
                    <div 
                      hidden={chosenRegion === ''}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenTown([])
                        formikProps.setFieldValue('town', [])
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'town-field'}
                      disabled={chosenRegion === ''}
                      name="town"
                      isMulti
                      placeholder={'Miasto/Miejscowość'}
                      options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenTown}
                      setValue={setChosenTown}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.town &&
                        Boolean(formikProps.errors.town)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithoutLabel}>
                    <div 
                      hidden={chosenTown.length === 0}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenDistrict([])
                        formikProps.setFieldValue('district', [])
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'district-field'}
                      disabled={chosenTown.length === 0}
                      name="district"
                      isMulti
                      placeholder={'Dzielnica (opcjonalnie)'}
                      options={districtList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenDistrict}
                      setValue={setChosenDistrict}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.district &&
                        Boolean(formikProps.errors.district)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                    {t('transaction_type_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenTransactionType("")
                      formikProps.setFieldValue('transactionType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'transaction-type-field'}
                      name="transactionType"
                      placeholder={'Wybierz typ transakcji'}
                      options={transactionTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenTransactionType}
                      setValue={setChosenTransactionType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.transactionType &&
                        Boolean(formikProps.errors.transactionType)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                    {t('property_type_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenPropertyType('')
                      setChosenSmsTemplates([])
                      formikProps.setFieldValue('propertyType', '')
                      formikProps.setFieldValue('smsTemplate', [])
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'property-type-field'}
                      name="propertyType"
                      placeholder={'Wybierz typ nieruchomości'}
                      options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenPropertyType}
                      setValue={setChosenPropertyType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.propertyType &&
                        Boolean(formikProps.errors.propertyType)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithLabel} style={{
                    ...(chosenPropertyType !== `${AdvertType.PLOT}` ? { display: 'none' } : {})
                  }}>
                    <p className={classes.fieldLabel}>
                    {t('plot_type_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenPropertySubType("")
                      formikProps.setFieldValue('propertySubType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'property-subtype-field'}
                      name="propertySubType"
                      placeholder={t('plot_type_field_placeholder')}
                      options={plotTypes}
                      value={chosenPropertySubType}
                      setValue={setChosenPropertySubType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.propertySubType &&
                        Boolean(formikProps.errors.propertySubType)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_price_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinPrice("")
                      formikProps.setFieldValue('minPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minPrice" 
                      placeholder={t('min_price_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMinPrice}
                      monetaryValue
                      setValue={setChosenMinPrice}
                      error={
                        formikProps.touched.minPrice &&
                        Boolean(formikProps.errors.minPrice)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_price_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxPrice("")
                      formikProps.setFieldValue('maxPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxPrice" 
                      placeholder={t('max_price_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMaxPrice}
                      setValue={setChosenMaxPrice}
                      monetaryValue
                      error={
                        formikProps.touched.maxPrice &&
                        Boolean(formikProps.errors.maxPrice)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_area_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinArea("")
                      formikProps.setFieldValue('minArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minArea" 
                      placeholder={t('min_area_field_placeholder')}
                      formikProps={formikProps}
                      monetaryValue
                      value={chosenMinArea}
                      setValue={setChosenMinArea}
                      error={
                        formikProps.touched.minArea &&
                        Boolean(formikProps.errors.minArea)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_area_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxArea("")
                      formikProps.setFieldValue('maxArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxArea" 
                      placeholder={t('max_area_field_placeholder')}
                      formikProps={formikProps}
                      monetaryValue
                      value={chosenMaxArea}
                      setValue={setChosenMaxArea}
                      error={
                        formikProps.touched.maxArea &&
                        Boolean(formikProps.errors.maxArea)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_price_per_area_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinPricePerArea("")
                      formikProps.setFieldValue('minPricePerArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minPricePerArea" 
                      placeholder={t('min_price_per_area_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMinPricePerArea}
                      setValue={setChosenMinPricePerArea}
                      monetaryValue
                      error={
                        formikProps.touched.minPricePerArea &&
                        Boolean(formikProps.errors.minPricePerArea)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_price_per_area_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxPricePerArea("")
                      formikProps.setFieldValue('maxPricePerArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxPricePerArea" 
                      placeholder={t('max_price_per_area_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMaxPricePerArea}
                      setValue={setChosenMaxPricePerArea}
                      monetaryValue
                      error={
                        formikProps.touched.maxPricePerArea &&
                        Boolean(formikProps.errors.maxPricePerArea)
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              > */}
                {/* <div className={classes.sectionLabel}>
                  {t('sms_settings_section_label')}
                </div> */}
                {/* TODO temporary commented */}
                {/* <div className={classes.rowContainer}>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('market_type_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMarketType("")
                      formikProps.setFieldValue('marketType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'market-tyoe-type-field'}
                      name="marketType"
                      placeholder={t('market_type_field_placeholder')}
                      options={marketTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenMarketType}
                      setValue={setChosenMarketType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.marketType &&
                        Boolean(formikProps.errors.marketType)
                      }
                    />
                  </div>
                </div> */}
                {/* <div className={classes.rowContainer}>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('sms_price_field_label')}
                    </p>
                    <div className={`${classes.clearButton}`} onClick={()=> {
                      setChosenSmsPrice("")
                      formikProps.setFieldValue('smsPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="smsPrice" 
                      placeholder={getSmsPricePlaceholder(chosenPropertyType)}
                      formikProps={formikProps}
                      value={chosenSmsPrice}
                      setValue={setChosenSmsPrice}
                      monetaryValue
                      error={
                        formikProps.touched.smsPrice &&
                        Boolean(formikProps.errors.smsPrice)
                      }
                    />
                  </div>
                </div> */}
              {/* </div> */}
              <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('sms_content_settings_section_label')}
                </div>
                <div className={classes.formSectionWarningLabel}>
                  {t('sms_settings_warning_label')}
                </div>
                <div 
                  className={classes.fieldWithLabel} 
                  style={{
                    width: '47%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('signature_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={MSG_SIGNATURE_LEN}
                    name="signature" 
                    inputPattern={smsMessagePattern}
                    placeholder={t('signature_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenSignature}
                    setValue={setChosenSignature}
                    error={
                      formikProps.touched.signature &&
                      Boolean(formikProps.errors.signature)
                    }
                  />
                </div>
                <div style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}>
                  <div className={classes.fieldLabel}>
                    {evalStringTemplate(t('sms_content_field_label'), {
                      cur: formikProps.values['smsTemplate'].length,
                      max: smsTemplates.filter(e => !e.removed && `${e.advertType}` === chosenPropertyType).length,
                      custom: chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' ? ' + wiadomość własna' : ''
                      // max: smsTemplates.length < MAX_SMS_TEMPLATES ? smsTemplates.length : MAX_SMS_TEMPLATES
                    })}
                  </div>
                  <div className={classes.fieldSubLabel} style={{ marginTop: '15px' }}>
                    {t('available_templates_label')}
                  </div>
                  {
                    smsTemplates.filter(e => !(e.removed  || `${e.advertType}` !== chosenPropertyType)).length === 0
                    ? <div
                        style={{
                          height: '30px',
                          marginTop: '10px',
                          marginLeft: '5px',
                          fontFamily: 'AvenirBook',
                          fontSize: '15px',
                          color: 'rgba(109,114,120,0.5)'
                        }}
                      >
                        {t('no_templates_available_label')}
                      </div>
                    : smsTemplates.map(e => (
                      <>
                        {
                          e.removed || `${e.advertType}` !== chosenPropertyType
                          ? null
                          : <div style={{
                              marginBottom: '2px',
                            }}>
                              <Checkbox 
                                name="smsTemplate"
                                checked={chosenSmsTemplates.includes(e.uuid)}
                                disabled={!chosenSmsTemplates.includes(e.uuid) && chosenSmsTemplates.length >= smsTemplates.length }
                                onBlur={() => {
                                  formikProps.setFieldTouched('smsTemplate')
                                }}
                                onChange={(checked) => {
                                  formikProps.setFieldTouched('smsTemplate')
                                  if (!checked && formikProps.values['smsTemplate'].includes(e.uuid)) {
                                    const indexToDelete = formikProps.values['smsTemplate'].indexOf(e.uuid)
                                    const newArray = [...formikProps.values['smsTemplate']]
                                    newArray.splice(indexToDelete, 1)
                                    formikProps.setFieldValue('smsTemplate', newArray)
                                    setChosenSmsTemplates(newArray)
                                  } else if (checked  && !formikProps.values['smsTemplate'].includes(e.uuid)) {
                                    formikProps.setFieldValue('smsTemplate', [
                                      ...formikProps.values['smsTemplate'],
                                      e.uuid
                                    ])
                                    setChosenSmsTemplates([
                                      ...formikProps.values['smsTemplate'],
                                      e.uuid
                                    ])
                                  }
                                }}
                                borderRadius={3}
                                size={15}
                                containerStyle={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  marginTop: '5px'
                                }}
                                style={{
                                  marginTop: '2px'
                                }}
                                icon={
                                  <div style={{
                                    fontSize: '15px',
                                    borderRadius: 3,
                                    height: '15px',
                                    color: Colors.CHECKBOX_ICON,
                                    backgroundColor: Colors.CHECKBOX_CHECKED,
                                    margin: 0
                                  }}>
                                    <Check fontSize={'inherit'} color={'inherit'}/>
                                  </div>
                                }
                                borderColor={Colors.CHECKBOX_BORDER}
                                labelStyle={{
                                  fontFamily: 'AvenirBook',
                                  color: Colors.LIGHT_GRAY_TEXT,
                                  fontSize: '15px',
                                  paddingLeft: '5px',
                                  whiteSpace: 'break-spaces'
                                }}
                                label={
                                  <>
                                    {
                                      e.template
                                    }{
                                      e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                        ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                        : null
                                    }
                                  </>
                                }
                                />
                            </div>
                        }
                      </>
                    ))
                  }
                  {
                    formikProps.touched.smsTemplate && Boolean(formikProps.errors.smsTemplate)
                    ? <div className={classes.errorLabel} style={{
                      float: 'left'
                    }}>
                      <ErrorMessage name={'smsTemplate'} />
                    </div> : null
                  }
                  {
                    chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' && !isEditingCustomTemplate
                    ? <>
                        <div className={classes.fieldSubLabel} style={{marginTop: '20px'}}>
                          {t('custom_templates_label')}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-start'
                          }}
                        >
                          <div
                            style={{
                              width: '90%',
                              fontFamily: 'AvenirBook',
                              color: Colors.LIGHT_GRAY_TEXT,
                              fontSize: '15px',
                              paddingLeft: '5px',
                              whiteSpace: 'break-spaces',
                              marginTop: '3px',
                              wordBreak: 'break-all'
                            }}
                          >
                            {chosenCustomSmsTemplate}{
                              chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                : null
                            }
                          </div>
                          <div
                            style={{
                              width: '5%',
                              textAlign: 'center',
                              color: 'blue',
                              fontSize: '22px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setIsEditingCustomTemplate(true)
                              setTempCustomSmsTemplate(chosenCustomSmsTemplate)
                            }}
                          >
                            <EditOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                          </div>
                          <div
                            style={{
                              width: '5%',
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setChosenCustomSmsTemplate('')
                              formikProps.setFieldValue('customTemplate', '')
                            }}
                          >
                            <img src={trashIcon} alt="remove-item" />
                          </div>

                        </div>
                      </>
                    : <>
                        <Formik
                          innerRef={customTemplateFormRef}
                          validateOnMount={true}
                          enableReinitialize
                          initialValues={{
                            customTemplate: tempCustomSmsTemplate
                          }}
                          onSubmit={(data) => {
                            setChosenCustomSmsTemplate(data.customTemplate)
                            setIsEditingCustomTemplate(false)
                            formikProps.setFieldValue('customTemplate', data.customTemplate)
                            resetCustomTemplateFormState()
                          }}
                        >
                          {(customTemplateFormikProps) => (
                            <Form
                              onKeyDown={(keyEvent) => {
                                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                  keyEvent.preventDefault()
                                }
                              }}
                              style={{
                                width: "100%",
                                marginTop: '20px'
                              }}
                            >
                              <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}>
                                <div 
                                  style={{
                                    width: '100%',
                                    position: 'relative',
                                    // marginBottom: '10px',
                                  }}
                                >
                                  <p className={classes.fieldLabel}>
                                    {t('add_custom_template_label')}
                                  </p>
                                  <BaseInput 
                                    count
                                    maxLen={
                                      TEMPLATE_MAX_MSG > 1
                                      ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                                      : TEMPLATE_MAX_MSG
                                    }
                                    rows={4}
                                    name="customTemplate"
                                    placeholder={t('add_custom_template_placeholder')}
                                    formikProps={customTemplateFormikProps}
                                    inputPattern={smsMessagePattern}
                                    value={tempCustomSmsTemplate}
                                    setValue={setTempCustomSmsTemplate}
                                    error={
                                      customTemplateFormikProps.touched.customTemplate &&
                                      Boolean(customTemplateFormikProps.errors.customTemplate)
                                    }
                                  />
                                </div>
                              </div>
                              <div 
                                style={{
                                  marginRight: '10%',
                                  fontFamily: 'AvenirBook',
                                  fontSize: '14px',
                                  marginTop: '-15px',
                                  marginBottom: '37px'
                                }}
                              >
                                {
                                 tempCustomSmsTemplate.length > 0 ? <>
                                    {`Liczba wiadomości:\u00a0`}{
                                      tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN
                                        ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{Math.ceil((tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)}</span>
                                        : 1
                                    }
                                  </> : '\u00A0'
                                }
                              </div>
                              <div style={{
                                float: 'right',
                                marginTop: '-45px'
                              }}>
                                <LoadingButton 
                                  children={
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '15px'
                                      }}
                                    >
                                      {
                                        isEditingCustomTemplate 
                                        ? <>
                                            <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                              <EditOutlinedIcon fontSize={'inherit'}/>
                                            </div>
                                            <div>
                                              Zapisz
                                            </div>
                                          </>
                                        : <>
                                            <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                              <Check fontSize={'inherit'}/>
                                            </div>
                                            <div>
                                              Dodaj
                                            </div>
                                          </>
                                      }

                                    </div>
                                  }
                                  type='submit'
                                  onClick={(ev) => {
                                    ev.preventDefault()
                                    customTemplateFormikProps.submitForm()
                                  }}
                                  style={{
                                    width: '150px',
                                    maxWidth: '150px',
                                    height: '30px',
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    backgroundColor: '#438737'
                                  }}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </> 
                  }
                </div>
              </div>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('email_notifications_search_params_section_label')}
                </div>
                <div
                  className={classes.fieldWithLabel} 
                  style={{
                    width: '100%',
                    marginTop: '10px'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('notifications_emails_list_field_label')}
                  </p>
                  {
                    chosenNotificationEmails.length === 0
                    ? <div
                        style={{
                          height: '30px',
                          marginTop: '10px',
                          marginLeft: '5px',
                          fontFamily: 'AvenirBook',
                          fontSize: '15px',
                          color: 'rgba(109,114,120,0.5)'
                        }}
                      >
                        {t('no_emails_added_label')}
                      </div>
                    : <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        {
                          chosenNotificationEmails.map((e: string, idx: number) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center',
                                  fontFamily: 'AvenirBook',
                                  height: '30px',
                                  backgroundColor: 'rgba(216,216,216,0.5)',
                                  color: '#8798AD',
                                  marginRight: '5px',
                                  marginBottom: '3px',
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                  paddingLeft: '10px',
                                  paddingRight: '5px',
                                  borderRadius: '5px'
                                }}
                              >
                                <div>
                                  {e}
                                </div>
                                <div 
                                  style={{
                                    marginTop: '7px',
                                    fontSize: '20px'
                                  }}
                                >
                                  <span 
                                    style={{
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      const newArray = [...chosenNotificationEmails]
                                      newArray.splice(idx, 1)
                                      setChosenNotificationEmails(newArray)
                                      formikProps.setFieldValue('notificationEmails', newArray)
                                    }}
                                  >
                                    <CloseIcon fontSize={'inherit'} color={'inherit'} />
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                  }
                </div>
                <div
                  className={classes.fieldWithLabel} 
                  style={{
                    width: '100%',
                    marginTop: '15px'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('notification_email_field_label')}
                  </p>
                  <Formik
                    innerRef={emailsFormRef}
                    validateOnMount={true}
                    enableReinitialize
                    validationSchema={addNotificationEmail(tEventList, chosenNotificationEmails)}
                    initialValues={{
                      notificationEmail: ''
                    }}
                    onSubmit={handleEmailFormSubmit}
                  >
                    {(emailsFormikProps) => (
                      <Form
                        onKeyDown={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault()
                          }
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <div
                            style={{
                              width: '47%'
                            }}
                          >
                            <BaseInput 
                              name="notificationEmail" 
                              placeholder={t('notification_email_placeholder')}
                              formikProps={emailsFormikProps}
                              value={chosenNotificationEmail}
                              setValue={setChosenNotificationEmail}
                              error={
                                emailsFormikProps.touched.notificationEmail &&
                                Boolean(emailsFormikProps.errors.notificationEmail)
                              }
                            />
                          </div>
                          <LoadingButton 
                            children={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '15px'
                                }}
                              >
                                <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                  <Check fontSize={'inherit'}/>
                                </div>
                                <div>
                                  Dodaj
                                </div>
                              </div>
                            }
                            onClick={(ev) => {
                              ev.preventDefault()
                              emailsFormikProps.submitForm()
                            }}
                            style={{
                              width: '150px',
                              maxWidth: '150px',
                              height: '30px',
                              marginTop: '5px',
                              marginLeft: '5px',
                              backgroundColor: '#438737'
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('save_button_label')}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  // const getSmsPricePlaceholder = (type: string) => {
	// 	// TODO temporary changes with marketType
  //   // if (!chosenMarketType || chosenMarketType === '') {
  //   //   return evalStringTemplate(t('sms_price_field_placeholder'), {
  //   //     val: '0,16'
  //   //   })
  //   // }
    
  //   const marketType = `${MarketType.PRIMARY}`

  //   let priceConfig
  //   if (config) {
  //     switch (marketType) {
  //     // switch (chosenMarketType) {
  //       case `${MarketType.PRIMARY}`:
  //         priceConfig = config.config.primaryMarketMinBidValue
  //         break
  //       case `${MarketType.SECONDARY}`:
  //         priceConfig = config.config.secondaryMarketMinBidValue
  //         break
  //       default: 
  //         return evalStringTemplate(t('sms_price_field_placeholder'), {
  //           val: '0,16'
  //         })
  //     }
  //   } else {
  //     switch (marketType) {
  //     // switch (chosenMarketType) {
  //       case `${MarketType.PRIMARY}`:
  //         priceConfig = defaultConfig.primaryMarketMinBidValue
  //         break
  //       case `${MarketType.SECONDARY}`:
  //         priceConfig = defaultConfig.secondaryMarketMinBidValue
  //         break
  //       default:
  //         return evalStringTemplate(t('sms_price_field_placeholder'), {
  //           val: '0,16'
  //         })
  //     }
  //   }
  //   switch (type) {
  //     case `${AdvertType.FLAT}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.flat).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.HOUSE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.house).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.PLOT}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.plot).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.SERVICE_PREMISE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.servicePremise).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.WAREHOUSE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.warehouse).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.GARAGE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.garage).toFixed(2).replace('.', ',')
  //       })
  //     default: 
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: '0,16'
  //       })
  //   }
  // }

  return (
    <>
      <CustomDialog hidden={!showAddDialog}>
        {addDialogContent()}
      </CustomDialog>

      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>

      <div  style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        {
          assumedIdentity 
          ? <div className={classes.assumedUserInfo}>
              <div
                style={{
                  marginRight: '10px'
                }}
              >
                {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
              </div>
              <CustomButton 
                textComponent={
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-1px',
                    fontSize: '18px'
                  }}>
                    <OpenInNewOutlined fontSize={'inherit'}/>
                    <span style={{
                      marginLeft: '3px',
                      marginTop: '2px',
                      fontSize: '12px',
                      fontFamily: 'Avenir'
                    }}>{t('close_user_assume_view_button_label')}</span>
                  </div>
                }
                style={{
                  width: '200px',
                  maxWidth: '200px',
                  height: '35px',
                  paddingLeft: '10px',
                  paddingRight: '15px',
                  paddingTop: '7px'
                }}
                onClick={() => {
                  window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                }}
              />
            </div>
          : <CustomButton 
              // data-intro={'Testujemy'}
              // data-step={1}
              // data-disable-interaction={true}
              disabled={tableData.filter(e => e.active).length >= (config?.config.activeSearchParamsLimit || 0)}
              textComponent={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-1px'
                }}>
                  <AddIcon />
                  <span style={{
                    marginTop: '1px'
                  }}>{t('add_new_event_button_label')}</span>
                </div>
              }
              style={{
                width: '150px',
                maxWidth: '150px',
                height: '35px',
                paddingLeft: '10px',
                paddingRight: '15px',
                paddingTop: '7px'
              }}
              onClick={() => {
                dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                setShowAddDialog(true)
              }}
            />
        }
        <div 
          className={classes.listCount} 
          // data-intro={'Testujemy 2'}
          // data-step={2}
          // data-disable-interaction={true}
        >
          {evalStringTemplate(tEventList('event_count_pattern'), {
            current: tableData.filter(e => e.active).length,
            max: config?.config.activeSearchParamsLimit || 0
          })}
        </div>
        <div style={{
          width: '100%'
        }}>
          <div className={classes.controlRow}>
            <div className={classes.dateRangeField}>
              <div className={classes.dateRangeLabel}>
                {t('date_range_label')}
              </div>
              <div 
                className={classes.clearButton} 
                onClick={() => {
                  setSelectedDateRange(null)
                }}
              >
                {t('clear_field_button_label')}
              </div> 
              <Flatpickr
                id={"date-range-picker"}
                data-enable-time
                value={
                  selectedDateRange && selectedDateRange.length >= 2
                  ? [
                    moment(selectedDateRange[0]).startOf("day").toDate(),
                    moment(selectedDateRange[1]).startOf("day").toDate(),
                  ]
                  : []
                }
                options={{
                  locale: {
                    firstDayOfWeek: 1,
                    rangeSeparator: ' - ',
                    months: {
                      shorthand: monthList,
                      longhand: monthList
                    },
                    weekdays: {
                      shorthand: dayListShorthand,
                      longhand: dayList
                    }
                  },
                  dateFormat: "d.m.Y",
                  mode: 'range',
                }}
                onClose={(date) => {
                  if (date && date.length === 2) {
                    setSelectedDateRange(date)
                  }
                }}
                render={({ defaultValue, value, ...props }, ref) => {
                  return (
                    <CustomFlatpickrInput
                      defaultValue={defaultValue}
                      inputRef={ref}
                      width={'100%'}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className={classes.statTileSection}>
            {/* <div className={classes.auctionCountTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#4566C0'}
                label={'Liczba aukcji'}
                iconSrc={shareIcon}
                value={`${stats.auctionCount}`}
              />
            </div> */}

            <div className={classes.msgSentTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#F6AE3F'}
                label={'Wysłane wiadomości'}
                iconSrc={sendIcon}
                value={`${stats.messageSentCount}`}
              />
            </div>

            <div className={classes.responsesCountTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#F64A3F'}
                label={'Odpowiedzi'}
                iconSrc={dialogIcon}
                value={`${stats.responsesCount}`}
              />
            </div>

            <div className={classes.emptySpaceTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#FFFFFF'}
                label={''}
                iconSrc={''}
                value={''}
              />
            </div>

            <div className={classes.totalPriceTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#FFFFFF'}
                label={''}
                iconSrc={''}
                value={''}
              />
            </div>

            <div className={classes.totalPriceTile}>
              <StatTile
                textColor={'#FFFFFF'}
                backgroundColor={'#FFFFFF'}
                label={''}
                iconSrc={''}
                value={''}
              />
            </div>
            
            
          </div>
          <CustomTable 
            columnData={permissions.permissions.includes(PermissionLevel.CHILD) ? childAccountTableColumnData : tableColumnData}
            reloadingData={isReloadingData}
            content={tableData.slice((selectedPage - 1) * EVENTS_PER_PAGE, selectedPage * EVENTS_PER_PAGE)}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            placeholder={
            <div 
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                fontFamily: 'AvenirMedium',
                color: Colors.TABLE_HEADER_LABEL,
                fontSize: '16px'
              }}
            >
              <div>
                {t('no_data_in_table_placeholder')}
              </div>
              {
                !assumedIdentity ? <div style={{
                  display: tableData.filter(e => e.active).length >= (config?.config.activeSearchParamsLimit || 0) ? 'none' : 'flex',
                  alignItems: 'center',
                  marginTop: '5px'
                }}>
                  <div 
                    style={{
                      color: Colors.MAIN,
                      fontSize: '20px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                      setShowAddDialog(true)
                    }}
                  >
                    <AddIcon 
                      color={'inherit'} 
                      fontSize={'inherit'}
                    />
                    <span style={{
                      fontSize: '16px'
                    }}>
                      {t('add_new_event_button_table_placeholder')}&nbsp;
                    </span>
                  </div> 
                  {t('add_new_event_table_placeholder')}
                </div> : null
              }
            </div>
            }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center',
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventsList
