import { ErrorMessage, Field, FormikProps } from 'formik'
import React from "react"
import { passwordInputStyle } from "./passwordInputStyle"
import CustomPasswordField from '../CustomPasswordField/CustomPasswordField'

type Props = {
  name: string
  label?: string
  placeholder?: string
  labelStyle?: React.CSSProperties
  labelClassName?: string
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  error?: boolean
}

const PasswordInput = (props: Props) => {
  const classes = passwordInputStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div
      style={{
        minHeight: "85px"
      }}
    >
      {
        props.label ? <label htmlFor={props.name}>
        <div
          className={props.labelClassName || classes.label}
          style={{
            ...(props.labelStyle || {})
          }}
        >
          {props.label}
        </div>
        </label> : null 
      }
      <Field
        id={props.name}
        name={props.name}
        as={CustomPasswordField}
        type={showPassword ? "text" : "password"}
        className={classes.textField}
        error={props.error}
        placeholder={props.placeholder}
        value={props.value}
        setValue={props.setValue}
        showPasswordOnClick={handleClickShowPassword}
        showPassword={showPassword}
        formikProps={props.formikProps}
        errorLabel={props.error && <ErrorMessage name={props.name} />}
      />
    </div>
  )
}

export default PasswordInput
