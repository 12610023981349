import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import auth from "./translations/auth.json"
import messages from "./translations/messages.json"
import common from "./translations/common.json"
import dashboard from "./translations/dashboard.json"
import search from "./translations/search.json"
import offer from "./translations/offer.json"
import forms from "./translations/forms.json"
import eventList from "./translations/eventList.json"
import eventDetails from "./translations/eventDetails.json"
import phoneBlacklist from "./translations/phoneBlacklist.json"
import superuserSettings from "./translations/superuserSettings.json"
import myAgency from "./translations/myAgency.json"
import bulkActions from "./translations/bulkActions.json"
import faq from "./translations/faq.json"

const resources = {
    pl: {
        auth,
        messages,
        common,
        dashboard,
        search,
        offer,
        forms,
        eventList,
        eventDetails,
        phoneBlacklist,
        superuserSettings,
        myAgency,
        bulkActions,
        faq,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: "pl",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
