export const TEMPLATE_MAX_MSG = 2
export const TEMPLATE_SINGLE_MSG_LEN = 135
export const TEMPLATE_FIRST_MSG_LEN = 138
export const TEMPLATE_MSG_LEN = 160
export const TEMPLATE_MULTIPLE_MSG_LEN = 153
export const MSG_SIGNATURE_LEN = 21
export const FILE_SIZE_MAX = 5 * 1024 * 1024
export const FILE_SUPPORTED_FORMATS = ["image/png", "image/jpeg", "application/pdf"]

export enum SearchParamVersion {
  V_1_0 = '1.0.0',
  V_2_0 = '2.0.0'
}

export type IntroProps = {
  'data-title'?: string
  'data-step'?: number
  'data-intro'?: string
  'data-hint'?: string
  'data-tooltipClass'?: string
  'data-highlightClass'?: string
  'data-position'?: 'top' | 'left' | 'right' | 'bottom' | 'bottom-left-aligned' | 'bottom-middle-aligned' | 'bottom-right-aligned' | 'auto'
  'data-scrollTo'?: string
  'data-disable-interaction'?: boolean
}

export type Success = {
  success: true
}

const timeSelectHoursOptionsPrep: { value: string, label: string }[] = []
let timeStr = '', hour
for (let i = 0; i < 48; i++) {
  hour = Math.floor(i / 2)
  timeStr = `${hour < 10 ? `0${hour}` : hour}:${i%2 ? '30' : '00'}`
  timeSelectHoursOptionsPrep.push({
    label: timeStr,
    value: timeStr
  })
}

export const timeSelectHoursOptions = timeSelectHoursOptionsPrep

export const monthList: [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień'
]

export const dayListShorthand: [
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Nd',
  'Pn',
  'Wt',
  'Śr',
  'Cz',
  'Pt',
  'Sb'
]

export const dayList: [
  string,
  string,
  string,
  string,
  string,
  string,
  string
] = [
  'Niedziela',
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota'
]

export type SmsUserSettings = {
  privateMarketEmailNotifications: boolean
  allowedSendingSms: boolean
}

export const DefaultSmsUserSettings = {
  privateMarketEmailNotifications: false,
  allowedSendingSms: false
}

export type SmsUserSettingsResponse = {
  isAdminChild?: boolean
  isSuperAdminChild?: boolean
  settings: SmsUserSettings
}