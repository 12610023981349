import { CircularProgress } from '@material-ui/core'
import {
  EditOutlined as EditOutlinedIcon
} from "@material-ui/icons"
import {
  Menu,
  MenuItem
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import moment from "moment"
import React, { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import ReactTooltip from 'react-tooltip'
import DataApi from "../../api/DataApi"
import dialogIcon from '../../assets/images/dialog.webp'
import formIcon from '../../assets/images/form.webp'
import sendIcon from '../../assets/images/send.webp'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { PermissionLevel } from '../../types/auth'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList } from '../../types/common'
import { clientTypes, smsApiMessageTypes, smsResponseTypes } from '../../types/smsApiMessage'
import { getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { normalizePhoneNumber } from '../../utils/text'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import Pagination from '../common/Pagination/Pagination'
import StatTile from "../common/StatTile/StatTile"
import CustomTable from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import EditSuperEventDialog from '../EditSuperEventDialog/EditSuperEventDialog'
import { MessageEnum } from '../Messages/types'
import ActivateEventDialog from './ActivateEventDialog/ActivateEventDialog'
import AddUserReactionDialog from './AddUserReactionDialog/AddUserReactionDialog'
import { BID_PER_PAGE, tableColumnData } from './consts'
import DeactivateEventDialog from './DeactivateEventDialog/DeactivateEventDialog'
import { useSuperEventsDetails } from './useSuperEventsDetails'
import styles from './styles.module.scss'

const SuperEventDetails = () => {
  const query = useQuery()
  const { uuid } = useParams<{ uuid: string }>()
  const dispatch = useDispatch()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)
  const t = useTranslation('forms').t

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)
  const [addReactionFor, setAddReactionFor] = React.useState<string>(getFromQuery(query, 'add_reaction_for'))

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)
  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [isChildAccount, setIsChildAccount] = React.useState<boolean>(permissions.permissions.includes(PermissionLevel.CHILD))

  const [showEditDialog, setShowEditDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showAddUserReactionDialog, setShowAddUserReactionDialog] = React.useState<boolean>(false)

  const [chosenMessageUuid, setChosenMessageUuid] = React.useState<string>('')

  const { 
    regions, details, wonBidCount, wonBidList, bidStats,
    fetchRegionList, fetchDetails, fetchWonBidCount, fetchWonBidList
	} = useSuperEventsDetails()

  const addUserReactionFormRef = useRef() as any

  React.useEffect(() => {
    if (addReactionFor) {
      DataApi.checkIfCanAddReactionToMessage(addReactionFor, uuid).then(res => {
        setChosenMessageUuid(addReactionFor)
        openAddUserReactionDialog()
      }).catch((error) => onErrorResponseShowMessage(error))
    }
  }, [addReactionFor])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortName, sortOrder, from, to).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 
  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[0]).format() 
      : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, undefined, undefined, from, to).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortData.column, sortData.order, from, to).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [sortData])

  React.useEffect(() => {
    fetchRegionList(true).then(_ => {
      Promise.all([
        fetchDetails(uuid),
        fetchWonBidCount(uuid),
        fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE)
      ]).then(_ => {
        setIsLoading(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (wonBidCount > 0) {
      setPages(Math.ceil(wonBidCount / BID_PER_PAGE))
    } else {
      setPages(1)
    }
  }, [wonBidCount])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
    if (selectedPage > 0) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to).then(res => {
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedPage])

  React.useEffect(() => {
    let status, responseStatus, clientType
    const mapped = wonBidList.map((e, idx) => {
      status = smsApiMessageTypes.find(n => n.code === e.statusCode)
      responseStatus = smsResponseTypes.find(n => n.status === e.responseStatus)
      clientType = clientTypes.find(n => n.type === e.userReaction?.clientType)
      return {
        columns: [
          `${(selectedPage - 1) * BID_PER_PAGE + (idx + 1)}.`,
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.phone,
          status 
          ? <div style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={status.color}/>
              </svg></div>
              <span style={{ wordBreak: 'keep-all'}}>{status.label}</span>
            </div> 
          : '-',
          responseStatus
          ? <div style={{
              display: 'flex',
              width: 'auto',
              justifyContent: 'flex-start'
            }}>
            <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="11" r="7" fill={responseStatus.color}/>
            </svg></div>
            <span {...(e.response != null ? {'data-tip': `Odpowiedź użytkownika: "${e.response}"`} : {})}>
              {responseStatus.label}
            </span>
          </div> 
          : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          clientType
          ? <div style={{
              display: 'flex',
              width: 'auto',
              justifyContent: 'flex-start'
            }}>
            <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="11" r="7" fill={clientType.color}/>
            </svg></div>
            <span {...(e.response != null ? {'data-tip': e.userReaction?.message} : {})}>
              {clientType.label}
            </span>
          </div> 
          : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          <Menu 
            menuButton={<button className={styles.optionsMenuButton}>{'Akcje '}<strong>{'\u02C5'}</strong></button>} 
            transition
          >
            {
              responseStatus && e.userReaction == null
              ? <MenuItem
                  onClick={() => {
                    setChosenMessageUuid(e.messageUuid)
                    openAddUserReactionDialog()
                  }}
                >
                  {t('add_user_reaction_to_message_label')}
                </MenuItem>
              : null
            }
            <MenuItem 
              onClick={async () => {
                try {
                  const mappedData = {
                    number: normalizePhoneNumber(e.phone)
                  }
                  await DataApi.addPhoneToBlacklist(mappedData)
                  dispatch(addSuccessMessage(MessageEnum.PHONE_ADDED_TO_BLACKLIST))
                } catch (error) {
                  onErrorResponseShowMessage(error)
                }
              }}
            >
              {t('add_to_blacklist_button_label')}
            </MenuItem>
          </Menu>
        ]
      }
    })
    setTableData(mapped)
  }, [wonBidList])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [tableData])
  
  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowEditDialog(false)
      setShowActivateDialog(false)
      setShowDeactivateDialog(false)
    }
  }, [message])

  const openAddUserReactionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowAddUserReactionDialog(true)
  }

  const closeAddUserReactionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddUserReactionDialog(false)
  }

  const afterAddUserReaction = async () => {
    try {
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      await fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to)
      await fetchWonBidCount(uuid, from, to)
    } catch (error) {
      onErrorResponseShowMessage(error)
    }
  }

  const closeEditDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowEditDialog(false)
  }

  const closeConfirmDeactivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const closeConfirmActivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const activateEvent = async () => {
    try {
      await DataApi.activateSearchParams(details.uuid)
      await fetchDetails(details.uuid)
    } catch (error) {
      onErrorResponseShowMessage(error)
    }
  }

  const deactivateEvent = async () => {
    try {
      await DataApi.deactivateSearchParams(details.uuid)
      await fetchDetails(details.uuid)
    } catch (error) {
      onErrorResponseShowMessage(error)
    }
  }

  return (
    <>
      <ReactTooltip 
        multiline
        effect={'solid'} 
        textColor={'black'} 
        backgroundColor={'white'}
        arrowColor={'transparent'}
        className={styles.tooltip}
      />
      <EditSuperEventDialog
        hidden={!showEditDialog}
        details={details}
        onClose={() => {
          closeEditDialog()
        }}
        afterSubmit={async () => { await fetchDetails(uuid) }}
      />
      <ActivateEventDialog
        hidden={!showActivateDialog}
        onClose={closeConfirmActivateDialog}
        onConfirm={activateEvent}
      />
      <DeactivateEventDialog
        hidden={!showDeactivateDialog}
        onClose={closeConfirmDeactivateDialog}
        onConfirm={deactivateEvent}
      />
      <AddUserReactionDialog
        hidden={!showAddUserReactionDialog}
        messageUuid={chosenMessageUuid}
        afterSubmit={afterAddUserReaction}
        onClose={closeAddUserReactionDialog}
      />
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}> 
        { 
          details 
          ? <div className={styles.topContainer}>
              <div style={{
                fontFamily: 'Avenir',
                marginRight: '15px',
                marginTop: '6px',
                fontSize: '16px',
                wordWrap: 'break-word',
                overflow: 'auto'
              }}>
                <span style={{
                  textTransform: 'uppercase'
                }}>{`${details?.name} `}</span>{isChildAccount ? '' : `(${details?.userEmail})`}
              </div>
              <div className={styles.topButtonsContainer}>
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-4px',
                      fontSize: '15px'
                    }}>
                      <EditOutlinedIcon fontSize="inherit" />
                      <span style={{
                        paddingTop: '2px',
                        paddingLeft: '3px',
                        fontSize: '15px',
                        fontFamily: 'Avenir'
                      }}>
                        {t('edit_event_button_label')}
                      </span>
                    </div>
                  }
                  style={{
                    width: '150px',
                    maxWidth: '130px',
                    height: '30px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    backgroundColor: Colors.GREEN_BUTTON_COLOR
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowEditDialog(true)
                  }}
                />
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-4px',
                      fontSize: '15px'
                    }}>
                      <span style={{
                        paddingTop: '2px',
                        fontSize: '15px',
                        fontFamily: 'Avenir'
                      }}>
                        {details.active ? t('deactivate_event_button_label') : t('activate_event_button_label')}
                      </span>
                    </div>
                  }
                  style={{
                    maxWidth: '160px',
                    height: '30px',
                    marginLeft: '5px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    backgroundColor: details.active ? Colors.RED_BUTTON_COLOR : Colors.BLUE_BUTTON_COLOR
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    details.active ? setShowDeactivateDialog(true) : setShowActivateDialog(true)
                  }}
                />
              </div>
            </div> 
          : null 
        }
        <div className={styles.controlRow}>
          <div className={styles.dateRangeField}>
            <div className={styles.dateRangeLabel}>
              {t('date_range_label')}
            </div>
            <div 
              className={styles.clearButton} 
              onClick={() => {
                setSelectedDateRange(null)
              }}
            >
              {t('clear_field_button_label')}
            </div>
            <Flatpickr
              id={"date-range-picker"}
              data-enable-time
              value={
                selectedDateRange && selectedDateRange.length >= 2
                ? [
                  moment(selectedDateRange[0]).startOf("day").toDate(),
                  moment(selectedDateRange[1]).startOf("day").toDate(),
                ]
                : []
              }
              options={{
                locale: {
                  firstDayOfWeek: 1,
                  rangeSeparator: ' - ',
                  months: {
                    shorthand: monthList,
                    longhand: monthList
                  },
                  weekdays: {
                    shorthand: dayListShorthand,
                    longhand: dayList
                  }
                },
                dateFormat: "d.m.Y",
                mode: 'range',
              }}
              onClose={(date) => {
                if (date && date.length === 2) {
                  setSelectedDateRange(date)
                }
              }}
              render={({ defaultValue, value, ...props }, ref) => {
                return (
                  <CustomFlatpickrInput
                    defaultValue={defaultValue}
                    inputRef={ref}
                    width={'100%'}
                  />
                )
              }}
            />
          </div>
          
        </div>
        <div className={styles.statTileSection}>
          <div className={styles.msgSentTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F6AE3F'}
              label={'Wysłane wiadomości'}
              iconSrc={sendIcon}
              value={`${bidStats.messageSentCount}`}
            />
          </div>
          <div className={styles.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F64A3F'}
              label={'Odpowiedzi'}
              iconSrc={dialogIcon}
              value={`${bidStats.responsesCount}`}
            />
          </div>
          <div className={styles.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#438737'}
              label={'Odpowiedzi agenta'}
              iconSrc={formIcon}
              value={`${bidStats.userReactionCount}`}
            />
          </div>
        </div>
        <div style={{
          width: '100%'
        }}>
          <CustomTable 
            columnData={tableColumnData}
            content={tableData}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            reloadingData={isReloadingData}
            placeholder={
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontFamily: 'AvenirMedium',
                  color: Colors.TABLE_HEADER_LABEL,
                  fontSize: '16px'
                }}
              >
                <div>
                  {t('no_data_in_table_placeholder')}
                </div>
              </div>
              }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuperEventDetails
