import { customNumberInputFieldStyle } from './customNumberInputFieldStyle'
import React, { useRef } from 'react'
import { FormikProps } from 'formik'
import { monetaryValuePattern, numberPattern, integerValuePattern } from '../../../utils/validations/validationRegexps'
import { numberWithSpaces } from '../../../utils/text'

type Props = {
  name: string
  id?: string
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  countClassName?: string
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  type?: string
  integers?: boolean
  error?: boolean
  errorLabel?: JSX.Element
  formikProps?: FormikProps<any>
  monetaryValue?: boolean
  disabled?: boolean
}

const CustomNumberInputField = (props: Props) => {
  const [value, setValue] = React.useState<string>("")
  const classes = customNumberInputFieldStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } : {}
  )()

  return (
    <>
      <input
        {...props}
        className={`${(props.className || classes.input)}`}
        value={props.value != null ? props.value : value}
        onBlur={(ev) => {
          if (props.monetaryValue) {
            const start = ev.target.value.replace(/\s/g, '').replace(',', '.')
            const val = parseFloat(start)
            if (!isNaN(val)) {
              const tmp = numberWithSpaces(val.toFixed(2).replace('.', ',')).replace('.', ',')
              props.setValue ? props.setValue(tmp) : setValue(tmp)
              props.formikProps?.setFieldValue(
                props.name,
                start
              )
            }
          }
          props.formikProps?.handleBlur(ev)
        }}
        onChange={(ev) => {
          if (props.monetaryValue) {
            const start = ev.target.value.replace(/\s/g, '')
            const tmp = numberWithSpaces(start).replace('.', ',')
            const val = parseFloat(tmp)
            if (isNaN(val)) {
              props.setValue ? props.setValue('') : setValue('')
              props.formikProps?.setFieldValue(
                props.name,
                ''
              )
              return
            }
  
            if (
              (props.integers && !integerValuePattern.test(start))
              || (props.monetaryValue && !monetaryValuePattern.test(start))
              || (!props.monetaryValue && !numberPattern.test(start))
            ) {
              return
            }
  
            props.setValue ? props.setValue(tmp) : setValue(tmp)
            props.formikProps?.setFieldValue(
              props.name,
              start
            )
          } else {
            const val = parseFloat(ev.target.value)
            if (isNaN(val)) {
              props.setValue ? props.setValue('') : setValue('')
              props.formikProps?.setFieldValue(
                props.name,
                ''
              )
              return
            }

            if (
              (props.integers && !integerValuePattern.test(ev.target.value))
              || (props.monetaryValue && !monetaryValuePattern.test(ev.target.value))
              || (!props.monetaryValue && !numberPattern.test(ev.target.value))
            ) {
              return
            }
            props.setValue ? props.setValue(ev.target.value) : setValue(ev.target.value)
            props.formikProps?.setFieldValue(
              props.name,
              ev.target.value
            )
          }
        }}
      />
      {
        props.error && props.errorLabel ? <div className={props.errorLabelClassName || classes.errorLabel} style={{
          float: 'left'
        }}>
          {props.errorLabel}
        </div> : null
      }
    </>
  )
}

export default CustomNumberInputField
