import { ColumnData } from '../CustomTable/CustomTable'

export const BID_PER_PAGE = 25

export const tableColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '3%'
  },{
    label: 'Data i\u00A0godzina wysyłki',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  }, {
    label: 'Nr\u00A0telefonu',
    headerAlign: 'left',
    contentAlign: 'left',
    width: 'calc(46% - 180px)'
  }, {
    label: 'Status',
    name: 'statusCode', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Odpowiedź użytkownika',
    name: 'responseStatus',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Typ\u00A0klienta i\u00A0notka',
    name: 'clientType', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: '',
    name: '', 
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'center',
    width: '180px'
  }
]
