import * as Yup from "yup"

export const contactFormValidation = (t: (val: string) => string) => {
  return Yup.object({
    question: Yup.string()
      .max(120, t("common:max_120_signs_validation"))
      .required(t("common:field_required")),
    message: Yup.string()
      .max(600, t("common:max_600_signs_validation"))
      .required(t("common:field_required"))
  })
}

