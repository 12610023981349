import { ErrorMessage, Field, FormikProps } from "formik"
import fileUploadIcon from "../../../assets/images/ic-file-upload.webp"
import { fileUploadStyle } from "./fileUploadStyle"
import DeleteIcon from "@material-ui/icons/Delete"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { addErrorMessage } from '../../../redux/messages/actions';
import { MessageEnum } from '../../Messages/types';
import CustomButton from "../CustomButton/CustomButton"

type Props = {
  name: string
  error?: boolean
  labelStyle?: React.CSSProperties
  labelClassName?: string
  disabled?: boolean
  formikProps?: FormikProps<any>
  containerClassName?: string
  title?: string
  titleClassName?: string
  description?: string
  additionalComment?: string
  fileSizeMax: number
  fileSupportedFormats: string[]
}

const FileUpload = (props: Props) => {
  const classes = fileUploadStyle()
  const { t } = useTranslation(["faq"])
  const dispatch = useDispatch()

  const handleFileChange = (ev: Event) => {
    const chosenFile = (ev.target as HTMLInputElement).files[0]
    if (chosenFile.size > props.fileSizeMax) {
      dispatch(addErrorMessage(MessageEnum.FILE_SIZE_TOO_BIG))
    } else if (!props.fileSupportedFormats.includes(chosenFile.type)) {
      dispatch(addErrorMessage(MessageEnum.FILE_TYPE_INCORRECT))
    } else {
      props.formikProps.setFieldValue(props.name, chosenFile)
    }
  }

  return (
    <>
      <div className={classes.container || props.containerClassName}>
        <span className={props.titleClassName}>{props.title}</span>
        { 
          props.formikProps.values[props.name] != null 
          ? <span className={classes.fileName}>{props.formikProps.values[props.name].name}</span> 
          : null 
        }
        <label htmlFor={props.name}>
          <div
            className={classes.label || props.labelClassName}
            style={{
              ...(props.labelStyle || {}),
            }}
          >
            <img src={fileUploadIcon} />
            {props.formikProps.values[props.name] == null ? t("contact_form_file") : t("contact_form_another_file")}
          </div>
        </label>
        {
          props.formikProps.values[props.name] != null 
          ? <CustomButton
              className={classes.deleteFileButton}
              style={{
                height: '25px'
              }}
              onClick={() => props.formikProps.setFieldValue(props.name, null)}
              textComponent={<><DeleteIcon fontSize="small" />{t("delete_file_button")}</>}
            />
          : null
        }
        <span>{props.description}</span>
        <Field
          id={props.name}
          name={props.name}
          error={props.error}
          disabled={props.disabled}
          type="file"
          formikProps={props.formikProps}
          accept=".png,.jpg,.jpeg,.pdf"
          value={undefined}
          onChange={handleFileChange}
          onClick={(ev: Event) => (ev.target as HTMLInputElement).value = null}
        />
      </div>
      <div className={classes.fileUploadError}>
        <ErrorMessage name={props.name} />
      </div>
      <p className={classes.additionalComment}>{props.additionalComment}</p>
    </>
  )
}

export default FileUpload
