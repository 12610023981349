import { makeStyles } from "@material-ui/core"
import { Colors } from "../../types/colors"

export const faqPageStyle = makeStyles(() => ({
  container: {
    margin: "20px 40px",
    fontFamily: "AvenirBook",
  },
  pageHeader: {
    textTransform: "uppercase",
    fontSize: "16px",
    fontFamily: "AvenirBook",
    letterSpacing: "1.49px",
    lineHeight: "19px",
    marginTop: "33px",
    marginBottom: "33px",
    marginRight: "15px",
  },
  questionsList: {
    listStyle: "none",
    padding: "15px 15px 15px 30px",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  questionsListItem: {
    "& a": {
      textDecoration: "none",
      "&:hover, &:visited, &:link": {
        color: `${Colors.EDIT_CONFIG_TEXT}`,
      },
    },
    "&::before": {
      content: '""',
      borderColor: `transparent ${Colors.MAIN}`,
      borderStyle: "solid",
      borderWidth: "0.25em 0 0.25em 0.25em",
      display: "block",
      height: 0,
      width: 0,
      left: "-1em",
      top: "0.9em",
      position: "relative",
    },
  },
  card: {
    flex: "1",
    margin: "0 0 0 20px",
    "&:first-child": {
      margin: 0,
    },
    '@media (max-width: 700px)': {
      margin: 0,
      "&:first-child": {
        margin: "0 0 20px 0",
      },
    }
  },
  cardContactForm: {
    margin: "20px 0 0 0",
    flexBasis: "100%",
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
  }
}))
