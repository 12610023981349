import { Tag } from 'bbcode-to-react'
import { BB_CODE_IMAGES } from '../../../../../../assets/bbCode/bbCodeImages'


export default class ImageTag<T = { linkify: boolean }> extends Tag<T> {
  toReact() {
    return (
      <img src={BB_CODE_IMAGES[this.params['image']]} />
    )
  }
}