import React from "react"
import { customButtonStyle } from "./customButtonStyle"
// import { IntroProps } from '../../../types/common'

type Props = {
  text?: string
  textComponent?: React.ReactNode
  disabled?: boolean
  className?: string
  type?: "button" | "submit" | "reset" | undefined
  onClick?: () => void
  style?: React.CSSProperties
}/*& IntroProps*/

const CustomButton = (props: Props) => {
  const classes = customButtonStyle()

  return (
    <button
      // data-title={props["data-title"]}
      // data-step={props["data-step"]}
      // data-intro={props["data-intro"]}
      // data-hint={props["data-hint"]}
      // data-tooltipClass={props["data-tooltipClass"]}
      // data-highlightClass={props["data-highlightClass"]}
      // data-position={props["data-position"]}
      // data-scrollTo={props["data-scrollTo"]}
      // data-disable-interaction={props["data-disable-interaction"]}
      type={props.type ?? "button"}
      disabled={props.disabled}
      style={{ 
        position: "relative",
        height: '46px',
        outline: 'none',
        ...props.style 
      }}
      onClick={props.onClick}
      className={props.className || classes.loadingButton}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.textComponent || props.text}
      </span>
    </button>
  )
}

export default CustomButton
