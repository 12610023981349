import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import AuthApi from '../../../api/AuthApi'
import { removeToken } from '../../../redux/auth/actions'
import { addSuccessMessage } from '../../../redux/messages/actions'
import { removePermissions } from '../../../redux/permissions/actions'
import { removeUserName } from '../../../redux/user/actions'
import store from '../../../store'
import { onErrorResponseShowMessage } from '../../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from '../../../utils/token/StorageService'
import { MessageEnum } from '../../Messages/types'
import AuthCover from "../AuthCover/AuthCover"

const VerifyEmailChange = () => {
  const { t } = useTranslation("auth")
  const history = useHistory()
  const dispatch = useDispatch()
  const locations = useLocation()
  const query = new URLSearchParams(locations.search)
  const token = query.get("token")

  React.useEffect(() => {
    const checkAccountActivationToken = async () => {
      try {
        await AuthApi.verifyEmailChangeToken(token)
        StorageService.getInstance().clearUserCache()
        store.dispatch(removeToken())
        store.dispatch(removeUserName())
        store.dispatch(removePermissions())
        dispatch(
          addSuccessMessage(
            MessageEnum.EMAIL_HAS_BEEN_VERIFIED
          )
        )
      } catch (e) {
        onErrorResponseShowMessage(e, false)
      } finally {
        history.push("/login")
      }
    }
    checkAccountActivationToken()
  }, [token, history])

  return (
    <AuthCover>
      <CircularProgress size={48}/>
    </AuthCover>
  )
}

export default VerifyEmailChange
