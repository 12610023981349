import { BASE_AUTH } from "./baseRoutes"
import { BaseApiWithRefresh } from './BaseApiWithRefresh'
import { AuthData } from '../types/auth'
import { SmsUserSettings, SmsUserSettingsResponse } from '../types/common'

class AuthRefreshApi extends BaseApiWithRefresh {
  constructor() {
    super({
      baseURL: `${BASE_AUTH}/api/v1/sms/auth`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  public checkSession() {
    return this.post<Partial<AuthData>>(`/session/check`)
  }

  // TODO response type
  public getAccountDetails() {
    return this.get<any>(`/details`)
  }

  public getAccountSettings() {
    return this.get<SmsUserSettingsResponse>(`/settings`)
  }

  public resendEmailChangeEmail() {
    return this.post<any>(`/email/change/resend`)
  }

  // TODO response type
  // TODO data type
  public changePassword(data: any) {
    return this.post<any>(`/password/change`, JSON.stringify(data))
  }

  // TODO response type
  // TODO data type
  public editAccountDetails(data: any) {
    return this.patch<any>(`/details/edit`, JSON.stringify(data))
  }
  public editAccountConfigDetails(data: any) {
    return this.patch<any>(`/config/edit`, JSON.stringify(data))
  }

  public addNewAccount(data: any) {
    return this.post<any>(`/register`, JSON.stringify(data))
  }
}

export default new AuthRefreshApi()