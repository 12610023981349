import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon
} from "@material-ui/icons"
import { Form, Formik } from 'formik'
import moment from "moment"
import React, { useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import trashIcon from '../../assets/images/Trash.webp'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { Colors } from '../../types/colors'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { normalizePhoneNumber } from '../../utils/text'
import BaseInput from "../common/BaseInput/BaseInput"
import CustomButton from '../common/CustomButton/CustomButton'
import LoadingButton from "../common/LoadingButton/LoadingButton"
import Pagination from '../common/Pagination/Pagination'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { MessageEnum } from '../Messages/types'
import { phoneBlacklistStyle } from './phoneBlacklistStyle'
import { useEventsDetails } from './usePhoneBlacklist'
import { addPhoneToBlacklistValidation } from '../../utils/validations/addPhoneToBlacklistValidation'
import DataApi from '../../api/DataApi'
import { SortData } from '../CustomTable/util'

const tableColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '5%'
  }, {
    label: 'Data\u00A0i\u00A0godzina dodania',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '20%'
  }, {
    label: 'Nr\u00A0telefonu',
    headerAlign: 'left',
    contentAlign: 'left',
    width: '60%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    width: '15%'
  }
]

const ELEM_PER_PAGE = 25 // TODO move to separate file

const PhoneBlacklist = () => {
  const classes = phoneBlacklistStyle()

  const t = useTranslation("forms").t
  const tPhoneBlacklist = useTranslation("phoneBlacklist").t

  const dispatch = useDispatch()
  const message = useSelector((e: State) => e.messages)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)

  const [objectToRemove, setObjectToRemove] = React.useState<string>("")

  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [showAddToBlacklistDialog, setShowAddToBlacklistDialog] = React.useState<boolean>(false)
  const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = React.useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [chosenPhoneNumber, setChosenPhoneNumber] = React.useState<string>("")

  const { 
    config, blacklistEntriesCount, blacklistEntriesList, 
    fetchConfig, fetchBlacklistEntriesCount, fetchBlacklistEntriesList
  } = useEventsDetails()

  const formRef = useRef() as any

  const resetFormState = () => {
    setChosenPhoneNumber('')
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  React.useEffect(() => {
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchBlacklistEntriesCount().then(res => {
        fetchBlacklistEntriesList(ELEM_PER_PAGE, 0).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      // TODO column names
      setIsReloadingData(true)
      fetchBlacklistEntriesCount().then(res => {
        fetchBlacklistEntriesList(ELEM_PER_PAGE, 0, sortData.column, sortData.order).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [sortData])

  React.useEffect(() => {
    // dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    fetchConfig().then(res => {
      fetchBlacklistEntriesCount().then(res => {
        fetchBlacklistEntriesList(ELEM_PER_PAGE, (selectedPage - 1) * ELEM_PER_PAGE).then(res => {
          setIsLoading(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
  }, [selectedPage])

  React.useEffect(() => {
    if (blacklistEntriesCount > 0) {
      setPages(Math.ceil(blacklistEntriesCount / ELEM_PER_PAGE))
    } else {
      setPages(1)
    }
  }, [blacklistEntriesCount])

  React.useEffect(() => {
    if (selectedPage > 0) {
      setIsReloadingData(true)
      fetchBlacklistEntriesList(ELEM_PER_PAGE, (selectedPage - 1) * ELEM_PER_PAGE).then(res => {
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedPage])

  React.useEffect(() => {
    const mapped = blacklistEntriesList.map((e, idx) => {
      return {
        columns: [
          `${(selectedPage - 1) * ELEM_PER_PAGE + (idx + 1)}.`,
          moment(e.createdAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.number,
          <div 
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '10px',
              cursor: 'pointer',
              alignItems: 'center'
            }}
            onClick={() => {
              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
              setObjectToRemove(e.uuid)
              setShowConfirmRemoveDialog(true)
            }}
          >
            <img src={trashIcon} alt="remove-item" />
            <span style={{
              marginLeft: '6px',
              paddingTop: '4px',
              color: Colors.RED_BUTTON_COLOR,
              fontFamily: 'AvenirMedium',
              // textAlign: 'left'
            }}>{tPhoneBlacklist('remove_phone_from_blacklist_button_label')}</span>
          </div>
        ]
      }
    })
    setTableData(mapped)
  }, [blacklistEntriesList])
  
  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowAddToBlacklistDialog(false)
      setShowConfirmRemoveDialog(false)
    }
  }, [message])

  const closeAddToBlacklistDialog = (reset: boolean = true) => {
    if (reset) {
      resetFormState()
    }
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddToBlacklistDialog(false)
  }

  const closeConfirmRemoveDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowConfirmRemoveDialog(false)
  }

  const handleSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        number: normalizePhoneNumber(data.phone)
      }
      await DataApi.addPhoneToBlacklist(mappedData)
      await fetchBlacklistEntriesList(ELEM_PER_PAGE, (selectedPage - 1) * ELEM_PER_PAGE)
      setIsSubmitting(false)
      resetFormState()
      closeAddToBlacklistDialog(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const addAddPhoneToBlacklistDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
          marginBottom: '20px'
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('add_to_blacklist_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeAddToBlacklistDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={addPhoneToBlacklistValidation(tPhoneBlacklist)}
          initialValues={{
            phone: ''
          }}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div 
                  style={{
                    width: '80%',
                    position: 'relative'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_phone_to_blacklist_field_label')}
                  </p>
                  <BaseInput 
                    name="phone" 
                    placeholder={t('add_phone_to_blacklist_field_placeholder')}
                    formikProps={formikProps}
                    inputPattern={/^[0-9(\-)]*$/}
                    value={chosenPhoneNumber}
                    setValue={setChosenPhoneNumber}
                    error={
                      formikProps.touched.phone &&
                      Boolean(formikProps.errors.phone)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('save_button_label')}
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
  
  const confirmDeactivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tPhoneBlacklist('confirm_remove_from_blacklist_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmRemoveDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.removePhoneToBlacklist(objectToRemove)
                await fetchBlacklistEntriesList(ELEM_PER_PAGE, (selectedPage - 1) * ELEM_PER_PAGE)
                setIsSubmitting(false)
                closeConfirmRemoveDialog()
              } catch (error) {
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showAddToBlacklistDialog}
      >
        {addAddPhoneToBlacklistDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showConfirmRemoveDialog}
      >
        {confirmDeactivateDialogContent()}
      </CustomDialog>

      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        <div style={{
          alignItems: 'center',
          display: isLoading ? 'block' : 'flex'
        }}>
          <div style={{
            fontFamily: 'Avenir',
            textTransform: 'uppercase',
            marginRight: '15px',
            marginTop: '6px',
            fontSize: '16px'
          }}>
            {tPhoneBlacklist('main_label')}
          </div>
          <CustomButton 
            textComponent={
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '-4px',
                fontSize: '15px'
              }}>
                <div style={{fontSize: '20px', marginTop: '2px'}}>
                  <AddIcon fontSize={'inherit'}/>
                </div>
                <div style={{
                  marginTop: '-5px',
                  paddingTop: '1px',
                  paddingLeft: '3px',
                  fontSize: '15px',
                  fontFamily: 'Avenir'
                }}>
                  {t('add_to_blacklist_button_label')}
                </div>
              </div>
            }
            style={{
              maxWidth: '190px',
              height: '30px',
              paddingLeft: '10px',
              paddingRight: '15px',
              paddingTop: '7px',
              backgroundColor: Colors.RED_BUTTON_COLOR
            }}
            onClick={() => {
              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
              setShowAddToBlacklistDialog(true)
            }}
          />
        </div>
        <div style={{
          width: '100%'
        }}>
          <CustomTable 
            columnData={tableColumnData}
            content={tableData}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            reloadingData={isReloadingData}
            placeholder={
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontFamily: 'AvenirMedium',
                  color: Colors.TABLE_HEADER_LABEL,
                  fontSize: '16px'
                }}
              >
                <div>
                  {t('no_data_in_table_placeholder')}
                </div>
              </div>
              }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PhoneBlacklist
