import { Colors } from '../../../types/colors'
import { makeStyles } from '@material-ui/styles'

export type StyleProps = {
  borderColor?: string
  borderColorFocus?: string
}

export const customFlatpickrInputStyle = (props: StyleProps) => makeStyles(() => ({
  input: {
    width: '100%',
    backgroundColor: 'rgba(224, 231, 255, 0.2)',
    fontSize: '15px',
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
    paddingLeft: '14px',
    fontFamily: `AvenirBook`,
    paddingRight: '14px',
    color: Colors.LIGHT_GRAY_TEXT,
    border: `solid 1px ${props.borderColor || Colors.AUTH_INPUT_BORDER}`,
    borderRadius: '5px',
    '&:focus': {
      border: `solid 2px ${props.borderColorFocus || Colors.AUTH_INPUT_BORDER_FOCUS}`,
      outline: 'none !important',
      marginBottom: "-1px",
      marginTop: "-1px",
    }
  },
  inputError: {
    width: '100%',
    backgroundColor: 'rgba(224, 231, 255, 0.2)',
    fontSize: '15px',
    paddingTop: '9.5px',
    paddingBottom: '9.5px',
    paddingLeft: '14px',
    fontFamily: `AvenirBook`,
    paddingRight: '14px',
    color: Colors.LIGHT_GRAY_TEXT,
    border: `solid 1px red`,
    borderRadius: '5px',
    '&:focus': {
      border: `solid 2px red`,
      outline: 'none !important',
      marginBottom: "-1px",
      marginTop: "-1px",
    }
  }
}))