export const DefaultInitialFormValues = {
  name: '',
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  regions: [],
  subRegions: [],
  towns: [],
  districts: [],
  transactionTypes: [],
  propertyTypes: [],
  propertySubTypes: [],
  propertyOtherParams: [],
  signature: '',
  minPricePerArea: '',
  maxPricePerArea: '',
  notificationEmails: [] as string[],
  smsTemplate: [] as string[],
  customTemplate: '',
  includeAgencyOffers: false,
  includeOffersWithNoPhones: false,
  enableSpecialMode: false
}

export const DefaultChildAccountInitialFormValues = {
  name: '',
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  regions: [],
  subRegions: [],
  towns: [],
  districts: [],
  transactionTypes: [],
  propertyTypes: [],
  propertySubTypes: [],
  propertyOtherParams: [],
  signature: '',
  minPricePerArea: '',
  maxPricePerArea: '',
  notificationEmails: [] as string[],
  smsTemplate: [] as string[],
  customTemplate: ''
}