import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios"
import { isNullOrUndefined } from "util"

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class Api {
  protected api: AxiosInstance
  private mainRequestInterceptorIdx?: number
  private mainResponseInterceptorIdx?: number
  public constructor(config: AxiosRequestConfig) {
    this.api = axios.create(config)
  }
  protected setupBasicInterceptors() {
    this.createMainRequestInterceptor()
    this.createMainResponseInterceptor()
  }
  protected createMainRequestInterceptor() {
    // this middleware is been called right before the http request is made.
    this.mainRequestInterceptorIdx = this.api.interceptors.request.use(
      (param: AxiosRequestConfig) => ({
        ...param,
        timeout: 60 * 1000
      })
    )
  }
  protected createMainResponseInterceptor() {
    // this middleware is been called right before the response is get it by the method that triggers the request
    this.mainResponseInterceptorIdx = this.api.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }
  protected ejectMainRequestInterceptor() {
    if (!isNullOrUndefined(this.mainRequestInterceptorIdx)) {
      this.api.interceptors.request.eject(this.mainRequestInterceptorIdx)
      this.mainRequestInterceptorIdx = -1
    }
  }
  protected ejectMainResponseInterceptor() {
    if (!isNullOrUndefined(this.mainResponseInterceptorIdx)) {
      this.api.interceptors.response.eject(this.mainResponseInterceptorIdx)
      this.mainResponseInterceptorIdx = -1
    }
  }
  protected addRequestInterceptor(interceptor: any): number {
    return this.api.interceptors.request.use(interceptor)
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config)
  }

  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config)
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config)
  }

  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config)
  }

  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.head(url, config)
  }

  public post<T, R = AxiosResponse<T>>(url: string, data?: string | FormData, config?: AxiosRequestConfig): Promise<R> {
    return this.api.post(url, data, config)
  }

  public put<T, R = AxiosResponse<T>>(url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config)
  }

  public patch<T, R = AxiosResponse<T>>(url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config)
  }

  protected handleError = (error: AxiosError) => Promise.reject(error)
  protected handleResponse = ({ data }: AxiosResponse) => data
}
