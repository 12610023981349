import * as Yup from "yup"
import { normalizePhoneNumber } from '../text'

export const addPhoneToBlacklistValidation = (t: (val: string) => string) => {
  return Yup.object({
    phone: Yup.string()
      .required(t('common:field_required'))
      .test('valid_phone_number', t('phone_number_invalid_format'), (val) => {
        const number = normalizePhoneNumber(val)
        return number?.length === 9 || number?.length === 11
      }),
  })
}

