import { makeStyles } from "@material-ui/core"

export const attentionStyle = makeStyles(() => ({
  container: {
    backgroundColor: "rgba(247, 181, 0, 0.1)",
    display: 'flex',
    alignItems: 'center',
    fontSize: "16px",
    padding: "10px",
    "& p, & strong": {
      color: "#f7b500",
      padding: 0
    },
    margin: "10px 0"
  },
  icon: {
    padding: "4px 10px 4px 2px"
  }
}))
