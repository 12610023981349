import React from 'react'
import { getFromQuery, useQuery } from '../../utils/common'
import EventsList from '../EventsList/EventsList'
import SuperEventsList from '../SuperEventsList/SuperEventsList'

const SuperEventsListSelector = () => {
  const query = useQuery()

  return (
    <>
      {
        getFromQuery(query, 'assume') != null
        ? <EventsList />
        : <SuperEventsList />
      }
    </>
  )
}

export default SuperEventsListSelector
