import singleSmsEvent1 from '../../assets/images/tworzenie-eventu-1.png'
import singleSmsEvent2 from '../../assets/images/tworzenie-eventu-2.png'
import multipleSmsEvent1 from '../../assets/images/dodawanie-paczki-1.png'
import multipleSmsEvent2 from '../../assets/images/dodawanie-paczki-2.png'
import multipleSmsEvent3 from '../../assets/images/dodawanie-paczki-3.png'
import multipleSmsEvent4 from '../../assets/images/dodawanie-paczki-4.png'
import childAccountSetup from '../../assets/images/dodawanie-konta-agenta.png'
import accountDetailsChange from '../../assets/images/zmiana-danych-konta.png'
import statistics1 from '../../assets/images/statystyki-1.png'
import statistics2 from '../../assets/images/statystyki-2.png'
import clientsManagement1 from '../../assets/images/zarzadzanie-klientami-1.png'
import clientsManagement2 from '../../assets/images/zarzadzanie-klientami-2.png'
import clientsManagement3 from '../../assets/images/zarzadzanie-klientami-3.png'
import ownerAnswerCheck from '../../assets/images/odpowiedzi-uzytkownika.png'
import blacklist1 from '../../assets/images/blacklist-1.png'
import blacklist2 from '../../assets/images/blacklist-2.png'

export const BB_CODE_IMAGES = {
  "tworzenie-eventu-1": singleSmsEvent1,
  "tworzenie-eventu-2": singleSmsEvent2,
  "dodawanie-paczki-1": multipleSmsEvent1,
  "dodawanie-paczki-2": multipleSmsEvent2,
  "dodawanie-paczki-3": multipleSmsEvent3,
  "dodawanie-paczki-4": multipleSmsEvent4,
  "dodawanie-konta-agenta": childAccountSetup,
  "zmiana-danych-konta": accountDetailsChange,
  "statystyki-1": statistics1,
  "statystyki-2": statistics2,
  "zarzadzanie-klientami-1": clientsManagement1,
  "zarzadzanie-klientami-2": clientsManagement2,
  "zarzadzanie-klientami-3": clientsManagement3,
  "odpowiedzi-uzytkownika": ownerAnswerCheck,
  "blacklist-1": blacklist1,
  "blacklist-2": blacklist2,
}