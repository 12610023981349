import { AnyAction } from "redux"
import { ADD_NAME, REMOVE_NAME } from './types'


export type UserState = {
    name: string
}

const localStorageUser = typeof localStorage.getItem("user_name") === "string" ? localStorage.getItem("user_name") as string : ""

export const initialAuthValue = {
    name: localStorageUser
}

export const user = (state: UserState = initialAuthValue, action: AnyAction) => {
    switch(action.type) {
        case ADD_NAME:
            return { name: action.name }
        case REMOVE_NAME:
            return { name: "" }
        default:
            return state
    }
}