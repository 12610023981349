import { ErrorMessage, Field, FormikProps } from 'formik'
import CustomInputField from '../CustomInputField/CustomInputField'
import { baseInputStyles } from "./baseInputStyles"

type Props = {
  name: string
  label?: string
  rows?: number
  type?: string
  placeholder?: string
  error?: boolean
  labelStyle?: React.CSSProperties
  count?: boolean
  maxLen?: number
  labelClassName?: string
  byteLen?: boolean
  value?: string
  disabled?: boolean
  setValue?: React.Dispatch<React.SetStateAction<string>>
  formikProps?: FormikProps<any>
  inputPattern?: RegExp,
  fieldClassName?: string
  containerClassName?: string
  errorFieldClassName?: string
}

const BaseInput = (props: Props) => {
  const classes = baseInputStyles(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()

  return (
    <div 
      style={{ 
        minHeight: props.label ? "85px" : '62px',
        marginTop: 0
      }}
      className={props.containerClassName || ''}
    >
      {
        props.label ? <label htmlFor={props.name}>
        <div
          className={props.labelClassName || classes.label}
          style={{
            ...(props.labelStyle || {})
          }}
        >
          {props.label}
        </div>
        </label> : null 
      }
      <Field
        id={props.name}
        name={props.name}
        as={CustomInputField}
        className={[
          props.fieldClassName ?? classes.textField,
          props.error ? props.errorFieldClassName ?? classes.textFieldError : ''
        ].join(' ')}
        error={props.error}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type ? props.type : "text"}
        count={props.count}
        maxLen={props.maxLen}
        byteLen={props.byteLen}
        value={props.value}
        setValue={props.setValue}
        formikProps={props.formikProps}
        inputPattern={props.inputPattern}
        rows={props.rows}
        errorLabel={props.error && <ErrorMessage name={props.name} />}
      />
    </div>
  )
}

export default BaseInput

