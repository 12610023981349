import * as Yup from "yup"
import { email, forbiddenSpecialCharacters } from "./validationRegexps"


export const signInValidation = (t: (val: string) => string) => {
  return Yup.object({
    login: Yup.string()
      .required(t("common:field_required"))
      .matches(forbiddenSpecialCharacters, t("common:special_characters_forbidden_validation"))
      .matches(email, t("invalid_email_validation")),
    password: Yup.string().required(t("common:field_required")),
  })
}

