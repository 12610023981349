import React from 'react'
import DataApi from '../../api/DataApi'
import { SortDirection } from '../CustomTable/util'
import { checkIfAdmin } from '../../utils/common'
import { useSelector } from 'react-redux'
import { State } from '../../redux/reducers'

export const useEventsList = () => {
  const permissions = useSelector((e: State) => e.permissions)

  const [assumedUser, setAssumedUser] = React.useState<any>(null)
  const [config, setConfig] = React.useState<any>(null)
  const [regions, setRegions] = React.useState<any[]>([])
  const [smsTemplates, setSmsTemplates] = React.useState<any[]>([])
  const [data, setData] = React.useState<any[]>([])
  const [stats, setStats] = React.useState<any>({
    auctionCount: 0,
    messageSentCount: 0,
    responsesCount: 0,
    totalPrice: 0
  })

  const fetchAssumedUser = async (uuid?: string) => {
    try {
      if (!uuid || !checkIfAdmin(permissions.permissions)) return
      const data = await DataApi.getUserDetails(uuid)
      setAssumedUser(data)
    } catch (error) {
			throw error
    }
  }

  const fetchStats = async (from?: string, to?: string, assumeUuid?: string) => {
    try {
      const count = await DataApi.getSearchParamsStats(from, to, assumeUuid)
      setStats({
        auctionCount: count.auctionCount,
        messageSentCount: count.messageSentCount,
        responsesCount: count.responsesCount,
        totalPrice: count.totalPrice
      })
    } catch (error) {
			throw error
    }
  }

  const fetchConfig = async () => {
    try {
      const fetchedConfig = await DataApi.getActiveConfig()
      setConfig(fetchedConfig)
    } catch (error) {
			throw error
    }
  }

  const fetchRegionList = async (assumeUuid?: string) => {
    try {
      const fetchedRegions = await DataApi.getRegionList(undefined, assumeUuid)
      setRegions(fetchedRegions)
    } catch (error) {
			throw error
    }
  }

  const fetchSmsTemplateList = async (assumeUuid?: string) => {
    try {
      const fetchedTemplates = await DataApi.getSmsTemplateList(true, undefined, undefined, undefined, assumeUuid)
      setSmsTemplates(fetchedTemplates)
    } catch (error) {
			throw error
    }
  }

  const fetchData = async (sortName?: string, sortDirection?: SortDirection, from?: string, to?: string, assumeUuid?: string) => {
    try {
      if (sortName && sortDirection && ['name', 'bidValue', 'active', 'marketType'].includes(sortName)) {
        const fetchedData = await DataApi.getSearchParamsList(sortName, sortDirection, from, to, assumeUuid)
        setData(fetchedData)
      } else {
        const fetchedData = await DataApi.getSearchParamsList(undefined, undefined, from, to, assumeUuid)
        setData(fetchedData)
      }
    } catch (error) {
			throw error
    }
  }

	return { 
    config, regions, data, smsTemplates, stats, assumedUser,
    fetchConfig, fetchRegionList, fetchData, fetchSmsTemplateList, fetchStats, fetchAssumedUser
	}
}