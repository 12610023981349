import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon
} from '@material-ui/icons'
import moment from 'moment'
import React, { useRef } from 'react'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import dialogIcon from '../../assets/images/dialog.webp'
import sendIcon from '../../assets/images/send.webp'
import { addCmdMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import { AdvertType, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { PermissionLevel } from '../../types/auth'
import { dayList, dayListShorthand, monthList } from '../../types/common'
import { useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { sortRowData } from '../../utils/sort'
import { evalStringTemplate } from '../../utils/text'
import AddSuperEventDialog from '../AddSuperEventDialog/AddSuperEventDialog'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import Pagination from '../common/Pagination/Pagination'
import StatTile from '../common/StatTile/StatTile'
import CustomTable from '../CustomTable/CustomTable'
import { SortData, SortDirection } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { childAccountTableColumnData, tableColumnData } from './const'
import styles from './styles.module.scss'
import { useSuperEventsList } from './useSuperEventsList'

const EVENTS_PER_PAGE = 25

const SuperEventsList = () => {
  const query = useQuery()

  const t = useTranslation('forms').t
  const tEventList = useTranslation('eventList').t
  const dispatch = useDispatch()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)

  const { 
    config, data, stats, regions,
    fetchConfig, fetchData, fetchStats, fetchRegionList
	} = useSuperEventsList()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false)

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  React.useEffect(() => {
    Promise.all([
      fetchConfig(),
      fetchRegionList(),
    ]).then(_ => {
      Promise.all([
        fetchStats(),
        fetchData()
      ]).then(_ => {
        setIsLoading(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    let subregions = [], towns = [], districts = []
    const tmp = data.map((e, idx) => {
      subregions = []
      towns = []
      districts = []
      let params = ''
      if (e.params.region) {
        params += e.params.region.name
      }
      if (e.params.regions && e.params.regions.length === regions.length) {
        params += 'Cała Polska'
        for (let i = 0; i < e.params.regions.length; i++) {
          subregions.push(...(regions.find(el => el.uuid === e.params.regions[i].uuid)?.subRegions ?? []))
        }
      } else if (e.params.regions) {
        for (let i = 0; i < e.params.regions.length; i++) {
          if (i === 0) {
            params += e.params.regions[i].name
          } else {
            params += `, ${e.params.regions[i].name}`
          }
          subregions.push(...(regions.find(el => el.uuid === e.params.regions[i].uuid)?.subRegions ?? []))
        }
      }
      if (e.params.subregion) {
        params += `; Powiat: ${e.params.subregion.name}`
      }
      if (e.params.subregions && e.params.subregions.length === subregions.length) {
        params += '; Powiaty: Wszystkie'
        for (let i = 0; i < e.params.subregions.length; i++) {
          towns.push(...(subregions.find(el => el.uuid === e.params.subregions[i].uuid)?.towns ?? []))
        }
      } else if (e.params.subregions) {
        for (let i = 0; i < e.params.subregions.length; i++) {
          if (i === 0) {
            params += `; Powiaty: ${e.params.subregions[i].name}`
          } else {
            params += `, ${e.params.subregions[i].name}`
          }
        }
        for (let i = 0; i < e.params.subregions.length; i++) {
          towns.push(...(subregions.find(el => el.uuid === e.params.subregions[i].uuid)?.towns ?? []))
        }
      }
      if (e.params.town) {
        params += `; Miejscowość: ${e.params.town.name}`
      }
      if (e.params.towns && e.params.towns.length === towns.length) {
        params += '; Miejscowości: Wszystkie'
        for (let i = 0; i < e.params.towns.length; i++) {
          districts.push(...(towns.find(el => el.uuid === e.params.towns[i].uuid)?.districts ?? []))
        }
      } else if (e.params.towns) {
        params += '; Miejscowości: '
        for (const [idx, town] of e.params.towns.entries()) {
          if (idx > 0) params += ', '
          params += `${town.name}`
        }
        for (let i = 0; i < e.params.towns.length; i++) {
          districts.push(...(towns.find(el => el.uuid === e.params.towns[i].uuid)?.districts ?? []))
        }
      }
      if (e.params.district) {
        params += `; Dzielnica: ${e.params.district.name}`
      }
      if (e.params.districts && e.params.districts.length === districts.length) {
        params += '; Dzielnice: Wszystkie'
      } else if (e.params.districts) {
        params += '; Dzielnice: '
        for (const [idx, district] of e.params.districts.entries()) {
          if (idx > 0) params += ', '
          params += `${district.name}${e.params.towns.length > 1 ? ` (${district.town.name})` : ''}`
        }
      }
      if (e.params.advertType && e.params.advertSubType) {
        params += `; 
          ${
            propertyTypes.find(el => el.value === `${e.params.advertType}`).label
          }${
            e.params.advertType === AdvertType.PLOT && e.params.advertSubType != null 
            ? ` - ${plotTypes.find(el => el.value === `${e.params.advertSubType}`).label}` 
            : ''}`
      }
      if (e.params.advertTypes) {
        for (let i = 0; i < e.params.advertTypes.length; i++) {
          if (i === 0) {
            params += `; ${propertyTypes.find(el => el.value === `${e.params.advertTypes[i]}`).label}`
          } else {
            params += `, ${propertyTypes.find(el => el.value === `${e.params.advertTypes[i]}`).label}`
          }
          if (e.params.advertTypes[i] === AdvertType.PLOT && e.params.advertSubTypes && e.params.advertSubTypes.length > 0) {
            for (let i = 0; i < e.params.advertSubTypes.length; i++) {
              if (i === 0) {
                params += ` - ${plotTypes.find(el => el.value === `${e.params.advertSubTypes[i]}`).label}`
              } else {
                params += `, ${plotTypes.find(el => el.value === `${e.params.advertSubTypes[i]}`).label}`
              }
            }
          }
        }
      }
      if (e.params.offerType) {
        params += `; ${transactionTypes.find(el => el.value === `${e.params.offerType}`).label}`
      }
      if (e.params.offerTypes) {
        for (let i = 0; i < e.params.offerTypes.length; i++) {
          if (i === 0) {
            params += `; ${transactionTypes.find(el => el.value === `${e.params.offerTypes[i]}`).label}`
          } else {
            params += `, ${transactionTypes.find(el => el.value === `${e.params.offerTypes[i]}`).label}`
          }
        }
      }
      
      params += `; Pow.: ${e.params.minArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2 - ${e.params.maxArea.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}m\u00b2`
      params += `; Cena: ${e.params.minPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł - ${e.params.maxPrice.toLocaleString('en-US').replace(/,/g, ' ').replace('.', ',')}zł`

      if (permissions.permissions.includes(PermissionLevel.CHILD)) {
        return {
          uuid: e.uuid,
          active: e.active,
          rawData: [
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? 0 : e.responseCount/e.wonBidCount*100,
            e.active,
            ''
          ],
          columns: [
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? '0,00%' : `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%`,
            e.active
            ? <span className={styles.activeLabel}>
                {tEventList('active_label')}
              </span>
            : <span className={styles.inactiveLabel}>
                {tEventList('inactive_label')}
              </span>,
            <Link to={`/event/details/${e.uuid}`} className={styles.manageLink}>
              {t('manage_label')}
            </Link>
          ]
        }
      } else {
        return {
          uuid: e.uuid,
          active: e.active,
          rawData: [
            e.mainAccount ? 'KONTO GŁÓWNE' : e.userEmail,
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? 0 : e.responseCount/e.wonBidCount*100,
            e.active,
            ''
          ],
          columns: [
            e.mainAccount ? 'KONTO GŁÓWNE' : e.userEmail,
            e.name,
            params,
            e.wonBidCount,
            e.responseCount,
            e.responseCount === 0 ? '0,00%' : `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%`,
            e.active
            ? <span className={styles.activeLabel}>
                {tEventList('active_label')}
              </span>
            : <span className={styles.inactiveLabel}>
                {tEventList('inactive_label')}
              </span>,
            <Link to={`/event/details/${e.uuid}`} className={styles.manageLink}>
              {t('manage_label')}
            </Link>
          ]
        }
      }
    })
    if (sortData!= null && !['name', 'bidValue', 'active', 'marketType'].includes(sortData.column)) {
      sortRowData(tmp, sortData)
      setTableData(tmp)
    } else {
      setTableData(tmp)
    }
    setPages(Math.ceil(tmp.length / EVENTS_PER_PAGE))
  }, [data])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchStats(from, to).then(res => {
        fetchData(sortName, sortOrder, from, to).then(res => {
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 

  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchData(undefined, undefined, from, to).then(res => {
        setSelectedPage(1)
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      switch(sortData.column) {
        case 'name':
        case 'bidValue':
        case 'active':
        case 'marketType':
          setIsReloadingData(true)
          fetchData(sortData.column, sortData.order, from, to).then(res => {
            setIsReloadingData(false)
          }).catch(error => { onErrorResponseShowMessage(error)})
          break;
        default: 
          const tmpData = [...tableData]
          sortRowData(tmpData, sortData)
          setTableData([...tmpData])  
      }
      setSelectedPage(1)
    }
  }, [sortData])

  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowAddDialog(false)
    }
  }, [message])

  return (
    <>
      <AddSuperEventDialog
        hidden={!showAddDialog}
        onClose={() => {
          dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
          setShowAddDialog(false)
        }}
        afterSubmit={async () => {
          const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
            ? moment(selectedDateRange[0]).format() 
            : undefined
          const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
            ? moment(selectedDateRange[1]).add(1, 'days').format() 
            : undefined
          await fetchData(undefined, undefined, from, to)
          setSortData({column: '', order: SortDirection.DESC, default: true})
        }}
      />
      {
        isLoading
        ? <div className={styles.loadingContainer}>
            <div className={styles.loading}>
              <CircularProgress size={48}/>
            </div>
          </div>
        : <div className={styles.container}>
            <CustomButton 
              disabled={tableData.filter(e => e.active).length >= (config?.config.activeSearchParamsLimit || 0)}
              textComponent={
                <div className={styles.addEventButton}>
                  <AddIcon />
                  <span className={styles.addEventButtonLabel}>
                    {t('add_new_event_button_label')}
                  </span>
                </div>
              }
              style={{
                width: '150px',
                maxWidth: '150px',
                height: '35px',
                paddingLeft: '10px',
                paddingRight: '15px',
                paddingTop: '7px'
              }}
              onClick={() => {
                dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                setShowAddDialog(true)
              }}
            />
            <div className={styles.listCount}>
              {evalStringTemplate(tEventList('event_count_pattern'), {
                current: tableData.filter(e => e.active).length,
                max: config?.config.activeSearchParamsLimit || 0
              })}
            </div>
            <div className={styles.controlSection}>
              <div className={styles.dateRangeField}>
                <div className={styles.dateRangeLabel}>
                  {t('date_range_label')}
                </div>
                <div 
                  className={styles.clearButton} 
                  onClick={() => {
                    setSelectedDateRange(null)
                  }}
                >
                  {t('clear_field_button_label')}
                </div> 
                <Flatpickr
                  id={'date-range-picker'}
                  data-enable-time
                  value={
                    selectedDateRange && selectedDateRange.length >= 2
                    ? [
                      moment(selectedDateRange[0]).startOf("day").toDate(),
                      moment(selectedDateRange[1]).startOf("day").toDate(),
                    ]
                    : []
                  }
                  options={{
                    locale: {
                      firstDayOfWeek: 1,
                      rangeSeparator: ' - ',
                      months: {
                        shorthand: monthList,
                        longhand: monthList
                      },
                      weekdays: {
                        shorthand: dayListShorthand,
                        longhand: dayList
                      }
                    },
                    dateFormat: 'd.m.Y',
                    mode: 'range',
                  }}
                  onClose={(date) => {
                    if (date && date.length === 2) {
                      setSelectedDateRange(date)
                    }
                  }}
                  render={({ defaultValue, value, ...props }, ref) => {
                    return (
                      <CustomFlatpickrInput
                        defaultValue={defaultValue}
                        inputRef={ref}
                        width={'100%'}
                      />
                    )
                  }}
                />
              </div>
              <div className={styles.statTileSection}>
                <div className={styles.msgSentTile}>
                  <StatTile
                    textColor={'#FFFFFF'}
                    backgroundColor={'#F6AE3F'}
                    label={'Wysłane wiadomości'}
                    iconSrc={sendIcon}
                    value={`${stats.messageSentCount}`}
                  />
                </div>
                <div className={styles.responsesCountTile}>
                  <StatTile
                    textColor={'#FFFFFF'}
                    backgroundColor={'#F64A3F'}
                    label={'Odpowiedzi'}
                    iconSrc={dialogIcon}
                    value={`${stats.responsesCount}`}
                  />
                </div>
              </div>
              <CustomTable 
                columnData={permissions.permissions.includes(PermissionLevel.CHILD) ? childAccountTableColumnData : tableColumnData}
                reloadingData={isReloadingData}
                content={tableData.slice((selectedPage - 1) * EVENTS_PER_PAGE, selectedPage * EVENTS_PER_PAGE)}
                sort={true}
                sortData={sortData}
                sortSetter={setSortData}
                placeholder={
                  <div className={styles.tablePlaceholder}>
                    <div>
                      {t('no_data_in_table_placeholder')}
                    </div>
                    {
                      tableData.filter(e => e.active).length >= (config?.config.activeSearchParamsLimit || 0)
                      ? <div className={styles.tablePlaceholderAddEventContainer}>
                          <div 
                            className={styles.tablePlaceholderAddEventButton}
                            onClick={() => {
                              dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                              setShowAddDialog(true)
                            }}
                          >
                            <AddIcon 
                              color={'inherit'} 
                              fontSize={'inherit'}
                            />
                            <span className={styles.tablePlaceholderAddEventLabel}>
                              {t('add_new_event_button_table_placeholder')}&nbsp;
                            </span>
                          </div> 
                          {t('add_new_event_table_placeholder')}
                        </div> 
                      : null
                    }
                  </div>
                }
              />
              {
                pages <= 1
                ? null
                : <div className={styles.pagination}>
                    <div>
                      <Pagination 
                        pages={pages}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                      />
                    </div>
                  </div>
              }
            </div>
          </div>
      }
    </>
  )
}

export default SuperEventsList
