export const BASE_AUTH = process.env.REACT_APP_BASE_AUTH
export const BASE_DATA = process.env.REACT_APP_BASE_DATA
// export const BASE_AUTH = "http://135.125.133.181:5000"
// export const BASE_DATA = "http://135.125.133.181:5001"
// export const BASE_AUTH = "http://54.36.172.78:5000"
// export const BASE_DATA = "http://54.36.172.78:5001"
// export const BASE_AUTH = "https://sms.ofertodom.pl:5010"
// export const BASE_DATA = "https://sms.ofertodom.pl:5011"
export const REFRESH_PATH_END = process.env.REACT_APP_REFRESH_PATH_END


