export const smsApiMessageTypes = [
  {
    code: 401,
    label: 'nieznaleziona',
    color: '#F94144'
  },
  {
    code: 402,
    label: 'przedawniona',
    color: '#495057'
  },
  {
    code: 403,
    label: 'wysłana',
    color: '#F9C74F'
  },
  {
    code: 404,
    label: 'dostarczona',
    color: '#38B000'
  },
  {
    code: 405,
    label: 'niedostarczona',
    color: '#F94144'
  },
  {
    code: 406,
    label: 'nieudana',
    color: '#F94144'
  },
  {
    code: 407,
    label: 'odrzucona',
    color: '#F94144'
  },
  {
    code: 408,
    label: 'nieznany',
    color: '#ADB5BD'
  },
  {
    code: 409,
    label: 'kolejka',
    color: '#4361EE'
  },
  {
    code: 410,
    label: 'zaakceptowana',
    color: '#99D98C'
  },
  {
    code: 411,
    label: 'ponawianie',
    color: '#F65A38'
  },
  {
    code: 412,
    label: 'zatrzymanie',
    color: '#F3722C'
  }
]

export enum SmsResponseStatus {
  REJECTED,
  ACCEPTED,
  OTHER
}

export enum ClientType {
  UNDEFINED,
  OUT,
  PROSPECT,
  IN
}


export const smsResponseTypes = [
  {
    status: SmsResponseStatus.ACCEPTED,
    color: '#38B000',
    label: 'Potwierdzenie'
  },
  {
    status: SmsResponseStatus.REJECTED,
    color: '#F94144',
    label: 'Odrzucenie'
  },
  {
    status: SmsResponseStatus.OTHER,
    color: '#ADB5BD',
    label: 'Inna'
  }
]

export const clientTypes = [
  {
    type: ClientType.IN,
    color: '#38B000',
    label: 'IN',
    name: 'IN (umówione spotkanie)'
  },
  {
    type: ClientType.PROSPECT,
    color: '#f6e03f',
    label: 'PROSPECT',
    name: 'PROSPECT (prosi o telefon za tydzień)'
  },
  {
    type: ClientType.OUT,
    color: '#e02020',
    label: 'OUT',
    name: 'OUT (niezainteresowany współpracą)'
  }
]