import { CircularProgress } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AuthRefreshApi from '../../api/AuthRefreshApi'
import { addSuccessMessage } from '../../redux/messages/actions'
import { State } from '../../redux/reducers'
import { addUserName } from '../../redux/user/actions'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { changePasswordValidation } from '../../utils/validations/changePasswordValidation'
import { editUserDataValidation } from '../../utils/validations/editUserDataValidation'
import { commonAuthStyle } from '../auth/commonAuthStyle'
import BaseInput from '../common/BaseInput/BaseInput'
import CustomInputField from '../common/CustomInputField/CustomInputField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import PasswordInput from '../common/PasswordInput/PasswordInput'
import { MessageEnum } from '../Messages/types'
import { userDetailsStyle } from './userDetailsStyle'
import { useUserDetails } from './useUserDetails'
import { PermissionLevel } from '../../types/auth'


const UserDetails = () => {
  const classes = userDetailsStyle()
  const commonAuthClasses = commonAuthStyle()
  const permissions = useSelector((e: State) => e.permissions)

  const tForms = useTranslation("forms").t
  const tAuth = useTranslation("auth").t
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [userName, setUserName] = React.useState<string>("")
  const [userEmail, setUserEmail] = React.useState<string>("")
  const [allowedRegion, setAllowedRegion] = React.useState<string | null>(null)
  const [allowedTowns, setAllowedTowns] = React.useState<string | null>(null)

  const [oldPwd, setOldPwd] = React.useState<string>("")
  const [newPwd, setNewPwd] = React.useState<string>("")

  const [userDataFormInitialValues, setUserDataFormInitialValues] = React.useState<any>({
    name: '',
    email: ''
  })

  const [changePwdFormInitialValues, setChangePwdFormInitialValues] = React.useState<any>({
    oldPassword: '',
    newPassword: ''
  })

  const [userConfigurationInitialValues, setUserConfigurationInitialValues] = React.useState<any>({
    sendEmailNotifications: false,
    allowedSendingSms: true
  })

  const { 
    details,
    fetchAccountDetails,
  } = useUserDetails()

  const userDataFormRef = useRef() as any
  const userConfigFormRef = useRef() as any
  const pwdFormRef = useRef() as any

  const setUserDataFormInitialData = () => {
    setUserName(details.name)
    setUserEmail(details.email)
    setAllowedRegion(details.allowedRegions && details.allowedRegions.length > 0 ? details.allowedRegions[0].name : null)
    setUserConfigurationInitialValues({
      allowedSendingSms: details?.allowedSendingSms ?? true,
      sendEmailNotifications: details?.privateMarketEmailNotifications ?? false
    })
    let townsLabel
    if (details.allowedTowns && details.allowedTowns.length > 0) {
      townsLabel = ''
      for (const [idx, town] of details.allowedTowns.entries()) {
        if (idx > 0) townsLabel += ', '
        townsLabel += `${town.name}`
      }
    }
    setAllowedTowns(townsLabel ?? null)
    setUserDataFormInitialValues({
      name: details.name,
      email: details.email
    })
    userDataFormRef?.current?.resetForm()
    userConfigFormRef?.current?.resetForm()
  }

  React.useEffect(() => {
    fetchAccountDetails().then(res => {
      setIsLoading(false)
    }).catch(error => { onErrorResponseShowMessage(error)})
    // dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER)
  }, [])

  React.useEffect(() => {
    if (details) {
      setUserDataFormInitialData()
    }
  }, [details])

  const resetChangePwdForm = () => {
    setOldPwd('')
    setNewPwd('')
    setChangePwdFormInitialValues({
      oldPassword: '',
      newPassword: ''
    })
    if (pwdFormRef.current) {
      pwdFormRef.current.resetForm()
    }
  }

  const handleChangeUserDataFormSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        name: data.name.trim(),
        email: data.email.trim()
      }
      await AuthRefreshApi.editAccountDetails(mappedData)
			dispatch(addUserName(data.name))
      await fetchAccountDetails()

      if (details.email.toLowerCase() !== data.email.toLowerCase()) {
        dispatch(
          addSuccessMessage(
            MessageEnum.EMAIL_VERIFICATION_MESSAGE_HAS_BEEN_SEND
          )
        )
      } else {
        dispatch(
          addSuccessMessage(
            MessageEnum.USER_DATA_HAS_BEEN_CHANGED
          )
        )
      }
      
      setIsSubmitting(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleChangeUserConfigFormSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        privateMarketEmailNotifications: data.sendEmailNotifications,
        allowedSendingSms: data.allowedSendingSms
      }
      await AuthRefreshApi.editAccountConfigDetails(mappedData)
      await fetchAccountDetails()
      dispatch(
        addSuccessMessage(
          MessageEnum.USER_DATA_HAS_BEEN_CHANGED
        )
      ) 
      setIsSubmitting(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleChangePwdFormSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await AuthRefreshApi.changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      })
      dispatch(
        addSuccessMessage(
          MessageEnum.PASSWORD_HAS_BEEN_CHANGED
        )
      )
      resetChangePwdForm()
      setIsSubmitting(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      resetChangePwdForm()
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        minHeight: '100%',
        display: isLoading ? 'block' : 'flex'
      }}>
        <div style={{
          width: '65%',
        }}>
          <section>
            <div style={{
              height: '62px',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}>
              <div style={{
                float: 'left',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontFamily: 'AvenirMedium',
                letterSpacing: '1.49px',
                lineHeight: '22px',
                marginTop: '20px',
                marginBottom: '20px'
              }}>
                {tForms('user_data_section_label')}
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Formik
                innerRef={userDataFormRef}
                validationSchema={editUserDataValidation(tAuth)}
                enableReinitialize
                initialValues={userDataFormInitialValues}
                onSubmit={handleChangeUserDataFormSubmit}
              >
                {(formikProps) => (
                  <div style={{
                    width: '80%',
                    position: 'relative'
                  }}>
                    <Form 
                      onKeyDown={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault()
                        }
                      }}
                    >
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('user_data_form_name_field_label')}
                        </p>
                        <BaseInput 
                          count
                          maxLen={120}
                          name="name" 
                          placeholder={tForms('user_data_form_name_field_placeholder')}
                          formikProps={formikProps}
                          value={userName}
                          setValue={setUserName}
                          error={
                            formikProps.touched.name &&
                            Boolean(formikProps.errors.name)
                          }
                        />
                      </div>
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('user_data_form_email_field_label')}
                        </p>
                        <BaseInput
                          count
                          maxLen={120} 
                          name="email" 
                          placeholder={tForms('user_data_form_email_field_placeholder')}
                          formikProps={formikProps}
                          value={userEmail}
                          setValue={setUserEmail}
                          error={
                            formikProps.touched.email &&
                            Boolean(formikProps.errors.email)
                          }
                        />
                      </div>
                      { 
                        details?.pendingEmailChanges
                        ? <div
                            style={{ 
                              marginTop: "10px",
                              textAlign: 'right',
                              marginBottom: '10px',
                              textDecoration: "none",
                              fontSize: "15px",
                              color: '#202020',
                              fontFamily: "AvenirBook",
                              cursor: 'pointer'
                            }}
                            onClick={async () => {
                              try {
                                await AuthRefreshApi.resendEmailChangeEmail()
                                dispatch(addSuccessMessage(MessageEnum.EMAIL_CHANGE_VERIFICATION_MESSAGE_HAS_BEEN_SEND))
                              } catch (error) {
                                onErrorResponseShowMessage(error)
                              }
                            }}
                          >
                            {'Wyślij ponownie link potwierdzający zmianę adresu e-mail'}
                          </div>
                        : null
                      }
                      
                      
                      <div style={{
                        float: 'right',
                        marginBottom: '30px'
                      }}>
                        <LoadingButton 
                          text={tForms('save_button_label')}
                          // disabled={!formikProps.isValid || !formikProps.dirty}
                          type='submit'
                          loading={isSubmitting}
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            height: '40px'
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </section>

          
          <section>
            <div style={{
              height: '62px',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}>
              <div style={{
                float: 'left',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontFamily: 'AvenirMedium',
                letterSpacing: '1.49px',
                lineHeight: '22px',
                marginTop: '20px',
                marginBottom: '20px'
              }}>
                {tForms('user_system_config_label')}
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <div style={{ width: '80%' }}>
                {
                  permissions.permissions.includes(PermissionLevel.SUPERADMIN)
                  ?
                    <Formik
                      innerRef={userConfigFormRef}
                      enableReinitialize
                      initialValues={userConfigurationInitialValues}
                      onSubmit={handleChangeUserConfigFormSubmit}
                    >
                      {(formikProps) => (
                        <Form>
                          <div>
                            <p className={classes.fieldLabel}>
                              {tForms('send_mail_notifications_switch_label')}
                            </p>
                            <div
                              style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                                display: 'flex',
                                flexWrap: 'nowrap'
                              }}
                            >
                              <div>
                                <CustomSwitch 
                                  name={'sendEmailNotifications'}
                                  checked={formikProps.values.sendEmailNotifications}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: '10px',
                                  fontFamily: 'AvenirBook',
                                  fontSize: '15px',
                                  color: '#6D7278',
                                  marginTop: '-1px'
                                }}
                              >
                                {
                                  details?.privateMarketEmailNotifications ?? false
                                  ? 'wysyłka włączona'
                                  : 'wysyłka wyłączona'
                                }
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className={classes.fieldLabel}>
                              {tForms('allowed_sending_sms_switch_label')}
                            </p>
                            <div
                              style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                                display: 'flex',
                                flexWrap: 'nowrap'
                              }}
                            >
                              <div>
                                <CustomSwitch 
                                  name={'allowedSendingSms'}
                                  checked={formikProps.values.allowedSendingSms}
                                  formikProps={formikProps}
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: '10px',
                                  fontFamily: 'AvenirBook',
                                  fontSize: '15px',
                                  color: '#6D7278',
                                  marginTop: '-1px'
                                }}
                              >
                                {
                                  details?.allowedSendingSms ?? true
                                  ? 'wysyłka włączona'
                                  : 'wysyłka wyłączona'
                                }
                              </div>
                            </div>
                          </div>
                          <div style={{
                            float: 'right',
                            marginBottom: '30px'
                          }}>
                            <LoadingButton 
                              text={tForms('save_button_label')}
                              type='submit'
                              loading={isSubmitting}
                              style={{
                                width: '150px',
                                maxWidth: '150px',
                                height: '40px'
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  : <>
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('send_mail_notifications_switch_label')}
                        </p>
                        <div
                          style={{
                            marginTop: '15px',
                            marginBottom: '10px',
                            display: 'flex',
                            flexWrap: 'nowrap'
                          }}
                        >
                          <div>
                            <CustomSwitch 
                              disabled={true}
                              name={'sendEmailNotifications'}
                              checked={details?.privateMarketEmailNotifications ?? false}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: '10px',
                              fontFamily: 'AvenirBook',
                              fontSize: '15px',
                              color: '#6D7278',
                              marginTop: '-1px'
                            }}
                          >
                            {
                              details?.privateMarketEmailNotifications ?? false
                              ? 'wysyłka włączona'
                              : 'wysyłka wyłączona'
                            }
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('allowed_sending_sms_switch_label')}
                        </p>
                        <div
                          style={{
                            marginTop: '15px',
                            marginBottom: '10px',
                            display: 'flex',
                            flexWrap: 'nowrap'
                          }}
                        >
                          <div>
                            <CustomSwitch 
                              disabled={true}
                              name={'allowedSendingSms'}
                              checked={details?.allowedSendingSms ?? true}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: '10px',
                              fontFamily: 'AvenirBook',
                              fontSize: '15px',
                              color: '#6D7278',
                              marginTop: '-1px'
                            }}
                          >
                            {
                              details?.allowedSendingSms ?? true
                              ? 'wysyłka włączona'
                              : 'wysyłka wyłączona'
                            }
                          </div>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
          </section>
          
          <section>
            <div style={{
              height: '62px',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}>
              <div style={{
                float: 'left',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontFamily: 'AvenirMedium',
                letterSpacing: '1.49px',
                lineHeight: '22px',
                marginTop: '20px',
                marginBottom: '20px'
              }}>
                {'Dostępne lokalizacje'}
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <div style={{
                width: '80%',
                position: 'relative'
              }}>
                <div>
                  <p className={classes.fieldLabel}>
                    {'Województwo'}
                  </p>
                  <div style={{
                    height: '62px'
                  }}>
                    <CustomInputField 
                      disabled={true}
                      className={classes.textField}
                      name="allowedRegion" 
                      placeholder={'Wszystkie'}
                      value={allowedRegion}
                    />
                  </div>
                </div>
                <div>
                  <p className={classes.fieldLabel}>
                    {'Miejscowości'}
                  </p>
                  <div style={{
                    height: '62px',
                    fontFamily: 'AvenirBook'
                  }}>
                    <CustomInputField
                      disabled={true}
                      className={classes.textField}
                      name="allowedTowns" 
                      placeholder={'Wszystkie'}
                      value={allowedTowns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div style={{
              height: '62px',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}>
              <div style={{
                float: 'left',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontFamily: 'AvenirMedium',
                letterSpacing: '1.49px',
                lineHeight: '22px',
                marginTop: '20px',
                marginBottom: '20px'
              }}>
                {tForms('user_pwd_change_section_label')}
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Formik
                innerRef={pwdFormRef}
                validationSchema={changePasswordValidation(tAuth)}
                initialValues={changePwdFormInitialValues}
                onSubmit={handleChangePwdFormSubmit}
              >
                {(formikProps) => (
                  <div style={{
                    width: '80%',
                    position: 'relative'
                  }}>
                    <Form 
                      onKeyDown={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault()
                        }
                      }}
                    >
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('user_pwd_change_old_pwd_field_label')}
                        </p>
                        <PasswordInput 
                          name="oldPassword" 
                          placeholder={tForms('user_pwd_change_old_pwd_field_placeholder')}
                          formikProps={formikProps}
                          value={oldPwd}
                          setValue={setOldPwd}
                          error={
                            formikProps.touched.oldPassword &&
                            Boolean(formikProps.errors.oldPassword)
                          }
                        />
                      </div>
                      <div>
                        <p className={classes.fieldLabel}>
                          {tForms('user_pwd_change_new_pwd_field_label')}
                        </p>
                        <PasswordInput 
                          name="newPassword" 
                          placeholder={tForms('user_pwd_change_new_pwd_field_placeholder')}
                          formikProps={formikProps}
                          value={newPwd}
                          setValue={setNewPwd}
                          error={
                            formikProps.touched.newPassword &&
                            Boolean(formikProps.errors.newPassword)
                          }
                        />
                      </div>
                      <div style={{
                        float: 'right',
                        marginBottom: '30px'
                      }}>
                        <LoadingButton 
                          text={tForms('save_button_label')}
                          // disabled={!formikProps.isValid || !formikProps.dirty}
                          type='submit'
                          loading={isSubmitting}
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            height: '40px'
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </section>
        </div>
        {/* <div style={{
          width: '1px',
          borderLeft: '1px solid #BFC5D2',
          marginTop: '2%',
          marginBottom: '2%'
        }}>

        </div> */}
        {/* <div style={{
          width: 'calc(35% - 1px)'
        }}>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            <div style={{
              background: 'linear-gradient(180deg, #97B5F2 0%, #0B2E5E 100%)',
              width: '80%',
              height: '230px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}>
              <div
                style={{
                  marginRight: '10px',
                  marginTop: '8px'
                }}
              >
                <img 
                  src={walletIcon}
                  width={80}
                  height={70}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <div 
                  style={{
                    textAlign: 'center',
                    fontFamily: 'AvenirMedium',
                    fontSize: '40px'
                  }}
                >
                  {`${details?.balance.toFixed(2)} zł`}
                </div> 
                <div 
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Avenir',
                    fontSize: '16px'
                  }}
                >
                  BUDŻET
                </div> 
              </div>
            </div>

          </div>
        </div> */}


      </div>
    </>
  )
}

export default UserDetails
