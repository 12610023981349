import { Colors } from '../../../types/colors'
import { makeStyles } from '@material-ui/styles'

export type StyleProps = {
  borderColor?: string
  borderColorFocus?: string
}

export const customNumberInputFieldStyle = (props: StyleProps) => makeStyles(() => ({
  input: {
    width: '100%',
    backgroundColor: Colors.INPUT_BACKGROUND,
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    paddingLeft: '14px',
    paddingRight: '14px',
    border: `solid 1px ${props.borderColor || Colors.INPUT_BORDER}`,
    borderRadius: '5px',
    '&:focus': {
      border: `solid 2px ${props.borderColorFocus || Colors.INPUT_BORDER_FOCUS}`,
      outline: 'none !important',
      marginBottom: "-1px",
      marginTop: "-1px",
    }
  },
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '4px 14px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  },
  countLabel: {
    color: '#BFC5D2',
    textAlign: 'left',
    margin: '3px 2px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  }
}))