import React, { ReactNode } from "react"
import { Card, CardContent, CardHeader } from "@material-ui/core"
import { faqCardStyle } from "./faqCardStyle"

type Props = {
  icon: string
  title: string
  children: ReactNode
  subheader?: string
}

const FAQCard = (props: Props) => {
  const classes = faqCardStyle()

  return (
    <Card elevation={0} square={true} classes={{ root: classes.card }}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
          subheader: classes.cardHeaderSubheader,
        }}
        avatar={<img src={props.icon} />}
        title={props.title}
        subheader={props.subheader}
      />
      <CardContent classes={{ root: classes.cardContent }}>
        {props.children}
      </CardContent>
    </Card>
  )
}

export default FAQCard