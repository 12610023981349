import React from "react"

type Props = {
  hidden?: boolean
  children?: React.ReactNode[] | React.ReactNode
  style?: React.CSSProperties
  zIndex?: number
}

const defaultStyle: React.CSSProperties = {
  position: 'absolute',
  background: 'white',
  width: 'calc(100% - 20px)',
  border: '1px solid #979797',
  borderRadius: '1px',
  margin: '10px'
}

const CustomDialog = (props: Props) => {
  return (
    <div style={{
      ...(props.style || defaultStyle),
      zIndex: props.zIndex || 2001,
      display: props.hidden ? 'none' : 'block'
    }}>
      {props.children}
    </div>
  )
}


export default CustomDialog
