import { makeStyles } from "@material-ui/core"
import { Colors } from "../../../types/colors"

export const fileUploadStyle = makeStyles(() => ({
  fileUploadError: {
    color: "red",
    textAlign: "left",
    margin: "0px 14px 0px",
    fontFamily: "AvenirBook",
    fontSize: "12px",
  },
  deleteFileButton: {
    border: "1px solid #979797",
    borderRadius: "3px",
    display: "flex",
    padding: "0 10px",
    cursor: "pointer",
    fontSize: "12px",
    justifyContent: "center",
    marginLeft: "10px",
    marginTop: "8px",
    textTransform: "none",
    color: `${Colors.CONTENT_FAQ_TEXT}`,
    backgroundColor: "#fff",
    alignItems: "center",
    fontFamily: "AvenirBook"
  },
  container: {
    marginTop: "25px",
    display: "flex",
    flexWrap: 'wrap',
    "& input[type=file]": {
      display: "none",
    },
  },
  label: {
    border: "1px solid #979797",
    borderRadius: "3px",
    display: "flex",
    padding: "3px 9px",
    cursor: "pointer",
    fontSize: "12px",
    justifyContent: "spaceBetween",
    marginLeft: "10px",
    marginTop: "8px",
  },
  additionalComment: {
    fontSize: "12px",
    color: `${Colors.LIGHT_GRAY_TEXT}`,
    paddingTop: "7px",
  },
  fileName: {
    paddingLeft: '10px',
    paddingBottom: '5px',
    fontSize: '15px',
    marginTop: '10px',
    lineHeight: '1.47',
    color: `${Colors.MAIN}`,
    wordBreak: 'break-all'
  }
}))
