import React from "react"
import { paginationStyle } from "./paginationStyle"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { IconButton } from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { Colors } from '../../../types/colors'

type Props = {
  pages: number
  selectedPage: number
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
}

const Pagination = (props: Props) => {
  const [pageToShow, setPageToShow] = React.useState<(string | number)[]>([])
  const classes = paginationStyle()

  React.useEffect(() => {
    const limit = 7
    const pageArray: (string | number)[] = []

    if (props.pages <= limit) {
      for (let i = 1; i <= props.pages; i++) {
        pageArray.push(i)
      }
    } else if (props.selectedPage + Math.floor(limit / 2) > props.pages) {
      for (let i = props.pages - limit + 3; i < props.pages; i++) {
        pageArray.push(i)
      }
    } else if (props.selectedPage - Math.floor(limit / 2) < 1) {
      for (let i = 1; i <= limit - 2; i++) {
        pageArray.push(i)
      }
    } else {
      const startPoint = props.selectedPage - Math.floor((limit - 2) / 2)
      for (let i = startPoint; i <= startPoint + limit - 3; i++) {
        pageArray.push(i)
      }
    }

    if (pageArray.length && pageArray[0] === 2) {
      pageArray.unshift(...[1])
    } else if (pageArray.length && pageArray[0] !== 1) {
      pageArray.unshift(...[1, "..."])
    }

    if (pageArray.length && pageArray[pageArray.length - 1] === props.pages - 1) {
      pageArray.push(...[props.pages])
    } else if (
      pageArray.length &&
      pageArray[pageArray.length - 1] !== props.pages
    ) {
      pageArray.push(...["...", props.pages])
    }
    setPageToShow(pageArray)
  }, [props.selectedPage, props.pages])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "25px 0px",
        alignItems: "center",
      }}
    >
      <span
        style={{ 
          marginLeft: "20px",
          cursor: 'pointer',
          fontSize: '24px',
          marginTop: '5px',
          color: Colors.MAIN
        }}
        onClick={() => {
          if (props.selectedPage - 1 >= 1) {
            props.setSelectedPage(props.selectedPage - 1)
          }
        }}
      >
        <ArrowBackIcon 
          color={'inherit'}
          fontSize={'inherit'}
        />
      </span>

      <div style={{
        display: 'flex'
      }}>
        {pageToShow.map((e) => {
          if (typeof e === "number") {
            return (
              <button
                style={{
                  cursor: "pointer",
                  fontSize: '14px'
                }}
                className={`${classes.paginationElement} ${
                  e === props.selectedPage
                    ? classes.selectedOption
                    : ""
                }`}
                onClick={() => props.setSelectedPage(e)}
              >
                {e}
              </button>
            )
          }

          return <div className={classes.paginationElement}>{e}</div>
        })}
      </div>
      <span
        style={{ 
          marginRight: "20px",
          cursor: 'pointer',
          fontSize: '24px',
          marginTop: '5px',
          color: Colors.MAIN
        }}
        onClick={() => {
          if (props.selectedPage + 1 <= props.pages) {
            props.setSelectedPage(props.selectedPage + 1)
          }
        }}
      >
        <ArrowForwardIcon
          color={'inherit'}
          fontSize={'inherit'}
        />
      </span>
    </div>
  )
}

export default Pagination
