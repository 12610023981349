import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon,
  OpenInNewOutlined
} from "@material-ui/icons"
import { Form, Formik } from 'formik'
import React, { useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux"
import { useParams } from 'react-router'
import DataApi from '../../api/DataApi'
import walletIcon from '../../assets/images/wallet.webp'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { UserRoles } from '../../types/auth'
import { Colors } from '../../types/colors'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { addToBalanceValidation } from '../../utils/validations/addToBalanceValidation'
import { manageUserDataValidation } from '../../utils/validations/manageUserDataValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomSelectField from '../common/CustomSelectField/CustomSelectField'
import CustomSwitch from '../common/CustomSwitch/CustomSwitch'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import CustomDialog from '../CustomDialog/CustomDialog'
import { MessageEnum } from '../Messages/types'
import { manageUserStyle } from './manageUserStyle'
import { useManageUser } from './useManageUser'
import { useHistory } from 'react-router-dom'
import { AsyncCreatableSelectOption } from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import CustomAsyncCreatableSelectField from '../common/CustomAsyncCreatableSelectField/CustomAsyncCreatableSelectField'
import { formatNumber } from '../../utils/text'

// TODO strings

const ManageUser = () => {
  const classes = manageUserStyle()
  const tForms = useTranslation("forms").t
  const tSuperuserSettings = useTranslation("superuserSettings").t
  const dispatch = useDispatch()
  const history = useHistory()

  const { uuid } = useParams<{ uuid: string }>()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [townList, setTownList] = React.useState<any[]>([])

  const [userName, setUserName] = React.useState<string>("")
  const [userEmail, setUserEmail] = React.useState<string>("")
  const [userRole, setUserRole] = React.useState<string>("")
  const [allowedRegion, setAllowedRegion] = React.useState<string>('')
  const [allowedTowns, setAllowedTowns] = React.useState<string[]>([])
  const [subscriptionValue, setSubscriptionValue] = React.useState<string>('')
  const [userTags, setUserTags] = React.useState<AsyncCreatableSelectOption[]>([])
  const [sendEmailNotification, setSendEmailNotification] = React.useState<boolean>(false)
  const [allowedSendingSms, setAllowedSendingSms] = React.useState<boolean>(true)

  const [chosenAmount, setChosenAmount] = React.useState<string>("")

  const [showAddToBalanceDialog, setShowAddToBalanceDialog] = React.useState<boolean>(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)

  const [userDataFormInitialValues, setUserDataFormInitialValues] = React.useState<any>({
    name: '',
    email: '',
    role: '',
    allowedRegion: '',
    allowedTowns: [],
    sendEmailNotification: false,
    allowedSendingSms: true
  })

  const { 
    details, regions, userTagList,
    fetchAccountDetails, fetchRegionList, fetchUserTags
  } = useManageUser()

  const userDataFormRef = useRef() as any
  const addBalanceFormRef = useRef() as any

  const resetAddToBalanceFormState = () => {
    setChosenAmount('')
    if (addBalanceFormRef.current) {
      addBalanceFormRef.current.resetForm()
    }
  }

  const setUserDataFormInitialData = () => {
    setUserName(details.name)
    setUserEmail(details.email)
    setAllowedSendingSms(details.allowedSendingSms ?? true)
    setSendEmailNotification(details.privateMarketEmailNotifications ?? false)
    setUserRole(`${details.roles[0]}`)
    setAllowedRegion(details.allowedRegions && details.allowedRegions.length > 0 ? details.allowedRegions[0].uuid : '')
    setAllowedTowns(details.allowedTowns && details.allowedTowns.length > 0 ? details.allowedTowns.map(e => e.uuid) : [])
    setUserRole(`${details.roles[0]}`)
    setUserDataFormInitialValues({
      name: details.name,
      email: details.email,
      role: `${details.roles[0]}`,
      sendEmailNotifications: details.privateMarketEmailNotifications ?? false,
      allowedRegion: details.allowedRegions && details.allowedRegions.length > 0 ? details.allowedRegions[0].uuid : '',
      allowedTowns: details.allowedTowns && details.allowedTowns.length > 0 ? details.allowedTowns.map(e => e.uuid) : []
    })
    setSubscriptionValue(details.subscriptionValue != null ? formatNumber(details.subscriptionValue, true, true) : '')
    setUserTags(details.tags?.map(e => {
      return {
        label: e.label,
        value: e.uuid
      }
    }))
    if (userDataFormRef.current) {
      userDataFormRef.current.resetForm()
    }
  }

  React.useEffect(() => {
    fetchRegionList().then(res => {
      fetchUserTags('').then(res => {
        fetchAccountDetails(uuid).then(res => {
          setIsLoading(false)
        }).catch(error => { 
          onErrorResponseShowMessage(error)
          if (error.response?.status === 403 || error.response?.status === 404) {
            history.push('/')
          }
        })
      }).catch(error => { 
        onErrorResponseShowMessage(error)
        if (error.response?.status === 403 || error.response?.status === 404) {
          history.push('/')
        }
      })
    }).catch(error => { 
      onErrorResponseShowMessage(error)
      if (error.response?.status === 403 || error.response?.status === 404) {
        history.push('/')
      }
    })
  }, [])

  React.useEffect(() => {
    if (allowedRegion !== '') {
      const selRegion = regions.find(e => e.uuid === allowedRegion)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => allowedTowns.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      setAllowedTowns(selectedTowns.map(e => e.uuid))
    } else {
      setTownList([])
    }
  }, [allowedRegion])

  React.useEffect(() => {
    if (details) {
      setUserDataFormInitialData()
    }
  }, [details])

  const handleChangeUserDataFormSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.editUserDetails(uuid, {
        name: data.name.trim(),
        privateMarketEmailNotifications: data.sendEmailNotifications,
        allowedSendingSms: data.allowedSendingSms,
        role: parseInt(data.role),
        allowedRegions: allowedRegion !== '' ? [allowedRegion] : [],
        allowedTowns: allowedTowns ?? [],
        tags: userTags?.map((tag: AsyncCreatableSelectOption) => {
          return {
            label: tag.label,
            uuid: tag.value,
            isNew: tag.__isNew__,
          }
        }) ?? [],
        subscriptionValue: data.subscriptionValue ? parseFloat(data.subscriptionValue) : undefined
      })

      await fetchAccountDetails(uuid)
    
      dispatch(
        addSuccessMessage(
          MessageEnum.USER_DATA_HAS_BEEN_CHANGED
        )
      )
      setIsSubmitting(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const handleAddToBalanceFormSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.addToUserBalance(uuid, {
        amount: parseFloat(data.amount.replace(/\s/g, '').replace(',', '.')),
      })
      await fetchAccountDetails(uuid)
      dispatch(
        addSuccessMessage(
          MessageEnum.USER_DATA_HAS_BEEN_CHANGED
        )
      )
      setIsSubmitting(false)
      dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
      resetAddToBalanceFormState()
      setShowAddToBalanceDialog(false)
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const closeAddToBalanceDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    resetAddToBalanceFormState()
    setShowAddToBalanceDialog(false)
  }

  const addAddToBalanceDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
          marginBottom: '20px'
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tForms('add_to_balance_form_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeAddToBalanceDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={addBalanceFormRef}
          enableReinitialize
          validationSchema={addToBalanceValidation(tForms)}
          initialValues={{
            amount: ''
          }}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleAddToBalanceFormSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <div 
                  style={{
                    width: '80%',
                    position: 'relative'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_to_balance_form_amount_label')}
                  </p>
                  <BaseNumberInput 
                    name="amount" 
                    placeholder={tForms('price_placeholder')}
                    formikProps={formikProps}
                    monetaryValue
                    value={chosenAmount}
                    setValue={setChosenAmount}
                    error={
                      formikProps.touched.amount &&
                      Boolean(formikProps.errors.amount)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={tForms('save_button_label')}
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  const closeConfirmDeactivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const closeConfirmActivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const confirmDeactivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tSuperuserSettings('confirm_deactivate_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmDeactivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.deactivateUser(details.uuid)
                await fetchAccountDetails(details.uuid)
                setIsSubmitting(false)
                closeConfirmDeactivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const confirmActivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {tSuperuserSettings('confirm_activate_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmActivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.activateUser(details.uuid)
                await fetchAccountDetails(details.uuid)
                setIsSubmitting(false)
                closeConfirmActivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showAddToBalanceDialog}
      >
        {addAddToBalanceDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showActivateDialog}
      >
        {confirmActivateDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showDeactivateDialog}
      >
        {confirmDeactivateDialogContent()}
      </CustomDialog>
      
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        minHeight: '100%',
        display: isLoading ? 'none' : 'flex'
      }}>
        <div style={{
          width: '65%',
        }}>
          <section>
            <div style={{
              minHeight: '62px',
              paddingLeft: '20px',
              paddingRight: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}>
              <div style={{
                width: '50%',
                minWidth: '380px',
                textTransform: 'uppercase',
                fontSize: '16px',
                fontFamily: 'AvenirMedium',
                letterSpacing: '1.49px',
                lineHeight: '22px',
                marginTop: '20px',
                flexGrow: 1
              }}>
                {tForms('manage_user_section_label')}
              </div>
              {
                details
                ? <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexGrow: 1
                    }}
                  > 
                    <CustomButton 
                      textComponent={
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '-4px',                          
                          fontSize: '18px'
                        }}>
                          <OpenInNewOutlined fontSize={'inherit'}/>
                          <span style={{
                            paddingTop: '3px',
                            fontSize: '14px',
                            fontFamily: 'Avenir',
                            marginLeft: '3px'
                          }}>{tSuperuserSettings('show_user_view_button_label')}</span>
                        </div>
                      }
                      style={{
                        maxWidth: '220px',
                        flexShrink: 0,
                        marginRight: '5px',
                        height: '30px',
                        marginTop: '20px',
                        paddingLeft: '10px',
                        paddingRight: '15px',
                        paddingTop: '7px',
                      }}
                      onClick={() => {
                        window.location.href = `/?assume=${uuid}`
                      }}
                    /> 
                    <CustomButton 
                      textComponent={
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '-4px',
                          fontSize: '14px'
                        }}>
                          <span style={{
                            paddingTop: '3px',
                            fontSize: '14px',
                            fontFamily: 'Avenir'
                          }}>
                            {
                              details.active 
                              ? tSuperuserSettings('deactivate_event_button_label') 
                              : tSuperuserSettings('activate_event_button_label')
                            }
                          </span>
                        </div>
                      }
                      style={{
                        maxWidth: details.active ? '200px' : '200px',
                        // float: 'right',
                        flexShrink: 0,
                        height: '30px',
                        marginTop: '20px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '7px',
                        backgroundColor: details.active ? Colors.RED_BUTTON_COLOR : Colors.BLUE_BUTTON_COLOR
                      }}
                      onClick={() => {
                        dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                        details.active ? setShowDeactivateDialog(true) : setShowActivateDialog(true)
                      }}
                    />
                  </div>
                : null
              }
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Formik
                innerRef={userDataFormRef}
                validationSchema={manageUserDataValidation(tForms)}
                enableReinitialize
                initialValues={userDataFormInitialValues}
                onSubmit={handleChangeUserDataFormSubmit}
              >
                {(formikProps) => (
                  <div style={{
                    width: '80%',
                    position: 'relative'
                  }}>
                    <Form 
                      onKeyDown={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault()
                        }
                      }}
                    >
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('user_data_form_name_field_label')}
                          </p>
                          <BaseInput 
                            count
                            maxLen={120}
                            name="name" 
                            placeholder={tForms('user_data_form_name_field_placeholder')}
                            formikProps={formikProps}
                            value={userName}
                            setValue={setUserName}
                            error={
                              formikProps.touched.name &&
                              Boolean(formikProps.errors.name)
                            }
                          />
                        </div>
                        
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('user_data_form_email_field_label')}
                          </p>
                          <BaseInput
                            count
                            maxLen={120}
                            name="email" 
                            disabled={true}
                            placeholder={tForms('user_data_form_email_field_placeholder')}
                            formikProps={formikProps}
                            value={userEmail}
                            setValue={setUserEmail}
                            error={
                              formikProps.touched.email &&
                              Boolean(formikProps.errors.email)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {'Województwo'}
                          </p>
                          <div 
                            className={`${classes.clearButton}`} 
                            style={{
                              ...(allowedRegion === '' ? { display: 'none' } : {})
                            }}
                            onClick={() => {
                              setAllowedRegion("")
                              setAllowedTowns([])
                              formikProps.setFieldValue('allowedRegion', '')
                              formikProps.setFieldValue('allowedTowns', [])
                            }}
                          >
                            {tForms('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            instanceId={'region-field'}
                            name="allowedRegion"
                            placeholder={'Województwo'}
                            options={regions.map(e => {
                              return {
                                value: e.uuid,
                                label: e.name
                              }
                            }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={allowedRegion}
                            setValue={setAllowedRegion}
                            formikProps={formikProps}
                            error={
                              formikProps.touched.allowedRegion &&
                              Boolean(formikProps.errors.allowedRegion)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {'Miejscowości'}
                          </p>
                          <div 
                            className={`${classes.clearButton}`}
                            style={{
                              ...(allowedRegion === '' || allowedTowns.length === 0 ? { display: 'none' } : {})
                            }}
                            onClick={() => {
                              setAllowedTowns([])
                              formikProps.setFieldValue('allowedTowns', [])
                            }}
                          >
                            {tForms('clear_field_button_label')}
                          </div>
                          <CustomSelectField
                            instanceId={'town-field'}
                            disabled={allowedRegion === ''}
                            name="allowedTowns"
                            isMulti
                            placeholder={'Miasto/Miejscowość'}
                            options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                            value={allowedTowns}
                            setValue={setAllowedTowns}
                            formikProps={formikProps}
                            error={
                              formikProps.touched.allowedTowns &&
                              Boolean(formikProps.errors.allowedTowns)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '23.5%',
                            paddingRight: '1%'
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('add_user_form_role_field_label')}
                          </p>
                          <CustomSelectField
                            instanceId={'role-field'}
                            name="role"
                            options={UserRoles}
                            value={userRole}
                            setValue={setUserRole}
                            formikProps={formikProps}
                            error={
                              formikProps.touched.role &&
                              Boolean(formikProps.errors.role)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '29%'
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('subscription_value_field_label')}
                          </p>
                          <BaseNumberInput
                            monetaryValue
                            name="subscriptionValue" 
                            placeholder={tForms('subscription_value_field_placeholder')}
                            formikProps={formikProps}
                            value={subscriptionValue}
                            setValue={setSubscriptionValue}
                            error={
                              formikProps.touched.subscriptionValue &&
                              Boolean(formikProps.errors.subscriptionValue)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '30%',
                            paddingRight: '1%'
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('add_user_tag_field_label')}
                          </p>
                          <div 
                            className={`${classes.clearButton}`}
                            style={{
                              paddingRight: '10px',
                              ...(userTags.length === 0 ? { display: 'none' } : {})
                            }}
                            onClick={() => {
                              setUserTags([])
                            }}
                          >
                            {tForms('clear_field_button_label')}
                          </div>
                          <CustomAsyncCreatableSelectField
                            instanceId={'tags-field'}
                            name="tags"
                            loadOptions={async (inputValue: string) => {
                              const tags = await DataApi.searchUserTags(inputValue)
                              return tags.data.map(e => {
                                return {
                                  label: e.name,
                                  value: e.uuid
                                }
                              })
                            }}
                            placeholder={tForms('add_user_tag_field_placeholder')}
                            isMulti
                            options={[
                              ...userTags,
                              ...userTagList.filter(e => !userTags.find(n => n.value === e.value)),
                            ]}
                            value={userTags}
                            setValue={setUserTags}
                            formikProps={formikProps}
                            error={
                              formikProps.touched.tags &&
                              Boolean(formikProps.errors.tags)
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                            paddingRight: '1%'
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('send_mail_notifications_switch_label')}
                          </p>
                          <div
                            style={{
                              marginTop: '15px',
                              marginBottom: '10px',
                              display: 'flex',
                              flexWrap: 'nowrap'
                            }}
                          >
                            <div>
                              <CustomSwitch 
                                // disabled={true}
                                name={'sendEmailNotifications'}
                                checked={sendEmailNotification}
                                setChecked={setSendEmailNotification}
                                formikProps={formikProps}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '10px',
                                fontFamily: 'AvenirBook',
                                fontSize: '15px',
                                color: '#6D7278',
                                marginTop: '-1px'
                              }}
                            >
                              {
                                sendEmailNotification
                                ? 'wysyłka włączona'
                                : 'wysyłka wyłączona'
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div 
                          className={classes.fieldWithLabel}
                          style={{
                            width: '100%',
                            paddingRight: '1%'
                          }}
                        >
                          <p className={classes.fieldLabel}>
                            {tForms('allowed_sending_sms_switch_label')}
                          </p>
                          <div
                            style={{
                              marginTop: '15px',
                              marginBottom: '10px',
                              display: 'flex',
                              flexWrap: 'nowrap'
                            }}
                          >
                            <div>
                              <CustomSwitch 
                                name={'allowedSendingSms'}
                                checked={allowedSendingSms}
                                setChecked={setAllowedSendingSms}
                                formikProps={formikProps}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '10px',
                                fontFamily: 'AvenirBook',
                                fontSize: '15px',
                                color: '#6D7278',
                                marginTop: '-1px'
                              }}
                            >
                              {
                                allowedSendingSms
                                ? 'wysyłka włączona'
                                : 'wysyłka wyłączona'
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{
                        float: 'right',
                        marginBottom: '30px'
                      }}>
                        <LoadingButton 
                          text={tForms('save_button_label')}
                          // disabled={!formikProps.isValid || !formikProps.dirty}
                          type='submit'
                          loading={isSubmitting}
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            height: '40px'
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </section>
        </div>
        {/* <div style={{
          width: '1px',
          borderLeft: '1px solid #BFC5D2',
          marginTop: '2%',
          marginBottom: '2%'
        }}>

        </div>
        <div style={{
          width: 'calc(35% - 1px)'
        }}>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginTop: '20px',
              paddingLeft: '10%',
              paddingRight: '10%'
            }}
          >
            <div style={{
              background: 'linear-gradient(180deg, #97B5F2 0%, #0B2E5E 100%)',
              width: '100%',
              height: '230px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white'
            }}>
              <div
                style={{
                  marginRight: '10px',
                  marginTop: '8px'
                }}
              >
                <img 
                  src={walletIcon}
                  width={80}
                  height={70}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <div 
                  style={{
                    textAlign: 'center',
                    fontFamily: 'AvenirMedium',
                    fontSize: '40px'
                  }}
                >
                  {`${details?.balance.toFixed(2)} zł`}
                </div> 
                <div 
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Avenir',
                    fontSize: '16px'
                  }}
                >
                  BUDŻET
                </div> 
              </div>
            </div>
            <CustomButton 
              textComponent={
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-1px'
                }}>
                  <AddIcon />
                  <span style={{
                    marginTop: '1px'
                  }}>
                    {'Dodaj wpłatę'}
                  </span>
                </div>
              }
              style={{
                maxWidth: '170px',
                height: '35px',
                paddingLeft: '10px',
                paddingRight: '15px',
                paddingTop: '7px',
                marginBottom: '20px',
                backgroundColor: Colors.GREEN_BUTTON_COLOR,
              }}
              onClick={() => {
                dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                setShowAddToBalanceDialog(true)
              }}
            />
          </div>
        </div> */}


      </div>
    </>
  )
}

export default ManageUser
