import {
  AppBar,
  Avatar,
  CircularProgress,
  Drawer,
  IconButton,
  SvgIconTypeMap,
  Toolbar
} from "@material-ui/core"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"
import {
  Close as CloseIcon,
  Menu as MenuIcon
} from "@material-ui/icons"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import AuthRefreshApi from "../../api/AuthRefreshApi"
import fullLogo from '../../assets/images/logo.webp'
import eventsIcon from '../../assets/images/menu/events.webp'
import eventsSelectedIcon from '../../assets/images/menu/events_selected.webp'
import myAgencySelectedIcon from '../../assets/images/menu/ic-agency-active.webp'
import myAgencyIcon from '../../assets/images/menu/ic-agency-inactive.webp'
import blacklistSelectedIcon from '../../assets/images/menu/ic-blacklist-active.webp'
import blacklistIcon from '../../assets/images/menu/ic-blacklist-inactive.webp'
import closedMenuIcon from '../../assets/images/menu/menu.svg'
import superuserSettingsIcon from '../../assets/images/menu/settings.webp'
import superuserSettingsSelectedIcon from '../../assets/images/menu/settings_selected.webp'
import userIcon from '../../assets/images/menu/user.webp'
import userSelectedIcon from '../../assets/images/menu/user_selected.webp'
import helpSelectedIcon from '../../assets/images/menu/ic-help-active.webp'
import helpIcon from '../../assets/images/menu/ic-help-inactive.webp'
import bulkActionIcon from '../../assets/images/menu/ic-package-inactive.webp'
import bulkActionSelectedIcon from '../../assets/images/menu/ic-packages-active.webp'
import { removeToken } from "../../redux/auth/actions"
import { addCmdMessage } from '../../redux/messages/actions'
import { addPermissions, removePermissions } from '../../redux/permissions/actions'
import { State } from "../../redux/reducers"
import { addUserName, removeUserName } from '../../redux/user/actions'
import { PermissionLevel } from "../../types/auth"
import { Colors } from '../../types/colors'
import { checkIfAdmin, getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { StorageService } from "../../utils/token/StorageService"
import ChildAccountDetails from "../ChildAccountDetails/ChildAccountDetails"
import LoadingButton from '../common/LoadingButton/LoadingButton'
import CustomDialog from '../CustomDialog/CustomDialog'
import EventDetails from '../EventDetails/EventDetails'
import EventsList from '../EventsList/EventsList'
import FAQPage from "../FAQPage/FAQPage"
import ManageUser from '../ManageUser/ManageUser'
import { MessageEnum } from '../Messages/types'
import MyAgency from '../MyAgency/MyAgency'
import PhoneBlacklist from '../PhoneBlacklist/PhoneBlacklist'
import SuperEventDetailsSelector from "../SuperEventDetailsSelector/SuperEventDetailsSelector"
import SuperEventsListSelector from '../SuperEventsListSelector/SuperEventsListSelector'
import SuperuserSettings from '../SuperuserSettings/SuperuserSettings'
import UserDetails from '../UserDetails/UserDetails'
import { dashboardStyles } from "./dashboardStyles"
import FAQAnswerPage from "../FAQAnswerPage/FAQAnswerPage"
import BulkActionPage from "../BulkActionPage/BulkActionPage"
import BulkActionsDetailsPage from "../BulkActionsDetailsPage/BulkActionsDetailsPage"
import { SmsUserSettings } from '../../types/common'

enum MenuItemType {
  LABEL,
  ITEM,
  DIVIDER
}

type MenuItem = {
  key: string
  text?: string
  type: MenuItemType
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  inactiveIconSrc?: string
  activeIconSrc?: string
  link?: string
  subLinks?: string[]
  mobileTitleSrc?: string
  requiredEnabledSetting?: string
  allowedPermissionLevels?: PermissionLevel[]
  withAssumedIdentity?: boolean
}

const dashboardMenuList: MenuItem[] = [
  {
    type: MenuItemType.LABEL,
    key: 'label_1',
    text: 'zarządzaj wysyłką',
    withAssumedIdentity: true
  },
  {
    type: MenuItemType.ITEM,
    key: 'events_menu',
    text: "Wysyłka pojedynczych SMS",
    inactiveIconSrc: eventsIcon,
    activeIconSrc: eventsSelectedIcon,
    link: "/",
    subLinks: ['/event/details/'],
    mobileTitleSrc: "Wysyłka pojedynczych SMS",
    withAssumedIdentity: true
  },
  {
    type: MenuItemType.ITEM,
    key: 'events_bulk_menu',
    text: "Wysyłka paczek SMS",
    inactiveIconSrc: bulkActionIcon,
    activeIconSrc: bulkActionSelectedIcon,
    // requiredEnabledSetting: 'allowedSendingSms',
    link: "/action/bulk",
    subLinks: ['/'],
    mobileTitleSrc: "Wysyłka paczek SMS",
    withAssumedIdentity: true,
    allowedPermissionLevels: [PermissionLevel.ADMIN, PermissionLevel.STANDARD, PermissionLevel.CHILD, PermissionLevel.SUPERADMIN]
  },
  {
    type: MenuItemType.ITEM,
    key: 'blacklist_menu',
    text: "Blacklista numerów",
    inactiveIconSrc: blacklistIcon,
    activeIconSrc: blacklistSelectedIcon,
    link: "/blacklist",
    subLinks: [],
    mobileTitleSrc: "Blacklista",
    withAssumedIdentity: false
  },
  {
    type: MenuItemType.DIVIDER,
    key: 'divider_1',
    withAssumedIdentity: true
  },
  {
    type: MenuItemType.LABEL,
    key: 'label_2',
    text: 'ustawienia',
    withAssumedIdentity: true
  },
  {
    type: MenuItemType.ITEM,
    key: 'my_agency',
    text: "Moja agencja",
    inactiveIconSrc: myAgencyIcon,
    activeIconSrc: myAgencySelectedIcon,
    link: "/agency",
    subLinks: ['/user'],
    mobileTitleSrc: "Moja agencja",
    withAssumedIdentity: true,
    allowedPermissionLevels: [PermissionLevel.ADMIN, PermissionLevel.STANDARD, PermissionLevel.SUPERADMIN]
  },
  {
    type: MenuItemType.ITEM,
    key: 'user_menu',
    text: "Moje konto",
    inactiveIconSrc: userIcon,
    activeIconSrc: userSelectedIcon,
    link: "/account",
    subLinks: [],
    mobileTitleSrc: "Moje konto",
    withAssumedIdentity: false
  },
  {
    type: MenuItemType.ITEM,
    key: 'superuser_settings_menu',
    text: "Funkcje administratora",
    inactiveIconSrc: superuserSettingsIcon,
    activeIconSrc: superuserSettingsSelectedIcon,
    link: "/admin",
    subLinks: ['/user/'],
    mobileTitleSrc: "Funkcje administratora",
    withAssumedIdentity: false,
    allowedPermissionLevels: [PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN]
  }
]

const faqMenuLink: MenuItem = {
  type: MenuItemType.ITEM,
  key: 'faq',
  text: "Centrum pomocy",
  inactiveIconSrc: helpIcon,
  activeIconSrc: helpSelectedIcon,
  link: "/faq",
  subLinks: ['/'],
  mobileTitleSrc: "Centrum pomocy",
}

const Dashboard = () => {
  const query = useQuery()

  const token = useSelector((e: State) => e.auth.token)
  const name = useSelector((e: State) => e.user.name)
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)
	const dispatch = useDispatch()
  const { t } = useTranslation("dashboard")
  const tFaq = useTranslation("faq").t
  const history = useHistory()

  const [showCover, setShowCover] = React.useState<boolean>(false)
  const [showDialogCover, setShowDialogCover] = React.useState<boolean>(false)
  const [userSettings, setUserSettings] = React.useState<SmsUserSettings | null>(null)

  const [loadingToken, setLoadingToken] = React.useState<boolean>(true)
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

  const [showLogoutDialog, setShowLogoutDialog] = React.useState<boolean>(false)
  const [showUpdatesDialog, setShowUpdatesDialog] = React.useState<boolean>(false)

  const [updatesDialogDescription, setUpdatesDialogDescription] = React.useState<string>('')

  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)

  const classes = dashboardStyles({
    drawerOpen: drawerOpen
  })()

  React.useEffect(() => {
    if (!token) {
      history.replace("/login")
      StorageService.getInstance().clearUserCache()
      dispatch(removeToken())
      dispatch(removeUserName())
      dispatch(removePermissions())
    }
  }, [token])

  React.useEffect(() => {
    const checkToken = async () => {
      try {
        const res = await AuthRefreshApi.checkSession()
        if (res.changes?.minPrices && res.changes?.templates) {
          setUpdatesDialogDescription(t('config_update_dialog_description'))
          dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
          dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
          setShowUpdatesDialog(true)
        } else if (res.changes?.minPrices) {
          setUpdatesDialogDescription(t('config_update_dialog_prices_only_description'))
          dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
          dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
          setShowUpdatesDialog(true)
        } else if (res.changes?.templates) {
          setUpdatesDialogDescription(t('config_update_dialog_templates_only_description'))
          dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
          dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
          setShowUpdatesDialog(true)
        } else {
          dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
        }
        StorageService.getInstance().setUserName(res.name)
        dispatch(addUserName(res.name))
        dispatch(addPermissions(res.permissionLevels))
        setLoadingToken(false)
        setUserSettings(res.settings)
      } catch (e) {
        history.replace("/login")
        StorageService.getInstance().clearUserCache()
        dispatch(removeToken())
        dispatch(removeUserName())
        dispatch(removePermissions())
        onErrorResponseShowMessage(e)
      }
    }
    checkToken()
  }, [])

  React.useEffect(() => {
    switch (message.cmd) {
      case MessageEnum.ACCOUNT_REMOVED:
      case MessageEnum.UNAUTHORIZED:
      case MessageEnum.INACTIVE_USER:
        handleLogoutButtonClick()
        break
      case MessageEnum.ENABLE_PAGE_COVER:
        setShowCover(true)
        break
      case MessageEnum.DISABLE_PAGE_COVER:
        setShowCover(false)
        setShowDialogCover(false)
        break
      case MessageEnum.ENABLE_DIALOG_COVER:
        setShowDialogCover(true)
        break
      case MessageEnum.DISABLE_DIALOG_COVER:
        setShowDialogCover(false)
        break
    }
  }, [message])

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  const handleLogoutButtonClick = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    StorageService.getInstance().clearUserCache()
    dispatch(removeToken())
    dispatch(removeUserName())
    dispatch(removePermissions())
    history.replace("/login")
  }

  const checkIfCurrentPath = (link: string, subLinks: string[]): boolean => {
    if (link === history.location.pathname) {
      return true
    }
    for (let subLink of subLinks) {
      if (history.location.pathname.startsWith(`${link !== '/' ? link : ''}${subLink}`)) {
        return true
      }
    }
    return false
  }

  const mobilePathName = (): string => {
    let name = ""
    for (let e of dashboardMenuList) {
      if (e.type === MenuItemType.ITEM && checkIfCurrentPath(e.link, e.subLinks)) {
        name = t(e.mobileTitleSrc)
        break
      }
    }
    return name
  }

  const logoutButton = (
    <div 
      className={classes.logoutMenu} 
      style={{
        cursor: 'pointer',
        paddingBottom: '15px',
        paddingTop: '15px',
        paddingLeft: '3px'
      }}
      onClick={() => {
        dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
        setShowLogoutDialog(true)
      }}
    >
      <div
        className={classes.menuLinkIcon}
        style={{
          padding: "0 9px",
          fontSize: '30px'
        }}
      >
        <PowerSettingsNewIcon color="secondary" fontSize={'inherit'} />
      </div>
      {drawerOpen ? (
        <span 
          className={classes.menuLinkLabel}
          style={{
            marginTop: '6px'
          }}
        >
          {t("logout_buttom")}
        </span>
      ) : null}
    </div>
  )
 
  const createMenuListItem = (
    key: string,
    text: string,
    link: string,
    subLinks: string[],
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    inactiveIconSrc?: string,
    activeIconSrc?: string,
    disabled?: boolean
  ) => {
    let isCurrentPath = checkIfCurrentPath(link, subLinks)

    return (
      <li
        key={key}
        className={isCurrentPath ? classes.menuItemSelected : ""}
      >
        {
          disabled
          ? <div
              className={classes.menuLinkItem} 
              style={{
                padding: icon ? "15px 0px" : "15px 0px",
                marginLeft: isCurrentPath ? 0 : '3px',
                opacity: 0.5
              }}
            >
              {createMenuItemContent(key, text, link, subLinks, icon, inactiveIconSrc, activeIconSrc)}
            </div>
          : <Link
              to={`${link}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} 
              className={classes.menuLinkItem} 
              style={{
                padding: icon ? "15px 0px" : "15px 0px",
                marginLeft: isCurrentPath ? 0 : '3px'
              }}
            >
              {createMenuItemContent(key, text, link, subLinks, icon, inactiveIconSrc, activeIconSrc)}
            </Link>
        }
        
      </li>
    )
  }

  const createMenuItemContent = (
    key: string,
    text: string,
    link: string,
    subLinks: string[],
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    inactiveIconSrc?: string,
    activeIconSrc?: string
  ) => {
    let Icon = icon
    let isCurrentPath = checkIfCurrentPath(link, subLinks)

    return (
      <>
        <div className={classes.menuLinkIcon}>
          {
            Icon 
            ? <Icon color={isCurrentPath ? "primary" : "secondary"} /> 
            : (
              inactiveIconSrc && inactiveIconSrc !== '' && activeIconSrc && activeIconSrc !== ''
                ? <img 
                  width={25}
                  height={25}
                  src={isCurrentPath ? activeIconSrc : inactiveIconSrc} 
                  alt={'menu-icon'} 
                />
                : null
            )
          }
        </div>
        {
          drawerOpen 
          ? (
              <span className={classes.menuLinkLabel} style={{
                marginTop: Icon ? '2px' : '4px',
                color: isCurrentPath ? Colors.MENU_SELECTED_TEXT : Colors.SECONDARY
              }}>
                {text}
              </span>
            ) 
          : null
        }
      </>
    )
  }

  const createAvatar = (name: string) => {
    let initials = ''
    for (const part of name.split(' ')) {
      initials += part.charAt(0)
    }
    return (
      <div
        style={{
          display: "flex",
          margin: "0px 15px",
        }}
      >
        <Avatar
          style={{
            marginRight: "12px",
            width: "38px",
            height: "38px",
            fontSize: "18px",
            textTransform: 'uppercase'
          }}
          variant="rounded"
        >
          {initials}
        </Avatar>
        <div>
          <div className={classes.avatarTitle}>
            {t("hello")}
          </div>
          <div className={classes.avatarSubTitle}>
            {name}
          </div>
        </div>
      </div>
    )
  }

  const closeConfirmLogoutDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowLogoutDialog(false)
  }

  const closeUpdatesDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowUpdatesDialog(false)
  }

  const updatesDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('config_update_dialog_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '30px',
            marginTop: '-2px',
            marginLeft: '30px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeUpdatesDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 30px 5px 30px',
            fontFamily: 'AvenirBook',
            fontSize: '16px'
          }}
        >
          {updatesDialogDescription}
        </div>

        <div
          style={{
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            padding: '5px 30px 20px 30px',
            fontFamily: 'AvenirBook',
            fontSize: '16px',
            color: 'red'
          }}
        >
          {t('config_update_dialog_warning')}
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={t('config_update_dialog_button_label')}
            style={{
              height: '35px',
              width: '150px',
              flexGrow: 0
            }}
            onClick={closeUpdatesDialog}
          />
        </div>
      </div>
    )
  }

  const confirmLogoutDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_logout_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '30px',
            marginTop: '-2px',
            marginLeft: '30px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeConfirmLogoutDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmLogoutDialog()
            }}
          />
          <LoadingButton 
            text={'Tak,\u00A0wyloguj\u00A0mnie'}
            style={{
              height: '35px',
              width: '150px',
              flexGrow: 0
            }}
            onClick={handleLogoutButtonClick}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showLogoutDialog}
      >
        {confirmLogoutDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showUpdatesDialog}
      >
        {updatesDialogContent()}
      </CustomDialog>
      <div className={classes.pageContainer}>
        <div className={classes.drawerContainer}>
          <Drawer
            variant="permanent"
            className={`${
              drawerOpen ? classes.drawerOpen : classes.drawerClose
            } ${classes.drawer}`}
            classes={{
              paper: drawerOpen
                ? classes.drawerOpen
                : classes.drawerClose,
            }}
          >
            <div>
              <div className={classes.drawerIcon}>
                <IconButton
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  style={{ margin: "13px" }}
                  color="secondary"
                >
                  {drawerOpen ? <CloseIcon /> : <img 
                    width={24}
                    height={24}
                    src={closedMenuIcon} 
                    alt={'closed-menu-icon'} 
                  />}
                </IconButton>
              </div>
              <div
                style={{
                  opacity: drawerOpen ? '100%' : "0%",
                  marginTop: "15px",
                }}
              >
                {createAvatar(name)}
              </div>
              <ul className={classes.menuList}>
                {dashboardMenuList.map((e) => {
                  // Check if assume
                  if (permissions.permissions.findIndex(n => [PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN].includes(n)) > -1 && assumedIdentity && !e.withAssumedIdentity) {
                    return null
                  } else if (
                    !(permissions.permissions.findIndex(n => [PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN].includes(n)) > -1 && assumedIdentity) 
                    && e.allowedPermissionLevels != null && permissions.permissions.findIndex(n => e.allowedPermissionLevels.includes(n)) === -1) { // Check permissions
                    return null
                  }
                  switch (e.type) {
                    case MenuItemType.ITEM:
                      return createMenuListItem(
                        e.key,
                        e.text,
                        e.link,
                        e.subLinks,
                        e.icon,
                        e.inactiveIconSrc,
                        e.activeIconSrc,
                        e.requiredEnabledSetting != null && !assumedIdentity
                        ? !userSettings?.[e.requiredEnabledSetting]
                        : false
                      )
                    case MenuItemType.DIVIDER:
                      return (<hr className={classes.menuDivider}/>)
                    case MenuItemType.LABEL:
                      return (
                        drawerOpen
                        ? <div className={classes.drawerSectionLabel}>{e.text}</div>
                        : <div className={classes.drawerSectionLabelClosed}></div>
                      )
                  }
                  
                })}
              </ul>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            {
              assumedIdentity
              ? null
              : <ul className={classes.helpMenuLink}>
                  {createMenuListItem(faqMenuLink.key, faqMenuLink.text, faqMenuLink.link, faqMenuLink.subLinks, faqMenuLink.icon, faqMenuLink.inactiveIconSrc, faqMenuLink.activeIconSrc,)}
                </ul>
            }
            {logoutButton}
          </Drawer>
        </div>
        <div className={`${classes.mainContentContainer} ${drawerOpen ? classes.mainContentContainerShrink : classes.mainContentContainerEnlarge}`}>
          <AppBar className={classes.appBar} position="relative">
            <div className={classes.toolbarDesktop}>
              <Toolbar>
                <Link className={classes.appBarImage} to={`/${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`}>
                  <h1>
                    <img src={fullLogo} alt="logo" />
                  </h1>
                </Link>
                <div style={{ flexGrow: 1 }}></div>
              </Toolbar>
            </div>
            <div className={classes.toolbarSmall}>
              <Toolbar style={{ minHeight: 0, padding: 0 }}>
                <IconButton
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  color="secondary"
                >
                  {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <div style={{ flexGrow: 1 }}></div>
                <p style={{ 
                  fontFamily: "RubikMedium",
                  color: '#2E384D',
                  fontSize: '1rem',
                  lineHeight: '1.5' 
                }}>
                  {mobilePathName()}
                </p>
                <div style={{ flexGrow: 1 }}></div>
              </Toolbar>
            </div>
          </AppBar>
          <div
            className={`${classes.drawerSmall} ${
              drawerOpen
                ? classes.drawerSmallOpen
                : classes.drawerSmallClose
            }`}
          >
            <div>
              <IconButton
                onClick={() => setDrawerOpen(!drawerOpen)}
                style={{ margin: "13px" }}
                color="secondary"
              >
                {drawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </div>
            <div style={{ margin: "20px 0px 0px 10px" }}>{createAvatar}</div>
            <ul className={classes.menuList}>
              {dashboardMenuList.map((e) => {
                // Check if assume
                if (permissions.permissions.findIndex(n => [PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN].includes(n)) > -1 && assumedIdentity && !e.withAssumedIdentity) {
                  return null
                } else if (
                  !(permissions.permissions.findIndex(n => [PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN].includes(n)) > -1 && assumedIdentity) 
                  && e.allowedPermissionLevels != null && permissions.permissions.findIndex(n => e.allowedPermissionLevels.includes(n)) === -1) { // Check permissions
                  return null
                }
                switch (e.type) {
                  case MenuItemType.ITEM:
                    return createMenuListItem(
                      e.key,
                      e.text,
                      e.link,
                      e.subLinks,
                      e.icon,
                      e.inactiveIconSrc,
                      e.activeIconSrc,
                      e.requiredEnabledSetting != null 
                      ? !userSettings?.[e.requiredEnabledSetting]
                      : false
                    )
                  case MenuItemType.DIVIDER:
                    return (<hr className={classes.menuDivider}/>)
                  case MenuItemType.LABEL:
                    return (
                      drawerOpen
                      ? <div className={classes.drawerSectionLabel}>{e.text}</div>
                      : <div className={classes.drawerSectionLabelClosed}></div>
                    )
                }
              })}
            </ul>
            <div style={{ flexGrow: 1 }}></div>
            {
              assumedIdentity
              ? null
              : <ul className={classes.helpMenuLink}>
                  {createMenuListItem(faqMenuLink.key, faqMenuLink.text, faqMenuLink.link, faqMenuLink.subLinks, faqMenuLink.icon, faqMenuLink.inactiveIconSrc, faqMenuLink.activeIconSrc,)}
                </ul>
            }
            {logoutButton}
          </div>
          <main className={classes.mainContainer}>
            {
              loadingToken 
              ? <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
                <CircularProgress size={48}/>
              </div>
              : <Switch>
                  {permissions.permissions.includes(PermissionLevel.SUPERADMIN) ? <Route path="/" exact component={SuperEventsListSelector} /> : <Route path="/" exact component={EventsList} />}
                  {permissions.permissions.includes(PermissionLevel.SUPERADMIN) ? <Route path="/event/details/:uuid" exact component={SuperEventDetailsSelector} /> : <Route path="/event/details/:uuid" exact component={EventDetails} />}
                  <Route path="/blacklist" exact component={PhoneBlacklist} />
                  <Route path="/account" exact component={UserDetails} />
                  {permissions.permissions.includes(PermissionLevel.ADMIN) || permissions.permissions.includes(PermissionLevel.SUPERADMIN) || permissions.permissions.includes(PermissionLevel.STANDARD) ? <Route path="/agency" exact component={MyAgency} /> : <Route path="/agency" exact render={() => <Redirect to={'/'}/>} />}
                  {permissions.permissions.includes(PermissionLevel.ADMIN) || permissions.permissions.includes(PermissionLevel.SUPERADMIN) || permissions.permissions.includes(PermissionLevel.STANDARD) ? <Route path="/agency/user/:uuid" exact component={ChildAccountDetails} /> : <Route path="/agency/user/:uuid" exact render={() => <Redirect to={'/'}/>} />}
                  {permissions.permissions.includes(PermissionLevel.ADMIN) || permissions.permissions.includes(PermissionLevel.SUPERADMIN) ? <Route path="/admin" exact component={SuperuserSettings} /> : <Route path="/admin" exact render={() => <Redirect to={'/'}/>} />}
                  {permissions.permissions.includes(PermissionLevel.ADMIN) || permissions.permissions.includes(PermissionLevel.SUPERADMIN) ? <Route path="/admin/user/:uuid" exact component={ManageUser} /> : <Route path="/admin/user/:uuid" exact render={() => <Redirect to={'/'}/>} />}
                  <Route path="/action/bulk" exact component={BulkActionPage} />
                  <Route path="/action/bulk/details/:uuid" exact component={BulkActionsDetailsPage} />
                  <Route path="/faq" exact component={FAQPage} />
                  <Route path="/faq/:slug" exact component={FAQAnswerPage} />
                  <Route path="/" render={() => <Redirect to={'/'}/>} />
                </Switch>
            }
          </main>
        </div>
      </div>
      <div 
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2000,
            background: 'rgba(0,0,0,0.5)',
            display: showCover ? 'block' : 'none'
          }}
        ></div>
        <div 
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 3000,
            background: 'rgba(0,0,0,0.5)',
            display: showDialogCover ? 'block' : 'none'
          }}
        ></div>
    </>
  )
}

export default Dashboard
