import { makeStyles } from '@material-ui/styles'
import { Colors } from '../../../types/colors'

export const bulkActionDetailsStyle
 = makeStyles(() => ({
  container: {
    marginBottom: '20px',
    minHeight: '100px'
  },
  warningLabel: {
    fontFamily: 'AvenirMedium',
    fontSize: '16px',
    color: '#f6ae3f',
    marginTop: '20px'
  },
  title: {
    fontFamily: 'AvenirHeavy',
    textDecoration: 'underline',
    fontSize: '16px',
    color: '#2e384d',
    marginBottom: '15px'
  },
  field: {
    fontSize: '14px',
    color: '#6d7278',
    fontFamily: 'AvenirBook',
    marginBottom: '3px',
    lineHeight: '24px'
  },
  fieldTitle: {
    whiteSpace: 'nowrap',
    fontFamily: 'AvenirMedium',
    fontSize: '14px',
    color: '#6d7278'
  },
  templatesSection: {
    display: 'flex'
  },
  changeDateButtonContainer: {
    display: 'inline-block',
    marginLeft: '10px'
  },
  baseChangeDateButton: {
    flexGrow: 1,
    textTransform: "none",
    width: "100%",
    padding: "10px 0px",
    fontSize: "15px",
    fontWeight: "normal",
    marginTop: "5px",
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    backgroundColor: 'transparent'
  },
  activateChangeDateButton: {
    border: `solid 2px ${Colors.AUTH_BUTTON_COLOR}`,
    color: Colors.AUTH_BUTTON_COLOR,
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default'
    }
  },
  cancelChangeDateButton: {
    border: `solid 2px #000`,
    color: '#000',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default'
    }
  },
  sentDateErrorContainer: {
    display: 'inline-block',
    color: 'red',
    textDecoration: 'underline',
    fontFamily: 'Avenir',
    fontSize: '14px'
  }
}))