import store from "../../store"
import logger from "../logger/logger"
import { addErrorMessage } from "../../redux/messages/actions"
import { MessageEnum } from "../../components/Messages/types"
import { useHistory } from 'react-router-dom'

export type AdditionalErrorResponseShowMessageData = {
  bulkActionAccept?: boolean
  bulkActionReject?: boolean
}

export const onErrorResponseShowMessage= (e: any, loggedIn: boolean = true, additionalData?: AdditionalErrorResponseShowMessageData) => {
  // logger.consoleLog(e)
  let errorMessage: string = "something_went_wrong_message"
  switch(true) {
    // Register
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 202:
      errorMessage = MessageEnum.USER_ALREADY_EXISTS
      break
    // User confirmation email, change password
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 105:
      errorMessage = MessageEnum.TOKEN_INVALID
      break
    // User confirmation email, change password
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 108:
      errorMessage = MessageEnum.TOKEN_ALREADY_USED
      break
    // User inactive
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 103:
      errorMessage = loggedIn ? MessageEnum.LOST_ACCESS : MessageEnum.INACTIVE_USER
      break
    // User removed 
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 104:
      errorMessage = loggedIn ? MessageEnum.LOST_ACCESS : MessageEnum.ACCOUNT_REMOVED
      break
    // Incorrect Pwd
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 106:
      errorMessage = MessageEnum.INCORRECT_PASSWORD
      break
    // User login 
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 100:
      errorMessage = MessageEnum.INVALID_CREDENTIALS
      break
    // Unauthorized
    case e.response?.status === 401 && e.response?.data?.code === 100 && e.response?.data?.subcode === 102:
      errorMessage = MessageEnum.UNAUTHORIZED
      break
    // searchParamsNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 211:
      errorMessage = MessageEnum.ELEMENT_NOT_FOUND
      break
    // nameAlreadyExists
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 212:
      errorMessage = MessageEnum.NAME_ALREADY_EXISTS
      break
    // searchParamsLimitExceeded
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 213:
      errorMessage = MessageEnum.SEARCH_PARAMS_LIMIT_EXCEEDED
      break
    // smsTemplateNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 214:
      errorMessage = MessageEnum.SMS_TEMPLATE_NOT_FOUND
      break
    // regionNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 207:
      errorMessage = MessageEnum.REGION_NOT_FOUND
      break
    // subregionNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 208:
      errorMessage = MessageEnum.SUBREGION_NOT_FOUND
      break
    // townNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 209:
      errorMessage = MessageEnum.TOWN_NOT_FOUND
      break
    // districtNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 210:
      errorMessage = MessageEnum.DISTRICT_NOT_FOUND
      break
    // phoneExistsOnBlacklist
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 216:
      errorMessage = MessageEnum.PHONE_EXISTS_ON_BLACKLIST
      break
    // email taken  
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 217:
      errorMessage = MessageEnum.EMAIL_TAKEN
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 218:
      errorMessage = MessageEnum.NOT_ALLOWED_LOCATION
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 219:
      errorMessage = MessageEnum.RESTRICTED_TO_TOWNS
      break
    case e.response?.status === 409 && e.response?.data?.code === 100 && e.response?.data?.subcode === 107:
      errorMessage = MessageEnum.SAME_PWD_AS_OLD_ONE
      break
    //apiMessageNotFound
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 220:
      errorMessage = MessageEnum.API_MESSAGE_NOT_FOUND
      break
    //apiMessageNotBelongToParamFound
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 221:
      errorMessage = MessageEnum.API_MESSAGE_NOT_BELONG_TO_PARAM_FOUND
      break
    //reactionAlreadyExists
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 222:
      errorMessage = MessageEnum.REACTION_ALREADY_EXISTS
      break
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 201:
      errorMessage = MessageEnum.ELEMENT_NOT_FOUND
      break
    case e.response?.status === 404 && e.response?.data?.code === 200 && e.response?.data?.subcode === 223:
      errorMessage = MessageEnum.ELEMENT_NOT_FOUND
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 225 && additionalData?.bulkActionAccept:
      errorMessage = MessageEnum.BULK_ACTION_CANCELLED_ON_ACCEPT
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 225 && additionalData?.bulkActionReject:
      errorMessage = MessageEnum.BULK_ACTION_CANCELLED_ON_REJECT
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 225:
      errorMessage = MessageEnum.BULK_ACTION_CANCELLED
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 226:
      errorMessage = MessageEnum.BULK_ACTION_ALREADY_ACCEPTED
      break
    case e.response?.status === 409 && e.response?.data?.code === 200 && e.response?.data?.subcode === 227:
      errorMessage = MessageEnum.BULK_ACTION_ALREADY_REJECTED
      break

      
    // case e.response?. 
  }

  store.dispatch(addErrorMessage(errorMessage))
}
