import { ADD_PERMISSIONS, REMOVE_PERMISSIONS } from "./types"
import { AnyAction } from "redux"
import { PermissionLevel } from '../../types/auth'


export type PermissionState = {
  permissions: PermissionLevel[]
}

export const initialAuthValue = {
  permissions: []
}

export const permissions = (state: PermissionState = initialAuthValue, action: AnyAction) => {
  switch(action.type) {
    case ADD_PERMISSIONS:
      return { permissions: action.permissions }
    case REMOVE_PERMISSIONS:
      return { permissions: [] }
    default:
      return state
  }
}