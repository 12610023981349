import { CircularProgress } from '@material-ui/core'
import {
  Check,
  Close as CloseIcon,
  EditOutlined as EditOutlinedIcon,
  OpenInNewOutlined
} from "@material-ui/icons"
import { ErrorMessage, Form, Formik } from 'formik'
import moment from "moment"
import React, { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import ReactTooltip from 'react-tooltip'
import DataApi from "../../api/DataApi"
import dialogIcon from '../../assets/images/dialog.webp'
import removedRedIcon from '../../assets/images/removed_red.webp'
import sendIcon from '../../assets/images/send.webp'
import formIcon from '../../assets/images/form.webp'
import trashIcon from '../../assets/images/Trash.webp'
import Checkbox from '../../lib/react-custom-checkbox'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { AdvertType, MarketType, plotTypes, propertyTypes, transactionTypes } from '../../types/advert'
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList, MSG_SIGNATURE_LEN, TEMPLATE_MAX_MSG, TEMPLATE_MSG_LEN, TEMPLATE_MULTIPLE_MSG_LEN } from '../../types/common'
import { clientTypes, smsApiMessageTypes, smsResponseTypes, ClientType } from '../../types/smsApiMessage'
import { getFromQuery, useQuery, getTimeDiffInString, checkIfAdmin } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { evalStringTemplate, formatNumber, normalizePhoneNumber } from '../../utils/text'
import { addNotificationEmail } from '../../utils/validations/addNotificationEmail'
import { editParamsForm } from '../../utils/validations/editParamsForm'
import { smsMessagePattern } from '../../utils/validations/validationRegexps'
import BaseInput from "../common/BaseInput/BaseInput"
import BaseNumberInput from '../common/BaseNumberInput/BaseNumberInput'
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import CustomSelectField from "../common/CustomSelectField/CustomSelectField"
import LoadingButton from "../common/LoadingButton/LoadingButton"
import Pagination from '../common/Pagination/Pagination'
import StatTile from "../common/StatTile/StatTile"
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { eventsDetailsStyle } from './eventsDetailsStyle'
import { useEventsDetails } from './useEventsDetails'
import {
  Menu,
  MenuItem
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { addUserReactionValidation } from '../../utils/validations/addUserReactionValidation'
import { PermissionLevel } from '../../types/auth'
import { useHistory } from 'react-router-dom'

const tableColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '3%'
  },{
    label: 'Data i\u00A0godzina wysyłki',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  }, {
    label: 'Nr\u00A0telefonu',
    headerAlign: 'left',
    contentAlign: 'left',
    width: 'calc(46% - 180px)'
  }, {
    label: 'Status',
    name: 'statusCode', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Odpowiedź użytkownika',
    name: 'responseStatus',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Typ\u00A0klienta i\u00A0notka',
    name: 'clientType', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: '',
    name: '', 
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'center',
    width: '180px'
  }
]

const emptyFormData = {
  name: '',
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  // smsPrice: '',
  region: '',
  subRegion: '',
  town: '',
  district: '',
  transactionType: '',
  propertyType: '',
  propertySubType: '',
  signature: '',
  minPricePreArea: '',
  maxPricePerArea: '',
  // TODO temporary change
  // marketType: '',
  notificationEmails: [],
  customTemplate: '',
  smsTemplate: [] as string[]
}

const BID_PER_PAGE = 25 // TODO move to separate file
// const MAX_SMS_TEMPLATES = 5 // TODO move to separate file

const EventDetails = () => {
  const classes = eventsDetailsStyle()

  const query = useQuery()
  const history = useHistory()
  const t = useTranslation("forms").t
  const tEventDetails = useTranslation("eventDetails").t

  const dispatch = useDispatch()
  const { uuid } = useParams<{ uuid: string }>()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isAllowedToEdit, setIsAllowedToEdit] = React.useState<boolean>(false)
  const [showControlButtons, setShowControlButtons] = React.useState<boolean>(false)
  const [isValidData, setIsValidData] = React.useState<boolean>(true)
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)
  const [isEditingCustomTemplate, setIsEditingCustomTemplate] = React.useState<boolean>(false)
  const [isCustomTemplateRemoved, setIsCustomTemplateRemoved] = React.useState<boolean>(false)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  const [addReactionFor, setAddReactionFor] = React.useState<string>(getFromQuery(query, 'add_reaction_for'))

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [isChildAccount, setIsChildAccount] = React.useState<boolean>(permissions.permissions.includes(PermissionLevel.CHILD))

  const [showEditDialog, setShowEditDialog] = React.useState<boolean>(false)
  const [showDeactivateDialog, setShowDeactivateDialog] = React.useState<boolean>(false)
  const [showAddUserReactionDialog, setShowAddUserReactionDialog] = React.useState<boolean>(false)
  const [showActivateDialog, setShowActivateDialog] = React.useState<boolean>(false)
  const [showLeaveEditDialog, setShowLeaveEditDialog] = React.useState<boolean>(false)

  const [formInitialValues, setFormInitialValues] = React.useState<any>(emptyFormData)
  const [formInitialErrors, setFormInitialErrors] = React.useState<any>({})

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isInitialPropertyTypeSet, setIsInitialPropertyTypeSet] = React.useState<boolean>(false)

  const [subRegionList, setSubRegionList] = React.useState<any[]>([])
  const [townList, setTownList] = React.useState<any[]>([])
  const [districtList, setDistrictList] = React.useState<any[]>([])

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [chosenName, setChosenName] = React.useState<string>('')

  const [chosenRegion, setChosenRegion] = React.useState<string>('')
  const [chosenSubregion, setChosenSubregion] = React.useState<string>('')
  const [chosenTown, setChosenTown] = React.useState<string[]>([])
  const [chosenDistrict, setChosenDistrict] = React.useState<string[]>([])

  const [chosenTransactionType, setChosenTransactionType] = React.useState<string>('')
  const [chosenPropertyType, setChosenPropertyType] = React.useState<string>('')
  const [chosenPropertySubType, setChosenPropertySubType] = React.useState<string>("")

  const [chosenMinPrice, setChosenMinPrice] = React.useState<string>('')
  const [chosenMaxPrice, setChosenMaxPrice] = React.useState<string>('')
  const [chosenMinArea, setChosenMinArea] = React.useState<string>('')
  const [chosenMaxArea, setChosenMaxArea] = React.useState<string>('')
  const [chosenMinPricePerArea, setChosenMinPricePerArea] = React.useState<string>('')
  const [chosenMaxPricePerArea, setChosenMaxPricePerArea] = React.useState<string>('')

  const [chosenMarketType, setChosenMarketType] = React.useState<string>('')
  const [chosenNotificationEmail, setChosenNotificationEmail] = React.useState<string>('')
  const [chosenNotificationEmails, setChosenNotificationEmails] = React.useState<string[]>([])

  const [chosenSmsPrice, setChosenSmsPrice] = React.useState<string>('')

  const [chosenMessageUuid, setChosenMessageUuid] = React.useState<string>('')
  const [chosenUserReactionClientType, setChosenUserReactionClientType] = React.useState<number>(ClientType.UNDEFINED)
  const [chosenUserReactionMessage, setChosenUserReactionMessage] = React.useState<string>('')

  const [chosenSmsTemplates, setChosenSmsTemplates] = React.useState<string[]>([])
  const [chosenCustomSmsTemplate, setChosenCustomSmsTemplate] = React.useState<string>('')
  const [tempCustomSmsTemplate, setTempCustomSmsTemplate] = React.useState<string>('')
  const [chosenSignature, setChosenSignature] = React.useState<string>('')

  const [filteredSmsTemplates, setFilteredSmsTemplates] = React.useState<any[]>([])

  const { 
    config, regions, smsTemplates, details, wonBidCount, wonBidList, bidStats, assumedUser,
    fetchConfig, fetchRegionList, fetchSmsTemplateList, fetchDetails, fetchWonBidCount, fetchWonBidList,
    editDetails, setSmsTemplates, fetchAssumedUser
	} = useEventsDetails()

  const formRef = useRef() as any
  const emailsFormRef = useRef() as any
  const customTemplateFormRef = useRef() as any
  const addUserReactionFormRef = useRef() as any

  const setFormInitialData = () => {
    setChosenCustomSmsTemplate(details.customMessage ? details.customMessage.template : '')
    setChosenName(details.name)
    setChosenRegion(details.params.region.uuid)
    setChosenSubregion(details.params.subregion?.uuid || '' ) 
    setChosenTown(details.params.town 
      ? [details.params.town.uuid]
      : (details.params.towns?.map(e => e.uuid) || [])) 
    setChosenDistrict(
      details.params.district 
      ? [details.params.district.uuid]
      : (details.params.districts?.map(e => e.uuid) || []))
    setChosenTransactionType(`${details.params.offerType}`)
    setChosenPropertyType(`${details.params.advertType}`)
    setChosenPropertySubType(details.params.advertSubType != null ? `${details.params.advertSubType}` : '')
    setChosenMinPrice(formatNumber(details.params.minPrice, true, true))
    setChosenMaxPrice(formatNumber(details.params.maxPrice, true, true))
    setChosenMinArea(formatNumber(details.params.minArea, true, true))
    setChosenMaxArea(formatNumber(details.params.maxArea, true, true))
    setChosenMinPricePerArea(details.params.minPricePerArea != null ? formatNumber(details.params.minPricePerArea, true, true) : '')
    setChosenMaxPricePerArea(details.params.maxPricePerArea != null ? formatNumber(details.params.maxPricePerArea, true, true) : '')
    // setChosenSmsPrice(formatNumber(details.bidValue, true, true))
    setChosenSignature(details.signature || '')
    setChosenSmsTemplates(details.smsMessageUuids)
    setChosenNotificationEmails(details.notificationEmails)
    // setChosenMarketType(`${details.marketType}`)
    setFormInitialValues({
      name: details.name,
      minPrice: `${details.params.minPrice}`.replace('.', ','),
      maxPrice: `${details.params.maxPrice}`.replace('.', ','),
      minArea: `${details.params.minArea}`.replace('.', ','),
      maxArea: `${details.params.maxArea}`.replace('.', ','),
      // smsPrice: `${details.bidValue}`.replace('.', ','),
      region: details.params.region.uuid,
      subRegion: details.params.subregion?.uuid || '',
      town: details.params.town 
        ? [details.params.town.uuid]
        : (details.params.towns?.map(e => e.uuid) || []),
      district: details.params.district 
        ? [details.params.district.uuid]
        : (details.params.districts?.map(e => e.uuid) || []),
      transactionType: `${details.params.offerType}`,
      propertyType: `${details.params.advertType}`,
      propertySubType: details.params.advertSubType != null ? `${details.params.advertSubType}` : '',
      signature: details.signature || '',
      minPricePerArea: `${details.params.minPricePerArea ?? ''}`.replace('.', ','),
      maxPricePerArea: `${details.params.maxPricePerArea ?? ''}`.replace('.', ','),
      smsTemplate: details.smsMessageUuids,
      notificationEmails: details.notificationEmails,
      // TODO commented temporary
      // marketType: `${details.marketType}`,
      customTemplate: details.customMessage ? details.customMessage.template : ''
    })
    setIsCustomTemplateRemoved(false)
    setIsEditingCustomTemplate(false)
    setTempCustomSmsTemplate('')

    setIsInitialPropertyTypeSet(false)

    if (formRef.current) {
      formRef.current.resetForm()
      formRef.current.setFieldTouched('smsPrice', true, false)
    }
  }

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  React.useEffect(() => {
    if (addReactionFor) {
      DataApi.checkIfCanAddReactionToMessage(addReactionFor, uuid).then(res => {
        setChosenMessageUuid(addReactionFor)
        openAddUserReactionDialog()
      }).catch((error) => onErrorResponseShowMessage(error))
    }
  }, [addReactionFor])

  React.useEffect(() => {
    if (regions) {
      setChosenRegion('')
      formRef.current.setFieldValue('region', '')
    }
  }, [regions])

  React.useEffect(() => {
    if (isInitialPropertyTypeSet) {
      setChosenSmsTemplates([])
      formRef.current.setFieldValue('smsTemplate', [])
      setFilteredSmsTemplates(smsTemplates.filter(e => (!e.removed && `${e.advertType}` === chosenPropertyType)))
    } else if (chosenPropertyType !== '') {
      setChosenSmsTemplates(chosenPropertyType !== '' && details ? details.smsMessageUuids : [])
      formRef.current.setFieldValue('smsTemplate', details ? details.smsMessageUuids : [])
      setFilteredSmsTemplates(smsTemplates.filter(e => (!e.removed && `${e.advertType}` === chosenPropertyType) || details.smsMessageUuids.includes(e.uuid)))
      setIsInitialPropertyTypeSet(true)
      if (chosenPropertyType !== `${AdvertType.PLOT}`) {
        setChosenPropertySubType('')
        formRef?.current?.setFieldValue('propertySubType', '')
      }
    }
  }, [chosenPropertyType])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } 

  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[0]).format() 
      : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, undefined, undefined, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      setIsReloadingData(true)
      fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchWonBidList(uuid, BID_PER_PAGE, 0, sortData.column, sortData.order, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [sortData])

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm().then((res: any) => {
        setFormInitialErrors(res)
      })
    }
  }, [formInitialValues])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchConfig().then(res => {
        fetchRegionList(getFromQuery(query, 'assume') != null /*|| details?.childAccount*/).then(res => {
          fetchSmsTemplateList(getFromQuery(query, 'assume')).then(res => {
            fetchDetails(uuid, getFromQuery(query, 'assume')).then(res => {
              fetchWonBidCount(uuid, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
                fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, undefined, undefined, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
                  setIsLoading(false)
                }).catch(error => { onErrorResponseShowMessage(error)})
              }).catch(error => { onErrorResponseShowMessage(error)})
            }).catch(error => { 
              onErrorResponseShowMessage(error)
              if (error.response?.status === 404) {
                history.push('/')
              }
            })
          }).catch(error => { onErrorResponseShowMessage(error)})
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])
  
  React.useEffect(() => {
    if (details) {
      let valid = true, region, subregion, town
      if (details.params.region) {
        region = regions.find(e => e.uuid === details.params.region.uuid)
        valid = region != null
      }
      if (region && details.params.subregion) {
        subregion = region.subRegions.find(e => e.uuid === details.params.subregion.uuid)
        valid = subregion != null && !subregion.doNotShow
      }
      if (region && details.params.towns && details.params.towns.length > 0) {
        for (const townData of details.params.towns) {
          subregion =  region.subRegions.find(e => e.towns.findIndex(el => el.uuid === townData.uuid) > -1)
          if (!subregion) {
            valid = false
            break
          }
        }
      } else if (region) {
        for (const subReg of region.subRegions) {
          if (subReg.doNotShow) {
            valid = false
            break
          }
        }
      }

      if (isValidData && (!valid /*|| details?.childAccount*/)) {
        setIsValidData(false)
        setIsAllowedToEdit(false)
        fetchRegionList(true).catch(error => { onErrorResponseShowMessage(error)})
      } else {
        setIsAllowedToEdit(isValidData && !assumedIdentity /*&& !details?.childAccount*/)
        setShowControlButtons(true)
        setFormInitialData()
        setFilteredSmsTemplates(smsTemplates.filter(e => (!e.removed && e.advertType === details.params.advertType) || details.smsMessageUuids.includes(e.uuid)))
      }
    }
  }, [details, regions])

  React.useEffect(() => {
    if (wonBidCount > 0) {
      setPages(Math.ceil(wonBidCount / BID_PER_PAGE))
    } else {
      setPages(1)
    }
  }, [wonBidCount])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
    if (selectedPage > 0) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedPage])

  React.useEffect(() => {
    let status, responseStatus, clientType
    const mapped = wonBidList.map((e, idx) => {
      status = smsApiMessageTypes.find(n => n.code === e.statusCode)
      responseStatus = smsResponseTypes.find(n => n.status === e.responseStatus)
      clientType = clientTypes.find(n => n.type === e.userReaction?.clientType)
      return {
        columns: [
          `${(selectedPage - 1) * BID_PER_PAGE + (idx + 1)}.`,
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.phone,
          status 
          ? <div style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={status.color}/>
              </svg></div>
              <span style={{ wordBreak: 'keep-all'}}>{status.label}</span>
            </div> 
          : '-',
          responseStatus
            ? <div style={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'flex-start'
              }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={responseStatus.color}/>
              </svg></div>
              <span {...(e.response != null ? {'data-tip': `Odpowiedź użytkownika: "${e.response}"`} : {})}>
                {responseStatus.label}
              </span>
            </div> 
            : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          clientType
          ? <div style={{
              display: 'flex',
              width: 'auto',
              justifyContent: 'flex-start'
            }}>
            <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="11" r="7" fill={clientType.color}/>
            </svg></div>
            <span {...(e.response != null ? {'data-tip': e.userReaction?.message} : {})}>
              {clientType.label}
            </span>
          </div> 
          : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          <>
            {
              assumedIdentity
              ? ''
              : <Menu 
                  menuButton={<button className={classes.optionsMenuButton}>{'Akcje '}<strong>{'\u02C5'}</strong></button>} 
                  transition
                >
                  {
                    responseStatus && e.userReaction == null
                    ? <MenuItem
                        onClick={() => {
                          setChosenMessageUuid(e.messageUuid)
                          openAddUserReactionDialog()
                        }}
                      >
                        {t('add_user_reaction_to_message_label')}
                      </MenuItem>
                    : null
                  }
                  <MenuItem 
                    onClick={async () => {
                      try {
                        const mappedData = {
                          number: normalizePhoneNumber(e.phone)
                        }
                        await DataApi.addPhoneToBlacklist(mappedData)
                        dispatch(addSuccessMessage(MessageEnum.PHONE_ADDED_TO_BLACKLIST))
                      } catch (error) {
                        onErrorResponseShowMessage(error)
                      }
                    }}
                  >
                    {t('add_to_blacklist_button_label')}
                  </MenuItem>
                </Menu>
            }
          </>
        ]
      }
    })
    setTableData(mapped)
  }, [wonBidList])

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [tableData])

  React.useEffect(() => {
    const selRegion = regions.find(e => e.uuid === chosenRegion)
    if (chosenRegion && chosenRegion !== '' && selRegion) {
      const subRegions = selRegion.subRegions.filter(e => !e.doNotShow).map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setSubRegionList(subRegions)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      if (!(subRegions.findIndex(e => e.value === chosenSubregion) > -1)) {
        setChosenSubregion('')
        formRef.current.setFieldValue('subRegion', '')
      }
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion === '') {
      setSubRegionList([])
      setTownList([])
      setDistrictList([])
      setChosenSubregion('')
      setChosenTown([])
      setChosenDistrict([])
      formRef.current.setFieldValue('subRegion', '')
      formRef.current.setFieldValue('town', [])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenRegion])

  React.useEffect(() => {
    const selRegion = regions.find(e => e.uuid === chosenRegion)
    if (chosenRegion !== '' && chosenSubregion !== '' && selRegion) {
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const towns = selSubregion.towns.map(e => {
        return {
          value: e.uuid,
          label: e.name
        }
      })
      setTownList(towns)
      const selectedTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    } else if (chosenRegion !== '' && chosenSubregion === '') {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const towns: any[] = []
      const selectedTowns: any[] = []
      for (const sub of selRegion.subRegions) {
        selectedTowns.push(...sub.towns.filter(e => chosenTown.includes(e.uuid)))
        towns.push(...sub.towns.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setTownList(towns)
      setChosenTown(selectedTowns.map(e => e.uuid))
      formRef.current.setFieldValue('town', selectedTowns.map(e => e.uuid))
      const districts: any[] = []
      for (const town of selectedTowns) {
        districts.push(...town.districts.filter(e => chosenDistrict.includes(e.uuid)).map(e => e.uuid))
      }
      setChosenDistrict(districts)
      formRef.current.setFieldValue('district', districts)
    }
  }, [chosenSubregion])

  React.useEffect(() => {
    const selRegion = regions.find(e => e.uuid === chosenRegion)
    if (chosenRegion && chosenRegion !== '' && chosenSubregion === '' && chosenTown.length > 0 && selRegion) {
      const selSubregions = selRegion.subRegions.filter(e => e.towns.findIndex(el => chosenTown.includes(el.uuid)) > -1)
      const selTowns: any[] = []
      for (const subregion of selSubregions) {
        selTowns.push(...subregion.towns.filter(e => chosenTown.includes(e.uuid)))
      }
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: selTowns.length > 1 ? `${e.name} (${selTown.name})` : e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion && chosenRegion !== '' && chosenTown && chosenTown.length > 0 && selRegion) {
      const selRegion = regions.find(e => e.uuid === chosenRegion)
      const selSubregion = selRegion.subRegions.find(e => e.uuid === chosenSubregion)
      const selTowns = selSubregion.towns.filter(e => chosenTown.includes(e.uuid))
      const districts: any[] = []
      for (const selTown of selTowns) {
        districts.push(...selTown.districts.map(e => {
          return {
            value: e.uuid,
            label: e.name
          }
        }))
      }
      setDistrictList(districts)
      const selDistricts = districts.filter(e => chosenDistrict.includes(e.value))
      setChosenDistrict(selDistricts.map(e => e.value))
      formRef.current.setFieldValue('district', selDistricts.map(e => e.value))
    } else if (chosenRegion !== '' && chosenTown.length === 0) {
      setDistrictList([])
      setChosenDistrict([])
      formRef.current.setFieldValue('district', [])
    }
  }, [chosenTown])
  
  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowEditDialog(false)
      setShowActivateDialog(false)
      setShowDeactivateDialog(false)
    }
  }, [message])

  const handleEmailFormSubmit = (data: any, form: any) => {
    const newArray = [...chosenNotificationEmails]
    newArray.push(data.notificationEmail.trim())
    setChosenNotificationEmails(newArray)
    formRef.current.setFieldValue('notificationEmails', newArray)
    resetEmailsFormState()
  }

  const resetEmailsFormState = () => {
    setChosenNotificationEmail('')
    if (emailsFormRef.current) {
      emailsFormRef.current.resetForm()
    }
  }

  const resetAddUserReactionFormState = () => {
    setChosenUserReactionClientType(ClientType.UNDEFINED)
    setChosenUserReactionMessage('')
    if (addUserReactionFormRef.current) {
      addUserReactionFormRef.current.resetForm()
    }
  }

  const openAddUserReactionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowAddUserReactionDialog(true)
  }

  const closeAddUserReactionDialog = (reset: boolean = true) => {
    if (reset) {
      resetAddUserReactionFormState()
    }
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddUserReactionDialog(false)
  }

  const handleAddUserReactionSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      await DataApi.createUserReactionToMessage(chosenMessageUuid, {
        clientType: data.clientType,
        message: data.message
      })
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      await fetchWonBidList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to, getFromQuery(query, 'assume'))
      await fetchWonBidCount(uuid, from, to, getFromQuery(query, 'assume'))
      setIsSubmitting(false)
      closeAddUserReactionDialog()
    } catch (error) {
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const resetCustomTemplateFormState = () => {
    setTempCustomSmsTemplate('')
    if (customTemplateFormRef.current) {
      customTemplateFormRef.current.resetForm()
    }
  }

  const closeEditDialog = (reset: boolean = true) => {
    if (reset && !assumedIdentity) {
      setFormInitialData()
    }
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowEditDialog(false)
  }

  const closeConfirmDeactivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDeactivateDialog(false)
  }

  const closeConfirmActivateDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowActivateDialog(false)
  }

  const handleSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      const mappedData = {
        name: data.name === '' ? undefined : data.name.trim(),
        // bidValue: data.smsPrice === '' ? undefined : parseFloat(data.smsPrice.replace(',', '.')),
        params: {
          advertType: data.propertyType === '' ? undefined : parseInt(data.propertyType),
          advertSubType: data.propertySubType === '' ? undefined : parseInt(data.propertySubType),
          offerType: data.transactionType === '' ? undefined : parseInt(data.transactionType),
          regionUuid: data.region === '' ? undefined : data.region,
          subRegionUuid: data.subRegion === '' ? undefined : data.subRegion,
          townUuids: data.town === null ? undefined : data.town,
          districtUuids: data.district == null ? undefined : data.district,
          minArea: data.minArea === '' ? undefined : parseFloat(data.minArea.replace(',', '.')),
          maxArea: data.maxArea === '' ? undefined : parseFloat(data.maxArea.replace(',', '.')),
          minPrice: data.minPrice === '' ? undefined : parseFloat(data.minPrice.replace(',', '.')),
          maxPrice: data.maxPrice === '' ? undefined : parseFloat(data.maxPrice.replace(',', '.')),
          minPricePerArea: !data.minPricePerArea || data.minPricePerArea === '' ? undefined : parseFloat(data.minPricePerArea.replace(',', '.')),
          maxPricePerArea: !data.maxPricePerArea || data.maxPricePerArea === '' ? undefined : parseFloat(data.maxPricePerArea.replace(',', '.'))
        },
        notificationEmails: data.notificationEmails,
        // TODO commented temporary
        // marketType: data.marketType === '' ? undefined : parseInt(data.marketType),
        marketType: MarketType.PRIMARY,
        customMessage: (isCustomTemplateRemoved || data.customMessage == null) && data.customTemplate && data.customTemplate !== '' ? data.customTemplate : undefined,
        smsMessageUuids: data.smsTemplate,
        signature: !data.signature || data.signature === '' ? null : data.signature,
        removeCustomMessage: isCustomTemplateRemoved
      }
      await editDetails(uuid, mappedData)
      await fetchDetails(uuid)
      setIsSubmitting(false)
      closeEditDialog(false)
    } catch (error) {
      if (error.response?.status === 409 && error.response?.data?.code === 200 && error.response?.data?.subcode === 218) {
        try {
          await fetchRegionList()
        } catch (error) {
			    onErrorResponseShowMessage(error)
        }
      } else if (error.response?.status === 409 && error.response?.data?.code === 200 && error.response?.data?.subcode === 219) {
        formRef.current.setFieldTouched('town', true, false)
        formRef.current.setFieldError('town', t('common:field_required'))
      }
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  // const getSmsPricePlaceholder = (type: string) => {
	// 	// TODO temporary changes with marketType
  //   // if (!chosenMarketType || chosenMarketType === '') {
  //   //   return evalStringTemplate(t('sms_price_field_placeholder'), {
  //   //     val: '0,16'
  //   //   })
  //   // }

  //   const marketType = `${MarketType.PRIMARY}`

  //   let priceConfig
  //   if (config) {
  //     switch (marketType) {
  //     // switch (chosenMarketType) {
  //       case `${MarketType.PRIMARY}`:
  //         priceConfig = config.config.primaryMarketMinBidValue
  //         break
  //       case `${MarketType.SECONDARY}`:
  //         priceConfig = config.config.secondaryMarketMinBidValue
  //         break
  //       default: 
  //         return evalStringTemplate(t('sms_price_field_placeholder'), {
  //           val: '0,16'
  //         })
  //     }
  //   } else {
  //     switch (marketType) {
  //     // switch (chosenMarketType) {
  //       case `${MarketType.PRIMARY}`:
  //         priceConfig = defaultConfig.primaryMarketMinBidValue
  //         break
  //       case `${MarketType.SECONDARY}`:
  //         priceConfig = defaultConfig.secondaryMarketMinBidValue
  //         break
  //       default:
  //         return evalStringTemplate(t('sms_price_field_placeholder'), {
  //           val: '0,16'
  //         })
  //     }
  //   }
  //   switch (type) {
  //     case `${AdvertType.FLAT}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.flat).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.HOUSE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.house).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.PLOT}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.plot).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.SERVICE_PREMISE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.servicePremise).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.WAREHOUSE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.warehouse).toFixed(2).replace('.', ',')
  //       })
  //     case `${AdvertType.GARAGE}`:
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: (priceConfig.garage).toFixed(2).replace('.', ',')
  //       })
  //     default: 
  //       return evalStringTemplate(t('sms_price_field_placeholder'), {
  //         val: '0,16'
  //       })
  //   }
  // }

  const addEditDialogContent = () => {
    return (
      <div
        style={{
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('edit_event_section_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '62px',
            marginTop: '13px',
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                if (!isAllowedToEdit) {
                  closeEditDialog()
                } else {
                  dispatch(addCmdMessage(MessageEnum.ENABLE_DIALOG_COVER))
                  setShowLeaveEditDialog(true)
                }
              }}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
          
        <Formik
          innerRef={formRef}
          initialErrors={formInitialErrors}
          validateOnMount={true}
          initialTouched={{
            smsPrice: true
          }}
          enableReinitialize
          validationSchema={editParamsForm(tEventDetails, smsTemplates)}
          initialValues={formInitialValues}
          onSubmit={!isAllowedToEdit ? () => {} : handleSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
              style={{
                minWidth: "360px",
              }}
            >
              <div style={{
                borderBottom: '1px solid #BFC5D2'
              }}>
                <p className={classes.fieldLabel}>
                  {t('event_name_field_label')}
                </p>
                <BaseInput 
                  count
                  maxLen={240}
                  name="name" 
                  disabled={!isAllowedToEdit}
                  placeholder={t('event_name_field_placeholder')}
                  formikProps={formikProps}
                  value={chosenName}
                  setValue={setChosenName}
                  error={
                    formikProps.touched.name &&
                    Boolean(formikProps.errors.name)
                  }
                />
              </div>
              <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('advert_search_params_section_label')}
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('location_field_label')}
                    </p>
                    <div 
                      hidden={!isAllowedToEdit}
                      className={`${classes.clearButton}`} 
                      onClick={() => {
                        setChosenRegion('')
                        formikProps.setFieldValue('region', '')
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div> 
                    <CustomSelectField
                      instanceId={'region-field'}
                      disabled={!isAllowedToEdit}
                      name="region"
                      placeholder={'Województwo'}
                      options={regions.map(e => {
                        return {
                          value: e.uuid,
                          label: e.name
                        }
                      }).sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenRegion}
                      setValue={setChosenRegion}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.region &&
                        Boolean(formikProps.errors.region)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithoutLabel}>
                    <div 
                      hidden={chosenRegion === '' || !isAllowedToEdit}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenSubregion('')
                        formikProps.setFieldValue('subRegion', '')
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'subRegion-field'}
                      disabled={chosenRegion === '' || !isAllowedToEdit}
                      name="subRegion"
                      placeholder={'Powiat'}
                      options={subRegionList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenSubregion}
                      setValue={setChosenSubregion}
                      formikProps={formikProps}
                      warning={
                        chosenSubregion != null && chosenSubregion != ''
                      }
                      warningMessage={t('subregion_warning_label')}
                      error={
                        formikProps.touched.subRegion &&
                        Boolean(formikProps.errors.subRegion)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithoutLabel}>
                    <div 
                      hidden={chosenRegion === '' || !isAllowedToEdit}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenTown([])
                        formikProps.setFieldValue('town', [])
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'town-field'}
                      disabled={chosenRegion === '' || !isAllowedToEdit}
                      name="town"
                      isMulti
                      placeholder={'Miasto/Miejscowość'}
                      options={townList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenTown}
                      setValue={setChosenTown}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.town &&
                        Boolean(formikProps.errors.town)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithoutLabel}>
                    <div 
                      hidden={chosenTown.length === 0 || !isAllowedToEdit}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                        setChosenDistrict([])
                        formikProps.setFieldValue('district', [])
                      }}
                    >
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'district-field'}
                      disabled={chosenTown.length === 0 || !isAllowedToEdit}
                      name="district"
                      isMulti
                      placeholder={'Dzielnica (opcjonalnie)'}
                      options={districtList.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenDistrict}
                      setValue={setChosenDistrict}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.district &&
                        Boolean(formikProps.errors.district)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                    {t('transaction_type_field_label')}
                    </p>
                    <div 
                      hidden={!isAllowedToEdit}
                      className={`${classes.clearButton}`} 
                      onClick={()=> {
                      setChosenTransactionType('')
                      formikProps.setFieldValue('transactionType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'transaction-type-field'}
                      name="transactionType"
                      disabled={!isAllowedToEdit}
                      placeholder={'Wybierz typ transakcji'}
                      options={transactionTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenTransactionType}
                      setValue={setChosenTransactionType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.transactionType &&
                        Boolean(formikProps.errors.transactionType)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                    {t('property_type_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenPropertyType('')
                      setChosenSmsTemplates([])
                      formikProps.setFieldValue('propertyType', '')
                      formikProps.setFieldValue('smsTemplate', [])
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'property-type-field'}
                      disabled={!isAllowedToEdit}
                      name="propertyType"
                      placeholder={'Wybierz typ nieruchomości'}
                      options={propertyTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenPropertyType}
                      setValue={setChosenPropertyType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.propertyType &&
                        Boolean(formikProps.errors.propertyType)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithLabel} style={{
                    ...(chosenPropertyType !== `${AdvertType.PLOT}` ? { display: 'none' } : {})
                  }}>
                    <p className={classes.fieldLabel}>
                    {t('plot_type_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenPropertySubType("")
                      formikProps.setFieldValue('propertySubType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'property-subtype-field'}
                      disabled={!isAllowedToEdit}
                      name="propertySubType"
                      placeholder={t('plot_type_field_placeholder')}
                      options={plotTypes}
                      value={chosenPropertySubType}
                      setValue={setChosenPropertySubType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.propertySubType &&
                        Boolean(formikProps.errors.propertySubType)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_price_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinPrice('')
                      formikProps.setFieldValue('minPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minPrice" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('min_price_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMinPrice}
                      setValue={setChosenMinPrice}
                      monetaryValue
                      error={
                        formikProps.touched.minPrice &&
                        Boolean(formikProps.errors.minPrice)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_price_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxPrice('')
                      formikProps.setFieldValue('maxPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxPrice" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('max_price_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMaxPrice}
                      setValue={setChosenMaxPrice}
                      monetaryValue
                      error={
                        formikProps.touched.maxPrice &&
                        Boolean(formikProps.errors.maxPrice)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_area_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinArea('')
                      formikProps.setFieldValue('minArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minArea" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('min_area_field_placeholder')}
                      formikProps={formikProps}
                      monetaryValue
                      value={chosenMinArea}
                      setValue={setChosenMinArea}
                      error={
                        formikProps.touched.minArea &&
                        Boolean(formikProps.errors.minArea)
                      }
                    />
                  </div>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_area_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxArea('')
                      formikProps.setFieldValue('maxArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxArea" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('max_area_field_placeholder')}
                      formikProps={formikProps}
                      monetaryValue
                      value={chosenMaxArea}
                      setValue={setChosenMaxArea}
                      error={
                        formikProps.touched.maxArea &&
                        Boolean(formikProps.errors.maxArea)
                      }
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('min_price_per_area_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMinPricePerArea('')
                      formikProps.setFieldValue('minPricePerArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="minPricePerArea" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('min_price_per_area_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMinPricePerArea}
                      setValue={setChosenMinPricePerArea}
                      monetaryValue
                      error={
                        formikProps.touched.minPricePerArea &&
                        Boolean(formikProps.errors.minPricePerArea)
                      }
                    />
                  </div>
                  <div className={classes.fieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('max_price_per_area_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMaxPricePerArea('')
                      formikProps.setFieldValue('maxPricePerArea', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="maxPricePerArea" 
                      disabled={!isAllowedToEdit}
                      placeholder={t('max_price_per_area_field_placeholder')}
                      formikProps={formikProps}
                      value={chosenMaxPricePerArea}
                      setValue={setChosenMaxPricePerArea}
                      monetaryValue
                      error={
                        formikProps.touched.maxPricePerArea &&
                        Boolean(formikProps.errors.maxPricePerArea)
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('sms_settings_section_label')}
                </div> */}
                {/* TODO temporary commented */}
                {/* <div className={classes.rowContainer}>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('market_type_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenMarketType("")
                      formikProps.setFieldValue('marketType', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <CustomSelectField
                      instanceId={'market-tyoe-type-field'}
                      name="marketType"
                      disabled={!isAllowedToEdit}
                      placeholder={t('market_type_field_placeholder')}
                      options={marketTypes.sort((a, b) => a.label.localeCompare(b.label, 'pl'))}
                      value={chosenMarketType}
                      setValue={setChosenMarketType}
                      formikProps={formikProps}
                      error={
                        formikProps.touched.marketType &&
                        Boolean(formikProps.errors.marketType)
                      }
                    />
                  </div>
                </div> */}
                {/* <div className={classes.rowContainer}>
                  <div className={classes.lastFieldWithLabel}>
                    <p className={classes.fieldLabel}>
                      {t('sms_price_field_label')}
                    </p>
                    <div hidden={!isAllowedToEdit} className={`${classes.clearButton}`} onClick={()=> {
                      setChosenSmsPrice('')
                      formikProps.setFieldValue('smsPrice', '')
                    }}>
                      {t('clear_field_button_label')}
                    </div>
                    <BaseNumberInput
                      name="smsPrice" 
                      disabled={!isAllowedToEdit}
                      placeholder={getSmsPricePlaceholder(chosenPropertyType)}
                      formikProps={formikProps}
                      value={chosenSmsPrice}
                      setValue={setChosenSmsPrice}
                      monetaryValue
                      error={
                        formikProps.touched.smsPrice &&
                        Boolean(formikProps.errors.smsPrice)
                      }
                    />
                  </div>
                </div>
              </div> */}
              <div
                style={{
                  marginTop: '10px',
                  borderBottom: '1px solid #BFC5D2'
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('sms_content_settings_section_label')}
                </div>
                <div className={classes.formSectionWarningLabel}>
                  {t('sms_settings_warning_label')}
                </div>
                <div 
                  className={classes.fieldWithLabel} 
                  style={{
                    width: '47%',
                    // marginTop: '25px'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('signature_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={MSG_SIGNATURE_LEN}
                    disabled={!isAllowedToEdit}
                    name="signature" 
                    inputPattern={smsMessagePattern}
                    placeholder={t('signature_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenSignature}
                    setValue={setChosenSignature}
                    error={
                      formikProps.touched.signature &&
                      Boolean(formikProps.errors.signature)
                    }
                  />
                </div>
                <div style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}>
                  <div className={classes.fieldLabel}>
                    {evalStringTemplate(t('sms_content_field_label'), {
                      cur: formikProps.values['smsTemplate'].length,
                      max: filteredSmsTemplates.length,
                      custom: chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' ? ' + wiadomość własna' : ''
                    })}
                  </div>
                  <div className={classes.fieldSubLabel} style={{ marginTop: '15px' }}>
                    {t('available_templates_label')}
                  </div>
                  {
                    filteredSmsTemplates.map(e => (
                      <div style={{
                        marginBottom: '2px',
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <Checkbox 
                          name="smsTemplate"
                          checked={chosenSmsTemplates.includes(e.uuid)}
                          disabled={!isAllowedToEdit || (!chosenSmsTemplates.includes(e.uuid) && (chosenSmsTemplates.length >= filteredSmsTemplates.length || e.removed || `${e.advertType}` !== chosenPropertyType))}
                          onBlur={() => {
                            formikProps.setFieldTouched('smsTemplate')
                          }}
                          onChange={(checked) => {
                            formikProps.setFieldTouched('smsTemplate')
                            if (!checked && formikProps.values['smsTemplate'].includes(e.uuid)) {
                              const indexToDelete = formikProps.values['smsTemplate'].indexOf(e.uuid)
                              const newArray = [...formikProps.values['smsTemplate']]
                              newArray.splice(indexToDelete, 1)
                              formikProps.setFieldValue('smsTemplate', newArray)
                              setChosenSmsTemplates(newArray)
                            } else if (checked  && !formikProps.values['smsTemplate'].includes(e.uuid)) {
                              formikProps.setFieldValue('smsTemplate', [
                                ...formikProps.values['smsTemplate'],
                                e.uuid
                              ])
                              setChosenSmsTemplates([
                                ...formikProps.values['smsTemplate'],
                                e.uuid
                              ])
                            }
                          }}
                          borderRadius={3}
                          size={15}
                          containerStyle={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginTop: '5px'
                          }}
                          style={{
                            marginTop: '2px'
                          }}
                          icon={
                            <div style={{
                              fontSize: '15px',
                              borderRadius: 3,
                              height: '15px',
                              color: Colors.CHECKBOX_ICON,
                              backgroundColor: e.removed ? Colors.CHECKBOX_CHECKED_REMOVED : Colors.CHECKBOX_CHECKED,
                              margin: 0
                            }}>
                              <Check fontSize={'inherit'} color={'inherit'}/>
                            </div>
                          }
                          borderColor={Colors.CHECKBOX_BORDER}
                          labelStyle={{
                            fontFamily: 'AvenirBook',
                            color: e.removed ? Colors.CHECKBOX_CHECKED_REMOVED : Colors.LIGHT_GRAY_TEXT,
                            fontSize: '15px',
                            paddingLeft: '5px',
                            whiteSpace: 'break-spaces'
                          }}
                          label={
                            <>
                              {
                                e.template
                              }{
                                e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                  ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((e.template.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                  : null
                              }
                            </>
                          }
                        />
                        {
                          e.removed
                          ? <div
                              style={{
                                marginLeft: '5px',
                                marginTop: '3px',
                                textTransform: 'uppercase',
                                fontSize: '8px',
                                lineHeight: '8px',
                                fontFamily: 'AvenirHeavy',
                                letterSpacing: '1.49px',
                                color: 'white',
                                backgroundColor: Colors.CHECKBOX_CHECKED_REMOVED,
                                paddingLeft: '4px',
                                paddingRight: '4px',
                                paddingTop: '4px',
                                paddingBottom: '2px'
                              }}
                            >
                              {tEventDetails('template_removed_tag')}
                            </div>
                          : null
                        }
                        {
                          `${e.advertType}` !== chosenPropertyType
                          ? <div
                              style={{
                                marginLeft: '5px',
                                marginTop: '3px',
                                textTransform: 'uppercase',
                                fontSize: '8px',
                                lineHeight: '8px',
                                fontFamily: 'AvenirHeavy',
                                letterSpacing: '1.49px',
                                color: 'white',
                                backgroundColor: Colors.CHECKBOX_CHECKED_REMOVED,
                                paddingLeft: '4px',
                                paddingRight: '4px',
                                paddingTop: '4px',
                                paddingBottom: '2px'
                              }}
                            >
                              {tEventDetails('wrong_property_type_tag')}
                            </div>
                          : null
                        }
                      </div> 
                    ))
                  }
                  {
                    formikProps.touched.smsTemplate && 
                    Boolean(formikProps.errors.smsTemplate)
                    ? <div className={classes.errorLabel} style={{
                      float: 'left'
                    }}>
                      <ErrorMessage name={'smsTemplate'} />
                    </div> : null
                  }
                  {
                    (chosenCustomSmsTemplate && chosenCustomSmsTemplate !== '' && !isEditingCustomTemplate) || !isAllowedToEdit
                    ? <>
                        <div className={classes.fieldSubLabel} style={{marginTop: '20px'}}>
                          {t('custom_templates_label')}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'flex-start'
                          }}
                        >
                          { 
                            chosenCustomSmsTemplate  && chosenCustomSmsTemplate !== '' 
                            ? <div
                                style={{
                                  width: '90%',
                                  fontFamily: 'AvenirBook',
                                  color: Colors.LIGHT_GRAY_TEXT,
                                  fontSize: '15px',
                                  paddingLeft: '5px',
                                  whiteSpace: 'break-spaces',
                                  marginTop: '3px',
                                  wordBreak: 'break-all'
                                }}
                              >
                                {chosenCustomSmsTemplate}{
                                  chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN 
                                    ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{` (${Math.ceil((chosenCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)} wiadomości SMS)`}</span> 
                                    : null
                                }
                              </div>
                            : <div
                                style={{
                                  height: '30px',
                                  marginTop: '10px',
                                  marginLeft: '5px',
                                  fontFamily: 'AvenirBook',
                                  fontSize: '15px',
                                  color: 'rgba(109,114,120,0.5)'
                                }}
                              >
                                {t('no_custom_templates_added_label')}
                              </div>
                          }
                          { 
                            isAllowedToEdit
                            ? <>
                                <div
                                  style={{
                                    width: '5%',
                                    textAlign: 'center',
                                    color: 'blue',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setIsEditingCustomTemplate(true)
                                    setTempCustomSmsTemplate(chosenCustomSmsTemplate)
                                  }}
                                >
                                  <EditOutlinedIcon fontSize={'inherit'} color={'inherit'} />
                                </div>
                                <div
                                  style={{
                                    width: '5%',
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    const templateUuid = details?.customMessage?.uuid
                                    if (!isCustomTemplateRemoved && templateUuid != null && templateUuid !== '') {
                                      setIsCustomTemplateRemoved(true)
                                    }
                                    setChosenCustomSmsTemplate('')
                                    formRef?.current?.setFieldValue('customTemplate', '')
                                  }}
                                >
                                  <img src={trashIcon} alt="remove-item" />
                                </div>
                              </>
                            : null
                          }

                        </div>
                      </>
                    : <>
                        {
                          !isAllowedToEdit ? null : <Formik
                            innerRef={customTemplateFormRef}
                            validateOnMount={true}
                            enableReinitialize
                            initialValues={{
                              customTemplate: tempCustomSmsTemplate
                            }}
                            onSubmit={async (data) => {
                              setChosenCustomSmsTemplate(data.customTemplate)
                              setIsEditingCustomTemplate(false)
                              formikProps.setFieldValue('customTemplate', data.customTemplate)
                              resetCustomTemplateFormState()
                            }}
                          >
                            {(customTemplateFormikProps) => (
                              <Form
                                onKeyDown={(keyEvent) => {
                                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                    keyEvent.preventDefault()
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  marginTop: '20px'
                                }}
                              >
                                <div style={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}>
                                  <div 
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      // marginBottom: '10px',
                                    }}
                                  >
                                    <p className={classes.fieldLabel}>
                                      {t('add_custom_template_label')}
                                    </p>
                                    <BaseInput 
                                      count
                                      maxLen={
                                        TEMPLATE_MAX_MSG > 1
                                        ? TEMPLATE_MAX_MSG * TEMPLATE_MULTIPLE_MSG_LEN - MSG_SIGNATURE_LEN - 1
                                        : TEMPLATE_MAX_MSG
                                      }
                                      rows={4}
                                      name="customTemplate"
                                      placeholder={t('add_custom_template_placeholder')}
                                      formikProps={customTemplateFormikProps}
                                      inputPattern={smsMessagePattern}
                                      value={tempCustomSmsTemplate}
                                      setValue={setTempCustomSmsTemplate}
                                      error={
                                        customTemplateFormikProps.touched.customTemplate &&
                                        Boolean(customTemplateFormikProps.errors.customTemplate)
                                      }
                                    />
                                  </div>
                                </div>
                                <div 
                                  style={{
                                    marginRight: '10%',
                                    fontFamily: 'AvenirBook',
                                    fontSize: '14px',
                                    marginTop: '-15px',
                                    marginBottom: '37px'
                                  }}
                                >
                                  {
                                  tempCustomSmsTemplate.length > 0 ? <>
                                      {`Liczba wiadomości:\u00a0`}{
                                        tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0) > TEMPLATE_MSG_LEN
                                          ? <span style={{ color: Colors.MESSAGE_COUNT_LABEL }}>{Math.ceil((tempCustomSmsTemplate.length + chosenSignature.length + (chosenSignature.length > 0 ? 1 : 0)) / TEMPLATE_MULTIPLE_MSG_LEN)}</span>
                                          : 1
                                      }
                                    </> : '\u00A0'
                                  }
                                </div>
                                <div style={{
                                  float: 'right',
                                  marginTop: '-45px'
                                }}>
                                  <LoadingButton 
                                    children={
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          fontSize: '15px'
                                        }}
                                      >
                                        {
                                          isEditingCustomTemplate 
                                          ? <>
                                              <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                                <EditOutlinedIcon fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Zapisz
                                              </div>
                                            </>
                                          : <>
                                              <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                                <Check fontSize={'inherit'}/>
                                              </div>
                                              <div>
                                                Dodaj
                                              </div>
                                            </>
                                        }

                                      </div>
                                    }
                                    type='submit'
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      customTemplateFormikProps.submitForm()
                                    }}
                                    style={{
                                      width: '150px',
                                      maxWidth: '150px',
                                      height: '30px',
                                      marginTop: '5px',
                                      marginLeft: '5px',
                                      backgroundColor: '#438737'
                                    }}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        }
                      </> 
                  }
                </div>
              </div>
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <div className={classes.sectionLabel}>
                  {t('email_notifications_search_params_section_label')}
                </div>
                <div
                  className={classes.fieldWithLabel} 
                  style={{
                    width: '100%',
                    marginTop: '10px'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('notifications_emails_list_field_label')}
                  </p>
                  {
                    chosenNotificationEmails.length === 0
                    ? <div
                        style={{
                          height: '30px',
                          marginTop: '10px',
                          marginLeft: '5px',
                          fontFamily: 'AvenirBook',
                          fontSize: '15px',
                          color: 'rgba(109,114,120,0.5)'
                        }}
                      >
                        {t('no_emails_added_label')}
                      </div>
                    : <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        {
                          chosenNotificationEmails.map((e: string, idx: number) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center',
                                  fontFamily: 'AvenirBook',
                                  height: '30px',
                                  backgroundColor: 'rgba(216,216,216,0.5)',
                                  color: '#8798AD',
                                  marginRight: '5px',
                                  marginBottom: '3px',
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                  paddingLeft: '10px',
                                  paddingRight: '5px',
                                  borderRadius: '5px'
                                }}
                              >
                                <div>
                                  {e}
                                </div>
                                {
                                  !isAllowedToEdit ? null : <div 
                                    style={{
                                      marginTop: '7px',
                                      fontSize: '20px'
                                    }}
                                  >
                                    <span 
                                      style={{
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => {
                                        const newArray = [...chosenNotificationEmails]
                                        newArray.splice(idx, 1)
                                        setChosenNotificationEmails(newArray)
                                        formikProps.setFieldValue('notificationEmails', newArray)
                                      }}
                                    >
                                      <CloseIcon fontSize={'inherit'} color={'inherit'} />
                                    </span>
                                  </div>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                  }
                </div>
                {
                  isAllowedToEdit ? <div
                    className={classes.fieldWithLabel} 
                    style={{
                      width: '100%',
                      marginTop: '15px'
                    }}
                  >
                    <p className={classes.fieldLabel}>
                      {t('notification_email_field_label')}
                    </p>
                    <Formik
                      innerRef={emailsFormRef}
                      validateOnMount={true}
                      enableReinitialize
                      validationSchema={addNotificationEmail(tEventDetails, chosenNotificationEmails)}
                      initialValues={{
                        notificationEmail: ''
                      }}
                      onSubmit={handleEmailFormSubmit}
                    >
                      {(emailsFormikProps) => (
                        <Form
                          onKeyDown={(keyEvent) => {
                            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                              keyEvent.preventDefault()
                            }
                          }}
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '100%'
                            }}
                          >
                            <div
                              style={{
                                width: '47%'
                              }}
                            >
                              <BaseInput 
                                name="notificationEmail" 
                                placeholder={t('notification_email_placeholder')}
                                formikProps={emailsFormikProps}
                                value={chosenNotificationEmail}
                                setValue={setChosenNotificationEmail}
                                error={
                                  emailsFormikProps.touched.notificationEmail &&
                                  Boolean(emailsFormikProps.errors.notificationEmail)
                                }
                              />
                            </div>
                            <LoadingButton 
                              children={
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '15px'
                                  }}
                                >
                                  <div style={{ marginTop: '3px', marginRight: '3px' }}>
                                    <Check fontSize={'inherit'}/>
                                  </div>
                                  <div>
                                    Dodaj
                                  </div>
                                </div>
                              }
                              onClick={(ev) => {
                                ev.preventDefault()
                                emailsFormikProps.submitForm()
                              }}
                              style={{
                                width: '150px',
                                maxWidth: '150px',
                                height: '30px',
                                marginTop: '5px',
                                marginLeft: '5px',
                                backgroundColor: '#438737'
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div> : null
                }
              </div>
              { 
                isAllowedToEdit ? <div style={{
                  float: 'right',
                  marginBottom: '30px'
                }}>
                  <LoadingButton 
                    text={t('save_button_label')}
                    type='submit'
                    loading={isSubmitting}
                    style={{
                      width: '150px',
                      maxWidth: '150px',
                      height: '40px'
                    }}
                  />
                </div> : null
              }
            </Form>
          )}
        </Formik>
      </div>
    )
  }
  
  const confirmDeactivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_deactivate_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmDeactivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.deactivateSearchParams(details.uuid)
                await fetchDetails(details.uuid)
                setIsSubmitting(false)
                closeConfirmDeactivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                // closeConfirmDeactivateDialog()
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const confirmActivateDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_activate_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmActivateDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.activateSearchParams(details.uuid)
                await fetchDetails(details.uuid)
                setIsSubmitting(false)
                closeConfirmActivateDialog()
              } catch (error) {
                setIsSubmitting(false)
                closeConfirmActivateDialog()
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const closeLeaveEditDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_DIALOG_COVER))
    setShowLeaveEditDialog(false)
  }

  const confirmLeaveEditDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_leave_edit_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            height: '30px',
            marginTop: '-2px',
            marginLeft: '30px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeLeaveEditDialog()}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 30px',
            fontFamily: 'AvenirBook',
            fontSize: '16px'
          }}
        >
          {t('confirm_leave_edit_description')}
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeLeaveEditDialog()
            }}
          />
          <LoadingButton 
            text={'Tak,\u00A0wyjdź'}
            style={{
              height: '35px',
              width: '130px',
              flexGrow: 0
            }}
            onClick={() => {
              closeLeaveEditDialog()
              closeEditDialog()
            }}
          />
        </div>
      </div>
    )
  }

  const addUserReactionDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('add_user_reaction_dialog_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeAddUserReactionDialog(true)}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={addUserReactionFormRef}
          validationSchema={addUserReactionValidation(t)}
          enableReinitialize
          initialValues={{
            clientType: ClientType.UNDEFINED,
            message: '',
          }}
          onSubmit={handleAddUserReactionSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
            >
               <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '50%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_reaction_client_type_field_label')}
                  </p>
                  <CustomSelectField
                    instanceId={'role-field'}
                    name="clientType"
                    options={clientTypes.map(e => {
                      return {
                        label: e.name,
                        value: e.type
                      }
                    })}
                    placeholder={t('add_user_reaction_client_type_placeholder')}
                    value={chosenUserReactionClientType}
                    setValue={setChosenUserReactionClientType}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.clientType &&
                      Boolean(formikProps.errors.clientType)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer} style={{
                marginBottom: '15px'
              }}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '100%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_reaction_message_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={240}
                    rows={5}
                    name="message" 
                    placeholder={t('add_user_reaction_message_placeholder')}
                    formikProps={formikProps}
                    value={chosenUserReactionMessage}
                    setValue={setChosenUserReactionMessage}
                    error={
                      formikProps.touched.message &&
                      Boolean(formikProps.errors.message)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('save_button_label')}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <>
      <ReactTooltip 
        multiline
        effect={'solid'} 
        textColor={'black'} 
        backgroundColor={'white'}
        arrowColor={'#ffffff00'}
        className={classes.tooltip}
      /> 

      <CustomDialog hidden={!showEditDialog}>
        {addEditDialogContent()}
      </CustomDialog>
      
      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showDeactivateDialog}
      >
        {confirmDeactivateDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          width: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showAddUserReactionDialog}
      >
        {addUserReactionDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        zIndex={3001}
        hidden={!showLeaveEditDialog}
      >
        {confirmLeaveEditDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showActivateDialog}
      >
        {confirmActivateDialogContent()}
      </CustomDialog>

      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        {
          assumedIdentity
          ? <div className={classes.assumedUserInfo}>
              <div
                style={{
                  marginRight: '10px'
                }}
              >
                {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
              </div>
              <CustomButton 
                textComponent={
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-1px',
                    fontSize: '18px'
                  }}>
                    <OpenInNewOutlined fontSize={'inherit'}/>
                    <span style={{
                      marginLeft: '3px',
                      marginTop: '2px',
                      fontSize: '12px',
                      fontFamily: 'Avenir'
                    }}>{t('close_user_assume_view_button_label')}</span>
                  </div>
                }
                style={{
                  width: '200px',
                  maxWidth: '200px',
                  height: '35px',
                  paddingLeft: '10px',
                  paddingRight: '15px',
                  paddingTop: '7px'
                }}
                onClick={() => {
                  window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                }}
              />
            </div>
          : null
        }
        { details ? <div className={classes.topContainer}>
          <div style={{
            fontFamily: 'Avenir',
            marginRight: '15px',
            marginTop: '6px',
            fontSize: '16px',
            wordWrap: 'break-word',
            overflow: 'auto'
          }}>
            <span style={{
              textTransform: 'uppercase'
            }}>{`${details?.name} `}</span>{isChildAccount ? '' : `(${details?.userEmail})`}
          </div>
          <div className={classes.topButtonsContainer}>
         {
           showControlButtons
            ? <>
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-4px',
                      fontSize: '15px'
                    }}>
                      {!assumedIdentity && <EditOutlinedIcon fontSize="inherit" />}
                      <span style={{
                        paddingTop: '2px',
                        paddingLeft: '3px',
                        fontSize: '15px',
                        fontFamily: 'Avenir'
                      }}>
                        {!isAllowedToEdit ? t('show_details_button_label') : t('edit_event_button_label')}
                      </span>
                    </div>
                  }
                  style={{
                    width: '150px',
                    maxWidth: '130px',
                    height: '30px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    backgroundColor: !isAllowedToEdit ? Colors.BLUE_BUTTON_COLOR : Colors.GREEN_BUTTON_COLOR
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowEditDialog(true)
                  }}
                />
                {
                  !isAllowedToEdit
                  ? null
                  : <CustomButton 
                      textComponent={
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '-4px',
                          fontSize: '15px'
                        }}>
                          {/* <DeleteOutlinedIcon fontSize="inherit" /> */}
                          <span style={{
                            paddingTop: '2px',
                            fontSize: '15px',
                            fontFamily: 'Avenir'
                          }}>
                            {details.active ? t('deactivate_event_button_label') : t('activate_event_button_label')}
                          </span>
                        </div>
                      }
                      style={{
                        maxWidth: '160px',
                        height: '30px',
                        marginLeft: '5px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '7px',
                        backgroundColor: details.active ? Colors.RED_BUTTON_COLOR : Colors.BLUE_BUTTON_COLOR
                      }}
                      onClick={() => {
                        dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                        details.active ? setShowDeactivateDialog(true) : setShowActivateDialog(true)
                      }}
                    />
                }
              </>
            : null
          }
          </div>
        </div> : null }

        <div className={classes.controlRow}>
          <div className={classes.dateRangeField}>
            <div className={classes.dateRangeLabel}>
              {t('date_range_label')}
            </div>
            <div 
              className={classes.clearButton} 
              onClick={() => {
                setSelectedDateRange(null)
              }}
            >
              {t('clear_field_button_label')}
            </div>
            <Flatpickr
              id={"date-range-picker"}
              data-enable-time
              value={
                selectedDateRange && selectedDateRange.length >= 2
                ? [
                  moment(selectedDateRange[0]).startOf("day").toDate(),
                  moment(selectedDateRange[1]).startOf("day").toDate(),
                ]
                : []
              }
              options={{
                locale: {
                  firstDayOfWeek: 1,
                  rangeSeparator: ' - ',
                  months: {
                    shorthand: monthList,
                    longhand: monthList
                  },
                  weekdays: {
                    shorthand: dayListShorthand,
                    longhand: dayList
                  }
                },
                dateFormat: "d.m.Y",
                mode: 'range',
              }}
              onClose={(date) => {
                if (date && date.length === 2) {
                  setSelectedDateRange(date)
                }
              }}
              render={({ defaultValue, value, ...props }, ref) => {
                return (
                  <CustomFlatpickrInput
                    defaultValue={defaultValue}
                    inputRef={ref}
                    width={'100%'}
                  />
                )
              }}
            />
          </div>
        </div>

        <div className={classes.statTileSection}>
          <div className={classes.msgSentTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F6AE3F'}
              label={'Wysłane wiadomości'}
              iconSrc={sendIcon}
              value={`${bidStats.messageSentCount}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F64A3F'}
              label={'Odpowiedzi'}
              iconSrc={dialogIcon}
              value={`${bidStats.responsesCount}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#438737'}
              label={'Odpowiedzi agenta'}
              iconSrc={formIcon}
              value={`${bidStats.userReactionCount}`}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>

          {/* <div className={classes.totalPriceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#30A53B'}
              label={'Kwota (łącznie)'}
              iconSrc={coinsIcon}
              value={`${bidStats.totalPrice.toFixed(2).replace('.', ',')}`}
            />
          </div> */}
          
          
        </div>

        <div style={{
          width: '100%'
        }}>
          <CustomTable 
            columnData={tableColumnData}
            content={tableData}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            reloadingData={isReloadingData}
            placeholder={
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontFamily: 'AvenirMedium',
                  color: Colors.TABLE_HEADER_LABEL,
                  fontSize: '16px'
                }}
              >
                <div>
                  {t('no_data_in_table_placeholder')}
                </div>
              </div>
              }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <ReactTooltip 
        multiline
        effect={'solid'} 
        textColor={'black'} 
        backgroundColor={'white'}
        arrowColor={'#ffffff00'}
        className={classes.tooltip}
      />  */}
    </>
  )
}

export default EventDetails
