import { PermissionLevel } from "../types/auth"

export type ContactFormValues = {
  question: string
  message: string
  file: File
}

export type Question = {
  id: string
  permissions: PermissionLevel[]
  type: QuestionType
}

export enum QuestionType {
  MANUAL = 'manual',
  FAQ = 'faq',
}

export const FAQ_QUESTIONS = [
  {
    id: "ofertodom-sms-manual",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "single-sms-event",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "multiple-sms-event",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "child-account-setup",
    permissions: [PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "account-details-change",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "statistics-tracking",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "clients-management",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.MANUAL
  },
  {
    id: "unwanted-communication-problem",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
  {
    id: "sms-sending-problem",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
  {
    id: "more-answers-problem",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
  {
    id: "owner-answer-check",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
  {
    id: "sent-status-explanation",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
  {
    id: "agency-answer-explanation",
    permissions: [PermissionLevel.CHILD, PermissionLevel.STANDARD, PermissionLevel.ADMIN, PermissionLevel.SUPERADMIN],
    type: QuestionType.FAQ
  },
]
