import { makeStyles, Theme } from "@material-ui/core"
import { Colors } from '../../types/colors'

const drawerWidthOpened = 260
const drawerWidthClosed = 56

export type DashboardProps = {
    drawerOpen?: boolean
}

export const dashboardStyles = (props: DashboardProps) => makeStyles((theme: Theme) => ({
  avatarTitle: { 
    fontWeight: "normal",
    color: '#2E384D',
    fontSize: '0.875rem',
    fontFamily: 'AvenirBook',
    lineHeight: '1.57'
  },
  avatarSubTitle: { 
    fontWeight: "normal",
    color: '#B0BAC9',
    fontSize: '0.875rem',
    fontFamily: 'AvenirBook',
    lineHeight: '1.57'
  },
  pageContainer: {
    display: 'flex'
  },
  drawerContainer: {
    height: '100vh'
  },
  drawer: {
    width: drawerWidthOpened,
    overflow: "hidden",
    whiteSpace: "nowrap",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  drawerSmall: {
    position: "fixed",
    top: 0,
    width: "100%",
    bottom: 0,
    zIndex: 1200,
    backgroundColor: theme.palette.common.white,
    transition: "0.3s",
    display: "none",
    "@media (max-width: 1000px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  drawerSmallOpen: {
    transform: "translate(0%, 0%)",
  },
  drawerSmallClose: {
    transform: "translate(-100%, 0%)",
  },
  drawerOpen: {
    overflow: "hidden",
    width: drawerWidthOpened,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflow: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: drawerWidthClosed,
  },
  drawerIcon: {
    display: "flex",
    justifyContent: "center",
    width: drawerWidthClosed
  },
  drawerPaper: {
    width: "240px",
    overflow: "hidden",
  },
  menuList: {
    paddingLeft: "0px",
    marginTop: "35px",
    listStyleType: "none",
  },
  menuLinkItem: {
    display: "flex",
    textDecoration: "none",
  },
  menuLinkIcon: {
    padding: "0px 12.5px",
    display: "flex",
    justifyContent: "center",
  },
  menuLinkLabel: {
    color: '#B0BAC9',
    fontFamily: '"AvenirBook", "Roboto", "Arial", sans-serif',
    fontSize: '0.875rem',
    marginTop: '2px'
  },
  menuItemSelected: {
    backgroundColor: theme.palette.primary.light,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  logoutMenu: {
    display: "flex",
    textDecoration: "none",
    '&:hover': {
      backgroundColor: Colors.MENU_HOVERED,
    }
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    display: 'block'
  },
  appBarImage: {
    // marginLeft: "20px",
  },
  appBarButton: {
    padding: "5px",
    margin: "5px 1px",
  },
  appBarButtonIcon: {
    fontSize: "30px",
  },
  mainContentContainer: {
    backgroundColor: 'white',
    width: props.drawerOpen ? `calc(100% - ${drawerWidthOpened}px)` : `calc(100% - ${drawerWidthClosed}px)`,
    minHeight: "100vh",
    '@media (max-width: 1000px)': {
      width: '100%'
    }
  },
  mainContentContainerShrink: {
    '@media (min-width: 1001px)': {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 2
      })
    }
  },
  mainContentContainerEnlarge: {
    '@media (min-width: 1001px)': {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2
      })
    }
  },
  toolbarDesktop: {
    display: "block",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  toolbarSmall: {
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
    },
  },
  mainContainer: {
    position: 'relative',
    height: 'calc(100% - 65px)'
  },
  menuDivider: {
    borderTop: 'solid 1px rgba(151, 151, 151, 0.5)',
    borderLeft: 0,
    borderBottom: 0,
    borderRight: 0,
    marginLeft: '10px',
    marginRight: '10px'
  },
  drawerSectionLabel: {
    textTransform: 'uppercase',
    fontFamily: 'AvenirMedium',
    fontSize: '12px',
    paddingTop: '15px',
    paddingBottom: '10px',
    paddingLeft: '15px'
  },
  drawerSectionLabelClosed: {
    height: '41px'
  },
  helpMenuLink: {
    paddingLeft: "0px",
    listStyleType: "none",
  }
}))
