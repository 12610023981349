import { makeStyles } from '@material-ui/styles'
import { Colors } from '../../../types/colors'

export const calculateAdvertsFormStyle = makeStyles(() => ({
  container: {

  },
  fieldLabel: {
    fontFamily: 'AvenirBook',
    fontSize: '15px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: '#6D7278',
    marginBottom: '5px',
    marginLeft: '2px'
  },
  fieldWithLabel: {
    width: '23.5%',
    marginRight: '2%',
    position: 'relative'
  },
  fieldWithoutLabel: {
    width: '23.5%',
    marginRight: '2%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  lastFieldWithLabel: {
    width: '23.5%',
    position: 'relative'
  },
  lastFieldWithoutLabel: {
    width: '23.5%',
    paddingTop: '22.6px',
    position: 'relative'
  },
  rowContainer: {
    display: 'flex',
    marginBottom: '10px'
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontFamily: 'AvenirBook',
    fontSize: '12px',
    color: Colors.CLEAR_FIELD_BUTTON
  },
  sectionLabel: {
    textTransform: 'uppercase',
    fontFamily: 'AvenirBook',
    fontSize: '13px',
    letterSpacing: '1.21px',
    lineHeight: '18px',
    color: Colors.LIGHT_GRAY_TEXT,
    marginBottom: '10px',
    marginLeft: '2px'
  },
  errorLabel: {
    color: 'red',
    textAlign: 'left',
    margin: '4px 14px 0px',
    fontFamily: "AvenirBook",
    fontSize: '12px'
  },
  resetButton: {
    flexGrow: 1,
    textTransform: "none",
    width: '150px',
    maxWidth: '150px',
    height: '40px',
    padding: "10px 0px",
    fontSize: "15px",
    fontWeight: "normal",
    marginTop: "5px",
    fontFamily: "AvenirMedium, Robot, Arial, sans-serif",
    backgroundColor: 'transparent',
    border: `solid 2px ${Colors.AUTH_BUTTON_COLOR}`,
    borderRadius: '5px',
    color: Colors.AUTH_BUTTON_COLOR,
    cursor: 'pointer',
    '@media (max-width: 1000px)': {
      marginTop: "10px",
    },
    // '&:hover': {
    //   backgroundColor: `#E0E7FF`,
    // },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default'
    }
  }
}))