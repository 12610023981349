import { customSelectFieldStyle } from './customSelectFieldStyle'
import React from 'react'
import { ErrorMessage, FormikProps } from 'formik'
import Scrollbars from 'react-custom-scrollbars'
import Select, { components } from 'react-select'
import { Colors } from '../../../types/colors'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: string
  instanceId: string
  name: string
  disabled?: boolean
  isMulti?: boolean
  className?: string
  errorClassName?: string
  errorLabelClassName?: string
  warningLabelClassName?: string
  warningMessage?: string
  warning?: boolean
  error?: boolean
  value?: string | string[] | number
  setValue?: React.Dispatch<React.SetStateAction<string[]>> | React.Dispatch<React.SetStateAction<string>> | React.Dispatch<React.SetStateAction<number>>
  options: any[]
  placeholder?: string
  formikProps?: FormikProps<any>
  customSelectStyles?: object
  noErrorLabel?: boolean
  includeAllOption?: boolean
  includeAllOptionLabel?: string
}

export const AllSelectOptionsValue = '-all-'

export function getSelectAllOption (label?: string, excluding?: boolean) {
  return {
    label: label ?? 'Wszystkie',
    value: AllSelectOptionsValue,
    excluding: excluding
  }
}

const renderSelectMenuListScrollbar = (props: any) => {
  const len = props.children.length
  return (
    <div style={{ 
      height: len ? (len < 10 ? `${len * 33}px` : '300px') : '33px', 
      backgroundColor: Colors.SELECT_LIST_BACKGROUND,
      color: Colors.SELECT_OPTION_DEFAULT_TEXT
    }}>
      <Scrollbars 
        universal={true} 
        renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, backgroundColor: Colors.SELECT_FIELD_SCROLLBAR, width: '4px', opacity: '0.5'}}/>}
      >
        {props.children}
      </Scrollbars>
    </div>
  )
}

const MultiOption = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const label = (getValue() as any[]).map(e => e.label).join(', ')
  return (
    <components.ValueContainer {...props as any}>
      <div style={{
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}>
      {`${nbValues > 1 ? `(${nbValues}) ` : ''}${label}`}
      </div>
      {nbValues === 0 ? children.slice(-2, -1) : null}
      {children.slice(-1)}
    </components.ValueContainer>
  )
}

const CustomSelectField = (props: Props) => {
  const [value, setValue] = React.useState<string | string[]>(null)

  const t = useTranslation("forms").t
  const classes = customSelectFieldStyle(
    props.error ? {
      borderColor: 'red',
      borderColorFocus: 'red'
    } :{}
  )()

  const customSelectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      flex: 1,
      "@media (max-width: 600px)": {
        width: '100%'
      }
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      borderRadius: 100
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 100
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      width: 0
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      fontSize: '15px',
      padding: 0,
      paddingLeft: 14,
      paddingTop: 8,
      paddingBottom: 8,
      fontFamily: 'AvenirBook',
      ...(props.isMulti ? { maxWidth: 'calc(100% - 40px)', flexWrap: 'nowrap' } : {})
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: Colors.SELECT_LIST_BACKGROUND,
      color: state.data.color || Colors.SELECT_OPTION_DEFAULT_TEXT,
      padding: 5,
      paddingLeft: state.data.level ? state.data.level * 20 + 5 : 5,
      fontFamily: 'AvenirBook',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: Colors.SELECT_OPTION_HOVER
      }
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      opacity: state.isDisabled ? 0.4 : 1,
      backgroundColor: state.isDisabled ? 'rgba(142,156,212,0.01)' : 'rgba(142,156,212,0.1)',
      borderRadius: '5px',
      border: `1px solid ${props.error ? 'red' : Colors.INPUT_BORDER}`,    
      boxShadow: 'none'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.placeholder ? Colors.SELECT_OPTION_DEFAULT_PLACEHOLDER : Colors.SELECT_OPTION_DEFAULT_TEXT,
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: 0,
      fontSize: '15px',
      fontFamily: 'AvenirBook',
      // ...(props.isMulti ? { marginLeft: '5px' } : {})
    })
  }

  const renderNoOptionMessage = (props: any) => {
    return (
      <div style={{
        fontFamily: 'AvenirBook',
        textAlign: 'center',
        color: Colors.LIGHT_GRAY_TEXT,
        paddingTop: '6px'
      }}>
        {t('no_options_select_message')}
      </div>
    )
  }

  const options = [
    ...(
      props.includeAllOption && props.options.length > 1
      ? [getSelectAllOption(props.includeAllOptionLabel, true)]
      : []
    ),
    ...props.options
  ]
  
  return (
    <div style={{ 
      minHeight: props.noErrorLabel ? '43px' : '62px',
      marginTop: 0
    }}>
      <Select 
        onBlur={()=> {
          props.formikProps?.setFieldTouched(props.name)
        }}
        instanceId={props.instanceId}
        backspaceRemovesValue={false}
        name={props.name}
        options={options}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        isClearable={false}
        closeMenuOnSelect={props.isMulti != null ? !props.isMulti : true}
        value={
          props.value
          ? Array.isArray(props.value)
            ? options.filter(el => (props.value as string[]).includes(el.value)) ?? null
            : options.find(el => el.value === props.value) ?? null
          : Array.isArray(value)
            ? options.filter(el => (value as string[]).includes(el.value)) ?? null
            : options.find(el => el.value === value) ?? null
        }
        onChange={(sel) => {
          if (props.isMulti) {
            if (Array.isArray(props.value) && sel.length !== 1 && props.value.findIndex(e => e === AllSelectOptionsValue) > -1) {
              sel = sel.filter(e => !e.excluding)
            } else {
              const excludingOption = sel.filter(e => e.excluding)
              if (excludingOption.length > 0) {
                sel = excludingOption
              }
            }
          }
          props.formikProps?.setFieldTouched(props.name)
          if (Array.isArray(sel)) {
            props.formikProps?.setFieldValue(
              props.name,
              sel?.map(e => e.value) || []
            )
            if (props.setValue) {
              ;(props.setValue as React.Dispatch<React.SetStateAction<string[]>>)(sel?.map(e => e.value) || [])
            } else {
              ;(setValue as React.Dispatch<React.SetStateAction<string[]>>)(sel?.map(e => e.value) || [])
            }
          } else {
            props.formikProps?.setFieldValue(
              props.name,
              sel?.value || ''
            )
            if (props.setValue) {
              props.setValue(sel?.value || '')
            } else {
              setValue(sel?.value || '')
            }
          }
          
        }}
        placeholder={props.placeholder}
        styles={props.customSelectStyles || customSelectStyles}
        components={{
            MenuList: renderSelectMenuListScrollbar,
            NoOptionsMessage: renderNoOptionMessage,
            ...(props.isMulti ? { Option: MultiOption, ValueContainer: ValueContainer } : {})
        }}
    />
      {
        !props.error && props.warning && props.warningMessage ? <div className={props.warningLabelClassName || classes.warningLabel} style={{
          float: 'left'
        }}>
          {props.warningMessage}
        </div> : null
      }
      {
        props.error && props.name ? <div className={props.errorLabelClassName || classes.errorLabel} style={{
          float: 'left'
        }}>
          <ErrorMessage name={props.name} />
        </div> : null
      }
    </div>
  )
}

export default CustomSelectField
