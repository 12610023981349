import { CircularProgress } from '@material-ui/core'
import {
  Close as CloseIcon,
  DeleteOutlined,
  OpenInNewOutlined
} from "@material-ui/icons"
import {
  Menu,
  MenuItem
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { Form, Formik } from 'formik'
import moment from "moment"
import React, { useRef } from "react"
import Flatpickr from "react-flatpickr"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import DataApi from "../../api/DataApi"
import dialogIcon from '../../assets/images/dialog.webp'
import formIcon from '../../assets/images/form.webp'
import sendIcon from '../../assets/images/send.webp'
import { addCmdMessage, addSuccessMessage } from '../../redux/messages/actions'
import { State } from "../../redux/reducers"
import { Colors } from '../../types/colors'
import { dayList, dayListShorthand, monthList } from '../../types/common'
import { ClientType, clientTypes, smsApiMessageTypes, smsResponseTypes } from '../../types/smsApiMessage'
import { checkIfAdmin, getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { normalizePhoneNumber } from '../../utils/text'
import { addUserReactionValidation } from '../../utils/validations/addUserReactionValidation'
import BaseInput from "../common/BaseInput/BaseInput"
import CustomButton from '../common/CustomButton/CustomButton'
import CustomFlatpickrInput from '../common/CustomFlatpickrInput/CustomFlatpickrInput'
import CustomSelectField from "../common/CustomSelectField/CustomSelectField"
import LoadingButton from "../common/LoadingButton/LoadingButton"
import Pagination from '../common/Pagination/Pagination'
import StatTile from "../common/StatTile/StatTile"
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { bulkActionsDetailsStyle } from './bulkActionsDetailsStyle'
import { useBulkActionsDetails } from './useBulkActionsDetails'
import BulkActionDetails from '../common/BulkActionDetails/BulkActionDetails'
import { BulkActionStatus } from '../../types/bulkAction'
import { PermissionLevel } from '../../types/auth'

const tableColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '3%'
  },{
    label: 'Data i\u00A0godzina wysyłki',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  }, {
    label: 'Nr\u00A0telefonu',
    headerAlign: 'left',
    contentAlign: 'left',
    width: 'calc(46% - 180px)'
  }, {
    label: 'Status',
    name: 'statusCode', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Odpowiedź użytkownika',
    name: 'responseStatus',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: 'Typ\u00A0klienta i\u00A0notka',
    name: 'clientType', 
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    width: '12%'
  }, {
    label: '',
    name: '', 
    sortable: false,
    headerAlign: 'left',
    contentAlign: 'center',
    width: '180px'
  }
]

const BID_PER_PAGE = 25 // TODO move to separate file

const BulkActionsDetailsPage = () => {
  const classes = bulkActionsDetailsStyle()

  const query = useQuery()
  const history = useHistory()
  const t = useTranslation("forms").t
  const tBulkActions = useTranslation("bulkActions").t

  const dispatch = useDispatch()
  const { uuid } = useParams<{ uuid: string }>()
  const message = useSelector((e: State) => e.messages)
  const permissions = useSelector((e: State) => e.permissions)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isChildAccount, setIsChildAccount] = React.useState<boolean>(permissions.permissions.includes(PermissionLevel.CHILD))
  const [isReloadingData, setIsReloadingData] = React.useState<boolean>(false)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  const [addReactionFor, setAddReactionFor] = React.useState<string>(getFromQuery(query, 'add_reaction_for'))

  const [selectedDateRange, setSelectedDateRange] = React.useState<Date[] | null>(null)
  const [selectedPage, setSelectedPage] = React.useState<number>(1)
  const [pages, setPages] = React.useState<number>(1)

  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false)
  const [showAddUserReactionDialog, setShowAddUserReactionDialog] = React.useState<boolean>(false)
  const [showDetailsDialog, setShowDetailsDialog] = React.useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const [tableData, setTableData] = React.useState<any[]>([])
  const [sortData, setSortData] = React.useState<SortData | null>(null)

  const [chosenMessageUuid, setChosenMessageUuid] = React.useState<string>('')
  const [chosenUserReactionClientType, setChosenUserReactionClientType] = React.useState<number>(ClientType.UNDEFINED)
  const [chosenUserReactionMessage, setChosenUserReactionMessage] = React.useState<string>('')

  const { 
    details, messageCount, messageList, stats, assumedUser,
    fetchDetails, fetchStats, fetchMessageList, fetchAssumedUser
	} = useBulkActionsDetails()

  const addUserReactionFormRef = useRef() as any
  
  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  React.useEffect(() => {
    if (addReactionFor) {
      DataApi.checkIfCanAddReactionToMessage(addReactionFor, uuid).then(res => {
        setChosenMessageUuid(addReactionFor)
        openAddUserReactionDialog()
      }).catch((error) => onErrorResponseShowMessage(error))
    }
  }, [addReactionFor])

  React.useEffect(() => {
    if (!isLoading) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchStats(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchMessageList(uuid, BID_PER_PAGE, 0, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedDateRange])

  React.useEffect(() => {
    const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[0]).format() 
      : undefined
    const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
      ? moment(selectedDateRange[1]).add(1, 'days').format() 
      : undefined
    if (sortData && sortData.default) {
      setIsReloadingData(true)
      fetchStats(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchMessageList(uuid, BID_PER_PAGE, 0, undefined, undefined, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (sortData) {
      setIsReloadingData(true)
      fetchStats(uuid, from, to, getFromQuery(query, 'assume')).then(res => {
        fetchMessageList(uuid, BID_PER_PAGE, 0, sortData.column, sortData.order, from, to, getFromQuery(query, 'assume')).then(res => {
          setSelectedPage(1)
          setIsReloadingData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [sortData])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchDetails(uuid, getFromQuery(query, 'assume')).then(res => {
        fetchStats(uuid, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
          fetchMessageList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, undefined, undefined, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
            setIsLoading(false)
          }).catch(error => { onErrorResponseShowMessage(error)})
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { 
        onErrorResponseShowMessage(error)
        if (error.response?.status === 404) {
          history.push('/')
        }
      })
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (messageCount > 0) {
      setPages(Math.ceil(messageCount / BID_PER_PAGE))
    } else {
      setPages(1)
    }
  }, [messageCount])

  React.useEffect(() => {
    if (selectedPage > pages) {
      setSelectedPage(pages)
    }
    if (selectedPage > 0) {
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      setIsReloadingData(true)
      fetchMessageList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    }
  }, [selectedPage])

  React.useEffect(() => {
    let status, responseStatus, clientType
    const mapped = messageList.map((e, idx) => {
      status = smsApiMessageTypes.find(n => n.code === e.statusCode)
      responseStatus = smsResponseTypes.find(n => n.status === e.responseStatus)
      clientType = clientTypes.find(n => n.type === e.userReaction?.clientType)
      return {
        columns: [
          `${(selectedPage - 1) * BID_PER_PAGE + (idx + 1)}.`,
          moment(e.sentAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.phone,
          status 
          ? <div style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={status.color}/>
              </svg></div>
              <span style={{ wordBreak: 'keep-all'}}>{status.label}</span>
            </div> 
          : '-',
          responseStatus
            ? <div style={{
                display: 'flex',
                width: 'auto',
                justifyContent: 'flex-start'
              }}>
              <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="7" fill={responseStatus.color}/>
              </svg></div>
              <span {...(e.response != null ? {'data-tip': `Odpowiedź użytkownika: "${e.response}"`} : {})}>
                {responseStatus.label}
              </span>
            </div> 
            : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          clientType
          ? <div style={{
              display: 'flex',
              width: 'auto',
              justifyContent: 'flex-start'
            }}>
            <div><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="11" r="7" fill={clientType.color}/>
            </svg></div>
            <span {...(e.response != null ? {'data-tip': e.userReaction?.message} : {})}>
              {clientType.label}
            </span>
          </div> 
          : <div style={{ marginLeft: '45px'}}>{'-'}</div>,
          <>
            {
              assumedIdentity
              ? ''
              : <Menu 
                  menuButton={<button className={classes.optionsMenuButton}>{'Akcje '}<strong>{'\u02C5'}</strong></button>} 
                  transition
                >
                  {
                    responseStatus && e.userReaction == null
                    ? <MenuItem
                        onClick={() => {
                          setChosenMessageUuid(e.uuid)
                          openAddUserReactionDialog()
                        }}
                      >
                        {t('add_user_reaction_to_message_label')}
                      </MenuItem>
                    : null
                  }
                  <MenuItem 
                    onClick={async () => {
                      try {
                        const mappedData = {
                          number: normalizePhoneNumber(e.phone)
                        }
                        await DataApi.addPhoneToBlacklist(mappedData)
                        dispatch(addSuccessMessage(MessageEnum.PHONE_ADDED_TO_BLACKLIST))
                      } catch (error) {
                        onErrorResponseShowMessage(error)
                      }
                    }}
                  >
                    {t('add_to_blacklist_button_label')}
                  </MenuItem>
                </Menu>
            }
          </>
        ]
      }
    })
    setTableData(mapped)
  }, [messageList])
  
  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [tableData])

  React.useEffect(() => {
    if (message.cmd === MessageEnum.DISABLE_PAGE_COVER) {
      setShowDetailsDialog(false)
      setShowAddUserReactionDialog(false)
      setShowCancelDialog(false)
    }
  }, [message])

  const resetAddUserReactionFormState = () => {
    setChosenUserReactionClientType(ClientType.UNDEFINED)
    setChosenUserReactionMessage('')
    if (addUserReactionFormRef.current) {
      addUserReactionFormRef.current.resetForm()
    }
  }

  const openAddUserReactionDialog = () => {
    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowAddUserReactionDialog(true)
  }

  const closeAddUserReactionDialog = (reset: boolean = true) => {
    if (reset) {
      resetAddUserReactionFormState()
    }
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddUserReactionDialog(false)
  }

  const handleAddUserReactionSubmit = async (data: any) => {
    try {
      setIsSubmitting(true)
      await DataApi.createUserReactionToMessage(chosenMessageUuid, {
        clientType: data.clientType,
        message: data.message
      })
      const sortName = sortData?.column || undefined
      const sortOrder = sortData?.order || undefined
      const from: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[0]).format() 
        : undefined
      const to: string | undefined = selectedDateRange && selectedDateRange.length > 0 
        ? moment(selectedDateRange[1]).add(1, 'days').format() 
        : undefined
      await fetchMessageList(uuid, BID_PER_PAGE, (selectedPage - 1) * BID_PER_PAGE, sortName, sortOrder, from, to, getFromQuery(query, 'assume'))
      await fetchStats(uuid, from, to, getFromQuery(query, 'assume'))
      setIsSubmitting(false)
      closeAddUserReactionDialog()
    } catch (error) {
      onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const openDetailsDialog = () => {
    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
    setShowDetailsDialog(true)
  }

  const closeDetailsDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowDetailsDialog(false)
  }

  const detailsDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tBulkActions('package_details_dialog_title')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeDetailsDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <div>
          <BulkActionDetails
            details={details}
          />
          </div>
      </div>
    )
  }

  const closeConfirmCancelDialog = () => {
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowCancelDialog(false)
  }
  
  const confirmCancelDialogContent = () => {
    return (
      <div
        style={{
          padding: '10px 10px 5px 10px',
          margin: '10px 10px 10px 10px'
        }}
      >
        <div style={{
          height: '30px'
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px'
          }}>
            {t('confirm_bulk_action_cancel_label')}
          </div>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          <LoadingButton 
            text={'Anuluj'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
                marginRight: '5px',
                flexGrow: 0
            }}
            onClick={() => {
              closeConfirmCancelDialog()
            }}
          />
          <LoadingButton 
            text={'Potwierdź'}
            loading={isSubmitting}
            style={{
              height: '35px',
              width: '100px',
              flexGrow: 0
            }}
            onClick={async () => {
              try {
                setIsSubmitting(true)
                await DataApi.cancelBulkAction(details.uuid)
                await fetchDetails(details.uuid)
                setIsSubmitting(false)
                closeConfirmCancelDialog()
              } catch (error) {
                setIsSubmitting(false)
                closeConfirmCancelDialog()
                onErrorResponseShowMessage(error)
              }
            }}
          />
        </div>
      </div>
    )
  }

  const addUserReactionDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {t('add_user_reaction_dialog_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {closeAddUserReactionDialog(true)}}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={addUserReactionFormRef}
          validationSchema={addUserReactionValidation(t)}
          enableReinitialize
          initialValues={{
            clientType: ClientType.UNDEFINED,
            message: '',
          }}
          onSubmit={handleAddUserReactionSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
            >
               <div className={classes.rowContainer}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '50%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_reaction_client_type_field_label')}
                  </p>
                  <CustomSelectField
                    instanceId={'role-field'}
                    name="clientType"
                    options={clientTypes.map(e => {
                      return {
                        label: e.name,
                        value: e.type
                      }
                    })}
                    placeholder={t('add_user_reaction_client_type_placeholder')}
                    value={chosenUserReactionClientType}
                    setValue={setChosenUserReactionClientType}
                    formikProps={formikProps}
                    error={
                      formikProps.touched.clientType &&
                      Boolean(formikProps.errors.clientType)
                    }
                  />
                </div>
              </div>
              <div className={classes.rowContainer} style={{
                marginBottom: '15px'
              }}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '100%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {t('add_user_reaction_message_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={240}
                    rows={5}
                    name="message" 
                    placeholder={t('add_user_reaction_message_placeholder')}
                    formikProps={formikProps}
                    value={chosenUserReactionMessage}
                    setValue={setChosenUserReactionMessage}
                    error={
                      formikProps.touched.message &&
                      Boolean(formikProps.errors.message)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={t('save_button_label')}
                  type='submit'
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <>
      <ReactTooltip 
        multiline
        effect={'solid'} 
        textColor={'black'} 
        backgroundColor={'white'}
        arrowColor={'#ffffff00'}
        className={classes.tooltip}
      />
                
      <CustomDialog hidden={!showDetailsDialog}>
        {detailsDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          maxWidth: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showCancelDialog}
      >
        {confirmCancelDialogContent()}
      </CustomDialog>

      <CustomDialog 
        style={{
          position: 'fixed',
          background: 'white',
          width: '650px',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        hidden={!showAddUserReactionDialog}
      >
        {addUserReactionDialogContent()}
      </CustomDialog>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'none' : 'block'
      }}>
        {
          assumedIdentity
          ? <div className={classes.assumedUserInfo}>
              <div
                style={{
                  marginRight: '10px'
                }}
              >
                {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
              </div>
              <CustomButton 
                textComponent={
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '-1px',
                    fontSize: '18px'
                  }}>
                    <OpenInNewOutlined fontSize={'inherit'}/>
                    <span style={{
                      marginLeft: '3px',
                      marginTop: '2px',
                      fontSize: '12px',
                      fontFamily: 'Avenir'
                    }}>{t('close_user_assume_view_button_label')}</span>
                  </div>
                }
                style={{
                  width: '200px',
                  maxWidth: '200px',
                  height: '35px',
                  paddingLeft: '10px',
                  paddingRight: '15px',
                  paddingTop: '7px'
                }}
                onClick={() => {
                  window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                }}
              />
            </div>
          : null
        }
        { 
          details 
          ? <div className={classes.topContainer}>
              <div style={{
                fontFamily: 'Avenir',
                marginRight: '15px',
                marginTop: '6px',
                fontSize: '16px',
                wordWrap: 'break-word',
                overflow: 'auto'
              }}>
                <span style={{
                  textTransform: 'uppercase'
                }}>
                  {`${details?.name} `}
                </span>
                <span>{isChildAccount ? '' : `(${details?.user.email})`}</span>
              </div>
              <div className={classes.topButtonsContainer}>
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-2px',
                      fontSize: '15px'
                    }}>
                      Szczegóły
                    </div>
                  }
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '30px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px'
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowDetailsDialog(true)
                  }}
                />
                {
                  // assumedIdentity || details?.user?.parent || details.status !== BulkActionStatus.WAITING
                  assumedIdentity || details.status >= BulkActionStatus.SCHEDULED
                  ? null
                  : <CustomButton 
                      textComponent={
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '-4px',
                          fontSize: '15px'
                        }}>
                          <DeleteOutlined fontSize="inherit" />
                          <span style={{
                            paddingTop: '2px',
                            fontSize: '15px',
                            fontFamily: 'Avenir'
                          }}>
                            {tBulkActions('cancel_package_button_label')}
                          </span>
                        </div>
                      }
                      style={{
                        width: '200px',
                        maxWidth: '200px',
                        height: '30px',
                        marginLeft: '5px',
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        paddingTop: '7px',
                        backgroundColor: Colors.RED_BUTTON_COLOR
                      }}
                      onClick={() => {
                        dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                        setShowCancelDialog(true)
                      }}
                    />
                }
              </div>
            </div> 
          : null 
        }

        <div className={classes.controlRow}>
          <div className={classes.dateRangeField}>
            <div className={classes.dateRangeLabel}>
              {t('date_range_label')}
            </div>
            <div 
              className={classes.clearButton} 
              onClick={() => {
                setSelectedDateRange(null)
              }}
            >
              {t('clear_field_button_label')}
            </div>
            <Flatpickr
              id={"date-range-picker"}
              data-enable-time
              value={
                selectedDateRange && selectedDateRange.length >= 2
                ? [
                  moment(selectedDateRange[0]).startOf("day").toDate(),
                  moment(selectedDateRange[1]).startOf("day").toDate(),
                ]
                : []
              }
              options={{
                locale: {
                  firstDayOfWeek: 1,
                  rangeSeparator: ' - ',
                  months: {
                    shorthand: monthList,
                    longhand: monthList
                  },
                  weekdays: {
                    shorthand: dayListShorthand,
                    longhand: dayList
                  }
                },
                dateFormat: "d.m.Y",
                mode: 'range',
              }}
              onClose={(date) => {
                if (date && date.length === 2) {
                  setSelectedDateRange(date)
                }
              }}
              render={({ defaultValue, value, ...props }, ref) => {
                return (
                  <CustomFlatpickrInput
                    defaultValue={defaultValue}
                    inputRef={ref}
                    width={'100%'}
                  />
                )
              }}
            />
          </div>
          
        </div>

        <div className={classes.statTileSection}>
          <div className={classes.msgSentTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F6AE3F'}
              label={'Wysłane wiadomości'}
              iconSrc={sendIcon}
              value={`${stats.count}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#F64A3F'}
              label={'Odpowiedzi'}
              iconSrc={dialogIcon}
              value={`${stats.responses}`}
            />
          </div>

          <div className={classes.responsesCountTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#438737'}
              label={'Odpowiedzi agenta'}
              iconSrc={formIcon}
              value={`${stats.reactions}`}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>

          <div className={classes.emptySpaceTile}>
            <StatTile
              textColor={'#FFFFFF'}
              backgroundColor={'#FFFFFF'}
              label={''}
              iconSrc={''}
              value={''}
            />
          </div>        
          
        </div>

        <div style={{
          width: '100%'
        }}>
          <CustomTable 
            columnData={tableColumnData}
            content={tableData}
            sort={true}
            sortData={sortData}
            sortSetter={setSortData}
            reloadingData={isReloadingData}
            placeholder={
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  fontFamily: 'AvenirMedium',
                  color: Colors.TABLE_HEADER_LABEL,
                  fontSize: '16px'
                }}
              >
                <div>
                  {t('no_data_in_table_placeholder')}
                </div>
              </div>
              }
          />
          <div style={{
            display: pages <= 1 ? 'none' : 'flex',
            justifyContent: 'center'
          }}>
            <div>
              <Pagination 
                pages={pages}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BulkActionsDetailsPage
