export enum MessageEnum  {
  SOMETHING_WENT_WRONG = 'something_went_wrong_message',
  USER_ALREADY_EXISTS = 'user_already_exist_message',
  TOKEN_INVALID = 'invalid_token_message',
  TOKEN_ALREADY_USED = 'token_already_used_message',
  INACTIVE_USER = 'user_is_inactive_message',
  ACCOUNT_REMOVED = 'account_removed_message',
  INVALID_CREDENTIALS = 'invalid_credentials_message',
  INCORRECT_PASSWORD = 'incorrect_password_message',
  ACCOUNT_HAS_BEEN_ACTIVATED = 'account_has_been_activated_message',
  USER_DATA_HAS_BEEN_CHANGED = 'user_data_has_been_changed_message',
  EMAIL_VERIFICATION_MESSAGE_HAS_BEEN_SEND = 'email_verification_message_has_been_send_message',
  EMAIL_CHANGE_VERIFICATION_MESSAGE_HAS_BEEN_SEND = 'email_change_verification_message_has_been_send_message',
  EMAIL_HAS_BEEN_VERIFIED = 'email_has_been_verified_message',
  PASSWORD_HAS_BEEN_CHANGED = 'password_has_been_changed_message',
  PASSWORD_RESET_HAS_BEEN_REQUESTED = 'user_reset_password_message',
  ACTIVATION_EMAIL_RESEND_HAS_BEEN_REQUESTED = 'user_resend_activation_email_message',
  ENABLE_PAGE_COVER = 'enable_page_cover_message',
  DISABLE_PAGE_COVER = 'disable_page_cover_message',
  ENABLE_DIALOG_COVER = 'enable_dialog_cover_message',
  DISABLE_DIALOG_COVER = 'disable_dialog_cover_message',
  UNAUTHORIZED = 'unauthorized_message',
  LOST_ACCESS = 'lost_access_message',
  NAME_ALREADY_EXISTS = 'name_already_exists_message',
  SEARCH_PARAMS_LIMIT_EXCEEDED = 'search_params_limit_exceeded_message',
  SMS_TEMPLATE_NOT_FOUND = 'sms_template_not_found_message',
  REGION_NOT_FOUND = 'region_not_found_message',
  SUBREGION_NOT_FOUND = 'subregion_not_found_message',
  TOWN_NOT_FOUND = 'town_not_found_message',
  DISTRICT_NOT_FOUND = 'district_not_found_message',
  PHONE_EXISTS_ON_BLACKLIST = 'phone_exists_on_blacklist_message',
  PHONE_ADDED_TO_BLACKLIST = 'phone_added_to_blacklist_message',
  EMAIL_TAKEN = 'email_taken_blacklist',
  CONFIG_HAS_BEEN_UPDATED = 'config_has_been_updated_message',
  NOT_ALLOWED_LOCATION = 'not_allowed_location_message',
  RESTRICTED_TO_TOWNS = 'restricted_to_towns_message',
  SAME_PWD_AS_OLD_ONE = 'same_pwd_as_old_one_message',
  API_MESSAGE_NOT_FOUND = 'api_message_not_found_message',
  API_MESSAGE_NOT_BELONG_TO_PARAM_FOUND = 'api_message_not_belong_to_param_found_message',
  REACTION_ALREADY_EXISTS = 'reaction_already_exists_message',
  FILE_SIZE_TOO_BIG = 'file_size_too_big_message',
  FILE_TYPE_INCORRECT = 'file_type_incorrect_message',
  ELEMENT_NOT_FOUND = 'element_not_found_message',
  BULK_ACTION_CANCELLED = 'bulk_action_cancelled_message',
  BULK_ACTION_CANCELLED_ON_ACCEPT = 'bulk_action_cancelled_on_accept_message',
  BULK_ACTION_CANCELLED_ON_REJECT = 'bulk_action_cancelled_on_reject_message',
  BULK_ACTION_ALREADY_ACCEPTED = 'bulk_action_already_accepted_message',
  BULK_ACTION_ALREADY_REJECTED = 'bulk_action_already_rejected_message'
}