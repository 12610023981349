import { CircularProgress } from '@material-ui/core'
import {
  Add as AddIcon,
  Close as CloseIcon,
  OpenInNewOutlined
} from "@material-ui/icons"
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useRef } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import AuthRefreshApi from '../../api/AuthRefreshApi'
import DataApi from '../../api/DataApi'
import { addCmdMessage } from '../../redux/messages/actions'
import { PermissionLevel } from '../../types/auth'
import { Colors } from '../../types/colors'
import { checkIfAdmin, getFromQuery, useQuery } from '../../utils/common'
import { onErrorResponseShowMessage } from '../../utils/onErrorReponseShowMessage/onErrorResponseShowMessage'
import { sortRowData } from '../../utils/sort'
import { addChildAccountValidation } from '../../utils/validations/addChildAccountValidation'
import BaseInput from '../common/BaseInput/BaseInput'
import CustomButton from '../common/CustomButton/CustomButton'
import LoadingButton from '../common/LoadingButton/LoadingButton'
import Pagination from '../common/Pagination/Pagination'
import CustomDialog from '../CustomDialog/CustomDialog'
import CustomTable, { ColumnData } from '../CustomTable/CustomTable'
import { SortData } from '../CustomTable/util'
import { MessageEnum } from '../Messages/types'
import { myAgencyStyle } from './myAgencyStyle'
import { useMyAgency } from './useMyAgency'
import { State } from '../../redux/reducers/index'

const usersColumnData: ColumnData[] = [
  {
    label: 'Lp.',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '15px'
    },
    width: '5%'
  },{
    label: 'Data\u00A0i\u00A0godzina dodania',
    name: 'createdAt',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '15%'
  }, {
    label: 'Imię\u00A0i\u00A0nazwisko',
    name: 'name',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '20%'
  }, {
    label: 'Adres\u00A0e\u2011mail',
    name: 'email',
    sortable: true,
    headerAlign: 'left',
    contentAlign: 'left',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '20%'
  }, {
    label: 'Aktywne eventy',
    name: 'active_search_params_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Wysłane SMSy',
    name: 'won_bid_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Liczba odpowiedzi',
    name: 'responses_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Konwersja',
    name: 'conversion',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Odpowiedzi agenta',
    name: 'user_reaction_count',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '5px',
      right: '5px'
    },
    width: '5%'
  }, {
    label: 'Status',
    name: 'status',
    sortable: true,
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      left: '10px',
      right: '10px'
    },
    width: '10%'
  }, {
    label: '',
    headerAlign: 'center',
    contentAlign: 'center',
    contentPadding: {
      right: '10px'
    },
    width: '5%'
  }
]

const ELEM_PER_PAGE = 20

const MyAgency = () => {
  const classes = myAgencyStyle()
  const tForms = useTranslation('forms').t
  const tMyAgency = useTranslation('myAgency').t
  const dispatch = useDispatch()
  const query = useQuery()
  const permissions = useSelector((e: State) => e.permissions)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isReloadingUserData, setIsReloadingUserData] = React.useState<boolean>(true)
  const [assumedIdentity, setAssumedIdentity] = React.useState<boolean>(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)

  const [selectedUsersPage, setSelectedUsersPage] = React.useState<number>(1)
  const [usersPages, setUsersPages] = React.useState<number>(1)

  const [usersTableData, setUsersTableData] = React.useState<any[]>([])
  const [usersSortData, setUsersSortData] = React.useState<SortData | null>(null)

  // add user
  const [chosenName, setChosenName] = React.useState<string>('')
  const [chosenEmail, setChosenEmail] = React.useState<string>('')

  // dialog controls
  const [showAddUserDialog, setShowAddUserDialog] = React.useState<boolean>(false)

  // dialog refs
  const formAddUserRef = useRef() as any
    
  const { 
    usersList, usersCount, assumedUser,
		fetchUsersList, fetchUsersCount, fetchAssumedUser
  } = useMyAgency()

  React.useEffect(() => {
    if (usersSortData && usersSortData.default) {
      setIsReloadingUserData(true)
      fetchUsersCount(getFromQuery(query, 'assume')).then(res => {
        fetchUsersList(ELEM_PER_PAGE, 0, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
          setSelectedUsersPage(1)
          setIsReloadingUserData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (usersSortData && isNaN(parseInt(usersSortData.column))) {
      setIsReloadingUserData(true)
      fetchUsersCount(getFromQuery(query, 'assume')).then(res => {
        fetchUsersList(ELEM_PER_PAGE, 0, usersSortData.column, usersSortData.order, getFromQuery(query, 'assume')).then(res => {
          setSelectedUsersPage(1)
          setIsReloadingUserData(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (usersSortData && !isNaN(parseInt(usersSortData.column))) {
      const tmpData = [...usersTableData]
      sortRowData(tmpData, usersSortData)
      setUsersTableData([...tmpData])
    }
  }, [usersSortData])

  React.useEffect(() => {
    fetchAssumedUser(getFromQuery(query, 'assume')).then(res => {
      fetchUsersCount(getFromQuery(query, 'assume')).then(res => {
        fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE, undefined, undefined, getFromQuery(query, 'assume')).then(res => {
          setIsLoading(false)
        }).catch(error => { onErrorResponseShowMessage(error)})
      }).catch(error => { onErrorResponseShowMessage(error)})
    }).catch(error => { onErrorResponseShowMessage(error)})
  }, [])

  React.useEffect(() => {
    if (usersCount > 0) {
      setUsersPages(Math.ceil(usersCount / ELEM_PER_PAGE))
    } else {
      setUsersPages(1)
    }
  }, [usersCount])

  React.useEffect(() => {
    if (!isReloadingUserData && selectedUsersPage > 0) {
      const sortName = usersSortData?.column
      const sortDirection = usersSortData?.order
      setIsReloadingUserData(true)
      fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE, sortName, sortDirection, getFromQuery(query, 'assume')).then(res => {
        setIsReloadingUserData(false)
      }).catch(error => { onErrorResponseShowMessage(error)})
    } else if (isReloadingUserData && selectedUsersPage === 1) {
      setIsReloadingUserData(false)
    }
  }, [selectedUsersPage])

  React.useEffect(() => {
    const mapped = usersList.map((e, idx) => {
      return {
        active: e.active,
        rawData: [
          idx + 1,
          moment(e.createdAt).unix(),
          e.name,
          e.email,
          e.eventCount,
          e.wonBidCount,
          e.responseCount,
          e.responseCount !== 0 ? e.responseCount/e.wonBidCount : 0,
          e.userReactionCount,
          e.active,
          '-'
        ],
        columns: [
          `${(selectedUsersPage - 1) * ELEM_PER_PAGE + (idx + 1)}.`,
          moment(e.createdAt).format('DD.MM.YYYY, HH:mm:ss'),
          e.name,
          e.email,
          e.eventCount,
          e.wonBidCount,
          e.responseCount,
          `${e.responseCount !== 0 ? `${(e.responseCount/e.wonBidCount*100).toFixed(2)}%` : '0,00%'}`,
          e.userReactionCount,
          e.active
          ? (
            !e.emailVerified
            ? <span style={{ color: Colors.WAITING_FOR_ACTIVATION_LABEL, wordBreak: 'keep-all' }}>{tMyAgency('waiting_for_activation_label')}</span>
            : <span style={{ color: Colors.ACTIVE_LABEL, wordBreak: 'keep-all' }}>{tMyAgency('active_label')}</span>
          )
          : <span style={{ color: Colors.INACTIVE_LABEL, wordBreak: 'keep-all' }}>{tMyAgency('inactive_label')}</span>,
          <Link
            to={`/agency/user/${e.uuid}${assumedIdentity ? `?assume=${getFromQuery(query, 'assume')}` : ''}`} 
            className={classes.manageLink}
          >
            {tForms('manage_label')}
          </Link>
        ]
      }
    })
    setUsersTableData(mapped)
  }, [usersList])

  React.useEffect(() => {
    setAssumedIdentity(getFromQuery(query, 'assume') != null && checkIfAdmin(permissions.permissions) ? true : false)
  }, [permissions])

  const resetAddUserFormState = () => {
    setChosenName('')
    setChosenEmail('')
    if (formAddUserRef.current) {
      formAddUserRef.current.resetForm()
    }
  }

  const handleAddUserSubmit = async (data: any, form: any) => { 
    try {
      setIsSubmitting(true)
      await DataApi.createChildAccount({
        name: data.name.trim(),
        email: data.email.trim(),
      })
      await fetchUsersList(ELEM_PER_PAGE, (selectedUsersPage - 1) * ELEM_PER_PAGE)
      await fetchUsersCount()
      resetAddUserFormState()
      setIsSubmitting(false)
      closeAddUserDialog()
    } catch (error) {
			onErrorResponseShowMessage(error)
      setIsSubmitting(false)
    }
  }

  const closeAddUserDialog = () => {
    resetAddUserFormState()
    dispatch(addCmdMessage(MessageEnum.DISABLE_PAGE_COVER))
    setShowAddUserDialog(false)
  }

  const addUserDialogContent = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        <div style={{
          height: '62px',
        }}>
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '22px',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            {tForms('add_new_child_account_section_label')}
          </div>
          <div style={{
            float: 'right',
            fontSize: '25px',
            marginTop: '13px'
          }}>
            <span 
              style={{
                cursor: 'pointer'
              }}
              onClick={closeAddUserDialog}
            >
              <CloseIcon fontSize={'inherit'}/>
            </span>
          </div>
        </div>
        <Formik
          innerRef={formAddUserRef}
          validationSchema={addChildAccountValidation(tMyAgency)}
          enableReinitialize
          initialValues={{
            name: '',
            email: '' 
          }}
          onSubmit={handleAddUserSubmit}
        >
          {(formikProps) => (
            <Form
              onKeyDown={(keyEvent) => {
                if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                  keyEvent.preventDefault()
                }
              }}
            >
              <div className={classes.rowContainer} style={{
                marginBottom: '15px'
              }}>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%',
                    paddingRight: '1%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_form_name_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={120}
                    name="name" 
                    placeholder={tForms('add_user_form_name_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenName}
                    setValue={setChosenName}
                    error={
                      formikProps.touched.name &&
                      Boolean(formikProps.errors.name)
                    }
                  />
                </div>
                <div 
                  className={classes.fieldWithLabel}
                  style={{
                    width: '49.5%'
                  }}
                >
                  <p className={classes.fieldLabel}>
                    {tForms('add_user_form_email_field_label')}
                  </p>
                  <BaseInput 
                    count
                    maxLen={120}
                    name="email" 
                    placeholder={tForms('add_user_form_email_field_placeholder')}
                    formikProps={formikProps}
                    value={chosenEmail}
                    setValue={setChosenEmail}
                    error={
                      formikProps.touched.email &&
                      Boolean(formikProps.errors.email)
                    }
                  />
                </div>
              </div>
              <div style={{
                float: 'right',
                marginBottom: '30px'
              }}>
                <LoadingButton 
                  text={tForms('save_button_label')}
                  type='submit'
                  // disabled={!formikProps.isValid || !formikProps.dirty}
                  loading={isSubmitting}
                  style={{
                    width: '150px',
                    maxWidth: '150px',
                    height: '40px'
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <>
      <CustomDialog 
        hidden={!showAddUserDialog} 
        style={{
          position: 'absolute',
          background: 'white',
          width: '80%',
          minHeight: '100px',
          border: '1px solid #979797',
          borderRadius: '1px',
          marginLeft: '10%',
          marginRight: '10%',
          marginTop: '10%'
        }}
      >
        {addUserDialogContent()}
      </CustomDialog>
      
      <div style={{
        width: '100%',
        height: '100%',
        padding: '15px',
        position: 'relative',
        backgroundColor: '#fff',
        display: isLoading ? 'block' : 'none'
      }}>
        <div style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress size={48}/>
        </div>
      </div>
      <div style={{
        padding: '15px 20px',
        display: isLoading ? 'none' : 'block'
      }}>
        <div style={{
          height: '62px',
        }}>
          {
          assumedIdentity 
            ? <div className={classes.assumedUserInfo}>
                <div
                  style={{
                    marginRight: '10px'
                  }}
                >
                  {`Użytkownik: ${assumedUser?.name} (${assumedUser?.email})`}
                </div>
                <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-1px',
                      fontSize: '18px'
                    }}>
                      <OpenInNewOutlined fontSize={'inherit'}/>
                      <span style={{
                        marginLeft: '3px',
                        marginTop: '2px',
                        fontSize: '12px',
                        fontFamily: 'Avenir'
                      }}>{tForms('close_user_assume_view_button_label')}</span>
                    </div>
                  }
                  style={{
                    width: '200px',
                    maxWidth: '200px',
                    height: '35px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px'
                  }}
                  onClick={() => {
                    window.location.href = `/admin/user/${getFromQuery(query, 'assume')}`
                  }}
                />
              </div>
            : null
          }
          <div style={{
            float: 'left',
            textTransform: 'uppercase',
            fontSize: '16px',
            fontFamily: 'AvenirMedium',
            letterSpacing: '1.49px',
            lineHeight: '19px',
            marginTop: '20px',
            marginBottom: '20px',
            marginRight: '15px',
          }}>
            {tForms('my_agency_label')}
          </div>
        </div>
        <div>
          <div>
            {
              assumedIdentity ? null 
              : <CustomButton 
                  textComponent={
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-1px'
                    }}>
                      <AddIcon />
                      <span style={{
                        marginTop: '1px'
                      }}>
                        {tForms('add_new_child_account_button_label')}
                      </span>
                    </div>
                  }
                  style={{
                    maxWidth: '200px',
                    height: '35px',
                    paddingLeft: '10px',
                    paddingRight: '15px',
                    paddingTop: '7px',
                    marginBottom: '20px'
                  }}
                  onClick={() => {
                    dispatch(addCmdMessage(MessageEnum.ENABLE_PAGE_COVER))
                    setShowAddUserDialog(true)
                  }}
                />
            }
            
            <CustomTable 
              columnData={usersColumnData}
              content={usersTableData}
              sort={true}
              headerFontSize={'12px'}
              contentFontSize={'12px'}
              sortData={usersSortData}
              sortSetter={setUsersSortData}
              reloadingData={isReloadingUserData}
              placeholder={
                <div 
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    fontFamily: 'AvenirMedium',
                    color: Colors.TABLE_HEADER_LABEL,
                    fontSize: '16px'
                  }}
                >
                  <div>
                    {tForms('no_data_in_table_placeholder')}
                  </div>
                </div>
              }
            />
            <div style={{
              display: usersPages <= 1 ? 'none' : 'flex',
              justifyContent: 'center'
            }}>
              <div>
                <Pagination 
                  pages={usersPages}
                  selectedPage={selectedUsersPage}
                  setSelectedPage={setSelectedUsersPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default MyAgency